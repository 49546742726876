export default class FlickerCode {
    constructor(newCode) {
        this.code = newCode.toUpperCase().replace(/[^a-fA-F0-9]/g, '');
        this.checksum();
    }

    toHex = (n, minlen) => {
        let s = n.toString(16).toUpperCase();
        while (s.length < minlen) {
            s = '0' + s;
        }
        return s;
    };

    quersumme = n => {
        let q = 0;
        while (n != 0) {
            q += n % 10;
            n = Math.floor(n / 10);
        }
        return q;
    };

    getPayload = () => {
        let i = 0;
        let payload = '';

        let len = parseInt(this.code.slice(0, 2), 16);
        i += 2;
        while (i < this.code.length - 2) {
            /* skip bcd identifier */
            i += 1;
            /* parse length */
            len = parseInt(this.code.slice(i, i + 1), 16);
            i += 1;
            payload += this.code.slice(i, i + len * 2);
            i += len * 2;
        }
        return payload;
    };

    checksum = () => {
        /* length check: first byte */
        const len = this.code.length / 2 - 1;
        this.code = this.toHex(len, 2) + this.code.substr(2);

        /* luhn checksum */
        const luhndata = this.getPayload();
        let luhnsum = 0;
        for (let i = 0; i < luhndata.length; i += 2) {
            luhnsum +=
                1 * parseInt(luhndata[i], 16) +
                this.quersumme(2 * parseInt(luhndata[i + 1], 16));
        }
        luhnsum = (10 - (luhnsum % 10)) % 10;
        this.code =
            this.code.substr(0, this.code.length - 2) +
            this.toHex(luhnsum, 1) +
            this.code.substr(this.code.length - 1);

        /* xor checksum */
        let xorsum = 0;
        for (let i = 0; i < this.code.length - 2; i++) {
            xorsum ^= parseInt(this.code[i], 16);
        }
        this.code =
            this.code.substr(0, this.code.length - 1) + this.toHex(xorsum, 1);
    };

    getCode = () => {
        return this.code;
    };
}
