export const updateCustomization = customization => {
    const colors = customization?.colors;
    if (colors) {
        if (!colors['color-text']) {
            colors['color-text'] = colors['color-primary'];
        }
        if (!colors['color-spinner']) {
            colors['color-spinner'] = colors['color-primary'];
        }
        if (!colors['color-link']) {
            colors['color-link'] = colors['color-primary'];
        }
        if (!colors['color-splash-header']) {
            colors['color-splash-header'] = colors['color-primary'];
        }
    }
    return customization;
};

export default updateCustomization;
