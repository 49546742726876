import React from 'react';
import PropTypes from 'prop-types';
import style from '../../../css/TokenTitle.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

const TokenTitle = ({className, children, sub, backArrow, arrowColor = '#05A5F0', ...props}) => {
    const classNames = cx({
        TokenTitle: !sub,
        TokenSubtitle: sub,
        'TokenTitle--with-back': backArrow,
        [className]: !!className,
    });
    return (
        <div className={classNames} role={sub ? undefined : 'heading'} {...props}>
            {backArrow &&
                <span className={cx('TokenTitle-backArrow')}>
                    <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 1L2 8.5 9.5 16M2 8.5h15"
                            stroke={arrowColor} strokeWidth="2" />
                    </svg>
                </span>
            || null}
            {children}
        </div>
    );
};

TokenTitle.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    sub: PropTypes.bool,
    backArrow: PropTypes.bool,
    onClick: PropTypes.func,
    arrowColor: PropTypes.string,
};

export default TokenTitle;
