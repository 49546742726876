import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import style from './Consent.css';
import CopyIcon from '../Icons/CopyIcon.js';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import ChangeButton from '../Pisp/Button.js';
import {
    backToBankSelector,
    proceedToBankForVrp,
    declineTerms,
} from 'actions/vrp';
import { setBank } from 'actions/shared';
import {
    copyOnClipboard,
    hideConsentText,
    formatCurrency,
    convertToReadableDate,
    sanitizeHTML,
} from 'util/index';
import { FormattedMessage, intlShape } from 'react-intl';
import TermsLoader from '../Terms/TermsLoader';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    CUSTOM_TPP_FEATURES as featureConfig,
    DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
    defaultAppName,
} from 'config/constants';
import HrefToPopup from '../Shared/HrefToPopup.js';

const cx = classNames.bind(style);

class VrpConsentBankFirstFlow extends Component {
    constructor(props) {
        super(props);
        this.contentRef = createRef();
        this.consentTextRef = createRef();
    }

    displayUserInfo(userInfo, userType) {
        const bankIbanOrAccNo = this.props.isFasterPayment
            ? userInfo?.accountNumber
            : userInfo?.iban;
        const bankBicOrSc = this.props.isFasterPayment
            ? userInfo?.sortCode
            : userInfo?.bic;
        return (
            <>
                {userInfo?.name && (
                    <div className={cx('Consent-data-row')}>
                        <div className={cx('Consent-data-row-title')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={`vrp.consent.${userType}.legalName`}
                            />
                        </div>
                        <div className={cx('Consent-data-row-detail')}>
                            <strong className={cx('Colored-Text')}>
                                {userInfo.name}
                            </strong>
                        </div>
                    </div>
                )}
                {bankIbanOrAccNo && (
                    <div className={cx('Consent-data-row')}>
                        <div className={cx('Consent-data-row-title')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={
                                    this.props.isFasterPayment
                                        ? `vrp.consent.${userType}.accountNumber`
                                        : `vrp.consent.${userType}.iban`
                                }
                            />
                        </div>
                        <div className={cx('Consent-data-row-detail')}>
                            <strong className={cx('Colored-Text')}>
                                {bankIbanOrAccNo}
                            </strong>
                        </div>
                    </div>
                )}
                {bankBicOrSc && (
                    <div className={cx('Consent-data-row')}>
                        <div className={cx('Consent-data-row-title')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={
                                    this.props.isFasterPayment
                                        ? `vrp.consent.${userType}.sortCode`
                                        : `vrp.consent.${userType}.bic`
                                }
                            />
                        </div>
                        <div className={cx('Consent-data-row-detail')}>
                            <strong className={cx('Colored-Text')}>
                                {bankBicOrSc}
                            </strong>
                        </div>
                    </div>
                )}
            </>
        );
    }

    render() {
        const {
            declineTerms,
            pispName,
            pispAlias,
            maxAmountPerPayment,
            businessName,
            backToBankSelector,
            refId,
            defaultBankId,
            description,
            modifyPreSelectedBank,
            proceedToBankForVrp,
            periodicLimits,
            debtorInfo,
            creditorInfo,
            selectedBankName,
            creditorBankName,
            expiration,
        } = this.props;
        const expireDate = new Date();
        expireDate.setDate(
            expireDate.getDate() + DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
        );

        const messageVariableValues = {
            BUSINESS_NAME: `<strong>${businessName}</strong>`,
            DISPLAY_NAME: pispName ? pispName : pispAlias,
            EXPIRATION_DATE: `<strong>${convertToReadableDate(
                expireDate,
            )}</strong>`,
        };

        const consentText = this.props.intl.formatMessage(
            {
                id: 'vrp.consent.text',
                defaultMessage: ' ',
            },
            messageVariableValues,
        );

        const hideConsentTextBlock = hideConsentText(consentText);

        return (
            <Fragment>
                <TokenContainer
                    className={cx('Pisp-Consent-subcontainer')}
                    innerRef={this.contentRef}
                    noMargin>
                    <div className="TokenContainer-margin">
                        <TokenTitle>
                            <FormattedMessage id={'vrp.consent.title'} />
                        </TokenTitle>
                        <TokenTitle sub>
                            <FormattedMessage
                                id={'vrp.consent.subtitle1'}
                                tagName={'p'}
                            />
                        </TokenTitle>
                    </div>
                    <div className={cx('Consent-data')}>
                        <TokenTitle sub>
                            {periodicLimits &&
                                periodicLimits.map((periodicLimit, index) => (
                                    <div
                                        key={index}
                                        className={cx('Consent-data-row')}>
                                        <div
                                            className={cx(
                                                'Consent-data-row-title',
                                            )}>
                                            <FormattedMessage
                                                tagName={'span'}
                                                id={'vrp.consent.maxAmountPer'}
                                                values={{
                                                    PERIOD_TYPE:
                                                        periodicLimit.periodType,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={cx(
                                                'Consent-data-row-detail',
                                            )}>
                                            <span
                                                className={cx('Colored-Text')}>
                                                {periodicLimit.maximumAmount}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.maxAmountPerPayment'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('Colored-Text')}>
                                        {maxAmountPerPayment}
                                    </span>
                                </div>
                            </div>
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.refId'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('RefID')}>
                                        <strong id="refId">{refId} </strong>
                                        <CopyIcon
                                            className={cx('Consent-CopyRefId')}
                                            ariaLabel={this.props.intl.formatMessage(
                                                {
                                                    id: 'vrp.consent.copyRefId',
                                                },
                                            )}
                                            onKeyPress={() =>
                                                copyOnClipboard(refId)
                                            }
                                            onClick={() =>
                                                copyOnClipboard(refId)
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                            {description && (
                                <div className={cx('Consent-data-row')}>
                                    <div
                                        className={cx(
                                            'Consent-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'vrp.consent.description'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'Consent-data-row-detail',
                                        )}>
                                        <strong className={cx('Description')}>
                                            {description}
                                        </strong>
                                    </div>
                                </div>
                            )}
                            {creditorBankName && (
                                <div className={cx('Consent-data-row')}>
                                    <div
                                        className={cx(
                                            'Consent-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'vrp.consent.payee.bankName'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'Consent-data-row-detail',
                                        )}>
                                        <strong className={cx('Colored-Text')}>
                                            {creditorBankName}
                                        </strong>
                                    </div>
                                </div>
                            )}
                            {this.displayUserInfo(creditorInfo, 'payee')}
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.payer.bankName'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('Consent-BankName')}>
                                        <strong>{selectedBankName}</strong>
                                    </span>
                                    {(defaultBankId === '' ||
                                        modifyPreSelectedBank) && (
                                        <ChangeButton
                                            ariaLabel={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'pisp.consent.changeBank.ariaLabel',
                                                },
                                            )}
                                            onClick={() => backToBankSelector()}
                                            className={cx('ChangeBank')}
                                            text={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'pisp.consent.changeBank.label',
                                                },
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            {debtorInfo &&
                                this.displayUserInfo(debtorInfo, 'payer')}
                            {expiration && (
                                <div className={cx('Consent-data-row')}>
                                    <div
                                        className={cx(
                                            'Consent-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'vrp.consent.expiration'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'Consent-data-row-detail',
                                        )}>
                                        <span className={cx('Colored-Text')}>
                                            {expiration}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </TokenTitle>
                    </div>
                    {!hideConsentTextBlock && (
                        <HrefToPopup
                            contentRef={this.consentTextRef}
                            fallbackToOverlay>
                            <div
                                className={cx({
                                    'Consent-details': true,
                                })}
                                ref={this.consentTextRef}
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(consentText),
                                }}
                            />
                        </HrefToPopup>
                    )}
                    <TermsLoader />
                </TokenContainer>

                <TokenButtonsWrapper>
                    <Button
                        status={Button.STATUS_IDLE}
                        onClick={proceedToBankForVrp}
                        scrollRef={this.contentRef}
                        text={<FormattedMessage id={'common.button.accept'} />}
                    />
                    {defaultBankId !== '' || modifyPreSelectedBank ? (
                        <Button
                            type={Button.TYPE_GHOST_WARNING}
                            onClick={declineTerms}
                            text={
                                <FormattedMessage
                                    id={'common.button.decline'}
                                />
                            }
                        />
                    ) : (
                        <Button
                            type={Button.TYPE_GHOST}
                            onClick={backToBankSelector}
                            status={Button.STATUS_IDLE}
                            text={
                                <FormattedMessage id={'common.button.back'} />
                            }
                        />
                    )}
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

VrpConsentBankFirstFlow.propTypes = {
    declineTerms: PropTypes.func.isRequired,
    pispName: PropTypes.string.isRequired,
    pispAlias: PropTypes.string.isRequired,
    maxAmountPerPayment: PropTypes.string,
    intl: intlShape.isRequired,
    businessName: PropTypes.string.isRequired,
    backToBankSelector: PropTypes.func.isRequired,
    proceedToBankForVrp: PropTypes.func.isRequired,
    selectedBank: PropTypes.object,
    refId: PropTypes.string.isRequired,
    setBank: PropTypes.func,
    defaultBankId: PropTypes.string,
    vrpRequestId: PropTypes.string.isRequired,
    description: PropTypes.string,
    modifyPreSelectedBank: PropTypes.bool,
    setDisplayFooterShadow: PropTypes.func,
    periodicLimits: PropTypes.array,
    isFasterPayment: PropTypes.bool,
    creditorInfo: PropTypes.object,
    debtorInfo: PropTypes.object,
    selectedBankName: PropTypes.string,
    creditorBankName: PropTypes.string,
    expiration: PropTypes.string,
};

const mapStateToProps = (
    { tokenRequestService, sharedService, vrpService },
    ownProps,
) => {
    const currency = vrpService.getVrpCurrency();
    const { tppName } = tokenRequestService.getTppProfile();
    const tppAlias = tokenRequestService.getTppAlias();
    const vrpRequestId = vrpService.getVrpRequestId();
    const maxAmountPerPayment = formatCurrency(
        vrpService.getMaxIndividualAmount(),
        currency,
        ownProps.intl.formatNumber,
    );
    const selectedBank = sharedService.getSelectedBank();
    const defaultBankId = tokenRequestService.getDefaultBankId() || '';
    const description = vrpService.getDescription();
    const modifyPreSelectedBank = tokenRequestService.getTppFeature(
        featureConfig.MODIFY_PRE_SELECTED_BANK,
        false,
    );
    const periodicLimits = vrpService
        .getPeriodicLimits()
        .map(periodicLimit => {
            return {
                ...periodicLimit,
                maximumAmount: formatCurrency(
                    periodicLimit.maximumAmount,
                    currency,
                    ownProps.intl.formatNumber,
                ),
            };
        });
    return {
        pispName: tppName,
        pispAlias: tppAlias?.value,
        businessName:
            tokenRequestService.getTppFeature(featureConfig.PIS_APP_NAME) ||
            defaultAppName,
        selectedBankName: sharedService.getSelectedBankName(),
        selectedBank,
        refId: vrpService.getVrpRefId(),
        defaultBankId,
        vrpRequestId,
        description,
        modifyPreSelectedBank,
        maxAmountPerPayment,
        periodicLimits,
        isFasterPayment: vrpService.getLocalInstrument() === 'FASTER_PAYMENTS',
        creditorInfo: vrpService.getCreditorInfo(),
        debtorInfo: vrpService.getDebtorInfo(),
        creditorBankName: vrpService.getCreditorBankName(),
        expiration: vrpService.getExpirationDate(),
    };
};

const mapDispatchToProps = {
    backToBankSelector,
    proceedToBankForVrp,
    setBank,
    declineTerms,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VrpConsentBankFirstFlow);
