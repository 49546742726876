import React from 'react';
import PropTypes from 'prop-types';
import style from './CrossIconSvg.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

const CrossIconSvg = ({ svgHeight, svgWidth, svgColor = '#FFFFFF' }) => {
    return (
        <div
            className={cx('TokenCross-icon')}
            style={{
                height: svgHeight + 'px',
                width: svgWidth + 'px',
            }}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_33_13877)">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill={svgColor}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_33_13877">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

CrossIconSvg.propTypes = {
    svgHeight: PropTypes.string,
    svgWidth: PropTypes.string,
    svgColor: PropTypes.string,
    scale: PropTypes.number,
};

export default CrossIconSvg;
