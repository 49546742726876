import { TppIntegrationClient } from 'actions/helpers/Api';
import { PATH_TO_VALIDATE_IBAN } from 'config/constants';

let timer = null;

export const handleCheckIban = ({
    iban,
    requestId,
    handleValidIban,
    handleInvalidIban,
    timeout,
}) => {
    // Clears running timer and starts a new one each time the user types
    if (timer) clearTimeout(timer);
    timer = setTimeout(async () => {
        let res;
        try {
            res = await TppIntegrationClient({
                method: 'put',
                url: PATH_TO_VALIDATE_IBAN,
                data: { iban, tokenRequestId: requestId },
            });
        } catch (error) {
            if (error.response?.status === 400) {
                // eslint-disable-next-line no-console
                console.error(
                    'IBAN validation failed with status',
                    error.response?.status,
                    error.response?.data,
                );
                handleInvalidIban();
            } else {
                handleValidIban();
            }
        }
        if (res) {
            if (res.status === 200) {
                handleValidIban();
            } else {
                // eslint-disable-next-line no-console
                console.error(
                    'IBAN validation failed with status',
                    res.status,
                    res.data,
                );
                handleInvalidIban();
            }
        }
    }, timeout);
};
