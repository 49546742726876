import * as shared from 'reducers/shared';
import * as user from 'reducers/user';
import * as tokenRequest from 'reducers/tokenRequest';
import * as token from 'reducers/token';
import * as vrp from 'reducers/vrp';
import { ReduxService } from '@token-io/lib-web-app';

const selectors = [shared, user, tokenRequest, token, vrp];
const reduxService = new ReduxService({ selectors });

const reducer = reduxService.getRootReducer();
const connect = reduxService.getConnect();

const getServices = reduxService.getServices;

const getRedactedState = getState => {
    if (typeof getState !== 'function') {
        const state = getState;
        getState = () => state;
    }
    return selectors.reduce(
        (acc, service) => ({
            ...acc,
            [service.name]: service.getRedactedState(
                () => getState()[service.name],
            ),
        }),
        {},
    );
};

const getSanitizedState = getState => {
    if (typeof getState !== 'function') {
        const state = getState;
        getState = () => state;
    }
    const state = { ...getState() };
    delete state.user;
    return state;
};

export {
    reducer as default,
    connect,
    getServices,
    getRedactedState,
    getSanitizedState,
};
