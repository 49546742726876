export const topBanksList = {
    AT: ['ngp-bkau', 'ngp-ubswa', 'ngp-vbwi', 'ngp-opsk', 'ngp-giba'],
    BE: ['stet-parb', 'ngp-bbru', 'ngp-gkcc', 'ngp-ftno', 'ngp-axab'],
    BG: ['ngp-uncrb', 'ngp-rzbb', 'ngp-stsa'],
    CZ: ['cobs-rzbc', 'cobs-gibacz', 'cobs-agba'],
    DE: ['ngp-hyve', 'ngp-ingdd', 'ngp-coba', 'ngp-ubswd', 'ngp-deutdedb'],
    DK: ['ngp-ndeadk'],
    ES: ['ngp-cgdi', 'ngp-bcoe', 'ngp-ucja', 'ngp-inal', 'stet-cmcies'],
    EE: ['ngp-lhvb', 'ngp-habaee', 'ngp-eeuh', 'ngp-parxee'],
    FI: ['ngp-hand', 'ngp-ndeafi'],
    FR: [
        'stet-agrifrpp813',
        'stet-soge',
        'stet-nord',
        'stet-bnpa-per',
        'stet-cmci',
    ],
    IT: ['ngp-cbi-05034', 'ngp-cbi-03069', 'ngp-cbi-03111', 'ngp-cbi-01005'],
    LT: ['ngp-habalt', 'ngp-cbvi', 'ngp-indu'],
    LV: ['ngp-habalv', 'ngp-unla', 'ngp-parx', 'ngp-mult'],
    NL: ['ngp-ingb', 'ngp-rabo', 'ngp-abna'],
    PT: ['ngp-tota', 'ngp-cgdipt', 'ngp-besc', 'ngp-actv', 'ngp-bapa'],
    SI: ['ngp-bacxsl', 'ngp-aban', 'ngp-haab', 'ngp-kspk', 'ngp-hdel'],
    SE: ['ngp-handse', 'ngp-swed', 'ngp-sebbpr', 'ngp-sebbco'],
    GB: [
        'ob-lloyds',
        'ob-barclays',
        'ob-hsbc',
        'ob-bankofscotland',
        'ob-santander',
    ],
    US: ['gold', 'iron'],
};
