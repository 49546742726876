import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SpinnerWithLockBig } from '@token-io/lib-web-components/src/Components/Spinner';
import { connect } from 'reducers';
import style from './Loading.css';
import classNames from 'classnames/bind';
import { argbToRgba, sessionStorageWrapper } from 'util/index';
import {
    defaultColors,
    SPLASH_STEP,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { FooterText } from 'components/App/Shared/FooterText';
import { getDashboardUrlByEnv, getUserParams } from '@token-io/lib-web-app';
import axios from 'axios';
import platform from 'platform';
import { setStep } from 'actions/shared/route';
import { sendTraceSpans } from '../../../config/constants';

const cx = classNames.bind(style);

const Loading = ({ color, displaySplashScreen, setStep, stepsUntilNow }) => {
    useEffect(() => {
        if (displaySplashScreen) {
            if (stepsUntilNow?.length === 1) {
                setStep(SPLASH_STEP);
            }
        }

        const platformIsIos = platform.description.includes('iOS');
        const isBankCallbackUrl = document.location.pathname.includes(
            '/app/auth',
        );

        const hasSessionRefreshed = sessionStorageWrapper.get(
            'hasSessionRefreshed',
        );

        const timeout = setTimeout(() => {
            if (isBankCallbackUrl && platformIsIos && !hasSessionRefreshed) {
                sessionStorageWrapper.set('hasSessionRefreshed', true);
                if (sendTraceSpans) {
                    const userParams = getUserParams();
                    //record span if forced refreshed
                    try {
                        axios.post(
                            `${getDashboardUrlByEnv()}/query-lightstep?sessionId=${sessionStorageWrapper.get(
                                'sessionId',
                            )}&forced-refresh=forced${userParams}`,
                        );
                    } catch (e) {
                        console.error(e); // eslint-disable-line
                    }
                }
                window.location.reload(true);
            }
        }, 7000);

        return () => {
            clearTimeout(timeout);
        };
    }, [displaySplashScreen]);

    return (
        <div className={cx('Loading')}>
            <SpinnerWithLockBig color={color} />
            <FooterText className={cx('LoadingFooter')} isSpinner />
        </div>
    );
};

Loading.propTypes = {
    color: PropTypes.string,
    displaySplashScreen: PropTypes.bool,
    setStep: PropTypes.func,
    stepsUntilNow: PropTypes.array,
};

const mapStateToProps = ({ tokenRequestService, sharedService }) => {
    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };
    const displaySplashScreen = !!tokenRequestService
        .getTppFeature(featureConfig.SPLASH_SCREEN)
        ?.trim();
    const stepsUntilNow = sharedService.getSteps();

    return {
        color:
            argbToRgba(colors['color-spinner']) ||
            argbToRgba(colors['color-primary']),
        displaySplashScreen,
        stepsUntilNow,
    };
};

const mapDispatchToProps = {
    setStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
