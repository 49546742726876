import React from 'react';
import './EyeIcon.css';
import PropTypes from 'prop-types';
import style from './../Pisp/Consent.css';
import classNames from 'classnames/bind';
import { BsEyeSlashFill } from 'react-icons/bs';
import { BsEyeFill } from 'react-icons/bs';

const cx = classNames.bind(style);

const EyeIcon = ({ setPasswordVisible, isVisible }) => {
    return isVisible ? (
        <span
            className={cx({ 'Eye-icon': true })}
            onClick={() => {
                setPasswordVisible(false);
            }}>
            <BsEyeSlashFill style={{ height: 'inherit' }} />
        </span>
    ) : (
        <span
            className={cx({ 'Eye-icon': true })}
            onClick={() => {
                setPasswordVisible(true);
            }}>
            <BsEyeFill style={{ height: 'inherit' }} />
        </span>
    );
};

EyeIcon.propTypes = {
    setPasswordVisible: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
};

EyeIcon.defaultProps = {
    setPasswordVisible: () => null,
};

export default EyeIcon;
