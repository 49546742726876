import React from 'react';
import PropTypes from 'prop-types';
import style from './CopyIcon.css';
import classNames from 'classnames/bind';
import { FaCopy } from 'react-icons/fa';

const cx = classNames.bind(style);

const CopyIcon = ({ onKeyPress, onClick, className, ariaLabel }) => {
    return (
        <span
            className={cx({
                [className]: !!className,
            })}
            aria-label={ariaLabel}
            onKeyPress={e => {
                if (e.key === 'Enter') onKeyPress();
            }}
            onClick={() => onClick()}
            tabIndex="0"
            role="button">
            <FaCopy
                className={cx('CopyIcon')}
                style={{ outline: 'none', border: 'none' }}
                title="Copy"
            />
        </span>
    );
};

CopyIcon.propTypes = {
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
};

CopyIcon.defaultProps = {
    onClick: () => null,
    onKeyPress: () => null,
};

export default CopyIcon;
