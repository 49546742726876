import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import style from './TokenButtonsWrapper.css';
import TokenButtonsContainer from '@token-io/lib-web-components/src/Components/Layout/TokenButtonsContainer';
import { CUSTOM_TPP_FEATURES as featureConfig } from 'config/constants';
import ButtonVisibilityWrapper from './ButtonVisibilityWrapper.js';
import Button from '@token-io/lib-web-components/src/Components/Button';

const cx = classNames.bind(style);

const isTypeGhost = type => {
    if (
        type &&
        (type === Button.TYPE_GHOST_WARNING || type === Button.TYPE_GHOST)
    ) {
        return true;
    }
    return false;
};

const TokenButtonsWrapper = ({
    children,
    displayShadow,
    buttonAlignment,
    className,
}) => {
    const alignButtonsVertically = buttonAlignment === 'COLUMN';
    const alignment = alignButtonsVertically ? 'column' : 'row-reverse';

    return (
        <TokenButtonsContainer
            className={cx({
                [className]: !!className,
                'Webapp-buttons-container': true,
                'Webapp-buttons-container--block':
                    React.Children.count(children) === 1,
                'Webapp-buttons-container--shadow': !!displayShadow,
            })}
            alignment={alignment}>
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    let buttonType = child.props?.type;
                    if (
                        isTypeGhost(child.props?.type) &&
                        !alignButtonsVertically
                    ) {
                        buttonType = Button.TYPE_SECONDARY;
                    }
                    const props = {
                        ...child.props,
                        type: buttonType,
                    };
                    if (child.props.scrollRef) {
                        return <ButtonVisibilityWrapper {...props} />;
                    }
                    return React.cloneElement(child, { ...props });
                }
                return child;
            })}
        </TokenButtonsContainer>
    );
};

TokenButtonsWrapper.displayName = 'TokenButtonsWrapper';

TokenButtonsWrapper.propTypes = {
    children: PropTypes.any,
    displayShadow: PropTypes.bool,
    className: PropTypes.string,
    buttonAlignment: PropTypes.string,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const buttonAlignment = tokenRequestService.getTppFeature(
        featureConfig.WEB_APP_BUTTONS_ALIGNMENT,
        'COLUMN',
    );

    const displayShadow = tokenRequestService.getTppFeature(
        featureConfig.DISPLAY_SHADOW,
        true,
    );

    return {
        buttonAlignment,
        displayShadow,
    };
};

export default connect(mapStateToProps)(TokenButtonsWrapper);
