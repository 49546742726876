const sessionStorageWrapper = {
    set: (k, v) => {
        try {
            if (!k) {
                return;
            }
            if (typeof v === 'object') {
                v = JSON.stringify(v);
            }
            window.sessionStorage.setItem(k, v);
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
    get: k => {
        let v;
        try {
            if (!k) {
                return window.sessionStorage;
            }
            v = window.sessionStorage.getItem(k);
            return JSON.parse(v);
        } catch (e) {
            console.log(e);
            return v;
        }
    },
    remove: k => {
        if (!k) {
            return;
        }
        try {
            window.sessionStorage.removeItem(k);
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
};

export default sessionStorageWrapper;
