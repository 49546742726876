import {
    TOKEN_POPUP_NAME,
    TOKEN_BANK_POPUP,
    popupInitTimeout,
    POPUP_INITIAL_WIDTH,
    POPUP_INITIAL_HEIGHT,
} from 'iframe/constants';
import Postie from '@token-io/lib-web-components/src/Postie';
import {
    isInternetExplorer,
    isSafari,
    isMSEdge,
    isFunction,
} from '@token-io/lib-web-components/src/Util';

// Pop up controller
function TokenPopup({ url, popupName }) {
    const _self = this;
    let _popup = null;
    let _postie = null;
    let _isVisible = false;
    let _onLoadCallback = null;
    let _onVisibilityChange = null;
    const _popupName = popupName || TOKEN_POPUP_NAME;
    let _keepalive = 0;

    function _popupExists() {
        if (!_popup) return false;
        return !_popup.closed;
    }

    function _hide() {
        if (!_isVisible) return;
        _popup.close();
        _popup = null;
    }

    function _setupKeepAlive() {
        _keepalive = setInterval(() => {
            const visible = _isOpen();
            if (visible === _isVisible) return;
            _dispatchKeepAlive(visible);
            if (!visible) {
                _unbindListeners();
                clearInterval(_keepalive);
                _keepalive = 0;
            }
        }, 500);
    }

    function _dispatchKeepAlive(visible) {
        if (!isFunction(_onVisibilityChange)) return;
        _onVisibilityChange(visible);
        _isVisible = visible;
    }

    function _waitUntilPopupIsInitialized() {
        let _popupInit = false;
        const startTime = new Date().getTime();
        let timePassed;
        while (!_popupInit) {
            if (_popup && isFunction(_popup.addEventListener)) {
                _popupInit = true;
            }
            timePassed = new Date().getTime() - startTime;
            if (timePassed >= popupInitTimeout) {
                throw new Error('Popup initialization timeout');
            }
        }
    }

    function _show() {
        const isIE = isInternetExplorer();
        const isEdge = isMSEdge();
        const isSaf = isSafari();
        const width = POPUP_INITIAL_WIDTH;
        const height =
            (screen.availHeight > POPUP_INITIAL_HEIGHT
                ? POPUP_INITIAL_HEIGHT
                : screen.availHeight) + (isSaf ? 23 : isEdge ? -130 : 0);

        const screenLeft =
            !isIE && !isEdge && window.screenLeft
                ? window.screenLeft
                : window.screenX;
        const screenTop =
            !isIE && !isEdge && window.screenTop
                ? window.screenTop
                : window.screenY;

        const center = {
            left: screenLeft + window.outerWidth / 2,
            top: screenTop + window.outerHeight / 2,
        };

        const left =
            screen.availWidth > width ? Math.floor(center.left - width / 2) : 0;
        const top =
            screen.availHeight > height
                ? Math.floor(center.top - height / 2)
                : 0;

        let props = 'location=yes,clearcache=yes';
        if (_popupName !== TOKEN_BANK_POPUP) {
            props =
                props +
                `,width=${width},height=${height},left=${left},top=${top},`;
        }

        _popup = window.open(url, _popupName, props);

        _waitUntilPopupIsInitialized();
    }

    function _isOpen() {
        return _popupExists();
    }

    function _destroy() {
        _hide();
    }

    function _setupMessages(postie) {
        postie.onMessage(
            'POPUP_IS_OPEN',
            function (data) {
                if (isFunction(_onLoadCallback)) _onLoadCallback();
            },
            true,
        );
    }

    function _onLoad() {
        _postie = new Postie({
            source: window,
            destination: _popup,
            origin: url,
        });
        _setupMessages(_postie);
    }

    function _bindListeners() {
        _onLoad();
        _setupKeepAlive();
    }

    function _unbindListeners() {
        if (_postie) _postie.destroy();
        _popup = null;
        _postie = null;
        _onLoadCallback = null;
        _onVisibilityChange = null;
        _keepalive = 0;
    }

    function _init() {
        _show();
        _bindListeners();
    }

    _self.isVisible = () => {
        return _isVisible();
    };

    _self.showPopup = () => {
        _show();
    };

    _self.hidePopup = () => {
        _hide();
    };

    _self.isOpen = () => {
        return _isOpen();
    };

    _self.onLoad = fn => {
        if (!isFunction(fn)) throw new Error('Function expected');
        _onLoadCallback = fn;
    };

    _self.onVisibilityChange = fn => {
        if (!isFunction(fn)) throw new Error('Function expected');
        _onVisibilityChange = fn;
    };

    _self.destroy = () => {
        _destroy();
        return _self;
    };

    _self.getPopup = () => _popup;

    _init();
}

export default TokenPopup;
