import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@token-io/lib-web-components/src/Components/Button';
import { connect } from 'reducers';
import { CUSTOM_TPP_FEATURES as featureConfig } from '../../../config/constants';
import { FormattedMessage } from 'react-intl';

const isInViewport = element => {
    try {
        const elementPosition = element.getBoundingClientRect();
        return (
            elementPosition.top >= 0 &&
            elementPosition.left >= 0 &&
            Math.abs(
                elementPosition.height +
                    element.scrollTop -
                    element.scrollHeight,
            ) < 20
        );
    } catch (e) {
        return false;
    }
};

const ButtonVisibilityWrapper = ({
    scrollRef,
    status,
    isButtonFlagEnabled,
    text,
    ...props
}) => {
    const [visibleStatus, setVisibleStatus] = useState(Button.STATUS_DISABLED);
    const [innerRef, setInnerRef] = useState(scrollRef);
    const [buttonText, setButtonText] = useState(
        isButtonFlagEnabled ? (
            <FormattedMessage id={'pisp.consent.button.scrollText'} />
        ) : (
            text
        ),
    );
    const fetchButtonStatus = () => {
        useLayoutEffect(() => {
            const scrollElement = innerRef.current;
            const updateVisiblity = () => {
                if (scrollElement.scrollHeight > scrollElement.clientHeight) {
                    // Scenario when scroller is actually present
                    // Enable and set back default text on scroll
                    if (isButtonFlagEnabled) {
                        if (isInViewport(scrollElement)) {
                            setButtonText(text);
                            setVisibleStatus(Button.STATUS_IDLE);
                        }
                    } else {
                        setButtonText(text);
                        setVisibleStatus(Button.STATUS_IDLE);
                    }
                } else {
                    // Scenario when scroller is not present and whole of content is visible
                    setVisibleStatus(Button.STATUS_IDLE);
                }
            };

            scrollElement?.addEventListener('scroll', updateVisiblity);
            window.addEventListener('resize', updateVisiblity);
            updateVisiblity();

            return () => {
                window.removeEventListener('resize', updateVisiblity);
                scrollElement?.removeEventListener('scroll', updateVisiblity);
            };
        }, []);

        return visibleStatus;
    };

    useEffect(() => {
        setInnerRef(scrollRef);
    }, []);

    const getStatus = () => {
        const { STATUS_DISABLED } = Button;
        const buttonStatus = fetchButtonStatus();
        if (status !== STATUS_DISABLED) {
            return scrollRef ? buttonStatus : status;
        }
        return status;
    };

    return <Button {...props} text={buttonText} status={getStatus()} />;
};

ButtonVisibilityWrapper.displayName = 'ButtonVisibilityWrapper';

ButtonVisibilityWrapper.propTypes = {
    status: PropTypes.oneOf([
        Button.STATUS_IDLE,
        Button.STATUS_DISABLED,
        Button.STATUS_LOADING,
    ]),
    scrollRef: PropTypes.any,
    text: PropTypes.any,
    isButtonFlagEnabled: PropTypes.bool,
};

const mapStateToProps = ({ tokenRequestService }) => {
    return {
        isButtonFlagEnabled: tokenRequestService.getTppFeature(
            featureConfig.ENABLE_ACCEPT_BUTTON,
            false,
        ),
    };
};

export default connect(mapStateToProps)(ButtonVisibilityWrapper);
