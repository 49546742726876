export default class FlickerClass {
    constructor(flickerCode) {
        this.flickerCode = flickerCode;
    }

    startTanBeamer(leds) {
        this.leds = leds.filter(led => led !== null);
    }

    startFlicker() {
        this.start(this.flickerCode);
    }

    updateSpeed = newSpeed => {
        this.stopFlicker();
        this.changeInterval(1000 / newSpeed);
        this.tanFlicker(this._interval);
        this.startFlicker();
    };

    updateSize = (newSize, image) => {
        if (!image) return;
        image.style.width = `${newSize}px`;
        image.style.height = `${newSize / 2}px`;
    };

    tanFlicker = interval => {
        this._interval = interval;
        this.symbols = {};
        this.symbols['0'] = [0, 0, 0, 0];
        this.symbols['1'] = [1, 0, 0, 0];
        this.symbols['2'] = [0, 1, 0, 0];
        this.symbols['3'] = [1, 1, 0, 0];
        this.symbols['4'] = [0, 0, 1, 0];
        this.symbols['5'] = [1, 0, 1, 0];
        this.symbols['6'] = [0, 1, 1, 0];
        this.symbols['7'] = [1, 1, 1, 0];
        this.symbols['8'] = [0, 0, 0, 1];
        this.symbols['9'] = [1, 0, 0, 1];
        this.symbols['A'] = [0, 1, 0, 1];
        this.symbols['B'] = [1, 1, 0, 1];
        this.symbols['C'] = [0, 0, 1, 1];
        this.symbols['D'] = [1, 0, 1, 1];
        this.symbols['E'] = [0, 1, 1, 1];
        this.symbols['F'] = [1, 1, 1, 1];
    };

    initializeDataBits = data => {
        data = '0FFF' + data;
        const dataBits = [];
        for (let i = 0; i < data.length; i += 2) {
            dataBits[i] = this.symbols?.[data[i + 1]] || [];
            dataBits[i + 1] = this.symbols?.[data[i]] || [];
        }

        return dataBits;
    };

    toLineStart = () => {
        this.offset = 0;
        this.sync = 1;
    };

    prepare = () => {
        this.refreshLeds([0, 0, 0, 0], 0);
        this._timer = null;
    };

    refreshLeds = (data, sync) => {
        this.leds[0].className = sync === 1 ? 'LedOn' : 'LedOff';
        for (let i = 1; i < this.leds.length; i++) {
            if (data[i - 1] === 1) {
                this.leds[i].className = 'LedOn';
            } else {
                this.leds[i].className = 'LedOff';
            }
        }
    };

    step = () => {
        this.refreshLeds(this.dataBits[this.offset], this.sync--);
        if (this.sync < 0) {
            this.sync = 1;
            this.offset += 1;
            if (this.offset > this.dataBits.length - 1) {
                this.toLineStart();
            }
        }
    };

    stopFlicker = () => {
        if (this._timer) {
            window.clearInterval(this._timer);
            this.prepare();
        }
    };

    start = tanCode => {
        this.dataBits = this.initializeDataBits(tanCode);
        this.toLineStart();
        // If times still active, stop it before starting again
        if (this._timer) {
            this.stopFlicker();
        }
        this._timer = window.setInterval(() => {
            this.step();
        }, this._interval);
    };

    changeInterval = newInterval => {
        this._interval = newInterval;
    };
}
