import {
    PISP_APP_INSTALL,
    PISP_ENTER_ALIAS,
    PISP_TRANSFER_OVERVIEW,
    ROUTE_PISP,
    LOADING_STEP,
    TOKEN_TYPE_BULK_TRANSFER,
    TOKEN_TYPE_TRANSFER,
    webAppUrl,
    TOKEN_TYPE_STANDING_ORDER,
    PISP_STANDING_ORDER_CONSENT_BANK_FIRST,
    PISP_CONSENT_BANK_FIRST,
    banksWithBranches,
    TOKEN_PROVIDER,
    INTERNAL_TOKEN_REQUEST_STATUS as requestStatus,
} from 'config/constants';
import {
    redirectToBankV2,
    completeFlow,
    loginFromLocalStorage,
    logoutFromLocalStorage,
    setBank,
    setBankInfo,
    validateAlias,
    completeFlowFromApp,
    clearMembers,
    setRecentBank,
    setResolvedPayload,
    setupBankRedirectUrl,
    setTokenId,
    showBankRedirectionScreen,
    terminateFlow,
    clearBank,
    yieldBanks,
    setAuthRequestId,
    clearInstructionsSource,
    getTokenRequest,
} from 'actions/shared';
import { backOrGoTo, changeRoute, setStep } from 'actions/shared/route';
import {
    getAndValidateAccounts,
    getBankFromLocalStorage,
    getTempMember,
    getTempMemberLS,
    getTokenClient,
    setMemberReceiptEmail,
    setTokenRequestStatus,
} from 'actions/shared/api';
import { EXCEPTION_NO_DESTINATION } from 'config/exceptions';
import { TokenException } from '@token-io/lib-web-app';
import {
    PISP_SELECT_BANK_BANK_FIRST,
    PISP_BULK_TRANSFER_CONSENT_BANK_FIRST,
    CUSTOM_TPP_FEATURES as featureConfig,
} from '../../config/constants';
import {
    setOneStepSupport,
    fetchBankBranches,
    setCountriesList,
    getAppCallbackUrl,
    initiateBankAuthFlow,
    isBankLive,
    setBankStatus,
    removeCheckForTokenReqRejection,
} from '../shared';
import { getBankDoesRequireDebtorAccount } from '../../util';
import queryString from 'query-string';
import { EXCEPTION_INVALID_TRANSFER_DESTINATION_URL } from 'config/exceptions';
import axios from 'axios';

const Token = getTokenClient();

export const setTransferTokenType = ({
    tppAlias,
    amount,
    currency,
    destinations,
    instructions,
    actingAs,
    executionDate,
    setTransferDestinationsUrl,
    remittanceReference,
    confirmFunds,
    memberId,
    returnRefundAccount,
} = {}) => {
    return {
        type: 'SET_TOKEN_TYPE',
        tokenType: TOKEN_TYPE_TRANSFER,
        tppAlias,
        amount,
        currency,
        destinations,
        instructions,
        actingAs,
        executionDate,
        setTransferDestinationsUrl,
        remittanceReference,
        confirmFunds,
        memberId,
        returnRefundAccount,
    };
};

export const setStandingOrderTokenType = ({
    tppAlias,
    amount,
    currency,
    destinations,
    instructions,
    actingAs,
    frequency,
    startDate,
    endDate,
    remittanceReference,
    memberId,
    returnRefundAccount,
} = {}) => {
    return {
        type: 'SET_TOKEN_TYPE',
        tokenType: TOKEN_TYPE_STANDING_ORDER,
        tppAlias,
        amount,
        currency,
        destinations,
        instructions,
        actingAs,
        frequency,
        startDate,
        endDate,
        remittanceReference,
        memberId,
        returnRefundAccount,
    };
};

export const setBulkTransferTokenType = ({
    tppAlias,
    amount,
    transfers,
    source,
    memberId,
} = {}) => {
    return {
        type: 'SET_TOKEN_TYPE',
        tokenType: TOKEN_TYPE_BULK_TRANSFER,
        tppAlias,
        amount,
        transfers,
        source,
        memberId,
    };
};

export const setDestinations = destinations => ({
    type: 'SET_DESTINATION_ACCOUNT',
    destinations,
});

export const setAccounts = accounts => ({
    type: 'SET_ACCOUNTS',
    accounts,
});

export const clearPaymentAccount = () => ({
    type: 'CLEAR_PAYMENT_ACCOUNT',
});

export const setReceiptEmail = email => ({
    type: 'SET_RECEIPT_EMAIL',
    email,
    redacted: ['email'],
});

// Sets the Iban if present
export const setInstructionsSourceIban = source => ({
    type: 'SET_INSTRUCTIONS_SOURCE_IBAN',
    source,
});

export const setMandatoryTransferFields = transfer => ({
    type: 'SET_MANDATORY_TRANSFER_FIELDS',
    transfer,
});

export const setMandatoryProviderTransferMetadata = (
    mandatoryFields,
    requiredMandatoryFields,
) => {
    if (requiredMandatoryFields?.stetFields) {
        return {
            type: 'SET_MANDATORY_STET_TRANSFER_METADATA',
            stetTransferMetadata: mandatoryFields?.stetMetadata,
        };
    } else if (requiredMandatoryFields?.polishapiFields) {
        return {
            type: 'SET_MANDATORY_POLISH_API_TRANSFER_METADATA',
            polishApiTransferMetadata: mandatoryFields?.polishApiMetadata,
        };
    }
};

export const setMandatoryStandingOrderFields = transfer => ({
    type: 'SET_MANDATORY_STANDING_ORDER_FIELDS',
    transfer,
});

export const setMandatoryProviderStandingOrderMetadata = (
    mandatoryFields,
    requiredMandatoryFields,
) => {
    if (requiredMandatoryFields?.stetFields) {
        return {
            type: 'SET_MANDATORY_STET_STANDING_ORDER_METADATA',
            stetTransferMetadata: mandatoryFields?.stetMetadata,
        };
    } else if (requiredMandatoryFields?.polishapiFields) {
        return {
            type: 'SET_MANDATORY_POLISH_API_STANDING_ORDER_METADATA',
            polishApiTransferMetadata: mandatoryFields?.polishApiMetadata,
        };
    }
};

export const setTokenRequestSignature = signature => ({
    type: 'SET_TOKEN_REQUEST_SIGNATURE',
    signature,
});

export const setPaymentAccountId = accountId => ({
    type: 'SET_PAYMENT_ACCOUNT',
    accountId,
});

// token actions

// Step 1 - show consent screen
export const initiatePispFlow = ({
    destinations,
    setTransferDestinationsUrl,
} = {}) => async (dispatch, { sharedService }) => {
    dispatch(changeRoute(ROUTE_PISP));
    if (!destinations?.length && !sharedService.isBulkTransfer()) {
        if (sharedService.isTransfer() && !setTransferDestinationsUrl) {
            throw new TokenException(
                EXCEPTION_NO_DESTINATION,
                'Transfer must have a destination or specify a callback url',
            );
        } else if (sharedService.isStandingOrder()) {
            throw new TokenException(
                EXCEPTION_NO_DESTINATION,
                'Standing Order token must have a destination',
            );
        }
    }

    await dispatch(setCountriesList());

    await dispatch(loginFromLocalStorage());

    dispatch(acceptTermsBankFirstFlow());
};

export const fetchTransferDestinationUrl = bank => async (
    dispatch,
    { tokenService, tokenRequestService },
) => {
    const { supportedTransferDestinationTypes, name, country } = bank;
    const url = tokenService.getSetTransferDestinationsUrl();
    const destinationUrl = url.concat('?').concat(
        queryString.stringify({
            bankName: name,
            country,
            supportedTransferDestinationTypes,
        }),
    );
    const customRedirectURL = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_WEB_APP_URL,
    );
    let callBacktUrl;
    if (customRedirectURL) {
        callBacktUrl = `${customRedirectURL}/api/crossborder-callback?destination-url=${encodeURIComponent(
            destinationUrl,
        )}`;
    } else {
        callBacktUrl = `${webAppUrl}/api/crossborder-callback?destination-url=${encodeURIComponent(
            destinationUrl,
        )}`;
    }
    try {
        const res = await axios.get(callBacktUrl);
        if (res.status === 200) {
            await dispatch(setBankAndProceedToConsent(bank));
        } else
            throw new TokenException(
                EXCEPTION_INVALID_TRANSFER_DESTINATION_URL,
                'Transfer Destination URL is invalid',
            );
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const acceptTermsBankFirstFlow = receiptEmail => async (
    dispatch,
    { sharedService, userService, tokenRequestService, tokenService },
) => {
    let defaultBankId;
    let country;

    const recentBanks = sharedService.getRecentBanks();

    defaultBankId = tokenRequestService.getDefaultBankId();
    const isDefaultBank = !!defaultBankId;

    const bankName =
        (recentBanks[0] &&
            recentBanks[0].id[0].toUpperCase() + recentBanks[0].id.slice(1)) ||
        '';
    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );
    const bank = getBankFromLocalStorage();
    let isFirstRecentBankSelected = false;
    // Check if recent bank supports the payment mode, if present
    if (
        !tokenService.getSetTransferDestinationsUrl() &&
        doesTppSupportRecentBanks &&
        defaultBankId === undefined &&
        recentBanks[0] &&
        !Object.keys(banksWithBranches).includes(bankName)
    ) {
        const { banks } = await dispatch(
            yieldBanks({ ids: [recentBanks[0].id] }),
        );
        const bankDetail = banks?.[0];
        if (bankDetail) {
            defaultBankId = recentBanks[0].id;
            country = recentBanks[0].selectedCountry;
        }
        if (!bank) {
            isFirstRecentBankSelected = true;
        }
    }
    dispatch(setReceiptEmail(receiptEmail));
    if (defaultBankId && !userService.hasPairedMembers() && !bank) {
        const { banks } = await dispatch(
            yieldBanks({
                ids: [defaultBankId],
                isDefaultBank,
            }),
        );
        let defaultBank = banks?.[0];

        // If the bank id is already provided in the token request then
        // consider country as first element of countries array from bank config
        if (!country) {
            country = defaultBank?.countries[0];
        }
        defaultBank = { ...defaultBank, selectedCountry: country };

        if (
            tokenService.getSetTransferDestinationsUrl() &&
            !tokenService.getDestinations()
        ) {
            await dispatch(fetchTransferDestinationUrl(defaultBank));
        }
        defaultBank.isFirstRecentBank = isFirstRecentBankSelected;
        await dispatch(setBankAndProceedToConsent(defaultBank));
    } else if (userService.hasPairedMembers() && bank) {
        await dispatch(goToTransferOverview(bank)); // Skips to transfer if linked
    } else {
        dispatch(setStep(PISP_SELECT_BANK_BANK_FIRST));
    }
};

export const backToBankSelector = () => async (dispatch, { userService }) => {
    const pispTransferOverview =
        userService.hasPairedMembers() && getBankFromLocalStorage();
    if (pispTransferOverview) {
        dispatch(backOrGoTo(PISP_TRANSFER_OVERVIEW, true));
    } else {
        dispatch(clearBank());
        dispatch(clearInstructionsSource());
        dispatch(setStep(PISP_SELECT_BANK_BANK_FIRST));
    }
};

// ** Step 2 - is bank selection, this is a shared action

// Step 3 - after bank selection, verify appless support and create a temp member.
// Otherwise ask for bank user's email.
export const setBankAndProceed = bank => async (
    dispatch,
    { tokenRequestService },
) => {
    dispatch(setStep(LOADING_STEP));
    await dispatch(setRecentBank(bank));
    dispatch(setBank(bank));
    if (bank.supportsCheckoutFlowV2) {
        // Only for direct bank backword compatibility
        await dispatch(redirectToBankV2());
    } else {
        const defaultAlias = tokenRequestService.getDefaultAlias();
        const guest = await dispatch(getTempMember());
        dispatch(setBankInfo(await guest.getBankInfo(bank.id)));
        if (bank.requiresOneStepPayment) {
            await dispatch(oneStepTransfer());
        } else if (bank.supportsAppless) {
            await dispatch(prepareGuestTransfer());
        } else if (defaultAlias) {
            const defaultAliasValue =
                typeof defaultAlias === 'object' && defaultAlias.value
                    ? defaultAlias.value
                    : defaultAlias;
            await dispatch(validateAlias(defaultAliasValue, 'EMAIL'));
        } else {
            dispatch(setStep(PISP_ENTER_ALIAS));
        }
    }
};

export const setBankAndProceedToConsent = bank => async (
    dispatch,
    { tokenRequestService, sharedService, tokenService },
) => {
    dispatch(setStep(LOADING_STEP));
    const isSelectedBankLive = await dispatch(isBankLive(bank.id));
    if (!isSelectedBankLive) {
        !bank.isFirstRecentBank &&
            (await dispatch(
                setBankStatus(bank.name || bank.id, isSelectedBankLive),
            ));
        await dispatch(backToBankSelector());
        return;
    }

    const { tokenRequest } = await dispatch(getTokenRequest());

    // Set token request result internal status
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_BANK_SELECTED),
    );

    if (
        tokenService.getSetTransferDestinationsUrl() &&
        !tokenService.getDestinations()
    ) {
        const { requestPayload } = tokenRequest;
        if (requestPayload.transferBody) {
            const destinations =
                requestPayload.transferBody.destinations ||
                requestPayload.transferBody.instructions?.transferDestinations;
            dispatch(setDestinations(destinations));
        }
    }
    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );

    if (
        doesTppSupportRecentBanks &&
        !Object.prototype.hasOwnProperty.call(banksWithBranches, bank.name)
    ) {
        await dispatch(setRecentBank(bank));
    }

    dispatch(setBank(bank));
    if (
        getBankDoesRequireDebtorAccount(
            bank,
            sharedService.getTokenType(),
            tokenService.getDestinations()?.[0],
        ) ||
        Object.keys(banksWithBranches).includes(bank.name)
    ) {
        dispatch(setOneStepSupport(true));
    } else {
        dispatch(setOneStepSupport(false));
    }

    if (Object.keys(banksWithBranches).includes(bank.name)) {
        await dispatch(
            fetchBankBranches(bank.name, bank.selectedCountry || bank.country),
        );
    }

    if (tokenRequestService.getHideConsent()) {
        dispatch(proceedToBank());
    } else {
        if (sharedService.isStandingOrder()) {
            dispatch(setStep(PISP_STANDING_ORDER_CONSENT_BANK_FIRST));
        } else if (sharedService.isBulkTransfer()) {
            dispatch(setStep(PISP_BULK_TRANSFER_CONSENT_BANK_FIRST));
        } else {
            dispatch(setStep(PISP_CONSENT_BANK_FIRST));
        }
    }
};

export const proceedToConsent = () => async (
    dispatch,
    { tokenRequestService, sharedService },
) => {
    if (tokenRequestService.getHideConsent()) {
        dispatch(requestPayment());
    } else {
        if (sharedService.isStandingOrder()) {
            dispatch(setStep(PISP_STANDING_ORDER_CONSENT_BANK_FIRST));
        } else if (sharedService.isBulkTransfer()) {
            dispatch(setStep(PISP_BULK_TRANSFER_CONSENT_BANK_FIRST));
        } else {
            dispatch(setStep(PISP_CONSENT_BANK_FIRST));
        }
    }
};

export const proceedToBank = receiptEmail => async (
    dispatch,
    { tokenRequestService, sharedService, userService },
) => {
    removeCheckForTokenReqRejection();
    dispatch(setStep(LOADING_STEP));

    // Set token request result internal status
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_CONSENT_ACCEPTED),
    );
    dispatch(setReceiptEmail(receiptEmail));
    const bank = sharedService.getSelectedBank();

    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );
    if (
        Object.prototype.hasOwnProperty.call(banksWithBranches, bank.name) &&
        doesTppSupportRecentBanks
    ) {
        await dispatch(setRecentBank(bank));
    }

    await Token.updateTokenRequest(tokenRequestService.getRequestId(), {
        ...tokenRequestService.getRequestOptions(),
        bankId: sharedService.getSelectedBankId(),
    });

    const defaultBankId = tokenRequestService.getDefaultBankId() || '';

    if (userService.hasPairedMembers() && bank && defaultBankId === '') {
        dispatch(requestPayment());
    } else if (bank.provider !== TOKEN_PROVIDER) {
        dispatch(initiateBankAuthFlow());
    } else if (bank.supportsCheckoutFlowV2) {
        // Only for direct bank backword compatibility
        await dispatch(redirectToBankV2());
    } else {
        const defaultAlias = tokenRequestService.getDefaultAlias();
        const guest = await dispatch(getTempMember());
        dispatch(setBankInfo(await guest.getBankInfo(bank.id)));
        if (bank.requiresOneStepPayment) {
            await dispatch(oneStepTransfer());
        } else if (bank.supportsAppless) {
            await dispatch(prepareGuestTransfer());
        } else if (defaultAlias) {
            const defaultAliasValue =
                typeof defaultAlias === 'object' && defaultAlias.value
                    ? defaultAlias.value
                    : defaultAlias;
            await dispatch(validateAlias(defaultAliasValue, 'EMAIL'));
        } else {
            dispatch(setStep(PISP_ENTER_ALIAS));
        }
    }
};

export const noApp = () => async (dispatch, { sharedService }) => {
    const bank = sharedService.getSelectedBank();
    if (bank && bank.supportsAppless) {
        await dispatch(prepareGuestTransfer());
    } else {
        dispatch(setStep(PISP_APP_INSTALL));
    }
};

export const clearBankAndBack = () => async (
    dispatch,
    { userService, tokenRequestService, sharedService },
) => {
    const pispTransferOverview =
        userService.hasPairedMembers() && getBankFromLocalStorage();
    await logoutFromLocalStorage();
    dispatch(clearMembers());
    const hasDefaultBankId = tokenRequestService.getDefaultBankId();
    if (hasDefaultBankId) {
        return dispatch(
            terminateFlow({
                error: 'access_denied',
                message: 'Token window was cancelled',
            }),
        );
    }
    if (pispTransferOverview) {
        dispatch(backOrGoTo(PISP_SELECT_BANK_BANK_FIRST, true));
    } else {
        if (sharedService.isStandingOrder()) {
            dispatch(backOrGoTo(PISP_STANDING_ORDER_CONSENT_BANK_FIRST, true));
        } else if (sharedService.isBulkTransfer()) {
            dispatch(backOrGoTo(PISP_BULK_TRANSFER_CONSENT_BANK_FIRST, true));
        } else {
            dispatch(backOrGoTo(PISP_CONSENT_BANK_FIRST, true));
        }
    }
};

export const goToTransferOverview = bank => async (
    dispatch,
    { tokenRequestService, userService },
) => {
    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );
    if (doesTppSupportRecentBanks) {
        await dispatch(setRecentBank(bank));
    }

    dispatch(setBank(bank));
    const member = userService.getFirstPairedMember();
    const accounts = await dispatch(getAndValidateAccounts(member, bank, true));
    dispatch(setAccounts(accounts));
    dispatch(setStep(PISP_TRANSFER_OVERVIEW));
    dispatch(setBankInfo(await member.getBankInfo(bank.id)));
};

export const setPaymentAccount = accountId => dispatch => {
    dispatch(setPaymentAccountId(accountId));
};

// Step 5a - create and approve transfer token.
export const requestPayment = () => async (
    dispatch,
    { userService, tokenService, tokenRequestService },
) => {
    const member = userService.getFirstPairedMember();
    const builder = await dispatch(
        createTransferTokenBuilderFromTokenRequest(member),
    );
    const tokenPayload = await builder
        .setAccountId(tokenService.getSourceAccountId())
        .buildPayload();
    const customRedirectURL = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_WEB_APP_URL,
    );
    const appCallbackUrl = getAppCallbackUrl(customRedirectURL);
    const { policy, resolvedPayload } = await member.prepareToken(
        tokenPayload,
        appCallbackUrl,
    );
    if (policy.singleSignature?.signer?.keyLevel === 'LOW') {
        const signature = await member.signTokenPayload(resolvedPayload, 'LOW');
        const token = await member.createToken(
            resolvedPayload,
            [signature],
            tokenRequestService.getRequestId(),
        );

        await dispatch(
            completeFlow(tokenRequestService.getRequestId(), token.id, member),
        );
    } else {
        await dispatch(
            completeFlowFromApp(
                await userService.getFirstPairedMember().firstAlias(),
            ),
        );
    }
};

export const prepareGuestTransfer = signature => async (
    dispatch,
    { tokenService, sharedService, tokenRequestService },
) => {
    if (!signature) {
        dispatch(showBankRedirectionScreen());
        let builder;
        const member = await dispatch(getTempMemberLS());
        const bank = sharedService.getSelectedBank();
        if (sharedService.isStandingOrder()) {
            builder = await dispatch(
                createStandingOrderTokenBuilderFromTokenRequest(member, bank),
            );
        } else if (sharedService.isBulkTransfer()) {
            builder = await dispatch(
                createBulkTransferTokenBuilderFromTokenRequest(member, bank),
            );
        } else
            builder = await dispatch(
                createTransferTokenBuilderFromTokenRequest(member, bank),
            );
        const tokenPayload = builder.buildPayload();
        const customRedirectURL = tokenRequestService.getTppFeature(
            featureConfig.CUSTOM_WEB_APP_URL,
        );
        const appCallbackUrl = getAppCallbackUrl(customRedirectURL);
        const { policy, resolvedPayload } = await member.prepareToken(
            tokenPayload,
            appCallbackUrl,
        );
        // set auth request id
        const url = new URL(policy.singleSignature?.signer?.authorizationUrl);
        const urlParams = new URLSearchParams(url.search);
        const authRequestId = urlParams.get('auth-request-id');
        dispatch(setAuthRequestId(authRequestId));
        dispatch(setResolvedPayload(resolvedPayload));
        let redirectUrl;
        if (customRedirectURL) {
            redirectUrl = `${
                policy.singleSignature?.signer?.authorizationUrl
            }&callback-url=${encodeURIComponent(
                `${customRedirectURL}/app/auth/callback`,
            )}`;
            dispatch(
                setupBankRedirectUrl(
                    `${customRedirectURL}/api/guest-authorization?redirect-url=${encodeURIComponent(
                        redirectUrl,
                    )}`,
                ),
            );
        } else {
            redirectUrl = `${
                policy.singleSignature?.signer?.authorizationUrl
            }&callback-url=${encodeURIComponent(
                `${webAppUrl}/app/auth/callback`,
            )}`;
            dispatch(
                setupBankRedirectUrl(
                    `${webAppUrl}/api/guest-authorization?redirect-url=${encodeURIComponent(
                        redirectUrl,
                    )}`,
                ),
            );
        }
    } else {
        const member = Token.getMember(Token.BrowserCryptoEngine);
        const tokenRequestId = tokenRequestService.getRequestId();
        const token = await member.createToken(
            tokenService.getResolvedPayload(),
            [signature],
            tokenRequestId,
        );
        await dispatch(completeFlow(tokenRequestId, token.id, member));
    }
};

export const oneStepTransfer = (transferId, transferStatus) => async (
    dispatch,
    { tokenService, sharedService, tokenRequestService },
) => {
    if (!transferId) {
        dispatch(showBankRedirectionScreen());
        const member = await dispatch(getTempMemberLS());
        const bank = sharedService.getSelectedBank();
        const tokenRequestId = tokenRequestService.getRequestId();
        let builder;
        if (sharedService.isStandingOrder()) {
            builder = await dispatch(
                createStandingOrderTokenBuilderFromTokenRequest(member, bank),
            );
        } else if (sharedService.isBulkTransfer()) {
            builder = await dispatch(
                createBulkTransferTokenBuilderFromTokenRequest(member, bank),
            );
        } else
            builder = await dispatch(
                createTransferTokenBuilderFromTokenRequest(member, bank),
            );
        const tokenPayload = builder.buildPayload();
        const customRedirectURL = tokenRequestService.getTppFeature(
            featureConfig.CUSTOM_WEB_APP_URL,
        );
        const appCallbackUrl = getAppCallbackUrl(customRedirectURL);
        const { resolvedPayload } = await member.prepareToken(
            tokenPayload,
            appCallbackUrl,
        );
        dispatch(setResolvedPayload(resolvedPayload));
        const signature = await member.signTokenPayload(resolvedPayload, 'LOW');
        const token = await member.createToken(
            resolvedPayload,
            [signature],
            tokenRequestId,
        );
        dispatch(setTokenId(token.id));
        const redirectState = tokenRequestService.getRequestRedirectState();
        const tokenRequestSignature = await member.signTokenRequestState(
            tokenRequestService.getRequestId(),
            token.id,
            tokenRequestService.getRequestPayload()
                ? encodeURIComponent(redirectState) // TODO: deprecated
                : redirectState,
        );
        dispatch(setTokenRequestSignature(tokenRequestSignature));
        try {
            let resp;
            if (sharedService.isStandingOrder()) {
                resp = await member.redeemStandingOrderToken(token.id);
            } else if (sharedService.isBulkTransfer()) {
                resp = await member.redeemBulkTransferToken(token.id);
            } else {
                resp = await member.redeemToken(token);
            }
            if (resp) {
                dispatch(
                    terminateFlow({
                        error: resp.status,
                    }),
                );
            }
        } catch (e) {
            if (e.authorizationDetails) {
                let redirectUrl;
                if (customRedirectURL) {
                    redirectUrl = `${
                        e.authorizationDetails.authorizationUrl
                    }&callback-url=${encodeURIComponent(
                        `${customRedirectURL}/app/auth/callback`,
                    )}`;
                } else {
                    redirectUrl = `${
                        e.authorizationDetails.authorizationUrl
                    }&callback-url=${encodeURIComponent(
                        `${webAppUrl}/app/auth/callback`,
                    )}`;
                }
                await dispatch(setupBankRedirectUrl(redirectUrl));
            } else throw new Error('No authorization details found');
        }
    } else {
        const member = Token.getMember(Token.BrowserCryptoEngine);
        const tokenRequestSignature = tokenRequestService.getTokenRequestSignature();
        await dispatch(
            completeFlow(
                tokenRequestService.getRequestId(),
                tokenService.getTokenId(),
                member,
                tokenRequestSignature,
                {
                    'transfer-id': transferId,
                    'transfer-status': transferStatus,
                },
            ),
        );
    }
};

export const createTransferTokenBuilderFromTokenRequest = (
    member,
    bank,
) => async (dispatch, { tokenService, tokenRequestService }) => {
    const refId = tokenService.getRefId();
    const amount = tokenService.getAmount();
    const currency = tokenService.getCurrency();
    const remittanceReference = tokenService.getRemittanceReference();
    const returnRefundAccountFlag = tokenService.getReturnRefundAccountFlag();
    const executionDate = tokenService.getExecutionDate();
    const destinations = tokenService.getDestinations();
    const receiptRequested = tokenService.receiptRequested();
    receiptRequested &&
        (await setMemberReceiptEmail(member, tokenService.getReceiptEmail()));
    const tokenRequestId = tokenRequestService.getRequestId();
    const actingAs = tokenRequestService.getTppActingAs();
    const confirmFunds = tokenService.getCafFlag();
    const pispAlias = tokenRequestService.getTppAlias();
    const description = tokenRequestService.getDescription();
    const providerTransferMetadata = tokenService.getInstructions()?.metadata
        ?.providerTransferMetadata;
    const builder = member
        .createTransferTokenBuilder(amount, currency)
        .setTokenRequestId(tokenRequestId)
        .setRefId(refId)
        .setToAlias(pispAlias)
        .setReceiptRequested(receiptRequested);
    if (destinations[0].account) {
        // deprecated
        builder.addDestinations(destinations);
    } else {
        builder.addTransferDestinations(destinations);
    }
    if (executionDate) {
        builder.setExecutionDate(executionDate);
    }
    actingAs && builder.setActingAs(actingAs);
    description && builder.setDescription(description);
    providerTransferMetadata &&
        builder.setProviderTransferMetadata(providerTransferMetadata);
    confirmFunds && builder.setConfirmFunds(confirmFunds);
    remittanceReference && builder.setRemittanceReference(remittanceReference);
    returnRefundAccountFlag &&
        builder.setReturnRefundAccount(returnRefundAccountFlag);
    const source = tokenService.getInstructions()?.source;
    if (source) {
        // If source bank id not same as selected bank id
        // set source with new selected bank
        if (source.bankId && source.bankId !== bank?.id) {
            builder.setSourceAccountGuest(bank?.id);
        } else {
            if (!source.bankId) {
                source.bankId = bank?.id;
            }
            builder.setSource(source);
        }
    } else {
        builder.setSourceAccountGuest(bank?.id);
    }

    return builder;
};

export const createStandingOrderTokenBuilderFromTokenRequest = (
    member,
    bank,
) => async (dispatch, { tokenService, tokenRequestService }) => {
    const refId = tokenService.getRefId();
    const amount = tokenService.getStandingOrderAmount();
    const currency = tokenService.getStandingOrderCurrency();
    const remittanceReference = tokenService.getStandingOrderRemittanceReference();
    const returnRefundAccountFlag = tokenService.getStandingOrderReturnRefundAccountFlag();
    const frequency = tokenService.getStandingOrderFrequency();
    const destinations = tokenService.getStandingOrderDestinations();
    const startDate = tokenService.getStandingOrderStartDate();
    const endDate = tokenService.getStandingOrderEndDate();
    const receiptRequested = tokenService.receiptRequested();
    receiptRequested &&
        (await setMemberReceiptEmail(member, tokenService.getReceiptEmail()));
    const tokenRequestId = tokenRequestService.getRequestId();
    const actingAs = tokenRequestService.getTppActingAs();
    const pispAlias = tokenRequestService.getTppAlias();
    const description = tokenRequestService.getDescription();
    const providerStandingOrderMetadata = tokenService.getStandingOrderInstructions()
        ?.metadata?.providerStandingOrderMetadata;
    const builder = member
        .createStandingOrderTokenBuilder(
            amount,
            currency,
            frequency,
            startDate,
            endDate,
        )
        .setTokenRequestId(tokenRequestId)
        .setRefId(refId)
        .setToAlias(pispAlias)
        .setReceiptRequested(receiptRequested)
        .addTransferDestinations(destinations);
    actingAs && builder.setActingAs(actingAs);
    description && builder.setDescription(description);
    remittanceReference && builder.setRemittanceReference(remittanceReference);
    returnRefundAccountFlag &&
        builder.setReturnRefundAccount(returnRefundAccountFlag);
    providerStandingOrderMetadata &&
        builder.setProviderMetadata(providerStandingOrderMetadata);
    const source = tokenService.getStandingOrderInstructions()?.source;
    if (source) {
        if (source.bankId && source.bankId !== bank?.id) {
            builder.setSourceAccountGuest(bank?.id);
        } else {
            if (!source.bankId) {
                source.bankId = bank?.id;
            }
            builder.setSource(source);
        }
    } else {
        builder.setSourceAccountGuest(bank?.id);
    }
    return builder;
};

export const createBulkTransferTokenBuilderFromTokenRequest = (
    member,
    bank,
) => async (dispatch, { tokenService, tokenRequestService }) => {
    const refId = tokenService.getRefId();
    const amount = tokenService.getBulkTransferAmount();
    const transfers = tokenService.getBulkTransfers();
    const receiptRequested = tokenService.receiptRequested();
    receiptRequested &&
        (await setMemberReceiptEmail(member, tokenService.getReceiptEmail()));
    const tokenRequestId = tokenRequestService.getRequestId();
    const actingAs = tokenRequestService.getTppActingAs();
    const pispAlias = tokenRequestService.getTppAlias();
    const description = tokenRequestService.getDescription();
    const builder = member
        .createBulkTransferTokenBuilder(transfers, amount)
        .setTokenRequestId(tokenRequestId)
        .setRefId(refId)
        .setToAlias(pispAlias)
        .setReceiptRequested(receiptRequested);
    actingAs && builder.setActingAs(actingAs);
    description && builder.setDescription(description);
    const source = tokenService.getBulkTransferSource();
    if (source) {
        if (source.bankId && source.bankId !== bank?.id) {
            builder.setSourceAccountGuest(bank?.id);
        } else {
            if (!source.bankId) {
                source.bankId = bank?.id;
            }
            builder.setSource(source);
        }
    } else {
        builder.setSourceAccountGuest(bank?.id);
    }
    return builder;
};
