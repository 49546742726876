/**
 * Action that adds a step in the user's navigation history,
 * which the user can manipulate using the browser back/forward buttons.
 *
 * @param step - name of the step
 * @param ignore - if true, navigation history is unaffected
 * @param replace - if true, this action replaces the previous step
 * instead of being added as a new step
 */
export const addStep = (step, ignore, replace) => ({
    type: 'ADD_STEP',
    step,
    ignore,
    replace,
});

/**
 * Action that goes back a step in the user's navigation history.
 * This is effectively equivalent to pressing the back button in the browser.
 */
export const backStep = () => ({
    type: 'BACK_STEP',
});

/**
 * Action that replaces the entire state at the top level.
 *
 * @param state - new state object, this replaces the old state
 */
export const replaceState = state => ({
    type: 'REPLACE_STATE',
    state,
});

/**
 * Universal error action.
 * Apps should listen to this and display a universal error page.
 *
 * @param error - the error
 */
export const throwError = error => ({
    type: 'THROW_ERROR',
    error,
});

export const terminate = error => ({
    type: 'TERMINATE_FLOW',
    error,
});
