import { schemes as schemeConfig } from 'config/constants';
import { camelToSnakeCase } from '../util';

const getSupportedPaymentNetworks = destinations => {
    try {
        const supportedPaymentNetworks = [];
        for (const destination of destinations) {
            const filtered = Object.keys(destination)
                .filter(value => Object.keys(schemeConfig).includes(value))
                .map(paymentNetwork => camelToSnakeCase(paymentNetwork));
            supportedPaymentNetworks.push(...filtered);
        }
        return [...new Set(supportedPaymentNetworks)];
    } catch (e) {
        // do nothing
    }
};

export default getSupportedPaymentNetworks;
