import React, { Fragment, useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import style from './TermsAndCondition.css';
import { FormattedMessage } from 'react-intl';

const cx = classNames.bind(style);

const EnfuseTermsAndCondition = ({ setDisplayFooterShadow }) => {
    useEffect(() => {
        setDisplayFooterShadow(false);
    }, []);

    const onClick = () => {
        window.close();
    };

    return (
        <Fragment>
            <div className="TermsAndCondition-div">
                <TokenContainer className={cx('TermsAndCondition-container')}>
                    <TokenTitle>
                        <FormattedMessage id={'terms.enfuse.title'} />
                    </TokenTitle>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.enfuse.para1.title'} />
                            :
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage
                                id={'terms.enfuse.para1.text.heading'}
                            />
                            :
                        </p>
                        <ol className={cx('points-content', 'bulleted-points')}>
                            <li>
                                <FormattedMessage
                                    id={'terms.enfuse.para1.text.point1'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id={'terms.enfuse.para1.text.point2'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id={'terms.enfuse.para1.text.point3'}
                                />
                            </li>
                        </ol>
                    </div>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.enfuse.para2.title'} />
                            :
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage
                                id={'terms.enfuse.para2.subpara1.text'}
                            />{' '}
                            <br />
                            Email:
                            <a
                                href="mailto:support@enfuce.com"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={
                                        'terms.enfuse.para2.subpara1.email.text'
                                    }
                                />
                            </a>
                            <br />
                            <br />
                            <FormattedMessage
                                id={'terms.enfuse.para2.subpara2.text'}
                            />
                            <br />
                            Email:
                            <a
                                href="mailto:info@token.io"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={
                                        'terms.enfuse.para2.subpara2.email.text'
                                    }
                                />
                            </a>
                            <br />
                            <br />
                            <FormattedMessage
                                id={'terms.enfuse.para2.subpara3.text'}
                            />
                        </p>
                    </div>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.enfuse.para3.title'} />
                            :
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.enfuse.para3.text'} />
                            <a
                                href="https://token.io/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.enfuse.para3.link'}
                                />
                            </a>
                            .
                        </p>
                    </div>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.enfuse.para4.title'} />
                            :
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage
                                id={'terms.enfuse.para4.subpara1.text.part1'}
                            />
                            <a
                                href="mailto:info@token.io"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.enfuse.para4.subpara1.email'}
                                />
                            </a>
                            <FormattedMessage
                                id={'terms.enfuse.para4.subpara1.text.part2'}
                            />
                            <br />
                            <br />
                            <FormattedMessage
                                id={'terms.enfuse.para4.subpara2.text'}
                            />
                            Email:
                            <a
                                href="mailto:kirjaamo@finanssivalvonta.fi"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.enfuse.para4.subpara2.email'}
                                />
                            </a>
                            .
                        </p>
                    </div>
                </TokenContainer>
            </div>
            <TokenButtonsWrapper>
                <Button
                    type="primary"
                    text={<FormattedMessage id={'terms.button'} />}
                    onClick={onClick}
                />
            </TokenButtonsWrapper>
        </Fragment>
    );
};

EnfuseTermsAndCondition.propTypes = {
    setDisplayFooterShadow: PropTypes.func,
};

export default EnfuseTermsAndCondition;
