import React from 'react';
import PropTypes from 'prop-types';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import { connect } from 'reducers';
import style from './RedirectOptions.css';
import classNames from 'classnames/bind';
import { CUSTOM_TPP_FEATURES as featureConfig } from 'config/constants';
import { redirectToBankUrl, goToTppAfterBackButton } from 'actions/shared';
import { FormattedMessage, intlShape } from 'react-intl';
import { FooterText } from 'components/App/Shared/FooterText';

const cx = classNames.bind(style);

const RedirectOptions = ({
    tppName,
    footer,
    intl,
    bankName,
    redirectToBankUrl,
    goToTppAfterBackButton,
}) => (
    <div className={cx('RedirectOptions')}>
        <TokenContainer className={cx('RedirectOptions-subcontainer')}>
            <TokenTitle>
                <FormattedMessage id="common.redirectOptions.title" />
            </TokenTitle>
            <FormattedMessage
                id="common.redirectOptions.text"
                tagName={'p'}
                values={{
                    TPP_NAME: tppName,
                    BANK_NAME: bankName,
                }}
            />
            <Button
                onClick={async () => {
                    await redirectToBankUrl();
                }}
                text={intl.formatMessage(
                    {
                        id: 'common.redirectOptions.redirectBank',
                    },
                    {
                        BANK_NAME: bankName,
                    },
                )}
            />
            <br />
            <Button
                onClick={async () => {
                    await goToTppAfterBackButton();
                }}
                text={intl.formatMessage(
                    {
                        id: 'common.redirectOptions.redirectTpp',
                    },
                    {
                        TPP_NAME: tppName,
                    },
                )}
            />
        </TokenContainer>
        {(!!footer && (
            <span className={cx('RedirectOptions-footer')}>{footer}</span>
        )) ||
            null}
    </div>
);

RedirectOptions.propTypes = {
    footer: PropTypes.node,
    hideFooter: PropTypes.bool.isRequired,
    tppName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    bankName: PropTypes.string,
    goToTppAfterBackButton: PropTypes.func.isRequired,
    redirectToBankUrl: PropTypes.func.isRequired,
};

RedirectOptions.defaultProps = {
    hideFooter: false,
};

const mapStateToProps = ({ tokenRequestService, sharedService }) => {
    const customFooterText = tokenRequestService.getTppFeature(
        featureConfig.FOOTER_TEXT,
    );
    const footer = customFooterText ? <FooterText /> : '';
    const tppName = tokenRequestService.getTppProfile()?.tppName;
    const actingAs = tokenRequestService.getTppActingAs();
    const bankName = sharedService.getSelectedBankName();
    return {
        footer,
        tppName: actingAs?.displayName || tppName,
        bankName,
    };
};

const mapDispatchToProps = {
    goToTppAfterBackButton,
    redirectToBankUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectOptions);
