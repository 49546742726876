import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import { terminateFlow } from 'actions/shared';
import './index.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.onCloseDialogClick = this.onCloseDialogClick.bind(this);
    }
    onCloseDialogClick() {
        this.props.terminateFlow({
            error: 'access_denied',
            message: 'Token window was cancelled',
        });
    }
    render() {
        return this.props.routes[this.props.currentRoute] || <span />;
    }
}

App.propTypes = {
    routes: PropTypes.object,
    currentRoute: PropTypes.string,
    terminateFlow: PropTypes.func,
};

const mapStateToProps = ({ sharedService }) => ({
    currentRoute: sharedService.getCurrentRoute(),
});

const mapDispatchToProps = {
    terminateFlow,
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default ConnectedApp;
