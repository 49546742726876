import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FlickerCode from './FlickerCode.js';
import FlickerClass from './FlickerClass';
import style from './FlickerImage.css';
import classNames from 'classnames/bind';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';

const cx = classNames.bind(style);

const FlickerImage = ({ flickerCode }) => {
    const flickerCodeInstance = new FlickerCode(flickerCode);
    const flickerImage = new FlickerClass(flickerCodeInstance.getCode());
    const led0Ref = useRef(null);
    const led1Ref = useRef(null);
    const led2Ref = useRef(null);
    const led3Ref = useRef(null);
    const led4Ref = useRef(null);
    const imageRef = useRef(null);
    const [speed, setSpeed] = useState(null);
    const [size, setSize] = useState(null);

    const currentSpeedPercentage = Math.floor((speed / 35) * 100);
    const currentSizePercentage = Math.floor(
        ((size - 210) * 100) / (330 - 210),
    );

    useEffect(() => {
        const leds = [
            led0Ref.current,
            led1Ref.current,
            led2Ref.current,
            led3Ref.current,
            led4Ref.current,
        ];
        flickerImage.startTanBeamer(leds);
    }, []);

    const updateSpeed = newSpeed => {
        setSpeed(newSpeed);
        flickerImage.updateSpeed(newSpeed);
    };

    const updateSize = newSize => {
        setSize(newSize);
        flickerImage.updateSize(newSize, imageRef.current);
    };

    return (
        <div className={cx('FlickerContainer')}>
            <div className={cx('FlickerItems')} ref={imageRef}>
                <table
                    className={cx('FlickerLeds')}
                    cellSpacing="0"
                    cellPadding="0">
                    <tbody>
                        <tr>
                            <td className={cx('Space')}></td>
                            <td ref={led0Ref}></td>
                            <td className={cx('SpaceInBetween')}></td>
                            <td ref={led1Ref}></td>
                            <td className={cx('SpaceInBetween')}></td>
                            <td ref={led2Ref}></td>
                            <td className={cx('SpaceInBetween')}></td>
                            <td ref={led3Ref}></td>
                            <td className={cx('SpaceInBetween')}></td>
                            <td ref={led4Ref}></td>
                            <td className={cx('Space')}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={cx('SlidersContainer')}>
                <label className={cx('SliderLabel')}>
                    Flicker code size - {currentSizePercentage}%
                </label>
                <Nouislider
                    className={cx('Slider')}
                    start={210}
                    connect={[true, false]}
                    step={5}
                    orientation="horizontal"
                    format={{
                        to: function (value) {
                            return value;
                        },
                        from: function (value) {
                            return value;
                        },
                    }}
                    range={{
                        min: 210,
                        max: 330,
                    }}
                    onUpdate={newSize => updateSize(newSize[0])}
                />
                <label className={cx('SliderLabel')}>
                    Flicker code speed - {currentSpeedPercentage}%
                </label>
                <Nouislider
                    className={cx('Slider')}
                    start={10}
                    connect={[true, false]}
                    step={1}
                    orientation="horizontal"
                    format={{
                        to: function (value) {
                            return value;
                        },
                        from: function (value) {
                            return value;
                        },
                    }}
                    range={{
                        min: 1,
                        max: 35,
                    }}
                    onUpdate={newSpeed => updateSpeed(newSpeed[0])}
                />
            </div>
        </div>
    );
};

FlickerImage.propTypes = {
    flickerCode: PropTypes.string,
};

export default FlickerImage;
