import {
    TOKEN_TYPE_VRP,
    LOADING_STEP,
    banksWithBranches,
    processedVrpStatusCodes,
    pendingVrpStatusCodes,
    CUSTOM_TPP_FEATURES as featureConfig,
    ROUTE_VRP,
    VRP_SELECT_BANK_FIRST,
    VRP_CONSENT_BANK_FIRST,
    VRP_CONSENT_STATUS,
    REQUEST_TYPE,
    VRP_CONSENT_INIT_STATUS,
    POPUP_SUCCESS,
    POPUP_FAILURE,
    ROUTE_LOADING,
    BANK_SELECTION_PRESETS,
} from 'config/constants';
import {
    setRecentBank,
    setupBankRedirectUrl,
    setHideConsent,
    fetchCountries,
    setMemberProperties,
    setResellerProfile,
    setTppProfile,
    setTppConfigurations,
    setDefaultBankId,
    setRedirectState,
    setRedirectUrl,
    setBankRedirectUrl,
    setRedirect,
    setTppSource,
    setCustomDevKey,
    getCountry,
    setDefaultCountry,
    setCountriesList,
    storeRecentBanks,
    yieldBanks,
    setBank,
    fetchBankBranches,
    clearInstructionsSource,
    clearBank,
    goToTpp,
    setTppReturnData,
} from 'actions/shared';
import {
    fetchVrpConsent,
    updateVrpRequest,
    declineVrpConsent,
    getMemberInfo,
    getMemberProfileById,
    getTempMember,
    getBankFromLocalStorage,
} from 'actions/shared/api';
import {
    getURLParameter,
    validateBrowserCompatibility,
    removeCustomCss,
    extractDateFromISO,
    addCustomCss,
} from 'util/index';
import { changeRoute, setStep } from 'actions/shared/route';
import {
    INTL_EXCEPTION_REQUEST_FAILED,
    EXCEPTION_VRP_REQUEST,
    EXCEPTION_INVALID_PATH,
    EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE,
} from 'config/exceptions';
import { TokenException } from '@token-io/lib-web-app';
import { updateCustomization } from '../helpers';
import { getOverallTppConfigurations } from '../helpers';

export const setVrpId = vrpId => ({
    type: 'SET_VRP_ID',
    vrpId,
});

export const setVrpRefId = vrpRefId => ({
    type: 'SET_VRP_REF_ID',
    vrpRefId,
});

export const setVrpStatus = status => ({
    type: 'SET_VRP_STATUS',
    status,
});

export const setVrpCreditorInfo = creditorInfo => ({
    type: 'SET_VRP_CREDITOR_INFO',
    creditorInfo,
});

export const setVrpDebtorInfo = debtorInfo => ({
    type: 'SET_VRP_DEBTOR_INFO',
    debtorInfo,
});

export const setVrpPeriodicLimit = periodicLimits => ({
    type: 'SET_VRP_PERIODIC_LIMITS',
    periodicLimits,
});

export const setVrpExpirationDate = expirationDate => ({
    type: 'SET_VRP_EXPIRATION_DATE',
    expirationDate,
});

export const setPispConsentAccepted = pispConsentAccepted => ({
    type: 'SET_PISP_CONSENT_ACCEPTED',
    pispConsentAccepted,
});

export const setVrpDescription = description => ({
    type: 'SET_VRP_DESCRIPTION',
    description,
});

export const setVrpLocalInstrument = localInstrument => ({
    type: 'SET_VRP_LOCAL_INSTRUMENT',
    localInstrument,
});

export const setVrpMaxAmount = maxIndividualAmount => ({
    type: 'SET_MAX_AMOUNT',
    maxIndividualAmount,
});

export const setVrpTokenType = ({ tppAlias, memberId } = {}) => {
    return {
        type: 'SET_TOKEN_TYPE',
        tokenType: TOKEN_TYPE_VRP,
        tppAlias,
        memberId,
    };
};

export const setCurrency = currency => ({
    type: 'SET_VRP_CURRENCY',
    currency,
});

// Return true if validation succeeded otherwise return false
export const validateVRPConsent = vrpConsent => async dispatch => {
    if (!vrpConsent) return;
    if (
        processedVrpStatusCodes.includes(vrpConsent.status) ||
        pendingVrpStatusCodes.includes(vrpConsent.status)
    ) {
        await dispatch(
            returnToTpp(POPUP_SUCCESS, {
                'member-id': vrpConsent.memberId,
                'vrp-consent-id': vrpConsent.id,
                status: vrpConsent.status,
                state: vrpConsent.initiation?.callbackState,
            }),
        );
        return false;
    }
    return true;
};

export const updateAndValidateVrpConsent = bankId => async (
    dispatch,
    { vrpService, tokenRequestService },
) => {
    const vrpId = vrpService.getVrpRequestId();
    const pispConsentAccepted = vrpService.getPispConsentAccepted();
    const vrpConsent = await updateVrpRequest(vrpId, {
        ...(!tokenRequestService.getDefaultBankId() && {
            bankId,
        }),
        pispConsentAccepted,
    });
    if (vrpConsent) {
        const { authentication, status } = vrpConsent;
        authentication?.redirectUrl &&
            dispatch(setBankRedirectUrl(authentication.redirectUrl));
        status && dispatch(setVrpStatus(status));
    }
    return await dispatch(validateVRPConsent(vrpConsent));
};

// Fetches vrp request info, and saves properties to redux store
export const fetchVrpConsentInfo = bankCallback => async (
    dispatch,
    { vrpService, tokenRequestService },
) => {
    let result;
    const vrpId = vrpService.getVrpRequestId();
    try {
        result = await fetchVrpConsent(vrpId);
    } catch (e) {
        throw new TokenException(
            INTL_EXCEPTION_REQUEST_FAILED,
            (e.response && e.response.data) || e.message,
        );
    }
    const vrpConsent = result && { ...result.vrpConsent };
    if (vrpConsent) {
        const subTppRefId = vrpConsent?.initiation?.onBehalfOfId;
        const memberResp = await getMemberInfo(
            vrpConsent.memberId,
            subTppRefId,
        );
        const { memberInfo } = memberResp;
        dispatch(setHideConsent(memberInfo.hideConsent));
        const bankSelectionPreset = memberResp.bankSelectionPreset;
        if (bankSelectionPreset === BANK_SELECTION_PRESETS.DISABLE_ALL) {
            throw new TokenException(
                EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE,
                EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE.message,
            );
        }

        const tppAlias = memberInfo?.alias;
        const realmId = tppAlias?.realmId;

        const memberProperties = {
            realmId,
            memberId: vrpConsent.memberId,
            subTppRefId,
        };
        dispatch(setMemberProperties(memberProperties));
        const guest = await dispatch(getTempMember());
        if (realmId) {
            const resellerProfile = await getMemberProfileById(realmId, guest);
            dispatch(setResellerProfile(resellerProfile));
        }
        const tppProfile = await getMemberProfileById(
            vrpConsent.memberId,
            guest,
        );
        dispatch(setTppProfile(tppProfile));

        let { tppConfigurations } = memberResp;
        if (tppConfigurations) {
            tppConfigurations = {
                ...updateCustomization(tppConfigurations),
                bankSelectionPreset,
            };
            dispatch(
                setTppConfigurations(
                    getOverallTppConfigurations(
                        memberProperties,
                        tppConfigurations,
                    ),
                ),
            );
        }
        dispatch(setVrpRefId(vrpConsent.initiation?.refId));
        dispatch(
            setVrpTokenType({
                tppAlias: tppAlias,
                memberId: vrpConsent.memberId,
            }),
        );

        vrpConsent.initiation?.bankId &&
            dispatch(setDefaultBankId(vrpConsent.initiation.bankId));
        vrpConsent.authentication?.redirectUrl &&
            dispatch(
                setBankRedirectUrl(vrpConsent.authentication?.redirectUrl),
            );
        vrpConsent.status && dispatch(setVrpStatus(vrpConsent.status));
        vrpConsent.initiation?.callbackState &&
            dispatch(setRedirectState(vrpConsent.initiation?.callbackState));
        vrpConsent.initiation?.currency &&
            dispatch(setCurrency(vrpConsent.initiation.currency));
        vrpConsent.initiation?.callbackUrl &&
            dispatch(setRedirectUrl(vrpConsent.initiation?.callbackUrl));
        vrpConsent.initiation?.creditor &&
            dispatch(setVrpCreditorInfo(vrpConsent.initiation?.creditor));
        vrpConsent.initiation?.debtor &&
            dispatch(setVrpDebtorInfo(vrpConsent.initiation?.debtor));
        vrpConsent.initiation?.maximumIndividualAmount &&
            dispatch(
                setVrpMaxAmount(vrpConsent.initiation?.maximumIndividualAmount),
            );
        vrpConsent.initiation?.localInstrument &&
            dispatch(
                setVrpLocalInstrument(vrpConsent.initiation?.localInstrument),
            );
        vrpConsent.initiation?.remittanceInformationPrimary &&
            dispatch(
                setVrpDescription(
                    vrpConsent.initiation?.remittanceInformationPrimary,
                ),
            );
        vrpConsent.initiation?.periodicLimits &&
            dispatch(
                setVrpPeriodicLimit(vrpConsent.initiation?.periodicLimits),
            );
        vrpConsent.initiation?.endDateTime &&
            dispatch(
                setVrpExpirationDate(
                    extractDateFromISO(vrpConsent.initiation?.endDateTime),
                ),
            );
        dispatch(setPispConsentAccepted(result?.pispConsentAccepted));
        if (tokenRequestService.hasCustomColors()) {
            await addCustomCss(vrpId, REQUEST_TYPE.VRP_CONSENT, {
                ...tokenRequestService.getTppMemberAndRealmId(),
                subTppId: subTppRefId,
            });
        } else {
            removeCustomCss();
        }
        await dispatch(storeRecentBanks());

        if (!bankCallback) {
            const proceedToWebappFlow = await dispatch(
                validateVRPConsent(vrpConsent),
            );
            if (proceedToWebappFlow) {
                return vrpConsent;
            }
        }
    }
};

// Step 1 - show consent screen
export const initiateVrpFlow = () => async dispatch => {
    dispatch(changeRoute(ROUTE_VRP));
    await dispatch(setCountriesList());
    dispatch(acceptTermsBankFirstFlow());
};

export const declineTerms = () => async (dispatch, { vrpService }) => {
    dispatch(changeRoute(ROUTE_LOADING));
    const vrpId = vrpService.getVrpRequestId();
    await declineVrpConsent(vrpId);
    dispatch(
        terminateVrpFlow({ error: 'access_denied', message: 'Terms declined' }),
    );
};

export const initiateBankCallbackFlowV2 = () => dispatch => {
    if (getURLParameter('vrp-consent-id')) {
        dispatch(initiateVrpBankCallbackFlow());
    } else {
        throw new TokenException(EXCEPTION_INVALID_PATH, 'Path is invalid');
    }
};

export const initiateVrpBankCallbackFlow = () => async dispatch => {
    const vrpConsentId = getURLParameter('vrp-consent-id');
    dispatch(setVrpId(vrpConsentId));
    const memberId = getURLParameter('member-id');
    const redirectUrl = getURLParameter('redirect-url');
    const status = getURLParameter('status');
    const state = getURLParameter('state');
    redirectUrl && dispatch(setRedirectUrl(redirectUrl));
    dispatch(
        returnToTpp(POPUP_SUCCESS, {
            'member-id': memberId,
            'vrp-consent-id': vrpConsentId,
            status,
            state,
        }),
    );
};

export const handleInitiation = () => async dispatch => {
    dispatch(setRedirect());
    dispatch(setTppSource(getURLParameter('source')));
    dispatch(setCustomDevKey(getURLParameter('dk')));
    const defaultCountry = await getCountry();
    dispatch(setDefaultCountry(defaultCountry));

    if (getURLParameter('vrp-consent-id')) {
        await dispatch(initiateVrpSetupRedirectFlow());
    } else {
        throw new TokenException(EXCEPTION_INVALID_PATH, 'Path is invalid');
    }
};

// Initiates the VRP flow. This is called as soon as the TPP redirects to the web-app to setup VRP
export const initiateVrpSetupRedirectFlow = () => async dispatch => {
    validateBrowserCompatibility();
    const vrpId = decodeURIComponent(getURLParameter('vrp-consent-id'));
    dispatch(setVrpId(vrpId));
    const vrpConsent = await dispatch(fetchVrpConsentInfo());
    if (vrpConsent) {
        await dispatch(fetchCountries(vrpConsent?.memberId));
        dispatch(initiateVrpFlow());
    }
};

export const proceedToBankForVrp = () => async (
    dispatch,
    { tokenRequestService, sharedService, tokenService, vrpService },
) => {
    dispatch(setStep(LOADING_STEP));
    dispatch(setPispConsentAccepted(true));
    const bank = sharedService.getSelectedBank();
    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );
    if (
        Object.prototype.hasOwnProperty.call(banksWithBranches, bank.name) &&
        doesTppSupportRecentBanks
    ) {
        await dispatch(setRecentBank(bank));
    }

    let isConsentValid = true;
    isConsentValid = await dispatch(updateAndValidateVrpConsent(bank.id));
    if (
        isConsentValid &&
        vrpService.getStatus() === VRP_CONSENT_STATUS.PENDING_REDIRECT_AUTH &&
        tokenService.getBankRedirectUrl()
    ) {
        await dispatch(setupBankRedirectUrl(tokenService.getBankRedirectUrl()));
    } else if (isConsentValid) {
        return dispatch(
            terminateVrpFlow({
                ...EXCEPTION_VRP_REQUEST[VRP_CONSENT_STATUS.UNSUCCESSFUL],
            }),
        );
    }
};

export const acceptTermsBankFirstFlow = () => async (
    dispatch,
    { tokenRequestService, sharedService },
) => {
    let defaultBankId;
    let country;

    const recentBanks = sharedService.getRecentBanks();

    defaultBankId = tokenRequestService.getDefaultBankId();

    const bankName =
        (recentBanks[0] &&
            recentBanks[0].id[0].toUpperCase() + recentBanks[0].id.slice(1)) ||
        '';
    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );
    // Check if recent bank supports the payment mode, if present
    if (
        doesTppSupportRecentBanks &&
        defaultBankId === undefined &&
        recentBanks[0] &&
        !Object.keys(banksWithBranches).includes(bankName)
    ) {
        const { banks } = await dispatch(
            yieldBanks({ ids: [recentBanks[0].id] }),
        );
        const bankDetail = banks?.[0];
        if (bankDetail) {
            defaultBankId = recentBanks[0].id;
            country = recentBanks[0].selectedCountry;
        }
    }

    const bank = getBankFromLocalStorage();
    if (defaultBankId && !bank) {
        const { banks } = await dispatch(yieldBanks({ ids: [defaultBankId] }));
        let defaultBank = banks?.[0];

        // If the bank id is already provided in the vrp setup request then
        // consider country as first element of countries array from bank config
        if (!country) {
            country = defaultBank?.countries[0];
        }
        defaultBank = { ...defaultBank, selectedCountry: country };

        await dispatch(setBankAndProceedToConsent(defaultBank));
    } else {
        dispatch(setStep(VRP_SELECT_BANK_FIRST));
    }
};

export const setBankAndProceedToConsent = bank => async (
    dispatch,
    { tokenRequestService, vrpService },
) => {
    dispatch(setStep(LOADING_STEP));

    const doesTppSupportRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );

    if (
        doesTppSupportRecentBanks &&
        !Object.prototype.hasOwnProperty.call(banksWithBranches, bank.name)
    ) {
        await dispatch(setRecentBank(bank));
    }

    dispatch(setBank(bank));

    if (Object.keys(banksWithBranches).includes(bank.name)) {
        await dispatch(
            fetchBankBranches(bank.name, bank.selectedCountry || bank.country),
        );
    }

    if (
        tokenRequestService.getHideConsent() ||
        vrpService.getPispConsentAccepted()
    ) {
        dispatch(proceedToBankForVrp());
    } else {
        dispatch(setStep(VRP_CONSENT_BANK_FIRST));
    }
};

export const backToBankSelector = () => async dispatch => {
    dispatch(clearBank());
    dispatch(clearInstructionsSource());
    dispatch(setStep(VRP_SELECT_BANK_FIRST));
};

export const returnToTpp = (popupStatus, payload) => async (
    dispatch,
    { tokenRequestService },
) => {
    dispatch(setTppReturnData({ popupStatus, payload }));
    await dispatch(fetchVrpConsentInfo(true));
    const doesTppSupportPsr44Flow = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_PSR44_FLOW,
    );
    if (doesTppSupportPsr44Flow) {
        const defaultBankId = tokenRequestService.getDefaultBankId();
        const { banks } = await dispatch(yieldBanks({ ids: [defaultBankId] }));
        let defaultBank = banks?.[0];
        defaultBank = {
            ...defaultBank,
            selectedCountry: defaultBank?.countries[0],
        };
        dispatch(setBank(defaultBank));
        dispatch(setStep(VRP_CONSENT_INIT_STATUS));
        dispatch(changeRoute(ROUTE_VRP));
    } else {
        await dispatch(goToTpp());
    }
};

export const terminateVrpFlow = message => async (
    dispatch,
    { tokenRequestService, vrpService },
) => {
    const redirectState = tokenRequestService.getRequestRedirectState();
    const requestId = vrpService.getVrpRequestId();
    dispatch(
        returnToTpp(POPUP_FAILURE, {
            ...message,
            'vrp-consent-id': requestId,
            state: redirectState,
        }),
    );
};
