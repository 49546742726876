import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import style from './Consent.css';
import { FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import MandatoryFields from './MandatoryFields';
import TermsLoader from 'components/App/Terms/TermsLoader';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import {
    backToBankSelector,
    proceedToBank,
    setMandatoryAccessFields,
} from 'actions/aisp';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
    CUSTOM_TPP_FEATURES as featureConfig,
    defaultAppName,
} from 'config/constants';
import { mandatoryFieldsConfig } from 'config/mandatoryFields';
import { declineTerms } from 'actions/shared';
import {
    sanitizeHTML,
    convertToReadableDate,
    hideConsentText,
    checkMandatoryFieldsPresent,
} from '../../../util/index.js';
import HrefToPopup from '../Shared/HrefToPopup.js';

const cx = classNames.bind(style);

class ConsentBankFirstFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.consentTextRef = createRef();
    }

    isIbanMandatory = () => {
        if (
            this.props.requiredMandatoryFields?.fields?.includes(
                mandatoryFieldsConfig.access?.iban,
            )
        ) {
            return true;
        }
        return false;
    };

    confirm = () => {
        const requiredMandatoryFields = this.props.requiredMandatoryFields;
        requiredMandatoryFields &&
            this.props.setMandatoryAccessFields(this.state.mandatoryFields);
        this.props.proceedToBank();
    };

    handleMandatoryFields = obj => {
        this.setState(prevState => ({
            mandatoryFields: {
                ...prevState.mandatoryFields,
                ...obj,
            },
        }));
    };

    handleIbanValidation = isIbanValid => {
        this.setState({ isIbanValid });
    };

    componentDidMount = () => {
        this.props.setDisplayFooterShadow(false);
    };

    render() {
        const {
            declineTerms,
            aispName,
            businessName,
            resources,
            backToBankSelector,
            defaultBankId,
            resourceTypeList,
            modifyPreSelectedBank,
            requiredMandatoryFields,
        } = this.props;
        const expireDate = this.props.tokenExpiration
            ? new Date(Number(this.props.tokenExpiration))
            : new Date();
        !this.props.tokenExpiration &&
            expireDate.setDate(
                expireDate.getDate() + DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
            );

        const messageVariableValues = {
            BUSINESS_NAME: `<strong>${businessName}</strong>`,
            DISPLAY_NAME: `<span>${aispName}</span>`,
            EXPIRATION_DATE: `<strong>${convertToReadableDate(
                expireDate,
            )}</strong>`,
        };

        const consentText = this.props.intl.formatMessage(
            {
                id: 'aisp.consent.text',
                defaultMessage: '',
            },
            messageVariableValues,
        );

        const hideConsentTextBlock = hideConsentText(consentText);

        const status = () => {
            if (
                this.state.mandatoryFields &&
                !checkMandatoryFieldsPresent(
                    this.state.mandatoryFields,
                    requiredMandatoryFields,
                    'access',
                )
            ) {
                return Button.STATUS_DISABLED;
            }
            if (this.isIbanMandatory()) {
                if (
                    this.state.mandatoryFields?.source?.accountIdentifier?.iban
                ) {
                    if (!this.state.isIbanValid) {
                        return Button.STATUS_DISABLED;
                    }
                } else {
                    return Button.STATUS_DISABLED;
                }
            } else {
                return Button.STATUS_IDLE;
            }
        };

        return (
            <Fragment>
                <TokenContainer
                    className={cx({
                        'Consent-container': true,
                        'Aisp-container': true,
                    })}
                    noMargin>
                    <div className={cx('Ais-container-margin')}>
                        <TokenTitle>
                            <FormattedMessage
                                id={
                                    resources[0].type
                                        ? 'aisp.caf.consent.title'
                                        : 'aisp.consent.title'
                                }
                            />
                        </TokenTitle>
                        <TokenTitle sub>
                            <FormattedMessage
                                id={'aisp.consent.subtitle1'}
                                values={{ BUSINESS_NAME: businessName }}
                            />
                        </TokenTitle>
                    </div>
                    <div className={cx('Consent-data')}>
                        {typeof resources[0] === 'string' ? (
                            resources.map(r => (
                                <div
                                    className={cx('Consent-data-row')}
                                    key={`aisp.consent.resources.${r}`}>
                                    <div
                                        className={cx(
                                            'Consent-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            id={`aisp.consent.resources.${r}`}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <FormattedMessage id={'aisp.consent.accountInfo'} />
                        )}
                        {requiredMandatoryFields?.fields && (
                            <MandatoryFields
                                requiredMandatoryFields={
                                    requiredMandatoryFields?.fields
                                }
                                resourceTypeList={resourceTypeList}
                                handleMandatoryFields={obj =>
                                    this.handleMandatoryFields(obj)
                                }
                                getIbanIsValid={this.handleIbanValidation}
                                parentState={this.state.mandatoryFields}
                                type="access"
                            />
                        )}
                    </div>

                    {!hideConsentTextBlock && (
                        <HrefToPopup
                            contentRef={this.consentTextRef}
                            fallbackToOverlay>
                            <div
                                className={cx({
                                    'Consent-details': true,
                                })}
                                ref={this.consentTextRef}
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(consentText),
                                }}
                            />
                        </HrefToPopup>
                    )}

                    <TermsLoader />
                </TokenContainer>

                <TokenButtonsWrapper>
                    <Button
                        status={status()}
                        onClick={this.confirm}
                        text={<FormattedMessage id={'common.button.accept'} />}
                    />
                    {defaultBankId !== '' && !modifyPreSelectedBank ? (
                        <Button
                            type={Button.TYPE_GHOST_WARNING}
                            onClick={declineTerms}
                            text={
                                <FormattedMessage
                                    id={'common.button.decline'}
                                />
                            }
                        />
                    ) : (
                        <Button
                            type={Button.TYPE_GHOST}
                            onClick={backToBankSelector}
                            status={Button.STATUS_IDLE}
                            text={
                                <FormattedMessage id={'common.button.back'} />
                            }
                        />
                    )}
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

ConsentBankFirstFlow.propTypes = {
    declineTerms: PropTypes.func.isRequired,
    aispName: PropTypes.string,
    businessName: PropTypes.string.isRequired,
    resources: PropTypes.array.isRequired,
    oneStepSupport: PropTypes.bool.isRequired,
    proceedToBank: PropTypes.func.isRequired,
    backToBankSelector: PropTypes.func.isRequired,
    banksBranchList: PropTypes.object,
    aispAlias: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    tokenExpiration: PropTypes.string,
    defaultBankId: PropTypes.string,
    resourceTypeList: PropTypes.object,
    requiredMandatoryFields: PropTypes.object,
    setMandatoryAccessFields: PropTypes.func,
    modifyPreSelectedBank: PropTypes.bool,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = ({
    tokenService,
    tokenRequestService,
    sharedService,
}) => {
    const tppName = tokenRequestService.getTppProfile().tppName;
    const tppAlias = tokenRequestService.getTppAlias();
    const actingAs = tokenRequestService.getTppActingAs();
    const defaultBankId = tokenRequestService.getDefaultBankId() || '';
    const resources = tokenService.getResources();
    const resourceTypeList = tokenService.getResourceTypeList();
    const tokenExpiration = tokenRequestService.getTokenExpiration();
    const selectedBank = sharedService.getSelectedBank();
    const modifyPreSelectedBank = tokenRequestService.getTppFeature(
        featureConfig.MODIFY_PRE_SELECTED_BANK,
        false,
    );
    return {
        aispAlias: actingAs?.secondaryName || tppAlias.value,
        businessName:
            tokenRequestService.getTppFeature(featureConfig.AIS_APP_NAME) ||
            defaultAppName,
        aispName: actingAs?.displayName || tppName,
        defaultBankId,
        resourceTypeList,
        resources: resources,
        oneStepSupport: sharedService.getDoesBankSupportOneStep(),
        banksBranchList: sharedService.getBanksBranchList() || {},
        requiredMandatoryFields: selectedBank?.mandatoryFields?.access,
        tokenExpiration,
        modifyPreSelectedBank,
    };
};

const mapDispatchToProps = {
    backToBankSelector,
    proceedToBank,
    declineTerms,
    setMandatoryAccessFields,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConsentBankFirstFlow);
