import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const DebounceRender = props => {
    const { timeout, children, ...rest } = props;

    const [isReadyToRender, setIsReadyToRender] = useState(false);
    const ref = useRef();

    useEffect(() => {
        setIsReadyToRender(false);
        if (ref.current) {
            clearTimeout(ref.current);
        }
        ref.current = setTimeout(() => {
            setIsReadyToRender(true);
            return () => {
                clearTimeout(ref.current);
            };
        }, timeout);
    }, [rest ? JSON.stringify(rest) : []]);

    return isReadyToRender && <>{React.cloneElement(children, { ...rest })}</>;
};

DebounceRender.propTypes = {
    timeout: PropTypes.number,
    children: PropTypes.any,
};

DebounceRender.defaultProps = {
    timeout: 300,
};

export default DebounceRender;
