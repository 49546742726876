export const mandatoryFieldsConfig = {
    transfer: {
        creditorName:
            'transfer_body.instructions.transfer_destinations.customer_data.legal_names',
        debtorName:
            'transfer_body.instructions.source.customer_data.legal_names',
        debtorAccount: 'transfer_body.instructions.source.account_identifier',
        debtorBic: 'transfer_body.instructions.source.bic',
        creditorAddress: [
            'transfer_body.instructions.transfer_destinations.customer_data.address.street',
            'transfer_body.instructions.transfer_destinations.customer_data.address.house_number',
            'transfer_body.instructions.transfer_destinations.customer_data.address.post_code',
            'transfer_body.instructions.transfer_destinations.customer_data.address.country',
            'transfer_body.instructions.transfer_destinations.customer_data.address.city',
        ],
        stet: {
            creditorAgent:
                'transfer_body.instructions.metadata.provider_transfer_metadata.stet_transfer_metadata.beneficiary.creditor_agent',
        },
        polishApi: {
            deliveryMode:
                'transfer_body.instructions.metadata.provider_transfer_metadata.polish_api_transfer_metadata.delivery_mode',
        },
        bban: 'transfer_body.instructions.source.account_identifier.bban',
    },
    standingOrder: {
        creditorName:
            'standing_order_body.instructions.transfer_destinations.customer_data.legal_names',
        debtorName:
            'standing_order_body.instructions.source.customer_data.legal_names',
        debtorAccount:
            'standing_order_body.instructions.source.account_identifier',
        debtorBic: 'standing_order_body.instructions.source.bic',
        stet: {
            creditorAgent:
                'standing_order_body.instructions.metadata.provider_standing_order_metadata.stet_standing_order_metadata.beneficiary.creditor_agent',
        },
        polishApi: {
            deliveryMode:
                'standing_order_body.instructions.metadata.provider_standing_order_metadata.polishapi_standing_order_metadata.delivery_mode',
        },
        bban: 'transfer_body.instructions.source.account_identifier.bban',
    },
    access: {
        iban: 'access_body.resource_type_list.source.account_identifier.iban',
        currency: 'access_body.resource_type_list.source.currency',
    },
};
