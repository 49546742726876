import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './TextInput.css';
import classNames from 'classnames/bind';
import EyeIcon from '../Icons/EyeIcon';

const cx = classNames.bind(style);

const TextInput = React.forwardRef(
    (
        {
            value,
            onChange,
            className,
            placeholder,
            dataTip,
            validateInput,
            type,
            ...props
        },
        ref,
    ) => {
        const [tabFocused, setFocus] = useState(false);
        const [isVisible, setVisibility] = useState(false);

        const setPasswordVisible = visible => {
            setVisibility(visible);
        };

        const setTabFocus = focus => {
            setFocus(focus);
        };
        const isPassword = type === 'password';
        const inputType = isPassword && !isVisible ? 'password' : 'text';

        return (
            <div
                className={cx({
                    'TextInput-container': true,
                })}
                onKeyUp={e => {
                    if (e.key === 'Tab') setTabFocus(true);
                }}
                tabIndex="-1">
                <input
                    placeholder={placeholder}
                    value={value}
                    onChange={e => onChange && onChange(e)}
                    onFocus={e => e.target.select()}
                    onBlur={() => {
                        setTabFocus(false);
                    }}
                    {...props}
                    className={cx({
                        TextInput__inner: true,
                        'Input--tabFocused': !!tabFocused,
                        [className]: !!className,
                        'Input--password': !!isPassword,
                        Invalid__input: !!validateInput,
                    })}
                    ref={ref}
                    type={inputType}
                    tabIndex="0"
                />
                {isPassword && (
                    <EyeIcon
                        setPasswordVisible={setPasswordVisible}
                        isVisible={isVisible}
                    />
                )}
                {dataTip !== '' ? (
                    <span className="Tooltip-text">{dataTip}</span>
                ) : null}
            </div>
        );
    },
);

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
    value: '',
    placeholder: '',
    onChange: () => {},
    dataTip: '',
    validateInput: false,
    type: 'text',
};

TextInput.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    dataTip: PropTypes.string,
    validateInput: PropTypes.bool,
    type: PropTypes.string,
};

export default TextInput;
