import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from '../../../css/List.css';
import {withStatic} from '../../Hoc.jsx';
import AbstractList from '../List/Abstract';
import ListRow from '../List/Row';
import ListCellRadio from '../List/CellRadio';
import ListCellLoader from '../List/CellLoader';
import {ROW_SIZE_STANDARD, ROW_SIZE_LARGE} from '../List/Abstract';
import RadioButton from '.';
import {compose, isNull} from '../../Util';
import {SEARCH_RESET_DELAY} from '../../config/constants';

const cx = classNames.bind(style);

let timer = Date.now();

const List = React.forwardRef(({
    data,
    value,
    loadingFirstBatch,
    loadingMore,
    border,
    selected,
    setSelected,
    withPointer,
    className,
    setSearch,
    searchTerm,
    rowClass,
    spinnerColor,
    size,
    allowDeselect,
    idKey,
    tabIndex,
    ariaLabel,
    showFocusOutline,
    focused,
    ...props
}, ref) => (
    <AbstractList
        ref={ref}
        className={cx({
            [className]: !!className,
            'List--no-border': border === false,
        })}
        onMouseOver={over => {
            const selectedIndex = data.findIndex(item => item === value);
            withPointer && !over && selectedIndex >= 0 && setSelected(selectedIndex, {
                preventScroll: true,
            });
        }}
        keyDown={event => {
            const { key } = event;
            if (key.length === 1 && /[a-z0-9]/i.test(key)) {
                const now = Date.now();
                if (now - timer >= SEARCH_RESET_DELAY) {
                    setSearch(key);
                } else {
                    setSearch(searchTerm + key);
                }
                timer = now;
            } else if (key === 'ArrowDown') {
                withPointer && setSelected(isNull(selected) ? 0 : selected + 1, {scroll: true, triggerChange: true});
            } else if (key === 'ArrowUp') {
                withPointer && setSelected(isNull(selected) ? 0 : selected - 1, {scroll: true, triggerChange: true});
            } else if (key === 'Tab' || key === 'Escape') {
                withPointer && allowDeselect && setSelected(null, {triggerChange: true});
                showFocusOutline(false);
                ref.current.blur();
            } else if (key === 'Enter') {
                event.stopPropagation();
                withPointer && setSelected(selected, {triggerChange: true});
            }
        }}
        keyUp={event => {
            const {key} = event;
            if (key === 'Tab') {
                showFocusOutline(true);
            }
        }}
        blur={blur => blur && showFocusOutline(false)}
        withPointer={withPointer}
        size={size}
        {...props}
        tabIndex={tabIndex}
        ariaLabel={ariaLabel}
        ariaExpanded={focused}
        role={'radiogroup'}
        activeDescendant={`${className}-${selected}`}>
        {([
            ...data.map((item, index) => (
                <ListRow
                    id={`${className}-${index}`}
                    key={item.key + item.title}
                    className={cx({
                        [rowClass]: !!rowClass,
                    })}
                    role='radio'
                    onClick={e => {
                        e.stopPropagation();
                        showFocusOutline(false);
                        withPointer && setSelected(index, {triggerChange: true});
                    }}
                    onMouseOver={over => withPointer && over && setSelected(index, {
                        preventScroll: true,
                    })}
                    ariaSelected={(selected === index)}
                    ariaLabel={item.title + item.data}>
                    <ListCellRadio
                        title={item.title}
                        data={item.data}
                        extra={item.extra}
                        value={(
                            (value && value.rawData && value.rawData[idKey]
                            || value && value.rawData) || value) === (
                            (item && item.rawData && item.rawData[idKey]
                            || item && item.rawData) || item)}
                        highlighted={selected === index} />
                </ListRow>
            )),
            (loadingFirstBatch || loadingMore) && (
                <ListRow
                    key={-1}>
                    <ListCellLoader spinnerColor={spinnerColor} />
                </ListRow>
            ),
        ]).filter(i => !!i)}
    </AbstractList>
));

List.displayName = 'RadioList';

List.propTypes = {
    data: PropTypes.array,
    loadingFirstBatch: PropTypes.bool,
    loadingMore: PropTypes.bool,
    border: PropTypes.bool,
    selected: PropTypes.number,
    setSearch: PropTypes.func,
    searchTerm: PropTypes.string,
    rowClass: PropTypes.string,
    spinnerColor: PropTypes.string,
    value: PropTypes.any,
    setSelected: PropTypes.func,
    withPointer: PropTypes.bool,
    allowDeselect: PropTypes.bool,
    idKey: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    status: PropTypes.oneOf([
        RadioButton.STATUS_IDLE,
        RadioButton.STATUS_DISABLED,
    ]),
    tabIndex: PropTypes.string,
    ariaLabel: PropTypes.string,
    focused: PropTypes.bool,
    showFocusOutline: PropTypes.func,
};

List.defaultProps = {
    border: true,
    selected: null,
    setSelected: () => null,
    showFocusOutline: () => null,
    size: ROW_SIZE_STANDARD,
    idKey: 'id',
};

const ListComposer = compose(
    withStatic({
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    }),
);

export default ListComposer(List);
