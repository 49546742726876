import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import style from '../../../css/Spinner.css';
import classNames from 'classnames/bind';
import spinner from '../../../assets/spinner.svg';
import '../../../assets/spinner-big.svg';
import spinnerWhite from '../../../assets/spinner-white.svg';

const cx = classNames.bind(style);

const defaults = {
    big: {
        width: 106,
        height: 106,
        viewBox: '0 0 106 106',
        strokeWidth: 10.5,
    },
    small: {
        width: 15,
        height: 15,
        viewBox: '0 0 106 106',
        strokeWidth: 15,
    },
};

const SpinnerSVG = ({strokeWidth, color = '#1ea6ed', ...props}) => (
    <svg {...props}>
        <g fill='none' strokeMiterlimit={10} strokeWidth={strokeWidth}>
            <circle cx={53} cy={53} r={45}
                stroke='#e9eaf0'
                strokeDasharray='206 71 0'
                transform='rotate(274 53 53)' />
            <circle cx={53} cy={53} r={45}
                stroke={color}
                strokeDasharray='71 227 0'
                transform='rotate(180 53 53)' />
        </g>
    </svg>
);

SpinnerSVG.propTypes = {
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
};

export const SpinnerBig = ({className, color, message}) => (
    <div className={cx('SpinnerContainer')}>
        {message && (
            <div className={cx('SpinnerMessage')}>
                {message}
            </div>
        )}
        <SpinnerSVG
            className={cx([
                'SpinnerBig__image',
                className,
            ])}
            color={color}
            {...defaults.big} />
    </div>
);

SpinnerBig.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    color: PropTypes.string,
};

export const SpinnerSmall = ({className, color}) => (
    <SpinnerSVG
        className={cx([
            'SpinnerSmall__image',
            className,
        ])}
        color={color}
        {...defaults.small} />
);

SpinnerSmall.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

export const SpinnerImage = ({className, message, white}) => {
    return (
        <Fragment>
            {message && (
                <div className={cx('SpinnerMessage')}>
                    {message}
                </div>
            )}
            <img className={cx([
                'SpinnerImage',
                className,
            ])} src={white ? spinnerWhite : spinner} />
        </Fragment>
    );
};

SpinnerImage.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    white: PropTypes.bool,
};

const SpinnerWithLockSVG = ({strokeWidth, color = '#1ea6ed', ...props}) => (
    <Fragment>
        <svg {...props}>
            <g fill='none' strokeMiterlimit={10} strokeWidth={strokeWidth}>
                <circle cx={53} cy={53} r={45}
                    stroke='#e9eaf0'
                    strokeDasharray='206 71 0'
                    transform='rotate(274 53 53)' />
                <circle cx={53} cy={53} r={45}
                    stroke={color}
                    strokeDasharray='71 227 0'
                    transform='rotate(180 53 53)' />
            </g>
        </svg>
        <svg width='28' height='42' viewBox='0 0 28 42' fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <rect x='1' y='17' width='26' height='24' rx='2' stroke='#A5A5AA' strokeWidth='2' />
            <path d='M5 10C5 5.02944 9.02944 1 14 1C18.9706 1 23 5.02944 23 10V17H5V10Z' stroke='#A5A5AA' strokeWidth='2' />
            <path d='M9 10C9 7.23858 11.2386 5 14 5C16.7614 5 19 7.23858 19 10V17H9V10Z' stroke='#A5A5AA' strokeWidth='2' />
            <mask id='path-4-inside-1' fill='white' >
                <path fillRule='evenodd' clipRule='evenodd' d='M17.1786 28.4286C17.6939 27.7553 18 26.9134 18 26C18 23.7909 16.2091 22 14 22C11.7909 22 10 23.7909 10 26C10 26.9134 10.3061 27.7553 10.8214 28.4286L10 35H18L17.1786 28.4286Z' />
            </mask>
            <path d='M17.1786 28.4286L15.5903 27.2132L15.0924 27.8638L15.194 28.6767L17.1786 28.4286ZM10.8214 28.4286L12.806 28.6767L12.9076 27.8638L12.4097 27.2132L10.8214 28.4286ZM10 35L8.01544 34.7519L7.73444 37H10V35ZM18 35V37H20.2656L19.9846 34.7519L18 35ZM16 26C16 26.4589 15.8479 26.8766 15.5903 27.2132L18.7669 29.6441C19.5398 28.634 20 27.3679 20 26H16ZM14 24C15.1046 24 16 24.8954 16 26H20C20 22.6863 17.3137 20 14 20V24ZM12 26C12 24.8954 12.8954 24 14 24V20C10.6863 20 8 22.6863 8 26H12ZM12.4097 27.2132C12.1521 26.8766 12 26.4589 12 26H8C8 27.3679 8.46017 28.634 9.2331 29.6441L12.4097 27.2132ZM11.9846 35.2481L12.806 28.6767L8.83686 28.1806L8.01544 34.7519L11.9846 35.2481ZM18 33H10V37H18V33ZM15.194 28.6767L16.0154 35.2481L19.9846 34.7519L19.1631 28.1806L15.194 28.6767Z' fill='#A5A5AA' mask='url(#path-4-inside-1)' />
        </svg>
    </Fragment>
);

SpinnerWithLockSVG.propTypes = {
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
};

export const SpinnerWithLockBig = ({className, color, message, label = 'You are being redirected'}) => (
    <Fragment>
        {message &&
            <div className={cx('SpinnerContainer')} style={{justifyContent: 'none'}}>
                <div className={cx('SpinnerContainerText')}>
                    <div className={cx('SpinnerContainerLockText')}>
                        {label}
                    </div>
                    <div className={cx('SpinnerMessage SpinnerMessageText')}>
                        {message}
                    </div>
                </div>
                <div className={cx('SpinnerContainer')}>
                    <SpinnerWithLockSVG
                        className={cx([
                            'SpinnerBig__image',
                            className,
                        ])}
                        color={color}
                        {...defaults.big} />
                </div>
            </div>
            ||
            <div className={cx('SpinnerContainer')}>
                <SpinnerWithLockSVG
                    className={cx([
                        'SpinnerBig__image',
                        className,
                    ])}
                    color={color}
                    {...defaults.big} />
            </div>
        }
    </Fragment>
);

SpinnerWithLockBig.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    color: PropTypes.string,
    label: PropTypes.node,
};

const Spinner = ({message}) => (
    <div className={cx('SpinnerContainer')}>
        <SpinnerImage message={message} />
    </div>
);

Spinner.propTypes = {
    message: PropTypes.node,
};

export default Spinner;
