import React from 'react';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import { setBankAndProceedToConsent, backToBankSelector } from 'actions/aisp';
import BankSelectorFirst from '../Shared/BankSelectorFirst';

// Higher order component BankSelector for AISP
const AispBankSelectorFirst = props => <BankSelectorFirst {...props} />;

AispBankSelectorFirst.propTypes = {
    setBankAndProceedToConsent: PropTypes.func,
    backToBankSelector: PropTypes.func,
};

const mapDispatchToProps = {
    setBankAndProceedToConsent,
    backToBankSelector,
};

export default connect(null, mapDispatchToProps)(AispBankSelectorFirst);
