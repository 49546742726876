import React from 'react';
import PropTypes from 'prop-types';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import { FormattedMessage } from 'react-intl';
import {
    unsuccessfulStatusCodes,
    successfulStatusCodes,
    submittedStatusCodes,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import SuccessIcon from '../Icons/SuccessIcon';
import FailureIcon from '../Icons/FailureIcon';
import ClockIcon from '../Icons/ClockIcon';
import style from './PaymentStatusTitle.css';
import classNames from 'classnames/bind';
import { connect } from 'reducers';

const cx = classNames.bind(style);

const Successful = ({ transferStatus }) => {
    return (
        <TokenTitle
            className={cx({
                'PaymentInitStatus-status--success': true,
            })}>
            <div>
                <SuccessIcon
                    title="success"
                    className={cx('PaymentInitStatus-status-icon')}
                />
            </div>
            <b>
                <FormattedMessage
                    tagName={'span'}
                    id={`pisp.paymentInitStatus.status_title.${transferStatus}`}
                />
            </b>
        </TokenTitle>
    );
};

Successful.propTypes = {
    transferStatus: PropTypes.string,
};

const Unsuccessful = ({ transferStatus }) => {
    return (
        <TokenTitle
            className={cx({
                'PaymentInitStatus-status--error': true,
            })}>
            <div>
                <FailureIcon
                    title="failure"
                    className={cx('PaymentInitStatus-status-icon')}
                />
            </div>
            <b>
                <FormattedMessage
                    tagName={'span'}
                    id={`pisp.paymentInitStatus.status_title.${transferStatus}`}
                />
            </b>
        </TokenTitle>
    );
};

Unsuccessful.propTypes = {
    transferStatus: PropTypes.string,
};

const Submitted = ({ transferStatus }) => {
    return (
        <TokenTitle
            className={cx({
                'PaymentInitStatus-status--submitted': true,
            })}>
            <div>
                <ClockIcon
                    title="submitted"
                    className={cx('PaymentInitStatus-status-icon')}
                />
            </div>
            <b>
                <FormattedMessage
                    tagName={'span'}
                    id={`pisp.paymentInitStatus.status_title.${transferStatus}`}
                />
            </b>
        </TokenTitle>
    );
};

Submitted.propTypes = {
    transferStatus: PropTypes.string,
};

const PaymentStatusSubTitle = ({
    transferStatus,
    seconds,
    pispName,
    hidePSR44Timer,
    removePSR44Timer,
}) => {
    const renderSubtitle = () => {
        if (unsuccessfulStatusCodes.includes(transferStatus)) {
            return (
                <TokenTitle role="alert" aria-live={'polite'} sub>
                    <FormattedMessage
                        id={`pisp.paymentInitStatus.status_message.${transferStatus}`}
                        values={{
                            MERCHANT_NAME: pispName,
                        }}
                    />
                </TokenTitle>
            );
        }

        return null;
    };

    const getSubtitleKey = () => {
        if (hidePSR44Timer) {
            return 'pisp.paymentInitStatus.subTitle.hidePSR44TimerMessage';
        } else if (removePSR44Timer) {
            return 'pisp.paymentInitStatus.subTitle.removePSR44TimerMessage';
        } else if (unsuccessfulStatusCodes.includes(transferStatus)) {
            return 'pisp.paymentInitStatus.subTitle.unSuccessfulMessage';
        }

        return `pisp.paymentInitStatus.status_message.${transferStatus}`;
    };

    if (
        ![
            ...successfulStatusCodes,
            ...submittedStatusCodes,
            ...unsuccessfulStatusCodes,
        ].includes(transferStatus)
    ) {
        return <></>;
    }

    return (
        <>
            {renderSubtitle()}
            {!removePSR44Timer && (
                <TokenTitle sub>
                    <b>
                        <FormattedMessage
                            id={'pisp.paymentInitStatus.doNotCloseMessage'}
                        />
                    </b>
                </TokenTitle>
            )}

            <TokenTitle
                role="alert"
                aria-live={seconds === 10 ? 'polite' : 'off'}
                sub>
                <FormattedMessage
                    id={getSubtitleKey()}
                    values={{
                        MERCHANT_NAME: pispName,
                        SECONDS: seconds,
                    }}
                />
            </TokenTitle>
        </>
    );
};

PaymentStatusSubTitle.propTypes = {
    transferStatus: PropTypes.string,
    seconds: PropTypes.number,
    pispName: PropTypes.string,
    hidePSR44Timer: PropTypes.bool,
    removePSR44Timer: PropTypes.bool,
};

const PaymentStatusTitle = ({
    transferStatus,
    pispName,
    seconds,
    hidePSR44Timer,
    removePSR44Timer,
}) => {
    const getTitleComponent = () => {
        if (successfulStatusCodes.includes(transferStatus)) {
            return <Successful transferStatus={transferStatus} />;
        } else if (submittedStatusCodes.includes(transferStatus)) {
            return <Submitted transferStatus={transferStatus} />;
        } else if (unsuccessfulStatusCodes.includes(transferStatus)) {
            return <Unsuccessful transferStatus={transferStatus} />;
        }

        return null;
    };

    return (
        <TokenTitle className={cx({ 'PaymentInitStatus-title': true })}>
            <FormattedMessage id={'pisp.paymentInitStatus.title'} />
            <center>
                {getTitleComponent()}
                <PaymentStatusSubTitle
                    transferStatus={transferStatus}
                    seconds={seconds}
                    pispName={pispName}
                    hidePSR44Timer={hidePSR44Timer}
                    removePSR44Timer={removePSR44Timer}
                />
            </center>
        </TokenTitle>
    );
};

PaymentStatusTitle.propTypes = {
    transferStatus: PropTypes.string,
    pispName: PropTypes.string,
    seconds: PropTypes.number,
    hidePSR44Timer: PropTypes.bool,
    removePSR44Timer: PropTypes.bool,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const actingAs = tokenRequestService.getTppActingAs();
    const { payload } = tokenRequestService.getTppReturnData();
    const tppName =
        payload['tpp-name'] || tokenRequestService.getTppProfile()?.tppName;

    return {
        hidePSR44Timer: Boolean(
            tokenRequestService.getTppFeature(featureConfig.HIDE_PSR44_TIMER),
        ),
        removePSR44Timer: Boolean(
            tokenRequestService.getTppFeature(featureConfig.REMOVE_PSR44_TIMER),
        ),
        pispName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            'merchant',
    };
};

export default connect(mapStateToProps, null)(PaymentStatusTitle);
