import { formatAsKeyValuePair } from 'util/index';

const formatCredentials = credentials => {
    if (!credentials) {
        return [];
    }
    const requiredCredentials = credentials.map(o => {
        if (o.options) {
            return {
                ...o,
                options: formatAsKeyValuePair(o.options),
            };
        }
        return o;
    });
    return requiredCredentials;
};

export default formatCredentials;
