import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {ROW_TYPE_1_COL, ROW_TYPE_2_COL, ROW_SIZE_STANDARD, ROW_SIZE_LARGE} from './Abstract';
import style from '../../../css/List.css';

const cx = classNames.bind(style);

const ListRow = ({
    type,
    size,
    onClick,
    className,
    children,
    onMouseOver,
    status,
    ariaLabel,
    ariaSelected,
    ariaHidden,
    role,
    id,
}) => (
    <div
        className={cx({
            'List__row': true,
            [`List__row--${type}`]: true,
            [`List__row--${size}`]: true,
            'List__row--hotspot': !!onClick,
            [className]: !!className,
        })}
        id={id}
        onClick={onClick}
        onMouseMove={() => {
            onMouseOver && onMouseOver(true);
        }}
        onMouseLeave={() => {
            onMouseOver && onMouseOver(false);
        }}
        aria-selected={role === 'option' ? ariaSelected : undefined}
        aria-checked={role === 'radio' ? ariaSelected : undefined}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        role={role}>
        {React.Children.map(children, child => React.cloneElement(child, {size, status}))}
    </div>
);

ListRow.propTypes = {
    type: PropTypes.oneOf([
        ROW_TYPE_1_COL,
        ROW_TYPE_2_COL,
    ]),
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    hover: PropTypes.bool,
    onMouseOver: PropTypes.func,
    children: PropTypes.node,
    status: PropTypes.string,
    id: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaSelected: PropTypes.bool,
    ariaHidden: PropTypes.string,
    role: PropTypes.string,
};

ListRow.defaultProps = {
    type: ROW_TYPE_1_COL,
    size: ROW_SIZE_STANDARD,
    onClick: null,
    role: 'option',
};

export default ListRow;
