import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './Consent.css';
import TextInput from './../Shared/TextInput.js';
import classNames from 'classnames/bind';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { mandatoryFieldsConfig } from 'config/mandatoryFields';

const cx = classNames.bind(style);

class StetMandatoryFields extends Component {
    componentDidMount() {
        const stetMetadata = this.props.transferMetadata;
        this.props.handleMandatoryFields({ stetMetadata });
    }

    handleCreditorAgentBicFi = value => {
        const state = this.props.state;
        this.props.handleMandatoryFields({
            stetMetadata: {
                ...state?.stetMetadata,
                beneficiary: {
                    ...state?.stetMetadata?.beneficiary,
                    creditorAgent: {
                        ...state?.stetMetadata?.beneficiary?.creditorAgent,
                        bicFi: value || undefined,
                    },
                },
            },
        });
    };

    handleCreditorAgentName = value => {
        const state = this.props.state;
        this.props.handleMandatoryFields({
            stetMetadata: {
                ...state?.stetMetadata,
                beneficiary: {
                    ...state?.stetMetadata?.beneficiary,
                    creditorAgent: {
                        ...state?.stetMetadata?.beneficiary?.creditorAgent,
                        name: value || undefined,
                    },
                },
            },
        });
    };

    displayCreditorAgentName = () => {
        return (
            <div className={cx('Consent-data-row')} key={'creditor_agent_name'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'pisp.consent.mandatory.creditorAgent.name.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e =>
                            this.handleCreditorAgentName(e.target.value)
                        }
                        placeholder={this.props.intl.formatMessage({
                            id:
                                'pisp.consent.mandatory.creditorAgent.name.placeholder',
                        })}
                        name="creditor_agent_name"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={
                            this.props?.state?.stetMetadata?.beneficiary
                                ?.creditorAgent?.name
                        }
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    displayCreditorAgentBicFi = () => {
        return (
            <div
                className={cx('Consent-data-row')}
                key={'creditor_agent_bicFi'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'pisp.consent.mandatory.creditorAgent.bicFi.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e =>
                            this.handleCreditorAgentBicFi(e.target.value)
                        }
                        placeholder={this.props.intl.formatMessage({
                            id:
                                'pisp.consent.mandatory.creditorAgent.bicFi.placeholder',
                        })}
                        name="creditor_agent_bicFi"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={
                            this.props.state?.stetMetadata?.beneficiary
                                ?.creditorAgent?.bicFi
                        }
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    render() {
        const { requiredMandatoryFields, transferMetadata, type } = this.props;
        const config = mandatoryFieldsConfig?.[type];
        const defaultCreditorAgentName =
            transferMetadata?.beneficiary?.creditorAgent?.name;
        const defaultCreditorAgentBicFi =
            transferMetadata?.beneficiary?.creditorAgent?.bicFi;

        return (
            <>
                {requiredMandatoryFields?.map(field => {
                    return (
                        <Fragment key={'stet_fields'}>
                            {field === config.stet.creditorAgent &&
                                !defaultCreditorAgentName &&
                                this.displayCreditorAgentName()}
                            {field === config.stet.creditorAgent &&
                                !defaultCreditorAgentBicFi &&
                                this.displayCreditorAgentBicFi()}
                        </Fragment>
                    );
                })}
            </>
        );
    }
}

StetMandatoryFields.propTypes = {
    requiredMandatoryFields: PropTypes.array,
    transferMetadata: PropTypes.object,
    intl: intlShape.isRequired,
    handleMandatoryFields: PropTypes.func,
    state: PropTypes.object,
    type: PropTypes.string,
};

StetMandatoryFields.defaultProps = {
    type: 'transfer',
};

export default injectIntl(StetMandatoryFields);
