import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import {
    LOADING_STEP,
    SPLASH_STEP,
    VRP_SELECT_BANK_FIRST,
    VRP_CONSENT_BANK_FIRST,
    VRP_CONSENT_INIT_STATUS,
} from 'config/constants';
import Loading from 'components/App/Shared/Loading';
import SplashScreen from 'components/App/Shared/SplashScreen';
import Background from 'components/App/Background';
import VrpConsentBankFirstFlow from './VrpConsentBankFirstFlow';
import VrpBankSelectorFirst from './VrpBankSelectorFirst';
import VrpConsentInitStatus from './VrpConsentInitStatus';

class Vrp extends Component {
    constructor(props) {
        super(props);
    }
    static getStepComponent(currentStep) {
        const steps = {
            [VRP_SELECT_BANK_FIRST]: <VrpBankSelectorFirst />,
            [VRP_CONSENT_BANK_FIRST]: <VrpConsentBankFirstFlow />,
            [VRP_CONSENT_INIT_STATUS]: <VrpConsentInitStatus />,
        };
        return steps[currentStep];
    }

    static getStepComponentWithoutBackground(currentStep) {
        const stepsWithoutBackground = {
            [LOADING_STEP]: <Loading />,
            [SPLASH_STEP]: <SplashScreen />,
        };
        return stepsWithoutBackground[currentStep];
    }

    render() {
        if (
            this.props.previousStep === SPLASH_STEP &&
            !this.props.fadeOutProp
        ) {
            return <SplashScreen stepToSet={this.props.currentStep} />;
        }

        const { currentStep } = this.props;
        const component = Vrp.getStepComponent(currentStep) ? (
            <Background> {Vrp.getStepComponent(currentStep)} </Background>
        ) : (
            Vrp.getStepComponentWithoutBackground(currentStep)
        );

        return <>{component}</>;
    }
}

Vrp.propTypes = {
    currentStep: PropTypes.string.isRequired,
    previousStep: PropTypes.string,
    fadeOutProp: PropTypes.bool,
};

const mapStateToProps = ({ sharedService }) => ({
    currentStep: sharedService.getCurrentStep(),
    previousStep: sharedService.getPreviousStep(),
    fadeOutProp: sharedService.getFadeOutProp(),
});

export default connect(mapStateToProps)(Vrp);
