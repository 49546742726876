import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {ROW_SIZE_STANDARD, ROW_SIZE_LARGE} from './Abstract';
import RadioButton from '../Radio';
import style from '../../../css/List.css';

const cx = classNames.bind(style);

const ListCellRadio = ({
    title,
    data,
    extra,
    highlighted,
    size,
    className,
    value,
    status,
}) => (
    <div className={cx({
        'List__row__cell': true,
        'List__row__cell-radio': true,
        [`List__row__cell--${size}`]: true,
        [`List__row__cell--${status}`]: !!status,
        [className]: !!className,
    })}>
        <div className={cx('List__row__cell__main')}>
            {(size === ROW_SIZE_LARGE) && title && (
                <span className={cx('List__row__cell__title')}>
                    {title}
                </span>
            )}
            <span className={cx('List__row__cell__data')}>
                {data}
            </span>
            {(size === ROW_SIZE_LARGE) && extra && (
                <span className={cx('List__row__cell__extra__info')}>
                    {extra}
                </span>
            )}
        </div>
        <div className={cx('List__row__cell__extra')}>
            <div className={cx('List__row__cell__extra__radio')}>
                <RadioButton value={value} hover={highlighted} />
            </div>
        </div>
    </div>
);

ListCellRadio.propTypes = {
    title: PropTypes.node,
    data: PropTypes.node.isRequired,
    value: PropTypes.bool,
    extra: PropTypes.node,
    highlighted: PropTypes.bool,
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    className: PropTypes.string,
    status: PropTypes.string,
};

ListCellRadio.defaultProps = {
    title: '',
    data: '',
    extra: '',
    value: false,
    highlighted: false,
    size: ROW_SIZE_STANDARD,
};

export default ListCellRadio;
