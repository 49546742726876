export const developerKeys = {
    'd3b08f1f-83d9-4969-98f0-73c887e95a8d': 'token.io',
    '63c763ed-0ae7-406a-a70c-ef4e07922459': 'tokenapp@token.io',
    yodlee1234: 'yodlee@token.io',
    '5qY7lqQw8NOl9gng0ZHgT4xdiDqxqoGVutuZwrUYQsI': 'tempdev@token.io',
    icefire: 'icefire@token.io',
    xcordis123: 'xcordis@token.io',
    virtusa123: 'virtusa@token.io',
    sbsi123: 'sberbank@token.io',
    paymentworld123: 'paymentworld@token.io',
    finacle123: 'finacle@token.io',
    creditagricole123: 'creditagricole@token.io',
    cardlytics123: 'cardlytics@token.io',
    swipppay123: 'swipppay@token.io',
    caxton123: 'caxton@token.io',
    thehouseshop123: 'thehouseshop@token.io',
    squirrel123: 'squirrel@token.io',
    fairfx123: 'fairfx@token.io',
    elephant123: 'elephant@token.io',
    jeton9678: 'jeton@token.io',
    aptap1432: 'aptap@token.io',
    't0k3n!': 'internal@token.io',
    '4qY7lqQw8NOl9gng0ZHgT4xdiDqxqoGVutuZwrUYQsI': 'ratelimited@token.io',
    'f3982819-5d8d-4123-9601-886df2780f42': 'tests@token.io',
    '2e67a21e-7017-4af3-a456-74cba2b1c9ed': 'mastercard@token.io',
    mastercard: 'mastercard@token.io',
    volantetest: 'volantetest@token.io',
    couttstest: 'couttstest@token.io',
    'caxton-test': 'cxep-test@token.io',
    cma9test: 'cma9test@token.io',
    'contis-test': 'contis-test@token.io',
    sturents: 'sturents@token.io',
    'unity-test': 'unity-test@token.io',
    'avantcard-test': 'avantcard-test@token.io',
    'leumi-test': 'leumi-test@token.io',
    'pnbil-test': 'pnbil-test@token.io',
    'inbank-test': 'inbank-test@token.io',
    'germany-test': 'germany-test@token.io',
    'french-test': 'french-test@token.io',
    'global-test': 'global-test@token.io',
    'sbsi-test': 'sbsi-test@token.io',
    'fincom-test': 'fincom-test@token.io',
    'tuba-test': 'tuba-test@token.io',
    'talkremit-test': 'talkremit-test@token.io',
    'gohenry-test': 'gohenry-test@token.io',
    'bitstamp-test': 'bitstamp-test@token.io',
    'bitstamp-dev': 'bitstamp-dev@token.io',
    thinkmoney: 'thinkmoney@token.io',
    'tandem-test': 'tandem-test@token.io',
    'grpl-test': 'grpl-test@token.io',
    centrefordentistry: 'centrefordentistry@token.io',
    'n26-test': 'n26-test@token.io',
    anpost: 'anpost-test@token.io',
    sbhr: 'sbhr-test@token.io',
    vertbase: 'vertbase@token.io',
    saltedge: 'saltedge@token.io',
    toshl: 'toshl@token.io',
    blackpepper: 'blackpepper@token.io',
    elavon: 'elavon@token.io',
    ghana: 'ghana@token.io',
    binarapps: 'binarapps@token.io',
    smartym: 'smartym@token.io',
    straal: 'straal@token.io',
    sumup: 'sumup@token.io',
    'jaja-test': 'jaja-test@token.io',
    etherbank: 'etherbank@token.io',
    infosys: 'infosys-demo@token.io',
    'lhv-test': 'lhv-test@token.io',
    airwallex: 'airwallex@token.io',
    soldo: 'soldo@token.io',
    coop: 'coop@token.io',
    'coop-fank': 'coop-fank@token.io',
    northmill: 'northmill@token.io',
};
