import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { connect } from 'reducers';
import { sanitizeHTML } from 'util/index.js';
import style from './TermsLoader.css';
import classNames from 'classnames/bind';
import HrefToPopup from '../Shared/HrefToPopup';

const cx = classNames.bind(style);

const LINK_URLS_WITH_TAG_ID = {
    'token-terms': window.location.origin + '/terms',
};

const TermsLoader = ({ isAisp, isVrp, intl }) => {
    const tAndCRef = useRef();

    const getTranslationId = () => {
        if (isAisp) {
            return 'aisp.consent.termsAndConditions.loader';
        } else if (isVrp) {
            return 'vrp.consent.termsAndConditions.loader';
        } else {
            return 'pisp.consent.termsAndConditions.loader';
        }
    };

    return (
        <HrefToPopup
            contentRef={tAndCRef}
            defaultUrls={LINK_URLS_WITH_TAG_ID}
            fallbackToOverlay>
            <div
                className={cx('TermsConditions-loader')}
                ref={tAndCRef}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(
                        intl.formatMessage({
                            id: getTranslationId(),
                        }),
                    ),
                }}>
            </div>
        </HrefToPopup>
    );
};

TermsLoader.propTypes = {
    isAisp: PropTypes.bool,
    isVrp: PropTypes.bool,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ sharedService }) => {
    return {
        isAisp: sharedService.isAisp(),
        isVrp: sharedService.isVrp(),
    };
};

export default connect(mapStateToProps)(TermsLoader);
