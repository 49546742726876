import React from 'react';
import {withStatic} from '../../Hoc.jsx';
import AbstractSearch, {
    ICON_ALIGN_LEFT,
    ICON_ALIGN_RIGHT,
} from './Abstract';
import classNames from 'classnames/bind';
import style from '../../../css/DefaultSearch.css';
const cx = classNames.bind(style);

const Search = React.forwardRef((props, ref) => (
    <AbstractSearch
        className={cx('DefaultSearch__container')}
        searchInputClass={cx('DefaultSearch__searchInput')}
        ref={ref}
        {...props} />
));

Search.displayName = 'Search';

export default withStatic({
    ICON_ALIGN_LEFT,
    ICON_ALIGN_RIGHT,
})(Search);
