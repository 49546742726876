import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { SpinnerSmall } from '@token-io/lib-web-components/src/Components/Spinner';
import style from './SmallSpinner.css';

const cx = classNames.bind(style);

const SmallSpinner = ({ className, color }) => {
    return (
        <span
            className={cx({
                [className]: !!className,
            })}>
            <SpinnerSmall
                className={cx({
                    Custom_spinner: true,
                })}
                color={color}
            />
        </span>
    );
};

SmallSpinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

export default SmallSpinner;
