import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import style from './BulkTransferConsent.css';
import EditIcon from '../Icons/EditIcon.js';
import FeedbackIcon from '../Icons/FeedbackIcon.js';
import InvalidIbanText from '../Shared/InvalidIbanText.js';
import { handleCheckIban } from '../Shared/ValidateIban';
import CopyIcon from '../Icons/CopyIcon.js';
import TextInput from '../Shared/TextInput.js';
import ChangeButton from './Button.js';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import ButtonVisibilityWrapper from '../Shared/ButtonVisibilityWrapper.js';
import Button from '@token-io/lib-web-components/src/Components/Button';
import {
    backToBankSelector,
    proceedToBank,
    setInstructionsSourceIban,
} from 'actions/pisp';
import { declineTerms, setBank } from 'actions/shared';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    formatCurrency,
    formatDate,
    copyOnClipboard,
    hideConsentText,
    filterDestinationAccount,
} from 'util/index';
import {
    defaultAppName,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { FormattedMessage, intlShape } from 'react-intl';
import TermsLoader from '../Terms/TermsLoader';
import { banksWithBranches } from '../../../config/constants';
import { sanitizeHTML } from '../../../util';

const cx = classNames.bind(style);

class BulkTransferConsentBankFirstFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iban: '',
            bic: '',
            buttonStatus: 'disabled',
            isIbanValid: false,
            displayIbanSpinner: false,
        };
        this.confirm = this.confirm.bind(this);
        this.contentRef = createRef();
        this.ibanInputDisplay = this.ibanInputDisplay.bind(this);
        this.defaultIbanDisplay = this.defaultIbanDisplay.bind(this);
        this.ibanWithCountry = this.ibanWithCountry.bind(this);
        this.checkSubBranch = this.checkSubBranch.bind(this);
        this.iconDisplay = this.iconDisplay.bind(this);
    }

    ibanWithCountry() {
        return (
            (this.state.iban &&
                this.state.iban.length > 2 &&
                this.state.iban) ||
            (this.props.selectedBank && this.props.selectedBank.country) ||
            ''
        );
    }

    checkSubBranch(iban) {
        let validBranchNumber = false;
        const branchList =
            this.props.banksBranchList &&
            this.props.banksBranchList[this.props.selectedBankName];
        if (branchList && iban.length >= 12) {
            const branchNumber = iban.slice(4, 12);
            if (branchList[branchNumber]) {
                this.props.setBank(branchList[branchNumber]);
                validBranchNumber = true;
            }
        }
        this.setState({
            iban: iban,
            validBranchNumber: validBranchNumber,
            isIbanValid: false,
            displayIbanSpinner: true,
        });
        return validBranchNumber;
    }

    iconDisplay() {
        const selectedBankHavingSubbranch = Object.keys(
            banksWithBranches,
        ).includes(this.props.selectedBankName);
        const displayIbanSpinner = this.state.displayIbanSpinner;
        return (
            <FeedbackIcon
                isValid={this.state.validBranchNumber && this.state.isIbanValid}
                preCondition={
                    selectedBankHavingSubbranch && this.state.iban.length >= 12
                }
                displaySpinner={displayIbanSpinner}
            />
        );
    }

    ibanInputDisplay() {
        return (
            <div className={cx('Consent-data-row')}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage tagName={'span'} id={'pisp.iban.title'} />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <div style={{ display: 'flex' }}>
                        {this.iconDisplay()}
                        <TextInput
                            onChange={e =>
                                this.checkSubBranch(
                                    e.target.value?.toUpperCase(),
                                )
                            }
                            placeholder={this.props.intl.formatMessage({
                                id: 'pisp.iban.description',
                            })}
                            key="iban"
                            name="iban_inputField"
                            className={cx({
                                TextInput__inner__input: true,
                                'AliasInput-field': true,
                                'IbanInput-field': true,
                            })}
                            validateInput={
                                !this.state.isIbanValid &&
                                !this.state.displayIbanSpinner &&
                                !!this.state.iban
                            }
                            aria-required="true"
                            value={this.ibanWithCountry()}
                            required
                            autoFocus
                        />
                    </div>
                    {this.state.iban &&
                        !this.state.isIbanValid &&
                        !this.state.displayIbanSpinner && (
                        <InvalidIbanText
                            text={this.props.intl.formatMessage({
                                id: 'pisp.iban.notValid',
                            })}
                        />
                    )}
                </div>
            </div>
        );
    }

    defaultIbanDisplay() {
        return (
            <div className={cx('Consent-data-row')}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage tagName={'span'} id={'pisp.iban.title'} />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <span className={cx('Colored-Text')}>
                        <strong>{this.props.defaultIban}</strong>&nbsp;
                        <EditIcon
                            className={cx('Consent-DefaultIban')}
                            ariaLabel={this.props.intl.formatMessage({
                                id: 'pisp.iban.focus',
                            })}
                            onKeyPress={() =>
                                this.props.setInstructionsSourceIban({
                                    iban: '',
                                    bic: this.state.bic,
                                })
                            }
                            onClick={() =>
                                this.props.setInstructionsSourceIban({
                                    iban: '',
                                    bic: this.state.bic,
                                })
                            }
                        />
                    </span>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.iban !== this.state.iban) {
            const timeout = this.props.defaultIban ? 0 : 1000;
            handleCheckIban({
                iban: this.state.iban,
                requestId: this.props.tokenRequestId,
                handleValidIban: this.handleValidIban,
                handleInvalidIban: this.handleInvalidIban,
                timeout,
            });
        }
        if (
            this.props?.defaultIban &&
            !this.state.isIbanValid &&
            prevState.iban
        ) {
            this.props.setInstructionsSourceIban();
        }
    }

    handleInvalidIban = () => {
        this.setState({
            isIbanValid: false,
            displayIbanSpinner: false,
        });
    };

    handleValidIban = () => {
        this.setState({
            isIbanValid: true,
            displayIbanSpinner: false,
        });
    };

    componentDidMount() {
        this.props.setDisplayFooterShadow(false);
        const iban = this.props.defaultIban;
        const bic = this.props.defaultBic;
        if (
            iban &&
            Object.prototype.hasOwnProperty.call(
                banksWithBranches,
                this.props.selectedBankName,
            )
        ) {
            const validBranchNumber = this.checkSubBranch(iban);
            if (!validBranchNumber) {
                this.props.setInstructionsSourceIban({
                    iban: '',
                    bic: this.state.bic,
                });
            }
        }
        if (iban) {
            this.setState({ iban, bic });
        }
    }

    confirm = () => {
        if (this.props.oneStepSupport) {
            this.props.setInstructionsSourceIban({
                iban: this.state.iban,
                bic: this.state.bic,
            });
        }
        this.props.proceedToBank();
    };

    render() {
        const {
            declineTerms,
            pispName,
            pispAlias,
            total,
            businessName,
            oneStepSupport,
            backToBankSelector,
            defaultIban,
            executionDate,
            selectedBankName,
            refId,
            defaultBankId,
            bulkTransfers,
            displayBeneficiaryDetails,
            description,
            modifyPreSelectedBank,
        } = this.props;
        const { iban } = this.state;
        const selectedBankHavingSubbranch = Object.keys(
            banksWithBranches,
        ).includes(this.props.selectedBankName);
        const status = () => {
            if (
                selectedBankHavingSubbranch &&
                !this.state.validBranchNumber &&
                !defaultBankId
            ) {
                return Button.STATUS_DISABLED;
            }
            if (oneStepSupport === true && !(iban?.length > 2)) {
                return Button.STATUS_DISABLED;
            } else if (oneStepSupport === true && !this.state.isIbanValid) {
                return Button.STATUS_DISABLED;
            } else return Button.STATUS_IDLE;
        };
        const destinationAccount = filterDestinationAccount(
            bulkTransfers[0].destination,
        );
        const messageVariableValues = {
            BUSINESS_NAME: businessName,
            DISPLAY_NAME: pispName ? pispName : pispAlias,
            REFUND_ACCOUNT: '',
        };
        const consentText = this.props.intl.formatMessage(
            {
                id: 'pisp.consent.text',
                defaultMessage: '',
            },
            messageVariableValues,
        );
        const showBeneficiaryDetails = !!displayBeneficiaryDetails;
        const hideConsentTextBlock = hideConsentText(consentText);

        return (
            <Fragment>
                <TokenContainer
                    className={cx('Pisp-Consent-subcontainer')}
                    innerRef={this.contentRef}
                    noMargin>
                    <div className="TokenContainer-margin">
                        <TokenTitle>
                            <FormattedMessage id={'pisp.consent.title'} />
                        </TokenTitle>
                        <TokenTitle sub>
                            <FormattedMessage
                                id={'pisp.consent.subtitle1'}
                                tagName={'p'}
                            />
                        </TokenTitle>
                    </div>
                    <div className={cx('Consent-data')}>
                        <TokenTitle sub>
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.bulkTransfer.bulkPayment'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('Colored-Text')}>
                                        {total}
                                    </span>
                                </div>
                            </div>
                            {executionDate ? (
                                <div className={cx('Consent-data-row')}>
                                    <div
                                        className={cx(
                                            'Consent-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'pisp.consent.executionDate'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'Consent-data-row-detail',
                                        )}>
                                        <span className={cx('Colored-Text')}>
                                            {executionDate}
                                        </span>
                                    </div>
                                </div>
                            ) : null}
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.consent.beneficiary'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('Consent-pisp-alias')}>
                                        {pispName ? (
                                            <strong>{pispName}</strong>
                                        ) : (
                                            <strong>{pispAlias}</strong>
                                        )}
                                    </span>
                                </div>
                            </div>
                            {showBeneficiaryDetails &&
                                Object.keys(destinationAccount).map(key => {
                                    return (
                                        <div
                                            key={key}
                                            className={cx('Consent-data-row')}>
                                            <div
                                                className={cx(
                                                    'Consent-data-row-title',
                                                )}>
                                                <FormattedMessage
                                                    tagName={'span'}
                                                    id={`pisp.consent.destination.${key}`}
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'Consent-data-row-detail',
                                                )}>
                                                <span>
                                                    <strong>
                                                        {
                                                            destinationAccount[
                                                                key
                                                            ]
                                                        }
                                                    </strong>
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.consent.bankName'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('Consent-BankName')}>
                                        <strong>{selectedBankName}</strong>
                                    </span>
                                    {(defaultBankId === '' ||
                                        modifyPreSelectedBank) && (
                                        <ChangeButton
                                            ariaLabel={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'pisp.consent.changeBank.ariaLabel',
                                                },
                                            )}
                                            onClick={() => backToBankSelector()}
                                            className={cx('ChangeBank')}
                                            text={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'pisp.consent.changeBank.label',
                                                },
                                            )}
                                        />
                                    )}
                                </div>
                            </div>

                            {oneStepSupport
                                ? defaultIban === ''
                                    ? this.ibanInputDisplay()
                                    : this.defaultIbanDisplay()
                                : null}
                            <div className={cx('Consent-data-row')}>
                                <div className={cx('Consent-data-row-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.consent.refId'}
                                    />
                                </div>
                                <div className={cx('Consent-data-row-detail')}>
                                    <span className={cx('RefID')}>
                                        <strong id="refId">{refId} </strong>
                                        <CopyIcon
                                            className={cx('Consent-CopyRefId')}
                                            ariaLabel={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'pisp.consent.copyRefId',
                                                },
                                            )}
                                            onKeyPress={() =>
                                                copyOnClipboard(refId)
                                            }
                                            onClick={() =>
                                                copyOnClipboard(refId)
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                            {description && (
                                <div className={cx('Consent-data-row')}>
                                    <div
                                        className={cx(
                                            'Consent-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'pisp.consent.description'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'Consent-data-row-detail',
                                        )}>
                                        <strong className={cx('Description')}>
                                            {description}
                                        </strong>
                                    </div>
                                </div>
                            )}
                        </TokenTitle>
                    </div>
                    {!hideConsentTextBlock && (
                        <div
                            className={cx({
                                'Consent-details': true,
                            })}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(consentText),
                            }}
                        />
                    )}
                    <TermsLoader />
                </TokenContainer>
                <TokenButtonsWrapper>
                    <Button
                        status={status()}
                        scrollRef={this.contentRef}
                        onClick={this.confirm}
                        text={<FormattedMessage id={'common.button.accept'} />}
                    />
                    {defaultBankId !== '' || modifyPreSelectedBank ? (
                        <Button
                            type={Button.TYPE_GHOST_WARNING}
                            onClick={declineTerms}
                            text={
                                <FormattedMessage
                                    id={'common.button.decline'}
                                />
                            }
                        />
                    ) : (
                        <Button
                            type={Button.TYPE_GHOST}
                            onClick={backToBankSelector}
                            text={
                                <FormattedMessage id={'common.button.back'} />
                            }
                        />
                    )}
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

BulkTransferConsentBankFirstFlow.propTypes = {
    declineTerms: PropTypes.func.isRequired,
    pispName: PropTypes.string.isRequired,
    pispAlias: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    businessName: PropTypes.string.isRequired,
    backToBankSelector: PropTypes.func.isRequired,
    oneStepSupport: PropTypes.bool.isRequired,
    proceedToBank: PropTypes.func.isRequired,
    defaultIban: PropTypes.string.isRequired,
    defaultBic: PropTypes.string.isRequired,
    setInstructionsSourceIban: PropTypes.func.isRequired,
    executionDate: PropTypes.string,
    selectedBankName: PropTypes.string,
    selectedBank: PropTypes.object,
    refId: PropTypes.string.isRequired,
    banksBranchList: PropTypes.object,
    setBank: PropTypes.func,
    defaultBankId: PropTypes.string,
    bulkTransfers: PropTypes.array,
    tokenRequestId: PropTypes.string.isRequired,
    description: PropTypes.string,
    displayBeneficiaryDetails: PropTypes.bool,
    modifyPreSelectedBank: PropTypes.bool,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = (
    { tokenService, tokenRequestService, sharedService },
    ownProps,
) => {
    const { tppName } = tokenRequestService.getTppProfile();
    const tppAlias = tokenRequestService.getTppAlias();
    const actingAs = tokenRequestService.getTppActingAs();
    const total = formatCurrency(
        tokenService.getBulkTransferAmount(),
        tokenService.getBulkTransferCurrency(),
        ownProps.intl.formatNumber,
    );
    const defaultIban = tokenService.getBulkTransferSource();
    const selectedBankName = sharedService.getSelectedBankName();
    const selectedBank = sharedService.getSelectedBank();
    const executionDate = formatDate(tokenService.getExecutionDate());
    const tokenRequestId = tokenRequestService.getRequestId();
    const description = tokenRequestService.getDescription();
    const displayBeneficiaryDetails = tokenRequestService.getTppFeature(
        featureConfig.DISPLAY_BENEFICARY_DETAILS,
        false,
    );
    const modifyPreSelectedBank = tokenRequestService.getTppFeature(
        featureConfig.MODIFY_PRE_SELECTED_BANK,
        false,
    );
    return {
        pispName: actingAs?.displayName || tppName,
        pispAlias: actingAs?.secondaryName || tppAlias.value,
        total,
        businessName:
            tokenRequestService.getTppFeature(featureConfig.PIS_APP_NAME) ||
            defaultAppName,
        oneStepSupport: sharedService.getDoesBankSupportOneStep(),
        defaultIban:
            (defaultIban &&
                defaultIban.account.iban &&
                defaultIban.account.iban.iban) ||
            '',
        defaultBic:
            (defaultIban &&
                defaultIban.account.iban &&
                defaultIban.account.iban.bic) ||
            '',
        selectedBankName,
        selectedBank,
        executionDate,
        refId: tokenService.getRefId(),
        banksBranchList: sharedService.getBanksBranchList() || {},
        defaultBankId: tokenRequestService.getDefaultBankId() || '',
        bulkTransfers: tokenService.getBulkTransfers(),
        tokenRequestId,
        description,
        modifyPreSelectedBank,
    };
};

const mapDispatchToProps = {
    backToBankSelector,
    proceedToBank,
    setBank,
    setInstructionsSourceIban,
    declineTerms,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BulkTransferConsentBankFirstFlow);
