import { combineReducers } from 'redux';
import {
    REDACTED_VALUE,
    TYPE_2_ALIAS,
    CUSTOM_TPP_FEATURES as featureConfig,
    BANK_SELECTION_PRESETS,
} from 'config/constants.js';
import { createReducer } from '@token-io/lib-web-app';
import { getFeatureFromTppConfig, keyExists } from 'util/index';

const customization = createReducer(
    {},
    {
        SET_CUSTOMIZATION_ID: (state, action) => {
            const { id } = action;
            return {
                ...state,
                id,
            };
        },
    },
);

const tpp = createReducer(
    {},
    {
        SET_TOKEN_TYPE: (state, action) => {
            const alias = action.tppAlias;
            const actingAs = action.actingAs;
            const memberId = action.memberId;
            return {
                ...state,
                alias,
                actingAs,
                profile: { ...state?.profile, memberId },
            };
        },
        SET_PROFILE: (state, action) => {
            const profile = action.profile;
            return { ...state, profile };
        },
        SET_RESELLER_PROFILE: (state, action) => {
            const resellerProfile = action.profile;
            return { ...state, resellerProfile };
        },
        SET_HIDE_CONSENT: (state, action) => {
            const hideConsent = action.hideConsent;
            return { ...state, hideConsent };
        },
        SET_TPP_SOURCE: (state, action) => {
            const { tppSource } = action;
            return { ...state, tppSource };
        },
        SET_USE_BANK_REDIRECTION_SCREEN: (state, action) => {
            const { useBankRedirectionScreen } = action;
            return { ...state, useBankRedirectionScreen };
        },
        SET_TPP_REDIRECT_URL: (state, action) => {
            const { redirectUrl } = action;
            return { ...state, redirectUrl };
        },
        SET_TPP_CONFIGURATIONS: (state, action) => {
            const { configurations } = action;
            if (
                configurations.bankSelectionPreset ===
                BANK_SELECTION_PRESETS.ENABLE_ALL
            ) {
                delete configurations[
                    featureConfig.SUPPORTED_COUNTRIES_WITH_BANK_IDS
                ];
            }
            return { ...state, configurations };
        },
        SET_TPP_OVERALL_TRANSLATIONS: (state, action) => {
            const { translations } = action;
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    translations,
                },
            };
        },
        SET_TPP_RETURN_DATA: (state, action) => {
            const { tppReturnData } = action;
            return { ...state, tppReturnData };
        },
        //Sets member props after fetching from getMember api
        SET_TPP_REQUESTED_INFO: (state, action) => {
            const { properties } = action;
            return { ...state, properties };
        },
    },
);

const request = createReducer(
    {},
    {
        SET_TOKEN_REQUEST_ID: (state, action) => {
            const id = action.tokenRequestId;
            return { ...state, id };
        },
        SET_TOKEN_REQUEST_OPTIONS: (state, action) => {
            const options = action.tokenRequestOptions;
            return { ...state, options };
        },
        SET_REDIRECT_URL: (state, action) => {
            const redirectUrl = action.redirectUrl;
            return { ...state, redirectUrl };
        },
        SET_REDIRECT_STATE: (state, action) => {
            const redirectState = action.redirectState;
            return { ...state, redirectState };
        },
        SET_TOKEN_REQUEST_SIGNATURE: (state, action) => {
            const tokenRequestSignature = action.signature;
            return { ...state, tokenRequestSignature };
        },
        SET_DESTINATION_COUNTRY: (state, action) => {
            const destinationCountry = action.destinationCountry;
            return { ...state, destinationCountry };
        },
        SET_DESCRIPTION: (state, action) => {
            const description = action.description;
            return { ...state, description };
        },
        SET_REQUEST_COUNTRIES: (state, action) => {
            const countries = action.countries;
            return { ...state, countries };
        },
        SET_TOKEN_EXPIRATION: (state, action) => {
            const tokenExpiration = action.tokenExpiration;
            return { ...state, tokenExpiration };
        },
        SET_LINKING_REQUEST_ID: (state, action) => {
            const linkingRequestId = action.linkingRequestId;
            return { ...state, linkingRequestId };
        },
        SET_AUTH_REQUEST_ID: (state, action) => {
            const authRequestId = action.authRequestId;
            return { ...state, authRequestId };
        },
        SET_USE_CREDENTIAL_FLOW: (state, action) => {
            const useCredentialFlow = action.useCredentialFlow;
            return { ...state, useCredentialFlow };
        },
        // TODO: deprecated
        SET_TOKEN_PAYLOAD: (state, action) => {
            const payload = action.tokenPayload;
            return { ...state, payload };
        },
    },
);

const defaults = createReducer(
    {},
    {
        SET_DEFAULT_BANK_ID: (state, action) => {
            const bankId = action.defaultBankId;
            return { ...state, bankId };
        },
        SET_DEFAULT_ALIAS: (state, action) => {
            const alias = action.defaultAlias;
            return { ...state, alias };
        },
    },
);

export const reducer = combineReducers({
    customization,
    tpp,
    request,
    defaults,
});

export const selector = getState => {
    const tppRealmId = () =>
        getState().tpp.properties?.realmId || getState().tpp.alias?.realmId;
    const tppMemberId = () =>
        getState().tpp.properties?.memberId ||
        getState().tpp?.profile?.memberId;
    return {
        getRequestPayload: () => getState().request.payload, // TODO: deprecated
        getRequestId: () => getState().request.id,
        getRequestOptions: () => getState().request.options,
        getUseCredentialFlow: () => getState().request.useCredentialFlow,
        getRequestRedirectUrl: () => getState().request.redirectUrl,
        getRequestRedirectState: () => getState().request.redirectState,
        getDestinationCountry: () => getState().request.destinationCountry,
        getDescription: () => getState().request.description,
        getRequestCountries: () => getState().request.countries,
        getTokenExpiration: () => getState().request.tokenExpiration,
        getLinkingRequestId: () => getState().request.linkingRequestId,
        hasCustomization: () => !!getState().customization.id,
        hasCustomColors: () =>
            !!keyExists(
                getFeatureFromTppConfig(
                    getState().tpp.configurations,
                    featureConfig.CUSTOM_COLORS,
                ),
            ),
        getTppAlias: () => getState().tpp.alias,
        getTppProfile: () => getState().tpp.profile,
        getTppActingAs: () => getState().tpp.actingAs,
        getRealmIdFromMemberInfo: () =>
            getState().tpp.properties?.realmId || '',
        getTppMemberAndRealmId: () => {
            const realmId = tppRealmId();
            const memberId = tppMemberId();
            if (realmId || memberId) {
                return { realmId, memberId };
            } else return null;
        },
        isSourceApp: () => getState().tpp.tppSource === 'app',
        isSourceWebapp: () =>
            !getState().tpp.tppSource || getState().tpp.tppSource === 'webapp',
        getTppRedirectUrl: () => getState().tpp.redirectUrl,
        isTppRedirectError: () =>
            Boolean(getState().tpp.redirectUrl?.includes('error=')),
        getHideConsent: () => getState().tpp.hideConsent,
        getDefaultBankId: () => getState().defaults.bankId,
        getDefaultAlias: () => getState().defaults.alias,
        useBankRedirectionScreen: () =>
            getState().tpp.useBankRedirectionScreen &&
            getState().tpp.useBankRedirectionScreen === 'true',
        isIOSType2Flow: () => TYPE_2_ALIAS.includes(getState().tpp.alias.value),
        getTppReturnData: () => getState().tpp.tppReturnData,
        getAuthRequestId: () => getState().request.authRequestId,
        getTokenRequestSignature: () =>
            getState().request.tokenRequestSignature,
        getTppConfigurations: () => getState().tpp.configurations,
        getOverallRegionalConfigurations: () =>
            getState().tpp.configurations?.regionalConfigurations,
        getTppFeature: (feature, defaultValue) =>
            getFeatureFromTppConfig(
                getState().tpp.configurations,
                feature,
                defaultValue,
            ),
        hasTppFeatures: () => !!keyExists(getState().tpp.configurations),
        getResellerName: () => getState().tpp.resellerProfile?.tppName,
        isSubTpp: () => !!getState().tpp.properties?.subTppRefId,
    };
};

export const getRedactedState = getState => {
    const state = getState();
    return {
        ...state,
        defaults: {
            ...state.defaults,
            email: REDACTED_VALUE,
        },
    };
};

export const name = 'tokenRequest';
