import { getTppConfig, deepMergeObjects } from 'util/index';

// Respect web-app local tpp configurations over config from tpp-integration sevice api
const getOverallTppConfigurations = (memberProps, tppConfigurationsFromTI) => {
    if (memberProps) {
        const localResellerConfig = getTppConfig(memberProps?.realmId);
        const localMemberConfig = getTppConfig(memberProps?.memberId);
        const localSubTppConfig = getTppConfig(memberProps?.subTppRefId);

        const overallLocalTppConfig = {
            ...localResellerConfig,
            ...localMemberConfig,
            ...localSubTppConfig,
        };

        const regionalConfigurations = deepMergeObjects(
            {},
            tppConfigurationsFromTI?.regionalConfigurations,
        );
        if (overallLocalTppConfig?.regionalConfigurations) {
            deepMergeObjects(
                regionalConfigurations,
                overallLocalTppConfig?.regionalConfigurations,
            );
        }

        const translations = {
            ...regionalConfigurations?.default?.translations,
        };

        const doesTranslationsExist = Object.keys(translations)?.length !== 0;

        const doesRegionalConfigurationsExist =
            Object.keys(regionalConfigurations)?.length !== 0;

        return {
            ...tppConfigurationsFromTI,
            ...overallLocalTppConfig,
            ...(doesRegionalConfigurationsExist && {
                regionalConfigurations,
            }),
            ...(doesTranslationsExist && { translations }),
        };
    }
    return tppConfigurationsFromTI;
};

export default getOverallTppConfigurations;
