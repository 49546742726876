import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {isNull, formatDataList} from '../../Util';
import {withValue} from '../../Hoc.jsx';
import {API_CALL_DELAY, LIST_PAGE_SIZE} from '../../config/constants';

class AsyncDataProvider extends PureComponent {
    static propTypes = {
        page: PropTypes.number,
        pageSize: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        source: PropTypes.func.isRequired,
        fetchDataKey: PropTypes.string.isRequired,
        fetchPagingKey: PropTypes.string.isRequired,
        idKey: PropTypes.string.isRequired,
        titleKey: PropTypes.string.isRequired,
        dataKey: PropTypes.string.isRequired,
        extraKey: PropTypes.string.isRequired,
        withPointer: PropTypes.bool,
        value: PropTypes.any,
        setValue: PropTypes.func,
        fetchOnMount: PropTypes.bool,
        useCustomData: PropTypes.bool,
        customData: PropTypes.any,
        customLoading: PropTypes.bool,
        customFormat: PropTypes.func,
    }
    static defaultProps = {
        pageSize: 25,
        page: 1,
        fetchDataKey: 'data',
        fetchPagingKey: 'paging',
        idKey: 'id',
        titleKey: 'title',
        dataKey: 'data',
        extraKey: 'extra',
        withPointer: false,
        fetchOnMount: true,
        useCustomData: false,
    }
    constructor(props) {
        super(props);
        this.state = {
            loadingFirstBatch: false,
            loadingMore: false,
            end: LIST_PAGE_SIZE,
            page: props.page,
            data: [],
            selected: null,
            searchTerm: '',
        };
        this.fetchData = this.fetchData.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.getData = this.getData.bind(this);
    }
    setSearch(term) {
        const {searchTerm} = this.state;
        if (searchTerm === term) return;
        this.setState({
            page: 0,
            searchTerm: term,
        }, () => {
            clearTimeout(this.fetchTimer);
            this.fetchTimer = setTimeout(() => {
                this.fetchData({
                    page: 1,
                    search: term,
                });
            }, API_CALL_DELAY);
        });
    }
    setSelected(selected, {triggerChange, doneCallback} = {}) {
        const data = this.getData();
        const {setValue} = this.props;
        if (selected < 0 || selected >= data.length) return;
        this.setState({
            selected,
        }, () => {
            doneCallback && doneCallback();
        });
        if (triggerChange) {
            const value = !isNull(selected) && data[selected] || null;
            setValue(value);
        }
    }
    getData() {
        const {useCustomData, customData} = this.props;
        const {data} = this.state;
        return useCustomData ? customData : data;
    }
    getLoading() {
        const {useCustomData, customLoading} = this.props;
        const {loadingMore} = this.state;
        return useCustomData ? customLoading : loadingMore;
    }
    componentDidMount() {
        const {fetchOnMount} = this.props;
        if (!fetchOnMount) return;
        const {page, searchTerm} = this.state;
        this.fetchData({
            page,
            search: searchTerm,
        });
    }
    async fetchData(options = {}) {
        const {idKey, titleKey, dataKey, extraKey, fetchDataKey,
            fetchPagingKey, useCustomData, customFormat} = this.props;
        const {loadingFirstBatch, searchTerm} = this.state;
        const loadingMore = this.getLoading();
        if (loadingFirstBatch || loadingMore) return;
        options.perPage = this.props.pageSize;
        const isLoadingMore = options.page && options.page > 1 || false;
        options.search = searchTerm;
        if (this.state.end && (this.state.page >= this.state.end)) return;
        !isLoadingMore && this.setState({data: [], selected: null});
        this.setState({
            loadingFirstBatch: !isLoadingMore,
            loadingMore: isLoadingMore,
        });
        let data;
        try {
            data = await this.props.source(options);
        } catch (e) {
            throw e;
        } finally {
            this.setState({
                loadingFirstBatch: false,
            });
        }
        // loadingMore = isLoadingMore && paging.page !== 1;
        if (useCustomData) return;
        const {data: dataList, paging} = formatDataList(data, {
            fetchDataKey, fetchPagingKey, idKey, titleKey, dataKey, extraKey,
            customFormat,
        });
        this.setState(oldState => ({
            end: paging && paging.pageCount || oldState.end + this.props.pageSize,
            page: (isLoadingMore && oldState.page || 0) + 1,
            data: isLoadingMore
                && [...oldState.data, ...dataList]
                || dataList,
            loadingMore: false,
        }));
    }
    getValue() {
        const {value, idKey} = this.props;
        const {data} = this.state;
        if (value && value.rawData || !value || !data.length) {
            return value;
        }
        return data.find(item => item.rawData[idKey] === value[idKey]) || value;
    }
    render() {
        const {children, withPointer, value, idKey} = this.props;
        const {loadingFirstBatch, selected, searchTerm, page} = this.state;
        const data = this.getData();
        const loadingMore = this.getLoading();
        return (
            <React.Fragment>
                {React.Children.map(children, child => React.cloneElement(child, {
                    fetchData: this.fetchData,
                    setSelected: withPointer && this.setSelected,
                    loadingFirstBatch,
                    loadingMore,
                    page,
                    data,
                    selected: withPointer ? selected : null,
                    value: withPointer ? value : null,
                    withPointer,
                    setSearch: this.setSearch,
                    searchTerm,
                    idKey,
                }))}
            </React.Fragment>
        );
    }
}

export default withValue(AsyncDataProvider);
