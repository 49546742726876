import React from 'react';
import AbstractDropdown from './Abstract';
import classNames from 'classnames/bind';
import style from '../../../css/DefaultDropdown.css';

const cx = classNames.bind(style);

const Dropdown = props => (
    <AbstractDropdown
        className={cx('DefaultDropdown__container')}
        displayClass={cx('DefaultDropdown__container__display')}
        showCaret
        {...props} />
);

export default Dropdown;
