import React from 'react';
import PropTypes from 'prop-types';
import style from './CheckIcon.css';
import classNames from 'classnames/bind';
import { FaCheck } from 'react-icons/fa';

const cx = classNames.bind(style);

const CheckIcon = ({ className, title }) => (
    <FaCheck
        title={title}
        className={cx({
            CheckIcon: true,
            [className]: !!className,
        })}
    />
);

CheckIcon.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default CheckIcon;
