import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import style from '../Pisp/PaymentInitStatus.css';
import CopyIcon from '../Icons/CopyIcon.js';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import VrpConsentInitStatusTitle from './VrpConsentInitStatusTitle.js';
import { goToTpp } from 'actions/shared';
import { copyOnClipboard, formatCurrency } from 'util/index';
import { FormattedMessage, intlShape } from 'react-intl';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    CUSTOM_TPP_FEATURES as featureConfig,
    DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
} from 'config/constants';

const cx = classNames.bind(style);

class VrpConsentInitStatus extends Component {
    constructor(props) {
        super(props);
        this.state = { seconds: 10 };
    }
    async componentDidMount() {
        this.props.setDisplayFooterShadow(false);
        this.intervalId = setInterval(this.countdown, 1000);
    }

    countdown = () => {
        const timeoutFunc = this.props.goToTpp;
        const { seconds } = this.state;
        if (seconds < 1) {
            clearInterval(this.intervalId);
            timeoutFunc();
        } else {
            this.setState({
                seconds: seconds - 1,
            });
        }
    };

    displayUserInfo(userInfo, userType) {
        const bankIbanOrAccNo = this.props.isFasterPayment
            ? userInfo?.accountNumber
            : userInfo?.iban;
        const bankBicOrSc = this.props.isFasterPayment
            ? userInfo?.sortCode
            : userInfo?.bic;
        return (
            <>
                {userInfo?.name && (
                    <div className={cx('PaymentInitStatus-data-row')}>
                        <div className={cx('PaymentInitStatus-data-row-title')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={`vrp.consent.${userType}.legalName`}
                            />
                        </div>
                        <div
                            className={cx('PaymentInitStatus-data-row-detail')}>
                            <strong className={cx('Colored-Text')}>
                                {userInfo.name}
                            </strong>
                        </div>
                    </div>
                )}
                {bankIbanOrAccNo && (
                    <div className={cx('PaymentInitStatus-data-row')}>
                        <div className={cx('PaymentInitStatus-data-row-title')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={
                                    this.props.isFasterPayment
                                        ? `vrp.consent.${userType}.accountNumber`
                                        : `vrp.consent.${userType}.iban`
                                }
                            />
                        </div>
                        <div
                            className={cx('PaymentInitStatus-data-row-detail')}>
                            <strong className={cx('Colored-Text')}>
                                {bankIbanOrAccNo}
                            </strong>
                        </div>
                    </div>
                )}
                {bankBicOrSc && (
                    <div className={cx('PaymentInitStatus-data-row')}>
                        <div className={cx('PaymentInitStatus-data-row-title')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={
                                    this.props.isFasterPayment
                                        ? `vrp.consent.${userType}.sortCode`
                                        : `vrp.consent.${userType}.bic`
                                }
                            />
                        </div>
                        <div
                            className={cx('PaymentInitStatus-data-row-detail')}>
                            <strong className={cx('Colored-Text')}>
                                {bankBicOrSc}
                            </strong>
                        </div>
                    </div>
                )}
            </>
        );
    }

    render() {
        const {
            pispName,
            pispAlias,
            maxAmountPerPayment,
            refId,
            description,
            periodicLimits,
            debtorInfo,
            creditorInfo,
            selectedBankName,
            creditorBankName,
            expiration,
            initStatus,
            goToTpp,
        } = this.props;
        const { seconds } = this.state;
        const expireDate = new Date();
        expireDate.setDate(
            expireDate.getDate() + DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
        );

        return (
            <Fragment>
                <TokenContainer
                    className={cx('Vrp-PaymentInitStatus-subcontainer')}
                    noMargin>
                    <div
                        className={cx({
                            'TokenContainer-margin': true,
                            'PaymentInitStatus-margin': true,
                        })}>
                        <VrpConsentInitStatusTitle
                            vrpConsentStatus={initStatus}
                            seconds={seconds}
                        />
                    </div>
                    <div className={cx('PaymentInitStatus-data')}>
                        <TokenTitle sub>
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.initStatus.status'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span className={cx('Vrp-status')}>
                                        <strong>{initStatus}</strong>
                                    </span>
                                </div>
                            </div>
                            {periodicLimits &&
                                periodicLimits.map((periodicLimit, index) => (
                                    <div
                                        key={index}
                                        className={cx(
                                            'PaymentInitStatus-data-row',
                                        )}>
                                        <div
                                            className={cx(
                                                'PaymentInitStatus-data-row-title',
                                            )}>
                                            <FormattedMessage
                                                tagName={'span'}
                                                id={'vrp.consent.maxAmountPer'}
                                                values={{
                                                    PERIOD_TYPE:
                                                        periodicLimit.periodType,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={cx(
                                                'PaymentInitStatus-data-row-detail',
                                            )}>
                                            <span
                                                className={cx('Colored-Text')}>
                                                {periodicLimit.maximumAmount}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.maxAmountPerPayment'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span className={cx('Colored-Text')}>
                                        {maxAmountPerPayment}
                                    </span>
                                </div>
                            </div>
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.refId'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span className={cx('RefID')}>
                                        <strong id="refId">{refId} </strong>
                                        <CopyIcon
                                            className={cx('Consent-CopyRefId')}
                                            ariaLabel={this.props.intl.formatMessage(
                                                {
                                                    id: 'vrp.consent.copyRefId',
                                                },
                                            )}
                                            onKeyPress={() =>
                                                copyOnClipboard(refId)
                                            }
                                            onClick={() =>
                                                copyOnClipboard(refId)
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                            {description && (
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row',
                                    )}>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'vrp.consent.description'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-detail',
                                        )}>
                                        <strong className={cx('Description')}>
                                            {description}
                                        </strong>
                                    </div>
                                </div>
                            )}
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.payee.bankName'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <strong className={cx('Colored-Text')}>
                                        {creditorBankName}
                                    </strong>
                                </div>
                            </div>
                            {this.displayUserInfo(creditorInfo, 'payee')}
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'vrp.consent.payer.bankName'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span className={cx('Consent-BankName')}>
                                        <strong>{selectedBankName}</strong>
                                    </span>
                                </div>
                            </div>
                            {debtorInfo &&
                                this.displayUserInfo(debtorInfo, 'payer')}
                            {expiration && (
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row',
                                    )}>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'vrp.consent.expiration'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-detail',
                                        )}>
                                        <span className={cx('Colored-Text')}>
                                            {expiration}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </TokenTitle>
                    </div>
                </TokenContainer>
                <TokenButtonsWrapper>
                    <Button
                        type="primary"
                        text={
                            seconds === 0
                                ? this.props.intl.formatMessage(
                                    {
                                        id:
                                              'common.button.continueToMerchant',
                                    },
                                    {
                                        MERCHANT_NAME: pispName
                                            ? pispName
                                            : pispAlias,
                                    },
                                )
                                : this.props.intl.formatMessage(
                                    {
                                        id:
                                              'common.button.returningInSeconds',
                                    },
                                    { SECONDS: seconds },
                                )
                        }
                        onClick={goToTpp}
                    />
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

VrpConsentInitStatus.propTypes = {
    goToTpp: PropTypes.func.isRequired,
    pispName: PropTypes.string.isRequired,
    pispAlias: PropTypes.string.isRequired,
    maxAmountPerPayment: PropTypes.string,
    intl: intlShape.isRequired,
    selectedBank: PropTypes.object,
    refId: PropTypes.string.isRequired,
    vrpRequestId: PropTypes.string.isRequired,
    description: PropTypes.string,
    setDisplayFooterShadow: PropTypes.func,
    periodicLimits: PropTypes.array,
    isFasterPayment: PropTypes.bool,
    creditorInfo: PropTypes.object,
    debtorInfo: PropTypes.object,
    selectedBankName: PropTypes.string,
    creditorBankName: PropTypes.string,
    expiration: PropTypes.string,
    initStatus: PropTypes.string.isRequired,
};

const mapStateToProps = (
    { tokenRequestService, sharedService, vrpService },
    ownProps,
) => {
    const currency = vrpService.getVrpCurrency();
    const { tppName } = tokenRequestService.getTppProfile();
    const tppAlias = tokenRequestService.getTppAlias();
    const vrpRequestId = vrpService.getVrpRequestId();
    const maxAmountPerPayment = formatCurrency(
        vrpService.getMaxIndividualAmount(),
        currency,
        ownProps.intl.formatNumber,
    );
    const selectedBank = sharedService.getSelectedBank();
    const description = vrpService.getDescription();
    const modifyPreSelectedBank = tokenRequestService.getTppFeature(
        featureConfig.MODIFY_PRE_SELECTED_BANK,
        false,
    );
    const periodicLimits = vrpService
        .getPeriodicLimits()
        .map(periodicLimit => {
            return {
                ...periodicLimit,
                maximumAmount: formatCurrency(
                    periodicLimit.maximumAmount,
                    currency,
                    ownProps.intl.formatNumber,
                ),
            };
        });
    return {
        pispName: tppName,
        pispAlias: tppAlias.value,
        selectedBankName: sharedService.getSelectedBankName(),
        selectedBank,
        refId: vrpService.getVrpRefId(),
        vrpRequestId,
        description,
        modifyPreSelectedBank,
        maxAmountPerPayment,
        periodicLimits,
        isFasterPayment: vrpService.getLocalInstrument() === 'FASTER_PAYMENTS',
        creditorInfo: vrpService.getCreditorInfo(),
        debtorInfo: vrpService.getDebtorInfo(),
        creditorBankName: vrpService.getCreditorBankName(),
        expiration: vrpService.getExpirationDate(),
        initStatus: vrpService.getStatus(),
    };
};

const mapDispatchToProps = {
    goToTpp,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VrpConsentInitStatus);
