import { getURLParameter } from '.';
import {
    DEV_KEY,
    DEFAULT_DEV_KEY,
    REGEXP_DIRECT_CREDENTIALS_FLOW,
} from 'config/constants';
import getTokenReqIdFromURL from './getTokenReqIdFromURL';
import sessionStorageWrapper from './sessionStorageWrapper';

// Dev key from session storage
const getDevKeyFromSession = () => {
    const dkFromSession = sessionStorageWrapper.get('devKey');
    if (
        !getTokenReqIdFromURL() &&
        dkFromSession &&
        dkFromSession !== 'undefined'
    ) {
        return dkFromSession;
    }
    return null;
};

const getIsDirectCredentialsFlow = () => {
    return REGEXP_DIRECT_CREDENTIALS_FLOW.test(window.location.href);
};

const getInstantDevKey = () => {
    const dkFromURL = getURLParameter('dk'); // Dev key directly from initial token request webapp URL
    const dkFromSession = getDevKeyFromSession();
    const isDirectCredsFlow = getIsDirectCredentialsFlow();
    if (dkFromURL) {
        return dkFromURL;
    } else if (isDirectCredsFlow) {
        return DEFAULT_DEV_KEY;
    } else if (dkFromSession) {
        return dkFromSession;
    } else {
        return DEV_KEY;
    }
};

export default getInstantDevKey;
