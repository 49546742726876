import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import style from './PispTransferOverview.css';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import { ROW_SIZE_LARGE } from '@token-io/lib-web-components/src/Components/List/Abstract';
import { terminateFlow } from 'actions/shared';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    proceedToConsent,
    setPaymentAccount,
    clearBankAndBack,
} from 'actions/pisp';
import { transformAccounts, formatCurrency } from 'util/index';
import { FormattedMessage, intlShape } from 'react-intl';

const cx = classNames.bind(style);

class PispTransferOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        this.props.setDisplayFooterShadow(false);
    }

    render() {
        const {
            bank,
            alias,
            accounts,
            paymentAccount,
            proceedWithCheckout,
            terminateFlow,
            total,
            amountLimit,
            setPaymentAccount,
            goToBankSelection,
        } = this.props;
        const status = this.state.isLoading
            ? Button.STATUS_LOADING
            : Button.STATUS_IDLE;
        const accs = accounts.map(acc => ({
            ...acc,
            text: acc.key,
            key: (
                <div>
                    <span>{acc.key}</span>
                    <span>{acc.value}</span>
                </div>
            ),
        }));
        const defaultValue = accs.find(acc => acc.id === paymentAccount);
        return (
            <Fragment>
                <TokenContainer
                    className={cx([
                        'Pisp-TransferOverview-subcontainer',
                        'Pisp-TransferOverview-title',
                    ])}>
                    <TokenTitle
                        className={cx('Pisp-TransferOverview-title-label')}>
                        <FormattedMessage id={'pisp.transferOverview.title'} />
                    </TokenTitle>
                    <TokenTitle sub>
                        <FormattedMessage
                            id={'pisp.transferOverview.misc2'}
                            showIcon={false}
                            values={{
                                AMOUNT: amountLimit,
                            }}
                        />
                    </TokenTitle>
                    <div className={cx('Pisp-TransferOverview-subcontainer')}>
                        <div className={cx('Pisp-TransferOverview-menu')}>
                            {alias ? (
                                <dl
                                    className={cx(
                                        'Pisp-TransferOverview-menu-item',
                                    )}>
                                    <dt>
                                        <span
                                            className={cx(
                                                'Pisp-TransferOverview-menu-title',
                                            )}>
                                            <FormattedMessage
                                                id={
                                                    'pisp.transferOverview.tokenAccount'
                                                }
                                            />
                                        </span>
                                        <span
                                            className={cx(
                                                'Pisp-TransferOverview-menu-value',
                                            )}>
                                            {alias}
                                        </span>
                                    </dt>
                                </dl>
                            ) : (
                                ''
                            )}
                            <dl
                                className={cx(
                                    'Pisp-TransferOverview-menu-item',
                                )}>
                                <dt>
                                    <span
                                        className={cx(
                                            'Pisp-TransferOverview-menu-title',
                                        )}>
                                        <FormattedMessage
                                            id={'pisp.transferOverview.bank'}
                                        />
                                    </span>
                                    <span
                                        className={cx(
                                            'Pisp-TransferOverview-menu-value',
                                        )}>
                                        {bank.name}
                                    </span>
                                </dt>
                                <dd>
                                    <a
                                        href="#"
                                        className={cx(
                                            'Pisp-TransferOverview-menu-action',
                                        )}
                                        onClick={e => {
                                            e.preventDefault();
                                            goToBankSelection();
                                        }}>
                                        <FormattedMessage
                                            id={
                                                'pisp.transferOverview.changeBank'
                                            }
                                        />
                                    </a>
                                </dd>
                            </dl>
                            <dl
                                className={cx(
                                    'Pisp-TransferOverview-menu-item-vertical',
                                )}>
                                <dt>
                                    <span
                                        className={cx(
                                            'Pisp-TransferOverview-menu-title',
                                        )}>
                                        <FormattedMessage
                                            id={
                                                'pisp.transferOverview.bankAccount'
                                            }
                                        />
                                    </span>
                                </dt>
                                <dd>
                                    <StaticDataProvider
                                        idKey={'id'}
                                        titleKey={'text'}
                                        extraKey={'value'}
                                        source={() => accs}
                                        defaultValue={defaultValue || accs[0]}
                                        onChange={acc => {
                                            setPaymentAccount(acc.id);
                                        }}
                                        withPointer>
                                        <Dropdown size={ROW_SIZE_LARGE} />
                                    </StaticDataProvider>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div
                        className={cx([
                            'Pisp-TransferOverview-subcontainer',
                            'Pisp-TransferOverview-total',
                        ])}>
                        <dl>
                            <dt>
                                <FormattedMessage
                                    id={'pisp.transferOverview.total'}
                                />
                            </dt>
                            <dd>{total}</dd>
                        </dl>
                    </div>
                </TokenContainer>
                <TokenButtonsWrapper>
                    <Button
                        onClick={() => {
                            this.setState({ isLoading: true });
                            proceedWithCheckout();
                        }}
                        status={status}
                        text={<FormattedMessage id={'common.button.next'} />}
                        autoFocus
                    />
                    <Button
                        onClick={() => {
                            terminateFlow({
                                error: 'access_denied',
                                message: 'Checkout was canceled',
                            });
                        }}
                        type={Button.TYPE_GHOST_WARNING}
                        text={<FormattedMessage id={'common.button.cancel'} />}
                    />
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

PispTransferOverview.propTypes = {
    proceedWithCheckout: PropTypes.func.isRequired,
    terminateFlow: PropTypes.func.isRequired,
    bank: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    paymentAccount: PropTypes.string.isRequired,
    setPaymentAccount: PropTypes.func.isRequired,
    alias: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    amountLimit: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    goToBankSelection: PropTypes.func.isRequired,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = (
    { sharedService, tokenService, userService },
    ownProps,
) => {
    const alias = userService.getAlias();
    const bank = sharedService.getSelectedBank();
    const accounts = userService.getBankMemberAccounts(bank.id);
    return {
        bank: sharedService.getSelectedBank(),
        accounts: transformAccounts(accounts, ownProps.intl.formatNumber),
        paymentAccount: tokenService.getSourceAccountId(),
        alias: alias ? alias.value : '',
        total: formatCurrency(
            tokenService.getAmount(),
            tokenService.getCurrency(),
            ownProps.intl.formatNumber,
        ),
        amountLimit: formatCurrency(
            30,
            tokenService.getCurrency(),
            ownProps.intl.formatNumber,
        ),
    };
};

const mapDispatchToProps = {
    proceedWithCheckout: proceedToConsent,
    terminateFlow,
    setPaymentAccount,
    goToBankSelection: clearBankAndBack,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PispTransferOverview);
