import React from 'react';
import PropTypes from 'prop-types';
import style from './CrossIconCircle.css';
import classNames from 'classnames/bind';
import { FaRegTimesCircle } from 'react-icons/fa';

const cx = classNames.bind(style);

const CrossIconCircle = ({ className, title }) => (
    <FaRegTimesCircle
        title={title}
        className={cx({
            CrossIcon: true,
            [className]: !!className,
        })}
    />
);

CrossIconCircle.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default CrossIconCircle;
