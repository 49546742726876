import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import List from '../List';
import {isNull} from '../../Util';
import Overlay from '../Overlay';
import ListCell from '../List/Cell';
import SimpleScroll from '../Scroll/Simple';
import iconDropdownCaret from '../../../assets/icon-dropdown-caret.svg';
import {ROW_SIZE_STANDARD, ROW_SIZE_LARGE} from '../List/Abstract';
import style from '../../../css/Dropdown.css';

const cx = classNames.bind(style);

class AbstractDropdown extends PureComponent {
    constructor() {
        super();
        this.state = {
            focused: false,
        };
        this.handleClickContainer = this.handleClickContainer.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }
    handleClickContainer() {
        this.props.setDropDownState(undefined);
        this.setState({isTabKeyPressed: false});
        this.setState(prevState => ({focused: !prevState.focused}));
    }
    onKeyDown(event, dummy) {
        if (event.key === 'Enter') {
            if (this.state.focused === true) dummy.focus();
            this.props.setDropDownState(undefined);
            this.setState(prevState => ({focused: !prevState.focused}));
        } else if (event.key === 'Tab') {
            if (this.state.focused === true) dummy.focus();
            this.setState({focused: false, isTabKeyPressed: false});
        }
    }
    onKeyUp(event) {
        if (event.key === 'Tab') {
            this.setState({isTabKeyPressed: true});
        }
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.value && !this.props.value) return;
        if (!prevProps.value || !this.props.value
            || this.props.value.key !== prevProps.value.key) {
            this.setState({
                focused: false,
            });
        }
    }
    render() {
        const {value, disabled, className, labelClass, rowClass, listClass, scrollClass,
            displayClass, selectedClass, unselectedClass, label, placeholder, align, allowDeselect,
            showCaret, selected, data, setSelected, withPointer, setSearch, size, width, searchTerm,
            CellComponent, dropDownState, ariaLabel, overlayClass,
        } = this.props;
        const focused = dropDownState !== undefined ? dropDownState : this.state.focused;
        const dropdownClasses = cx({
            'Dropdown__container': true,
            [`Dropdown__container--${size}`]: true,
            'Dropdown__container--open': focused,
            'Dropdown__container--disabled': disabled,
            'Dropdown__container--tabFocused': this.state.isTabKeyPressed,
            [className]: !!className,
        });
        const labelClasses = cx({
            [labelClass]: !!labelClass,
        });
        const displayClasses = cx({
            'Dropdown__container__display': true,
            [displayClass]: !!displayClass,
            [selectedClass]: selected >= 0 && selectedClass,
            [unselectedClass]: selected < 0 && unselectedClass,
            'Dropdown__container__display--placeholder': isNull(value),
        });
        return (
            <div
                className={dropdownClasses}
                onClick={this.handleClickContainer}
                ref={r => this.dummy = r}
                onKeyDown={e => this.onKeyDown(e, this.dummy)}
                onKeyUp={e => this.onKeyUp(e)}
                onBlur={() => this.setState({isTabKeyPressed: false})}
                tabIndex='0'
                aria-label={ariaLabel}
                role='button'
            >
                {label && <span className={labelClasses}>{label}</span>}
                <div className={displayClasses} style={{display: 'inline-block'}}>
                    {!isNull(value)
                        ? <CellComponent
                            {...value}
                            size={size} />
                        : placeholder}
                </div>
                {showCaret &&
                    <img className={cx('Dropdown__container__caret')}
                        src={iconDropdownCaret} alt='' />}
                {focused &&
                    <Overlay
                        dummy={this.dummy}
                        overlayClass={overlayClass}
                        anchor={Overlay.ANCHOR_BOTTOM}
                        onClick={() => {
                            this.setState({
                                focused: false,
                            });
                        }}
                        align={align}
                        width={width}>
                        <SimpleScroll
                            selected={selected}
                            maxHeight={275}
                            withPointer={withPointer}
                            setSelected={setSelected}
                            className={cx({
                                'Dropdown__scroll': true,
                                [scrollClass]: !!scrollClass,
                            })}
                            autoFocus>
                            <List
                                className={listClass}
                                data={data}
                                setSearch={setSearch}
                                searchTerm={searchTerm}
                                value={value}
                                size={size}
                                rowClass={rowClass}
                                allowDeselect={allowDeselect}
                                CellComponent={CellComponent}
                                tabIndex='-1'
                                focused={focused} />
                        </SimpleScroll>
                    </Overlay>
                }
            </div>
        );
    }
}

AbstractDropdown.defaultProps = {
    data: [],
    size: ROW_SIZE_STANDARD,
    disabled: false,
    placeholder: 'Please select',
    align: Overlay.ALIGN_LEFT,
    width: 0,
    CellComponent: ListCell,
    setDropDownState: () => {},
};

AbstractDropdown.propTypes = {
    data: PropTypes.array,
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    selected: PropTypes.number,
    value: PropTypes.any,
    setSelected: PropTypes.func,
    setSearch: PropTypes.func,
    searchTerm: PropTypes.string,
    withPointer: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    listClass: PropTypes.string,
    displayClass: PropTypes.string,
    labelClass: PropTypes.string,
    selectedClass: PropTypes.string,
    unselectedClass: PropTypes.string,
    rowClass: PropTypes.string,
    overlayClass: PropTypes.string,
    scrollClass: PropTypes.string,
    showCaret: PropTypes.bool,
    allowDeselect: PropTypes.bool,
    placeholder: PropTypes.string,
    align: PropTypes.oneOf([
        Overlay.ALIGN_LEFT,
        Overlay.ALIGN_RIGHT,
    ]),
    width: PropTypes.number,
    CellComponent: PropTypes.func.isRequired,
    dropDownState: PropTypes.bool,
    setDropDownState: PropTypes.func,
    ariaLabel: PropTypes.string,
};

export default AbstractDropdown;
