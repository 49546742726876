import React from 'react';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import { setBankAndProceedToConsent, backToBankSelector } from 'actions/vrp';
import BankSelectorFirst from '../Shared/BankSelectorFirst';

// Higher order component BankSelector for AISP
const VrpBankSelectorFirst = props => <BankSelectorFirst {...props} />;

VrpBankSelectorFirst.propTypes = {
    setBankAndProceedToConsent: PropTypes.func,
    backToBankSelector: PropTypes.func,
};

const mapDispatchToProps = {
    setBankAndProceedToConsent,
    backToBankSelector,
};

export default connect(null, mapDispatchToProps)(VrpBankSelectorFirst);
