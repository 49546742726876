import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {withStatic} from '../../Hoc.jsx';
import List from '../List';
import {isNull} from '../../Util';
import TextInput from '../TextInput';
import Overlay from '../Overlay';
import ListCell from '../List/Cell';
import InfiniteScroll from '../Scroll/Infinite';
import iconSearch from '../../../assets/icon-search.svg';
import {ROW_SIZE_STANDARD, ROW_SIZE_LARGE} from '../List/Abstract';
import style from '../../../css/DefaultSearch.css';

const cx = classNames.bind(style);

export const ICON_ALIGN_LEFT = 'left';
export const ICON_ALIGN_RIGHT = 'right';

class AbstractSearch extends PureComponent {
    constructor() {
        super();
        this.state = {
            focused: false,
            tabFocused: false,
            activeDescendant: undefined,
        };
        this.searchFieldRef = React.createRef();
    }
    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                focused: false,
            });
        }
        this.props.setSearchFocus(this.state.focused);
    }

    componentDidMount() {
        this.input = this.searchFieldRef.current._comp.current._comp.current._comp.current;
        this.props.setSearchFocus(this.state.focused);
    }
    render() {
        const {disabled, className, searchInputClass, selected, data, listClass,
            setSelected, withPointer, placeholder, fetchData, page, rowClass, spinnerColor,
            loadingFirstBatch, loadingMore, setSearch, value, size, allowDeselect,
            scrollClass, iconAlignment, CellComponent, ariaLabel, id, customInputOnChange, customInputValue
        } = this.props;
        const {focused} = this.state;
        const searchInputClasses = cx({
            'Search__container': true,
            'Search__container--open': focused,
            'Search__container--disabled': disabled,
            [className]: !!className,
        });
        const listClasses = cx({
            'Search-dropdown': true,
            'Search-dropdown--show': focused,
            [listClass]: !!listClass,
        });
        const icon = <img src={iconSearch} alt='' />;
        const input = (<TextInput
            className={cx({
                'Search__input--focus': focused,
            })}
            ref={this.searchFieldRef}
            inputFieldClass={cx({
                [searchInputClass]: !!searchInputClass,
            })}
            placeholder={placeholder}
            status={loadingFirstBatch && TextInput.STATUS_LOADING || TextInput.STATUS_IDLE}
            append={iconAlignment == ICON_ALIGN_RIGHT && icon}
            prepend={iconAlignment == ICON_ALIGN_LEFT && icon}
            id={id}
            tabFocused={this.state.tabFocused}
            spinnerColor={spinnerColor}
            {
                // Custom input handler for search text box
                ...(customInputOnChange
                        ? {
                            value: customInputValue,
                            useCustomData: true,
                            customSetValue: customInputOnChange
                        } : {}
                    )
            }
            onFocusChange={focused => {
                focused && this.setState({focused});
            }}
            onClick={() => {
                const {focused} = this.state;
                if (focused) return;
                this.setState({focused: true});
            }}
            onChange={value => {
                setSearch(value);
            }}
            onKeyUp={e => {
                e.preventDefault();
                if (!focused) {
                    this.setState({focused: true});
                } else {
                    setSelected(isNull(selected) ? 0 : selected - 1);
                }
            }}
            onKeyDown={e => {
                const { key } = e;
                let activeElementIndex = selected;
                if (!focused) {
                    this.setState({focused: true});
                } else if (key === 'ArrowDown') {
                    activeElementIndex = isNull(selected) ? 0 : selected + 1;
                    this.setState({ activeDescendant: `${listClasses}-${activeElementIndex}` });
                    setSelected(isNull(selected) ? 0 : selected + 1);
                } else if (key === 'ArrowUp') {
                    activeElementIndex = isNull(selected) ? 0 : selected - 1;
                    this.setState({ activeDescendant: `${listClasses}-${activeElementIndex}` });
                    setSelected(isNull(selected) ? 0 : selected - 1);
                } else if (key === 'Escape' || key === 'Tab') {
                    allowDeselect && setSelected(null, {triggerChange: true});
                    this.setState({ focused: false, tabFocused: false });
                } else if (key === 'Enter') {
                    setSelected(selected, {triggerChange: true});
                }
            }}
            onKeyEnter={() => {
                if (focused) {
                    setSelected(selected, {triggerChange: true});
                } else {
                    this.setState({focused: true});
                }
            }}
            onKeyEscape={() => allowDeselect && setSelected(null, {triggerChange: true})}
            activeDescendant={this.state.activeDescendant}
            ariaLabel={ariaLabel}
        />);
        return (
            <div
                className={searchInputClasses}
                ref={r => this.dummy = r}
                role="combobox"
                onKeyUp={e => { if (e.key === 'Tab') this.setState({tabFocused: true});} }
                onBlur={() => this.setState({tabFocused: false})}
                aria-expanded={focused}>
                {input}
                {focused &&
                    <Overlay
                        dummy={this.dummy}
                        anchor={Overlay.ANCHOR_BOTTOM}
                        onClick={() => {
                            this.setState({
                                focused: false,
                            });
                        }}>
                        <div role="region" aria-live="polite" className="visuallyHidden">
                            {`${data.length} results found`}
                        </div>
                        <InfiniteScroll
                            selected={selected}
                            fetchData={fetchData}
                            page={page}
                            loadingFirstBatch={false}
                            loadingMore={loadingMore}
                            maxHeight={275}
                            withPointer={withPointer}
                            data={data}
                            setSelected={setSelected}
                            setSearch={setSearch}
                            className={cx({
                                'Search__scroll': true,
                                [scrollClass]: !!scrollClass,
                            })}
                            autoFocus>
                            <List
                                className={listClasses}
                                value={value}
                                size={size}
                                rowClass={rowClass}
                                spinnerColor={spinnerColor}
                                allowDeselect={allowDeselect}
                                CellComponent={CellComponent}
                                activeDescendant={this.state.activeDescendant}
                                tabIndex='-1'
                                focused={focused} />
                        </InfiniteScroll>
                    </Overlay>
                }
            </div>
        );
    }
}

AbstractSearch.defaultProps = {
    data: [],
    size: ROW_SIZE_STANDARD,
    disabled: false,
    placeholder: 'Click to search',
    iconAlignment: ICON_ALIGN_LEFT,
    CellComponent: ListCell,
    setSearchFocus: () => {},
};

AbstractSearch.propTypes = {
    data: PropTypes.array,
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    iconAlignment: PropTypes.oneOf([
        ICON_ALIGN_LEFT,
        ICON_ALIGN_RIGHT,
    ]),
    selected: PropTypes.number,
    value: PropTypes.any,
    setSelected: PropTypes.func,
    setSearch: PropTypes.func,
    withPointer: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    listClass: PropTypes.string,
    searchInputClass: PropTypes.string,
    rowClass: PropTypes.string,
    scrollClass: PropTypes.string,
    placeholder: PropTypes.string,
    fetchData: PropTypes.func,
    page: PropTypes.number,
    loadingFirstBatch: PropTypes.bool,
    loadingMore: PropTypes.bool,
    spinnerColor: PropTypes.string,
    allowDeselect: PropTypes.bool,
    CellComponent: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string,
    setSearchFocus: PropTypes.func,
    customInputOnChange: PropTypes.func,
    customInputValue: PropTypes.string,
};

export default withStatic({
    ICON_ALIGN_LEFT,
    ICON_ALIGN_RIGHT,
})(AbstractSearch);
