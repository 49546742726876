import React from 'react';
import PropTypes from 'prop-types';
import style from './HomeIconSvg.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

const HomeIconSvg = ({
    bgColor,
    svgHeight,
    svgWidth,
    scale = -10,
    svgColor = '#000000',
}) => {
    return (
        <div
            className={cx('TokenHome-icon')}
            style={{
                height: svgHeight + 'px',
                width: svgWidth + 'px',
                ...(bgColor && { backgroundColor: bgColor }),
            }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                width={parseInt(svgWidth, 10) + scale - 2}
                height={parseInt(svgHeight, 10) + scale}
                fill={svgColor}
                viewBox="0 0 24 24">
                <path d="M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z" />
            </svg>
        </div>
    );
};

HomeIconSvg.propTypes = {
    svgHeight: PropTypes.string,
    svgWidth: PropTypes.string,
    svgColor: PropTypes.string,
    bgColor: PropTypes.string,
    scale: PropTypes.number,
};

export default HomeIconSvg;
