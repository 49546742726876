import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import { terminateFlow } from 'actions/shared';
import Button from '@token-io/lib-web-components/src/Components/Button';
import { FormattedMessage } from 'react-intl';
import { isPopup, getURLParameter } from 'util/index';
import { CUSTOM_TPP_FEATURES as featureConfig } from 'config/constants';
import Background from 'components/App/Background';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import DefaultErrorPage from './DefaultErrorPage';
import GPHSBCErrorPage from './GPHSBCErrorPage';
import HSBCErrorPage from './HSBCErrorPage';

const ErrorPage = ({
    displayCustomErrorPage,
    terminateFlow,
    isRedirect,
    tppName,
    error,
    tppCategory,
}) => {
    const displayButton =
        (isPopup() || isRedirect) &&
        !(error?.redirectBack === false) &&
        tppName;
    const onClickHandler = () => {
        const encTraceID = getURLParameter('trace-id');
        const encTokenTraceID = getURLParameter('token-trace-id');
        const traceId = encTraceID && decodeURIComponent(encTraceID);
        const tokenTraceID =
            encTokenTraceID && decodeURIComponent(encTokenTraceID);

        terminateFlow({
            error: error?.code || 'server_error',
            message: error?.message,
            'trace-id': traceId || tokenTraceID,
        });
    };

    const ErrorPage = () => {
        if (displayCustomErrorPage) {
            switch (tppCategory) {
                case 'HSBC':
                    return <HSBCErrorPage />;
                case 'GP-HSBC':
                    return <GPHSBCErrorPage />;
            }
        }
        return <DefaultErrorPage />;
    };

    return (
        <Background>
            {ErrorPage()}
            {displayButton && (
                <TokenButtonsWrapper>
                    <Button
                        type="primary"
                        text={
                            <FormattedMessage
                                id={'common.button.continueToMerchant'}
                                values={{ MERCHANT_NAME: tppName }}
                            />
                        }
                        onClick={onClickHandler}
                    />
                </TokenButtonsWrapper>
            )}
        </Background>
    );
};

ErrorPage.propTypes = {
    isRedirect: PropTypes.bool,
    terminateFlow: PropTypes.func.isRequired,
    tppName: PropTypes.string,
    displayCustomErrorPage: PropTypes.bool,
    tppCategory: PropTypes.string,
    error: PropTypes.object,
};

const mapStateToProps = ({ sharedService, tokenRequestService }) => {
    const tppCategory = tokenRequestService.getTppFeature(
        featureConfig.CATEGORY,
    );
    const displayCustomErrorPage = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_ERROR,
    );
    const tppAlias = tokenRequestService.getTppAlias();
    const tppName = tokenRequestService.getTppProfile()?.tppName;
    const actingAs = tokenRequestService.getTppActingAs();
    return {
        tppName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            tppAlias?.value,
        error: sharedService.getError(),
        isRedirect: sharedService.getIsRedirect(),
        tppCategory,
        displayCustomErrorPage,
    };
};

const mapDispatchToProps = { terminateFlow };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
