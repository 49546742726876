import {combineReducers, compose} from 'redux';
import {connect as reduxConnect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {throwError} from './actions';

export class ReduxService {
    constructor(options = {}) {
        this.options = options;
        if (!options.selectors)
            throw new Error('Need to pass in selectors');
        this.getServices = this.getServices.bind(this);
    }

    /**
     * Gets all services from the app state.
     */
    getServices(getState) {
        if (typeof getState !== 'function') {
            const state = getState;
            getState = () => state;
        }
        return this.options.selectors.reduce(
            (acc, service) =>
                ({
                    ...acc,
                    [service.name + 'Service']: service.selector(() =>
                        getState()[service.name]),
                }),
            {}
        );
    }

    /**
     * Gets the custom connect function that wraps the default one provided by react-redux.
     * This function has extra features such as top-level error-handling, intl-injection,
     * and providing services instead of the state object as an argument to mapStateToProps.
     */
    getConnect() {
        const wrapMapStateToProps = (getServices, mapStateToProps) =>
            (state, ownProps) => mapStateToProps(getServices(state), ownProps);
        return (mapStateToProps, mapDispatchToProps, mergeProps, options) => {
            const wrapObject = (actions, dispatch, dispatched) =>
                Object.assign({}, ...Object.keys(actions).map(k => ({
                    [k]: async (...args) => {
                        try {
                            dispatched
                                ? await actions[k](...args)
                                : await dispatch(actions[k](...args));
                        } catch (error) {
                            dispatch(throwError(error));
                        }
                    },
                })));
            const wrapTryCatch = mapDispatch => (dispatch, ownProps) => {
                const isFunction = typeof mapDispatch === 'function';
                const actions = isFunction ? mapDispatch(dispatch, ownProps) : mapDispatch;
                return wrapObject(actions, dispatch, isFunction);
            };
            const wrappedMapState = mapStateToProps
                && wrapMapStateToProps(this.getServices, mapStateToProps);
            const safeMapDispatch = mapDispatchToProps && wrapTryCatch(mapDispatchToProps);
            return compose(
                injectIntl,
                reduxConnect(
                    wrappedMapState,
                    safeMapDispatch,
                    mergeProps,
                    options
                )
            );
        };
    }

    /**
     * Gets the root reducer from the selectors.
     * The root reducer also handles top-level state replacement.
     */
    getRootReducer() {
        return (state, action) => {
            if (action.type === 'REPLACE_STATE') {
                state = {...state, ...action.state};
            }
            const appReducer = combineReducers(this.options.selectors.reduce((acc, service) => {
                return {...acc, [service.name]: service.reducer};
            }, {}));
            return appReducer(state, action);
        };
    }
}
