import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {isNull} from '../../Util';
import style from '../../../css/Scroll.css';
import classNames from 'classnames/bind';
import {SCROLL_BOTTOM_LOAD_DISTANCE} from '../../config/constants';

const cx = classNames.bind(style);

class InfiniteScroll extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        fetchData: PropTypes.func.isRequired,
        page: PropTypes.number,
        loadingFirstBatch: PropTypes.bool,
        loadingMore: PropTypes.bool,
        selected: PropTypes.number,
        maxHeight: PropTypes.number,
        searchTerm: PropTypes.string,
        setSelected: PropTypes.func,
        withPointer: PropTypes.bool,
        className: PropTypes.string,
    }
    static defaultProps = {
        fetchData: () => null,
        page: 1,
        loadingFirstBatch: false,
        loadingMore: false,
        selected: null,
    }
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.overflow = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
        this.checkScroll = this.checkScroll.bind(this);
        this.setSelected = this.setSelected.bind(this);
    }
    /*
     * Scrolls list item into view
     *
     * @param {index} selected - index of the selected item to scroll into view, this is
     * essentially always the one that is denoted by cursor
     */
    handleScroll(selected) {
        const container = this.container.current;
        const overflow = this.overflow.current;
        if (selected === null) {
            container.scrollTop = 0;
            return 0;
        }
        const el = overflow.children[selected];
        if(!(el && container && overflow)) return;
        const offsetUp = el.offsetTop - overflow.offsetTop - container.scrollTop;
        const offsetDown = offsetUp + el.offsetHeight - container.offsetHeight;
        const scrollUp = offsetUp < 0;
        const scrollDown = offsetDown > 0;
        const scrollBy = scrollDown && offsetDown || scrollUp && offsetUp || 0;
        if (scrollBy === 0) return 0;
        container.scrollTop += scrollBy;
        return scrollBy;
    }
    /*
     * Handler for when the user scrolls, checks if close to bottom and fires API call to load
     * more banks
     */
    async checkScroll() {
        if (this.props.loadingMore || this.props.loadingFirstBatch || this.checkingScroll) return;
        const container = this.container.current;
        const overflow = this.overflow.current;
        if (overflow.offsetHeight - container.offsetHeight - container.scrollTop
            < SCROLL_BOTTOM_LOAD_DISTANCE) {
            this.checkingScroll = true;
            await this.props.fetchData({
                page: this.props.page + 1,
                search: this.props.searchTerm,
            });
        }
    }
    componentDidUpdate(prevProps) {
        this.checkingScroll = false;
        if (this.props.selected !== prevProps.selected) {
            this.handleScroll(this.props.selected);
        }
    }
    componentDidMount() {
        const {selected} = this.props;
        if (isNull(selected)) return;
        this.handleScroll(selected);
    }
    setSelected(selected, {doneCallback, preventScroll, ...options} = {}) {
        if (this.props.withPointer) {
            this.props.setSelected(selected, {
                doneCallback: () => {
                    !preventScroll && this.handleScroll(selected);
                    doneCallback && doneCallback();
                },
                ...options,
            });
        }
    }
    render() {
        const {children, maxHeight, className, ...props} = this.props;
        return (
            <div
                className={cx({
                    'Scroll': true,
                    [className]: !!className,
                })}
                onScroll={this.checkScroll}
                ref={this.container}
                style={{
                    maxHeight,
                }}>
                {React.Children.map(children, child => React.cloneElement(child, {
                    ref: this.overflow,
                    border: false,
                    ...props,
                    setSelected: this.setSelected,
                }))}
            </div>
        );
    }
}

export default InfiniteScroll;
