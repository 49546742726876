import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import RadioList from '@token-io/lib-web-components/src/Components/Radio/RadioList';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import SimpleScroll from '@token-io/lib-web-components/src/Components/Scroll/Simple';
import { intlShape } from 'react-intl';
import msg from 'config/locales/en.json';
import { setBank } from 'actions/shared';
import { FormattedMessage } from 'react-intl';

const RecentBankList = ({ intl, setBank, selectedBankId, banks }) => (
    <Fragment>
        <TokenTitle sub className="BankSelector-search-label">
            <FormattedMessage id={'common.bankSelector.recent'} />
        </TokenTitle>
        <StaticDataProvider
            idKey={'id'}
            titleKey={'country'}
            dataKey={'name'}
            extraKey={'identifier'}
            source={() => banks}
            customFormat={({ id, name, identifier, selectedCountry }) => ({
                id,
                name,
                country: intl.formatMessage({
                    id: `common.country.${selectedCountry}`,
                    defaultMessage: msg.common.country[selectedCountry],
                }),
                identifier: identifier || '',
            })}
            useCustomData
            customData={banks}
            value={banks.find(item => item.id === selectedBankId)}
            customLoading={false}
            customSetValue={async (value, { doneCallback }) => {
                await setBank(value);
                doneCallback();
            }}
            withPointer>
            <SimpleScroll>
                <RadioList
                    size={'large'}
                    className={'RecentBankSelector'}
                    tabIndex="0"
                    ariaLabel={intl.formatMessage({
                        id: 'common.bankSelector.recent',
                    })}
                    focused
                />
            </SimpleScroll>
        </StaticDataProvider>
    </Fragment>
);

RecentBankList.propTypes = {
    selectedBankId: PropTypes.string,
    setBank: PropTypes.func.isRequired,
    banks: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
};

RecentBankList.defaultProps = {
    banks: [],
};

const mapStateToProps = ({ sharedService }) => ({
    selectedBankId: sharedService.getSelectedBankId(),
});

const mapDispatchToProps = {
    setBank,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentBankList);
