import {
    INTERNAL_TOKEN_REQUEST_STATUS_REASON,
    SET_TOKEN_REQUEST_RESULT_STATUS_INTERNAL_PATH,
} from 'config/constants';
import storeWebappEvent from './storeWebappEvent';
import TppIntegrationClient from './tppIntegrationClient';

const setTokenRequestStatus = (internalStatus, extraData) => async (
    dispatch,
    { tokenRequestService, sharedService },
) => {
    const isTokenRequest = !sharedService.isVrp();
    try {
        // Independent Promises to resolve parallelly
        const promises = new Array(
            dispatch(
                storeWebappEvent({ event: internalStatus, payload: extraData }),
            ),
        );
        if (isTokenRequest) {
            const tokenRequestId = tokenRequestService.getRequestId();
            const params = {
                TOKEN_REQUEST_ID: tokenRequestId,
            };
            const payload = {
                status: internalStatus,
                statusReasonInformation:
                    INTERNAL_TOKEN_REQUEST_STATUS_REASON[internalStatus],
            };

            promises.push(
                TppIntegrationClient({
                    method: 'post',
                    url: SET_TOKEN_REQUEST_RESULT_STATUS_INTERNAL_PATH.replace(
                        /{([\w]+)}/g,
                        (match, group) => params[group],
                    ),
                    data: {
                        ...payload,
                    },
                }),
            );
        }
        await Promise.all(promises);
    } catch (e) {
        console.error(e); // eslint-disable-line
    }
};

export default setTokenRequestStatus;
