import {
    blockUserOnURLPaths,
    getTokenReqIdFromURL,
    sessionStorageWrapper,
} from 'util/index';
import { actionIfTokenReqProcessed } from 'actions/shared';
import {
    ROUTE_REDIRECT_OPTIONS,
    ROUTE_LOADING,
    EXTERNAL_TOKEN_REQUEST_STATUS,
} from 'config/constants';
import { EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL } from 'config/exceptions';
import { changeRoute } from 'actions/shared/route';
import { throwError, TokenException } from '@token-io/lib-web-app';

const noBack = oldRequestId => {
    // Compares current token request with the one present in redux state
    // given user has clicked browser back button
    if (sessionStorageWrapper.get('blockUserToGoBack') === true) {
        const currentRequestId = getTokenReqIdFromURL();
        if (currentRequestId) {
            if (currentRequestId === oldRequestId) {
                return true;
            }
        } else if (blockUserOnURLPaths()) {
            return true;
        }
    }
    return false;
};

const validateBrowserSession = async store => {
    const notAllowUserToGoBack = noBack(
        store.getState()?.tokenRequest?.request?.id,
    );

    const status = sessionStorageWrapper.get('tokenRequestStatus');
    if (status === EXTERNAL_TOKEN_REQUEST_STATUS.REJECTED) {
        store.dispatch(
            throwError(
                new TokenException(
                    EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL.REJECTED,
                    EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL.message,
                ),
            ),
        );
    }

    if (notAllowUserToGoBack) {
        store.getState().shared.route.route.current.id = ROUTE_LOADING;
        try {
            const isTokenReqProcessed = await store.dispatch(
                actionIfTokenReqProcessed({
                    fetchLatestTokenReq: true,
                    sameSession: true,
                }),
            );
            if (!isTokenReqProcessed) {
                await store.dispatch(changeRoute(ROUTE_REDIRECT_OPTIONS));
            }
        } catch (e) {
            // Case where token request is REJECTED or EXPIRED
            store.dispatch(throwError(e));
        }
    }
};

export default validateBrowserSession;
