import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import style from './TermsAndCondition.css';
import { FormattedMessage } from 'react-intl';

const cx = classNames.bind(style);

const GMBHTermsAndConditions = ({ setDisplayFooterShadow }) => {
    useEffect(() => {
        setDisplayFooterShadow(false);
    }, []);

    const onClick = () => {
        window.close();
    };

    return (
        <>
            <div className="TermsAndCondition-div">
                <TokenContainer className={cx('TermsAndCondition-container')}>
                    <TokenTitle>
                        <FormattedMessage id={'terms.gmbh.title'} />
                    </TokenTitle>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.gmbh.para1.title'} />
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para1.heading'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para1.p1'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para1.p2'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para1.p3'} />
                        </p>
                    </div>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.gmbh.para2.title'} />
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para2.p1'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para2.p2'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para2.p3'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para2.p4'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage
                                id={'terms.gmbh.para2.email.label'}
                            />
                            <a
                                href="mailto:info@token.io"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.gmbh.para2.email.value'}
                                />
                            </a>
                        </p>
                    </div>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.gmbh.para3.title'} />
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para3.p1.s1'} />
                            <a
                                href="https://token.io/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.gmbh.para3.p1.link'}
                                />
                            </a>
                            .
                        </p>
                    </div>
                    <div className={cx('term')}>
                        <h4 className={cx('points-header')}>
                            <FormattedMessage id={'terms.gmbh.para4.title'} />
                        </h4>
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para4.p1'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para4.p2'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para4.p3.s1'} />
                            <a
                                href="https://www.bafin.buergerservice-bund.de/Formular/BasisKonto"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.gmbh.para4.p3.link'}
                                />
                            </a>
                            <FormattedMessage id={'terms.gmbh.para4.p3.s2'} />
                            <a
                                href="mailto:poststelle@bafin.de"
                                className={cx('termsLink')}>
                                <FormattedMessage
                                    id={'terms.gmbh.para4.p3.email'}
                                />
                            </a>
                            <FormattedMessage id={'terms.gmbh.para4.p3.s3'} />
                        </p>
                        <br />
                        <p className={cx('points-content')}>
                            <FormattedMessage id={'terms.gmbh.para4.p4'} />
                        </p>
                    </div>
                </TokenContainer>
            </div>
            <TokenButtonsWrapper>
                <Button
                    type="primary"
                    text={<FormattedMessage id={'terms.button'} />}
                    onClick={onClick}
                />
            </TokenButtonsWrapper>
        </>
    );
};

GMBHTermsAndConditions.propTypes = {
    setDisplayFooterShadow: PropTypes.func,
};

export default GMBHTermsAndConditions;
