const Dom = {
    applyStyle: (el, style) => {
        for (const key in style) {
            if (Object.prototype.hasOwnProperty.call(style, key)) {
                const value = style[key];
                if (value instanceof Array) {
                    for (let i = 0; i < value.length; i++) {
                        el.style[key] = value[i];
                    }
                } else {
                    el.style[key] = value;
                }
            }
        }
        return el;
    },

    applyAttrs: (el, attrs, curr) => {
        if (el.location) {
            return Dom.applyWindowAttrs(el, attrs, curr);
        }
        for (const key in attrs) {
            if (Object.prototype.hasOwnProperty.call(attrs, key)) {
                switch (key) {
                case 'style':
                    Dom.applyStyle(el, attrs.style);
                    break;
                case 'className':
                    if (el.classList.contains(attrs.className)) break;
                    el.classList.add(attrs.className);
                    break;
                default:
                    el.setAttribute(key, attrs[key]);
                }
            }
        }
        return true;
    },

    applyWindowAttrs: (w, attrs, curr) => {
        const availTop = screen.availTop;
        const availLeft = screen.availLeft;
        const availWidth = screen.availWidth;
        const availHeight = screen.availHeight;
        const width = Math.round(attrs.width || curr.width);
        const height = Math.round(attrs.height || curr.height);

        let left = Math.round(attrs.left || curr.left);
        let top = Math.round(attrs.top || curr.top);

        const center = attrs.center ? attrs.center : false;
        if (center) {
            left = Math.round((curr.left + curr.width / 2) - width / 2);
            top = Math.round((curr.top + curr.height / 2) - height / 2);
        }

        if (
            (curr.left === left || curr.left === availLeft) &&
            (curr.top === top || curr.top === availTop) &&
            (curr.width === width || curr.width === availWidth) &&
            (curr.height === height || curr.height === availHeight)
        ) {
            return true;
        }

        if (curr.left !== left || curr.top !== top) {
            w.moveTo(left, top);
        }

        if (curr.width !== width || curr.height !== height) {
            w.resizeTo(width, height);
        }

        return false;
    },

    createElement: (type, attrs) => {
        const el = window.document.createElement(type);
        Dom.applyAttrs(el, attrs);

        el.html = data => {
            if (typeof data === 'string') {
                el.innerHTML = data;
                return el;
            }
            if (data instanceof HTMLElement) {
                el.appendChild(data);
                return el;
            }
            if (data instanceof Array) {
                el.innerHTML = '';
                for (let i = 0; i < data.length; i++) {
                    el.appendChild(data[i]);
                }
                return el;
            }
            return el;
        };
        el.text = data => {
            el.textContent = data;
            return el;
        };
        return el;
    },

    isAChildOf: (_parent, _child) => {
        if (_parent === _child) {
            return false;
        }
        while (_child && _child !== _parent) {
            _child = _child.parentNode;
        }
        return _child === _parent;
    },
};

export default Dom;
