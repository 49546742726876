import { parseUrl, stringify } from 'query-string';
import base64url from 'base64url';
import { formatDataList } from '@token-io/lib-web-components/src/Util';
import {
    applessAisp,
    initiateAispFlow,
    noApp as aispNoApp,
    setAispTokenType,
    redirectToBank,
} from 'actions/aisp';
import {
    initiatePispFlow,
    noApp as pispNoApp,
    setTransferTokenType,
    setStandingOrderTokenType,
    prepareGuestTransfer,
    oneStepTransfer,
    setBulkTransferTokenType,
} from 'actions/pisp';
import {
    backOrGoTo,
    backStep,
    changeRoute,
    setStep,
} from 'actions/shared/route';
import {
    cancelPollForTokenRequestResult,
    fetchTokenRequest,
    getBankFromLocalStorage,
    getTokenClient,
    pollForTokenRequestResult,
    removeCancelTokenOnClose,
    saveBankToLocalStorage,
    fetchTokenRequestResultWithStatus,
    getMemberProfileById,
    getTempMember,
    setTokenRequestStatus,
    getBankStatus,
} from 'actions/shared/api';
import {
    APP_APPROVAL,
    AISP_ENTER_ALIAS,
    BANK_PAGE_SIZE,
    LOADING_STEP,
    PISP_ENTER_ALIAS,
    POPUP_FAILURE,
    POPUP_SUCCESS,
    ROUTE_LOADING,
    ROUTE_BANK_LOADING,
    ROUTE_REDIRECT_TO_TPP,
    TOKEN_TYPE_VRP,
    TOKEN_TYPE_ACCESS,
    TOKEN_TYPE_TRANSFER,
    TOKEN_TYPE_BULK_TRANSFER,
    TOKEN_TYPE_STANDING_ORDER,
    webAppUrl,
    banksWithBranches,
    ROUTE_BANK_REDIRECT,
    ROUTE_CSRF_MISMATCH,
    ALIAS_COUNTRY_LIST,
    PISP_PAYMENT_INIT_STATUS,
    ROUTE_PISP,
    TPP_LOADING,
    WEB_APP_EVENTS,
    GEO_LOCATION_RESTRICTION,
    CUSTOM_TPP_FEATURES as featureConfig,
    EXTERNAL_TOKEN_REQUEST_STATUS as externalRequestStatus,
    INTERNAL_TOKEN_REQUEST_STATUS as requestStatus,
    TOKEN_WEB_APP_IFRAME_NAME,
    TOKEN_BANK_POPUP_IS_OPEN,
    PENDING_CREDENTIALS_TOKEN_REQUEST_STATUS,
    REGIONS,
    tokenRequestResultTimeout,
    tokenRequestResultInterval,
    BANK_OUTAGE,
    PAYMENT_TYPE_GET_BANKS_OPTIONS as paymentTypeOptions,
    BANK_SELECTION_PRESETS,
    BANK_CHUNK_SIZE,
} from 'config/constants';
import {
    getHashValue,
    isContextIframe,
    getURLParameter,
    getUserDeviceMetadata,
    isPopup,
    localStorageWrapper,
    validateBrowserCompatibility,
    addCustomCss,
    removeCustomCss,
    formatBankBranchData,
    sessionStorageWrapper,
    keyExists,
    pollUntil,
    getWebappContext,
} from 'util/index';
import {
    EXCEPTION_UNSUPPORTED_CONTEXT,
    EXCEPTION_BANK_FLOW_FAILURE,
    EXCEPTION_INVALID_TOKEN_REQUEST_URL,
    EXCEPTION_NO_REDEEMER_ALIAS,
    EXCEPTION_REQUEST_CANCELLED,
    INTL_EXCEPTION_REQUEST_FAILED,
    INTL_EXCEPTION_APP_NOTIFICATION_TIMEOUT,
    EXCEPTION_TOKEN_ERROR,
    EXCEPTION_ACCESS_DENIED,
    EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL,
    EXCEPTION_TOKEN_REQUEST_STATUS_INTERNAL,
    EXCEPTION_TOKEN_REQUEST_RESTRICTED,
    EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE,
} from 'config/exceptions';
import {
    replaceState,
    throwError,
    TokenException,
    terminate,
} from '@token-io/lib-web-app';
import {
    formatCredentials,
    updateCustomization,
    getOverallTppConfigurations,
} from '../helpers';
import TokenCommunicator from 'services/TokenCommunicator';
import { topBanksList } from '../../config/topBanksList';
import { captureCredentials, initiateBankAuthFlow } from './credentials';
import { storeWebappEvent } from '../helpers/Api';
import { EXTERNAL_TOKEN_REQUEST_STATUS } from 'config/constants';

const Token = getTokenClient();

let GLOBAL_TOKEN_REQUEST;

// Used only for testing purpose.
// This is to clear/reset GLOBAL_TOKEN_REQUEST
export const resetGlobalTokenReq = () => {
    GLOBAL_TOKEN_REQUEST = null;
};

export const getTokenRequest = isBankCallback => async dispatch => {
    if (!GLOBAL_TOKEN_REQUEST) {
        GLOBAL_TOKEN_REQUEST = await dispatch(
            fetchTokenRequestInfo(isBankCallback),
        );
    }
    return GLOBAL_TOKEN_REQUEST;
};

export const setErrorSource = isTokenError => ({
    type: 'SET_ERROR_SOURCE',
    isTokenError,
});

export const setRedirect = () => ({
    type: 'SET_REDIRECT',
});

export const setLocale = locale => ({
    type: 'SET_LOCALE',
    locale,
});

export const setRefId = refId => ({
    type: 'SET_REF_ID',
    refId,
});

export const setCountries = countries => ({
    type: 'SET_COUNTRIES',
    countries,
});

export const setBanks = banks => ({
    type: 'SET_BANKS',
    banks,
});

export const saveRecentBanks = banks => ({
    type: 'SET_RECENT_BANKS',
    banks,
});

export const addBanks = banks => ({
    type: 'ADD_BANKS',
    banks,
});

export const clearBanks = () => ({
    type: 'CLEAR_BANKS',
});

export const setTppReturnData = tppReturnData => ({
    type: 'SET_TPP_RETURN_DATA',
    tppReturnData,
});

export const setSelectedBank = bank => ({
    type: 'SET_SELECTED_BANK',
    bank,
});

export const clearBank = () => ({
    type: 'CLEAR_SELECTED_BANK',
});

export const setBankInfo = bankInfo => ({
    type: 'SET_SELECTED_BANK_INFO',
    bankInfo,
});

export const setBankStatus = (bankName, isLive) => ({
    type: 'SET_BANK_STATUS',
    bankName,
    isLive,
});

export const clearBankStatus = () => ({
    type: 'CLEAR_BANK_STATUS',
});

export const setSelectedCountry = selectedCountry => ({
    type: 'SET_SELECTED_COUNTRY',
    selectedCountry,
});

export const addMember = member => ({
    type: 'ADD_MEMBER',
    member,
});

export const setAlias = alias => ({
    type: 'SET_ALIAS',
    alias,
    redacted: ['alias'],
});

export const clearMembers = () => ({
    type: 'CLEAR_MEMBERS',
});

export const setErrorMessage = message => ({
    type: 'SET_ERROR_MESSAGE',
    message,
});

export const clearErrorMessage = () => ({
    type: 'CLEAR_ERROR_MESSAGE',
});

export const setTokenRequestId = tokenRequestId => ({
    type: 'SET_TOKEN_REQUEST_ID',
    tokenRequestId,
});

export const setTppSource = tppSource => ({
    type: 'SET_TPP_SOURCE',
    tppSource,
});

export const setMemberProperties = properties => ({
    type: 'SET_TPP_REQUESTED_INFO',
    properties,
});

export const setCustomizationId = id => ({
    type: 'SET_CUSTOMIZATION_ID',
    id,
});

export const setUseBankRedirectionScreen = useBankRedirectionScreen => ({
    type: 'SET_USE_BANK_REDIRECTION_SCREEN',
    useBankRedirectionScreen,
});

export const setTppRedirectUrl = redirectUrl => ({
    type: 'SET_TPP_REDIRECT_URL',
    redirectUrl,
});

export const setTokenRequestOptions = (tokenRequestOptions = {}) => ({
    type: 'SET_TOKEN_REQUEST_OPTIONS',
    tokenRequestOptions,
});

export const setRedirectState = redirectState => ({
    type: 'SET_REDIRECT_STATE',
    redirectState,
});

export const setRedirectUrl = redirectUrl => ({
    type: 'SET_REDIRECT_URL',
    redirectUrl,
});

export const setDefaultBankId = defaultBankId => ({
    type: 'SET_DEFAULT_BANK_ID',
    defaultBankId,
});

export const setTokenTraceId = traceId => ({
    type: 'SET_TOKEN_TRACE_ID',
    traceId,
});

export const setDefaultAlias = defaultAlias => ({
    type: 'SET_DEFAULT_ALIAS',
    defaultAlias,
    redacted: ['defaultAlias'],
});

export const setDestinationCountry = destinationCountry => ({
    type: 'SET_DESTINATION_COUNTRY',
    destinationCountry,
});

export const setDescription = description => ({
    type: 'SET_DESCRIPTION',
    description,
});

export const setRequestCountries = countries => ({
    type: 'SET_REQUEST_COUNTRIES',
    countries,
});

export const setTokenExpiration = tokenExpiration => ({
    type: 'SET_TOKEN_EXPIRATION',
    tokenExpiration,
});

export const setLinkingRequestId = linkingRequestId => ({
    type: 'SET_LINKING_REQUEST_ID',
    linkingRequestId,
});

export const setAuthRequestId = authRequestId => ({
    type: 'SET_AUTH_REQUEST_ID',
    authRequestId,
});

export const setTokenId = tokenId => ({
    type: 'SET_TOKEN_ID',
    tokenId,
});

export const setOneStepSupport = oneStepSupport => ({
    type: 'SET_ONE_STEP_SUPPORT',
    oneStepSupport,
});

export const setTopBanks = banks => ({
    type: 'SET_TOP_BANKS',
    banks,
});

export const clearTopBanks = () => ({
    type: 'CLEAR_TOP_BANKS',
});

export const setResolvedPayload = resolvedPayload => ({
    type: 'SET_RESOLVED_PAYLOAD',
    resolvedPayload,
});

export const setBankRedirectUrl = bankRedirectUrl => ({
    type: 'SET_BANK_REDIRECT_URL',
    bankRedirectUrl,
});

export const clearBankRedirectUrl = () => ({
    type: 'SET_BANK_REDIRECT_URL',
    bankRedirectUrl: '',
});

export const setHideConsent = hideConsent => ({
    type: 'SET_HIDE_CONSENT',
    hideConsent,
});

export const setBrowserRedirectUrl = redirectUrl => ({
    type: 'SET_BROWSER_REDIRECT_URL',
    redirectUrl,
});

export const setDefaultCountry = defaultCountry => ({
    type: 'SET_DEFAULT_COUNTRY',
    defaultCountry,
});

export const setDevKey = devKey => ({
    type: 'SET_CUSTOM_DEV_KEY',
    devKey,
});

export const setFadeOutProp = fadeOutProp => ({
    type: 'SET_DISPLAY_FADE_OUT_ANIMATION',
    fadeOutProp,
});

export const setBankBranchList = (bankName, branchList) => ({
    type: 'SET_SUB_BRANCHES',
    bankName,
    branchList,
});

export const addBankBranchList = (bankName, branchList) => ({
    type: 'ADD_SUB_BRANCHES',
    bankName,
    branchList,
});

export const clearBankBranchList = bankName => ({
    type: 'CLEAR_SUB_BRANCHES',
    bankName,
});

export const setBankWithSubBranches = (bankName, status) => ({
    type: 'SET_BANKS_WITH_SUB_BRANCHES',
    bankName,
    status,
});

export const clearBankWithSubBranches = () => ({
    type: 'CLEAR_BANKS_WITH_SUB_BRANCHES',
});

// make sure that bankSelectionPreset is present in configurations
export const setTppConfigurations = configurations => {
    return {
        type: 'SET_TPP_CONFIGURATIONS',
        configurations,
    };
};

export const setTppOverallTranslations = translations => {
    return {
        type: 'SET_TPP_OVERALL_TRANSLATIONS',
        translations,
    };
};

export const setResellerProfile = profile => ({
    type: 'SET_RESELLER_PROFILE',
    profile,
});

// Clears Instructions source
export const clearInstructionsSource = () => ({
    type: 'CLEAR_INSTRUCTIONS_SOURCE',
});

// TODO: deprecated
export const setTokenPayload = tokenPayload => ({
    type: 'SET_TOKEN_PAYLOAD',
    tokenPayload,
});

export const setUseCredentialFlow = useCredentialFlow => ({
    type: 'SET_USE_CREDENTIAL_FLOW',
    useCredentialFlow,
});

export const setTppProfile = profile => ({
    type: 'SET_PROFILE',
    profile,
});

// thunks

export const validateContext = () => dispatch => {
    allowUserToGoBack();
    const isPopupOrIframe = isPopup() || isContextIframe();
    if (!isPopupOrIframe) {
        dispatch(
            throwError(
                new TokenException(
                    EXCEPTION_UNSUPPORTED_CONTEXT,
                    'Web-app must be launched in redirect or popup context',
                ),
            ),
        );
    }
};

export const setCustomDevKey = devKey => async dispatch => {
    if (devKey) {
        sessionStorageWrapper.set('devKey', devKey);
    } else {
        sessionStorageWrapper.remove('devKey');
    }
    dispatch(setDevKey(devKey));
};

export const setBank = bank => async dispatch => {
    dispatch(setSelectedBank(bank));
    dispatch(setCountry(bank?.selectedCountry));
};

export const setCountry = countryCode => async dispatch => {
    dispatch(setSelectedCountry(countryCode));
    dispatch(setTranslations());
};

export const actionIfTokenReqProcessed = (options = {}) => async dispatch => {
    let result;
    if (options?.fetchLatestTokenReq) {
        // Fetch latest token request response
        result = await dispatch(fetchTokenRequestInfo(true));
    } else {
        result = await dispatch(getTokenRequest(true));
    }
    const tokenRequest = result?.tokenRequest;
    const externalStatus = tokenRequest?.status;
    const statusReasonInformation = tokenRequest?.statusReasonInformation;
    const { internalStatus } = result;

    const transfer = await dispatch(getTransferDetails());
    if (transfer) {
        await dispatch(returnTransferData(transfer));
        return true;
    }
    // Throws exception if token request status is REJECTED because of GEO LOCATION RESTRICTION
    if (
        externalStatus === externalRequestStatus.REJECTED &&
        statusReasonInformation === GEO_LOCATION_RESTRICTION
    ) {
        dispatch(
            throwError(
                new TokenException(
                    EXCEPTION_TOKEN_REQUEST_RESTRICTED,
                    EXCEPTION_TOKEN_REQUEST_RESTRICTED.message,
                ),
            ),
        );
        return true;
    }

    // Throws exception if token request is EXPIRED or REJECTED
    if (EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL?.[externalStatus]) {
        throw new TokenException(
            EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL?.[externalStatus],
            EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL?.[externalStatus]?.message,
        );
    }

    if (internalStatus) {
        // Directly initiate credentials flow if token request internal status is among PENDING_CREDENTIALS_TOKEN_REQUEST_STATUS
        if (PENDING_CREDENTIALS_TOKEN_REQUEST_STATUS.includes(internalStatus)) {
            await dispatch(initiateBankAuthFlow());
            return true;
        }

        // Do not display consent page if PSU already accepted the consent
        if (internalStatus === requestStatus.PENDING_WEB_APP_CONSENT_ACCEPTED) {
            await dispatch(setHideConsent(true));
            return false;
        }

        if (EXCEPTION_TOKEN_REQUEST_STATUS_INTERNAL?.[internalStatus]) {
            if (
                !EXCEPTION_TOKEN_REQUEST_STATUS_INTERNAL?.[internalStatus]
                    ?.options?.ignoreIfSameSession ||
                !options?.sameSession
            ) {
                throw new TokenException(
                    EXCEPTION_TOKEN_REQUEST_STATUS_INTERNAL?.[internalStatus],
                    EXCEPTION_TOKEN_REQUEST_STATUS_INTERNAL?.[
                        internalStatus
                    ]?.message,
                );
            }
        }
    }

    return false;
};

// Fetches token request info, and saves properties to redux store
export const fetchTokenRequestInfo = bankCallback => async (
    dispatch,
    { tokenRequestService },
) => {
    let result;
    const tokenRequestId = tokenRequestService.getRequestId();
    try {
        result = await fetchTokenRequest(tokenRequestId);
        GLOBAL_TOKEN_REQUEST = result;
    } catch (e) {
        throw new TokenException(INTL_EXCEPTION_REQUEST_FAILED, e.message);
    }
    // Set TokenRequest Payload details
    await dispatch(setTokenRequestDetails(result));

    const { tokenRequest } = result;
    if (tokenRequest.requestPayload) {
        let isTokenRequestProcessed = false;
        // Perform appropriate action if token request is reused
        if (!bankCallback) {
            isTokenRequestProcessed = await dispatch(
                actionIfTokenReqProcessed(),
            );
        }

        if (isTokenRequestProcessed) {
            return null;
        }
    } else {
        // TODO: deprecated
        const { payload, options } = tokenRequest;
        if (!payload.to.alias) {
            throw new TokenException(
                EXCEPTION_NO_REDEEMER_ALIAS,
                'Redeemer alias must be set',
            );
        }
        dispatch(setRefId(payload.refId || Token.Util.generateNonce()));
        if (options && options.redirectUrl) {
            dispatch(setRedirectUrl(options.redirectUrl));
        }
        if (options && options.bankId) {
            dispatch(setDefaultBankId(options.bankId));
        }
        if (options && options.email) {
            dispatch(setDefaultAlias(options.email));
        }
        if (options && options.destinationCountry) {
            dispatch(setDestinationCountry(options.destinationCountry));
        }
    }
    await dispatch(storeRecentBanks());
    return result;
};

// This is called when the page redirects back from the bank, to the web-app. This function loads
// the state from localstorage, for aisp or pisp, gets the access token from the hash params
// and then finishes the appless checkout flow.
export const initiateBankCallbackFlow = () => async (
    dispatch,
    { tokenRequestService, sharedService },
) => {
    const requestId = getURLParameter('request-id');
    const authRequestId = getURLParameter('auth-request-id');
    const error = getURLParameter('message');

    if (requestId) {
        await dispatch(setTokenRequestId(requestId));
        await dispatch(getTokenRequest(true));
    }

    // Store webapp event of this moment
    await dispatch(
        storeWebappEvent({
            event: WEB_APP_EVENTS.WEB_APP_BANK_CALLBACK,
            payload: {
                requestId,
                error,
                authRequestId,
            },
        }),
    );

    if (error) {
        dispatch(handleError(decodeURIComponent(error)));
    } else {
        if (
            authRequestId &&
            authRequestId !== tokenRequestService.getAuthRequestId()
        ) {
            dispatch(
                terminateFlow({
                    error: 'INVALID_SESSION',
                    message: `Session for : ${
                        requestId || authRequestId
                    } is invalid`,
                }),
            );
        }
        if (requestId) {
            const b64Credentials = getURLParameter('credential-fields');
            if (b64Credentials) {
                // handle extra credentials post bank
                const credentials = JSON.parse(
                    base64url.decode(b64Credentials.replace('\\n|\\', '')),
                );
                const requiredCredentials = formatCredentials(
                    credentials?.fields,
                );
                dispatch(
                    captureCredentials(requiredCredentials, () => {
                        throw new TokenException(
                            EXCEPTION_BANK_FLOW_FAILURE,
                            'Error in capturing credentials post bank',
                        );
                    }),
                );
            } else {
                // checkout flow v2
                await dispatch(completeFlowV2(requestId));
            }
        } else if (getURLParameter('signature')) {
            // new guest flows
            const signature = JSON.parse(
                base64url.decode(getURLParameter('signature')),
            );
            if (sharedService.isAisp()) {
                // ignore for now, only PISP
            } else if (
                sharedService.isTransfer() ||
                sharedService.isStandingOrder() ||
                sharedService.isBulkTransfer()
            ) {
                await dispatch(prepareGuestTransfer(signature));
            }
        } else if (
            getHashValue('access-token') ||
            getHashValue('access_token')
        ) {
            // deprecated guest flows
            const accessToken =
                getHashValue('access-token') || getHashValue('access_token');
            if (sharedService.isAisp()) {
                await dispatch(applessAisp(accessToken));
            }
        } else if (getURLParameter('transfer-id')) {
            // one-step payment
            await dispatch(
                oneStepTransfer(
                    getURLParameter('transfer-id'),
                    getURLParameter('transfer-status'),
                ),
            );
        } else {
            // falls back on the error if all else doesn't exist
            dispatch(handleError(decodeURIComponent(error)));
        }
    }
};

export const handleError = error => async (
    dispatch,
    { tokenRequestService },
) => {
    const displayCustomErrorPage = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_ERROR,
    );
    const redirectUrl =
        (getURLParameter('redirect_url') &&
            decodeURIComponent(getURLParameter('redirect_url'))) ||
        (getURLParameter('redirect-url') &&
            decodeURIComponent(getURLParameter('redirect-url')));
    if (error === 'csrf_mismatch' && redirectUrl) {
        dispatch(setBrowserRedirectUrl(redirectUrl));
        dispatch(changeRoute(ROUTE_CSRF_MISMATCH));
    } else {
        const traceId =
            (getURLParameter('trace-id') &&
                decodeURIComponent(getURLParameter('trace-id'))) ||
            (getURLParameter('token-trace-id') &&
                decodeURIComponent(getURLParameter('token-trace-id')));
        const errorCode = decodeURIComponent(getURLParameter('error'));
        if (displayCustomErrorPage) {
            dispatch(setErrorSource(false));
            dispatch(throwError(new TokenException(errorCode, error)));
        } else {
            dispatch(
                terminateFlow({
                    message: decodeURIComponent(
                        error || EXCEPTION_BANK_FLOW_FAILURE,
                    ),
                    error: errorCode,
                    'trace-id': traceId,
                }),
            );
        }
    }
};

export const initiatePaymentInitStatus = () => async (
    dispatch,
    { tokenRequestService },
) => {
    const requestId = getURLParameter('request-id');
    await dispatch(setTokenRequestId(requestId));
    await dispatch(fetchTokenRequestData(true));
    const customErrorTextHtml = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_ERROR,
    );

    const error =
        getURLParameter('message') &&
        decodeURIComponent(getURLParameter('message'));
    if (error) {
        // Set token request result internal status
        await dispatch(
            setTokenRequestStatus(
                requestStatus.PROCESSED_FAILED_PAYMENT_ERROR_PAGE_DISPLAYED,
            ),
        );

        if (customErrorTextHtml) {
            dispatch(setErrorSource(true));
            // Throw custom exception for ex: HSBC only have EXCEPTION_TOKEN_ERROR
            // or EXCEPTION_BANK_ERROR
            dispatch(
                throwError(new TokenException(EXCEPTION_TOKEN_ERROR, error)),
            );
        } else {
            const errorCode = decodeURIComponent(getURLParameter('error'));
            dispatch(throwError(new TokenException(errorCode, error))); // Throw normal exception
        }
    } else {
        // Set token request result internal status
        await dispatch(
            setTokenRequestStatus(
                requestStatus.PROCESSED_COMPLETED_PAYMENT_CONFIRMATION_PAGE_DISPLAYED,
            ),
        );

        const { tppName, bankName, transferStatus } = JSON.parse(
            base64url.decode(getURLParameter('state')),
        );
        const result = await Token.getTokenRequestResult(requestId);
        const redirectState = tokenRequestService.getRequestRedirectState();

        dispatch(
            setTppReturnData({
                popupStatus: POPUP_SUCCESS,
                payload: {
                    tokenId: result.tokenId,
                    signature: JSON.stringify(result.signature),
                    state: redirectState,
                    'transfer-id': result.transferId,
                    'transfer-status': transferStatus,
                    'tpp-name': tppName,
                    'bank-name': bankName,
                    'request-id': requestId,
                },
            }),
        );
        dispatch(setStep(PISP_PAYMENT_INIT_STATUS));
        dispatch(changeRoute(ROUTE_PISP));
    }
};

export const setTokenRequestDetails = tokenRequestResponse => async dispatch => {
    if (!tokenRequestResponse) return;
    const {
        tokenRequest,
        hideConsent,
        tppConfigurations,
        bankSelectionPreset,
    } = tokenRequestResponse;
    const { requestPayload, requestOptions } = tokenRequest;

    if (bankSelectionPreset === BANK_SELECTION_PRESETS.DISABLE_ALL) {
        throw new TokenException(
            EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE,
            EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE.message,
        );
    }
    dispatch(setHideConsent(hideConsent));
    const subTppRefId = requestPayload?.actingAs?.refId;
    const payloadMember = requestPayload?.to;
    const realmId = payloadMember.alias?.realmId;
    let memberProperties = {
        realmId,
        memberId: payloadMember.id,
    };
    if (subTppRefId) {
        memberProperties = {
            ...memberProperties,
            subTppRefId: subTppRefId,
        };
    }
    dispatch(setMemberProperties(memberProperties));

    const guest = await dispatch(getTempMember());
    if (realmId) {
        const resellerProfile = await getMemberProfileById(realmId, guest);
        dispatch(setResellerProfile(resellerProfile));
    }
    const tppProfile = await getMemberProfileById(payloadMember.id, guest);
    dispatch(setTppProfile(tppProfile));
    const overallTppConfigs = {
        ...tppConfigurations,
        bankSelectionPreset,
    };
    dispatch(
        setTppConfigurations(
            getOverallTppConfigurations(
                memberProperties,
                updateCustomization(overallTppConfigs),
            ),
        ),
    );
    if (requestPayload) {
        const {
            customizationId,
            refId,
            callbackState,
            redirectUrl,
            destinationCountry,
            description,
            countries,
            tokenExpiration,
            transferBody,
            useCredentialFlow = false,
        } = requestPayload;
        const { bankId, from } = requestOptions;

        dispatch(setUseCredentialFlow(useCredentialFlow));
        customizationId && dispatch(setCustomizationId(customizationId));
        dispatch(setTokenRequestOptions(requestOptions));
        refId && dispatch(setRefId(refId));
        callbackState && dispatch(setRedirectState(callbackState));
        redirectUrl && dispatch(setRedirectUrl(redirectUrl));
        destinationCountry &&
            dispatch(setDestinationCountry(destinationCountry));
        description && dispatch(setDescription(description));
        countries &&
            countries.length !== 0 &&
            dispatch(setRequestCountries(countries));
        tokenExpiration && dispatch(setTokenExpiration(tokenExpiration));
        bankId && dispatch(setDefaultBankId(bankId));
        transferBody?.instructions?.source?.bankId &&
            dispatch(setDefaultBankId(transferBody.instructions.source.bankId));
        from?.alias && dispatch(setDefaultAlias(from.alias));
    }

    // Set Token Type, for example: ACCESS, TRANSFER, STANDING_ORDER, BULK_TRANSFER ... etc
    if (requestPayload.accessBody) {
        dispatch(
            setAispTokenType({
                tppAlias: requestPayload.to.alias,
                resources:
                    requestPayload.accessBody.accountResourceList?.resources ||
                    requestPayload.accessBody.resourceTypeList?.resources ||
                    requestPayload.accessBody.type,
                actingAs: requestPayload.actingAs,
                memberId: requestPayload.to.id,
                source: requestPayload.accessBody.resourceTypeList?.source,
            }),
        );
    } else if (requestPayload.transferBody) {
        const destinations =
            requestPayload.transferBody.destinations ||
            requestPayload.transferBody.instructions?.transferDestinations;
        const setTransferDestinationsUrl =
            requestPayload.transferBody.setTransferDestinationsUrl || '';
        dispatch(
            setTransferTokenType({
                tppAlias: requestPayload.to.alias,
                amount: requestPayload.transferBody.lifetimeAmount,
                currency: requestPayload.transferBody.currency,
                destinations,
                instructions: requestPayload.transferBody.instructions,
                actingAs: requestPayload.actingAs,
                executionDate: requestPayload.transferBody.executionDate,
                setTransferDestinationsUrl,
                remittanceReference:
                    requestPayload.transferBody.remittanceReference,
                confirmFunds: requestPayload.transferBody.confirmFunds,
                memberId: requestPayload.to.id,
                returnRefundAccount:
                    requestPayload.transferBody.returnRefundAccount,
            }),
        );
    } else if (requestPayload.standingOrderBody) {
        const destinations =
            requestPayload.standingOrderBody.instructions.transferDestinations;
        dispatch(
            setStandingOrderTokenType({
                tppAlias: requestPayload.to.alias,
                amount: requestPayload.standingOrderBody.amount,
                currency: requestPayload.standingOrderBody.currency,
                destinations,
                instructions: requestPayload.standingOrderBody.instructions,
                actingAs: requestPayload.actingAs,
                frequency: requestPayload.standingOrderBody.frequency,
                startDate: requestPayload.standingOrderBody.startDate,
                endDate: requestPayload.standingOrderBody.endDate,
                remittanceReference:
                    requestPayload.standingOrderBody.remittanceReference,
                memberId: requestPayload.to.id,
                returnRefundAccount:
                    requestPayload.standingOrderBody.returnRefundAccount,
            }),
        );
    } else if (requestPayload.bulkTransferBody) {
        const transfers = requestPayload.bulkTransferBody.transfers;
        dispatch(
            setBulkTransferTokenType({
                tppAlias: requestPayload.to.alias,
                amount: requestPayload.bulkTransferBody.totalAmount,
                transfers,
                source: requestPayload.bulkTransferBody.source,
                memberId: requestPayload.to.id,
            }),
        );
    }
};

// Initiates the redirect flow. This is called as soon as the TPP redirects to the web-app
export const initiateRedirectFlow = () => async (
    dispatch,
    { tokenRequestService },
) => {
    if (sessionStorageWrapper.get('blockUserToGoBack') === true) {
        return null;
    }
    allowUserToGoBack();
    dispatch(setRedirect());
    const tokenRequestId = decodeURIComponent(
        window.location.pathname.split('/')[3],
    );

    dispatch(setTokenRequestId(tokenRequestId));
    dispatch(setTppSource(getURLParameter('source')));
    dispatch(setCustomDevKey(getURLParameter('dk')));
    const defaultCountry = await getCountry();
    dispatch(setDefaultCountry(defaultCountry));
    dispatch(
        setUseBankRedirectionScreen(
            getURLParameter('use-bank-redirection-screen'),
        ),
    );

    const result = await dispatch(fetchTokenRequestInfo());
    const tokenRequest = result?.tokenRequest;

    // Set token request result internal status
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_INVOKED, {
            Context: getWebappContext(),
        }),
    );

    if (tokenRequestService.hasCustomColors()) {
        await addCustomCss(tokenRequestId);
    } else {
        removeCustomCss();
    }
    validateBrowserCompatibility();
    const requestPayload = tokenRequest?.requestPayload;
    await dispatch(fetchCountries(requestPayload?.to?.id));

    if (requestPayload) {
        if (requestPayload.accessBody) {
            await dispatch(
                initiateAispFlow(
                    requestPayload.accessBody.accountResourceList?.resources ||
                        requestPayload.accessBody.resourceTypeList?.resources ||
                        requestPayload.accessBody.type,
                ),
            );
        } else if (requestPayload.transferBody) {
            const destinations =
                requestPayload.transferBody.destinations ||
                requestPayload.transferBody.instructions?.transferDestinations;
            const setTransferDestinationsUrl =
                requestPayload.transferBody.setTransferDestinationsUrl || '';
            await dispatch(
                initiatePispFlow({
                    destinations,
                    setTransferDestinationsUrl,
                }),
            );
        } else if (requestPayload.standingOrderBody) {
            const destinations =
                requestPayload.standingOrderBody.instructions
                    .transferDestinations;
            await dispatch(
                initiatePispFlow({
                    destinations,
                }),
            );
        } else if (requestPayload.bulkTransferBody) {
            await dispatch(initiatePispFlow({}));
        }
    }
};

// Initiates the popup flow
export const initiatePopupFlow = ({ tokenRequestUrl, popupId }) => async () => {
    if (!tokenRequestUrl) return;
    // Saves the popup id to localStorage, so we can retrieve it after we redirect to/from the bank
    if (popupId && localStorageWrapper.get()) {
        localStorageWrapper.set('popup_id', popupId);
    } else {
        localStorageWrapper.remove('popup_id');
    }
    if (typeof tokenRequestUrl !== 'string') {
        throw new TokenException(EXCEPTION_INVALID_TOKEN_REQUEST_URL);
    }

    if (isContextIframe()) {
        window.document.getElementById(
            TOKEN_WEB_APP_IFRAME_NAME,
        ).src = tokenRequestUrl;
    }

    // Token Request API
    window.location.replace(tokenRequestUrl);
};

// This gets called when we want to redirect to the bank with the checkout flow v2.
export const redirectToBankV2 = () => async (
    dispatch,
    { sharedService, tokenRequestService },
) => {
    dispatch(showBankRedirectionScreen());
    const bank = sharedService.getSelectedBank();
    const requestId = await tokenRequestService.getRequestId();
    const bankAuthUrl = await Token.getDirectBankAuthUrl(bank.id, requestId);
    let redirectUrl;
    const customRedirectURL = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_WEB_APP_URL,
    );
    if (customRedirectURL) {
        redirectUrl = `${bankAuthUrl}&callback-url=${encodeURIComponent(
            `${customRedirectURL}/app/auth/callback`,
        )}`;
    } else {
        redirectUrl = `${bankAuthUrl}&callback-url=${encodeURIComponent(
            `${webAppUrl}/app/auth/callback`,
        )}`;
    }
    await dispatch(setupBankRedirectUrl(redirectUrl));
};

// Throws appropriate error and terminates the flow in case of invalid bank id
export const handleInvalidBankId = bankOptions => async dispatch => {
    const { country, ...restProps } = bankOptions;
    const options = country
        ? { ...restProps, countries: [country] }
        : { ...restProps };
    let excMessage = `Bank ID : ${options.ids?.[0]} is invalid`;

    if (options.ids?.length <= 0) {
        excMessage = `Merchant ${options.merchantName} has restricted the permission to access ${options.defaultBankId} Bank`;
        await dispatch(
            terminateFlow({
                error: 'access_denied',
                message: excMessage,
            }),
        );
        throw new TokenException(EXCEPTION_ACCESS_DENIED, excMessage);
    }
    const paymentRail = options.supportedPaymentNetworks;
    options.supportedPaymentNetworks = [];
    const resp = await Token.getBanks(options);
    const banks = resp?.banks;

    if (banks && banks.length > 0) {
        excMessage = `Selected payment scheme: ${paymentRail[0]?.toUpperCase()} is not supported by ${
            options.ids[0]
        } Bank.`;
        await dispatch(
            terminateFlow({
                error: 'access_denied',
                message: excMessage,
            }),
        );
        throw new TokenException(EXCEPTION_ACCESS_DENIED, excMessage);
    }
    delete options.memberId;
    const response = await Token.getBanks(options);
    const rawBanksData = response?.banks;

    if (rawBanksData && rawBanksData.length > 0) {
        excMessage = `Merchant ${options.merchantName} is not set up to connect to ${options.ids[0]} Bank`;
        await dispatch(
            terminateFlow({
                error: 'access_denied',
                message: excMessage,
            }),
        );
        throw new TokenException(EXCEPTION_ACCESS_DENIED, excMessage);
    }
    await dispatch(
        terminateFlow({ error: 'access_denied', message: excMessage }),
    );
    throw new TokenException(EXCEPTION_ACCESS_DENIED, excMessage);
};

export const fetchTopBanks = country => async dispatch => {
    // Get first five banks as top banks sorted by Rank
    const PAGE = 1;
    const PER_PAGE = 5;

    const banksResponse = await dispatch(
        yieldBanks({
            country: country.code,
            page: PAGE,
            perPage: PER_PAGE,
        }),
    );
    const { data: banks } = formatDataList(banksResponse, {
        fetchDataKey: 'banks',
        fetchPagingKey: 'paging',
        idKey: 'id',
        titleKey: 'name',
        dataKey: 'identifier',
    });

    findBankLogoData(banks);
    dispatch(setTopBanks(banks));
};

export const setTranslations = () => async (
    dispatch,
    { sharedService, tokenRequestService },
) => {
    const regionalConfigurations = tokenRequestService.getOverallRegionalConfigurations();
    const selectedCountry = sharedService.getSelectedCountry();

    let selectedRegion;
    for (const region in REGIONS) {
        if (REGIONS[region].includes(selectedCountry)) {
            selectedRegion = region;
            break;
        }
    }
    const translations = {
        ...regionalConfigurations?.default?.translations,
        ...regionalConfigurations?.[selectedRegion]?.translations,
    };
    if (keyExists(translations)) {
        dispatch(setTppOverallTranslations(translations));
    }
};

// Filters the banks according to it's payment type
export const getFilteredBanks = (banks, bankOptions) => async (
    dispatch,
    { tokenRequestService },
) => {
    const options = Object.assign({}, bankOptions);
    const supportedCountriesWithBanksByTpp = tokenRequestService.getTppFeature(
        featureConfig.SUPPORTED_COUNTRIES_WITH_BANK_IDS,
    );
    const hideDefaultCountriesAndBanks = tokenRequestService.getTppFeature(
        featureConfig.HIDE_DEFAULT_COUNTRIES_AND_BANKS,
    );
    const tppMember = tokenRequestService.getTppProfile();
    let filteredBanks = banks?.filter(b => {
        b.selectedCountry = options?.country && options.country?.toUpperCase();
        return b;
    });

    // Verifying if the default bank id passed is configured by the merchant
    if (options.isDefaultBank) {
        options.country = filteredBanks?.[0]?.country;
        options.defaultBankId = options.ids[0];
        options.ids = await dispatch(getFilteredBankIds(options));
    }
    if (
        !banks ||
        (hideDefaultCountriesAndBanks && !supportedCountriesWithBanksByTpp) ||
        (options.isDefaultBank && options.ids?.length <= 0)
    ) {
        filteredBanks = [];
    }
    if (
        (!filteredBanks || filteredBanks?.length <= 0) &&
        options.isDefaultBank
    ) {
        await dispatch(
            handleInvalidBankId({
                ...options,
                merchantName: tppMember.tppName,
            }),
        );
    }
    return filteredBanks;
};

// Returns the bankIds that are configured for a country by the merchant
export const getFilteredBankIds = bankOptions => async (
    dispatch,
    { tokenRequestService },
) => {
    const options = Object.assign({}, bankOptions);
    if (options.country) {
        const supportedCountriesWithBanksByTpp = tokenRequestService.getTppFeature(
            featureConfig.SUPPORTED_COUNTRIES_WITH_BANK_IDS,
        );
        const allowedBankIds =
            supportedCountriesWithBanksByTpp?.[options.country.toUpperCase()]
                ?.bankIds;
        if (allowedBankIds && allowedBankIds.length > 0) {
            options.ids = options.ids
                ? options.ids.filter(value => allowedBankIds.includes(value))
                : allowedBankIds;
        }
        if (options.ids && options.ids.length === 0) {
            options.ids = [];
        }
    }
    return options.ids;
};

export const getBankFeaturePaymentType = () => async (
    dispatch,
    { sharedService, tokenService },
) => {
    const tokenType = sharedService.getTokenType();
    if (tokenType === TOKEN_TYPE_VRP) {
        return paymentTypeOptions.SUPPORTS_VRP;
    } else if (tokenType === TOKEN_TYPE_ACCESS) {
        return paymentTypeOptions.SUPPORTS_AIS;
    } else if (tokenType === TOKEN_TYPE_TRANSFER) {
        if (tokenService.getExecutionDate()) {
            return paymentTypeOptions.SUPPORTS_SCHEDULED_PAYMENTS;
        } else {
            return paymentTypeOptions.SUPPORTS_SEND_PAYMENT;
        }
    } else if (tokenType === TOKEN_TYPE_STANDING_ORDER) {
        return paymentTypeOptions.SUPPORTS_STANDING_ORDER;
    } else if (tokenType === TOKEN_TYPE_BULK_TRANSFER) {
        return paymentTypeOptions.SUPPORTS_BULK_TRANSFER;
    } else return '';
};

const getBanksByChunks = async options => {
    const chunkSize = BANK_CHUNK_SIZE;
    const totalIds = options.ids?.length;

    // Split ids into chunks
    const chunks = [];
    for (let i = 0; i < totalIds; i += chunkSize) {
        chunks.push(options.ids.slice(i, i + chunkSize));
    }

    if (options.perPage < BANK_PAGE_SIZE) {
        const optionsWithIds = {
            ...options,
            ids: chunks[0],
            perPage: options.perPage,
        };
        const { banks, ...rest } = await Token.getBanks(optionsWithIds);
        return { banks, ...rest };
    }

    // Fetch banks for each chunk in parallel
    const bankPromises = chunks.map(async chunk => {
        const optionsWithIds = { ...options, ids: chunk, perPage: chunkSize };
        const { banks } = await Token.getBanks(optionsWithIds);
        return banks;
    });

    // Wait for all promises to resolve and combine the results
    const bankChunks = await Promise.all(bankPromises);
    const banks = bankChunks.flat();

    return {
        banks,
        paging: {
            page: 1,
            perPage: banks.length,
            pageCount: 1,
        },
    };
};

// Get the banks' details
export const yieldBanks = (options = {}) => async (
    dispatch,
    { sharedService, tokenRequestService, tokenService },
) => {
    const SORTING = 'rank';
    const tokenType = sharedService.getTokenType();
    options.supportedPaymentNetworks = tokenService.getTransferTypeDestinations(
        tokenType,
    );
    const tppMember = tokenRequestService.getTppProfile();
    options.perPage = options.perPage ?? BANK_PAGE_SIZE;
    options.sort = options.sort ?? SORTING;
    options.memberId = tppMember.memberId;
    if (tokenRequestService.getDestinationCountry()) {
        options.destinationCountry = tokenRequestService.getDestinationCountry();
    }
    // getting the bankIds that are configured by the merchant for the selected country (if any)
    const tppConfiguredBankIds = await dispatch(getFilteredBankIds(options));
    if (tppConfiguredBankIds) {
        options.ids = tppConfiguredBankIds;
    }
    const paymentTypeOption = await dispatch(getBankFeaturePaymentType());
    options.bankFeatures = paymentTypeOption && { [paymentTypeOption]: true };
    const { country, ...restProps } = options;
    const { ids } = restProps;
    const fetchOptions = country
        ? { ...restProps, countries: [country] }
        : { ...options };
    const { banks, ...rest } =
        ids?.length > BANK_CHUNK_SIZE * 2
            ? await getBanksByChunks(fetchOptions)
            : await Token.getBanks(fetchOptions);
    const filteredBanks = await dispatch(getFilteredBanks(banks, options));
    return { banks: filteredBanks, ...rest };
};

export const isBankLive = bankId => async (dispatch, { sharedService }) => {
    try {
        const productType = sharedService.isAisp() ? 'AIS' : 'SIP';
        const bankStatus = await getBankStatus(bankId, productType);
        if (!bankStatus) {
            return true;
        }
        return !BANK_OUTAGE.includes(bankStatus);
    } catch (e) {
        // ignore the bank status
        return true;
    }
};

export const fetchBanks = (options = {}, pagingCallback) => async dispatch => {
    const banksResponse = await dispatch(yieldBanks(options));
    const { data: banks, paging } = formatDataList(banksResponse, {
        fetchDataKey: 'banks',
        fetchPagingKey: 'paging',
        idKey: 'id',
        titleKey: 'name',
        dataKey: 'identifier',
    });

    if (!(options.page && options.page > 1 && paging.page !== 1)) {
        await dispatch(clearBankWithSubBranches());
    }

    const filteredBanks = dispatch(filterBanksHavingBranches(banks));

    findBankLogoData(filteredBanks);

    if (options.page && options.page > 1 && paging.page !== 1) {
        dispatch(addBanks(filteredBanks || []));
    } else {
        dispatch(setBanks(filteredBanks || []));
    }
    paging && pagingCallback && pagingCallback(paging);
};

export const fetchBankBranches = (bankName, country) => async dispatch => {
    const options = {
        country: country,
    };
    let { banks, paging } = await dispatch(yieldBanks({ country }));
    while (paging.page !== paging.pageCount) {
        options.page = paging.page + 1;
        const data = await dispatch(yieldBanks({ country }));
        banks = banks.concat(data.banks);
        paging = data.paging;
    }

    if (banks) {
        dispatch(
            setBankBranchList(bankName, formatBankBranchData(banks, bankName)),
        );
    }
};

export const fetchCountries = tppMemberId => async dispatch => {
    const countries = await Token.getCountries({ memberId: tppMemberId });
    if (!countries || countries.length === 0) {
        throw new TokenException(
            INTL_EXCEPTION_REQUEST_FAILED,
            `no countries are configured for member id: ${tppMemberId}`,
        );
    }
    dispatch(setCountries(countries));
};

export const validateAlias = (value, type) => async (
    dispatch,
    { sharedService },
) => {
    dispatch(clearErrorMessage());
    const bank = sharedService.getSelectedBank();
    const { realm, customAliasLabel } = sharedService.getSelectedBankInfo();
    let alias = {
        type: customAliasLabel ? 'CUSTOM' : type,
        value,
        realm: realm[0],
    };
    let keys;
    try {
        ({ keys } = await Token.provisionDeviceLow(
            alias,
            Token.BrowserCryptoEngine,
        ));
    } catch (e) {
        if (e.message.indexOf('Invalid alias') !== -1) {
            // Backward compatible with members with realm id
            try {
                if (realm[0] === '' || realm[0] === 'token') {
                    throw e;
                }
                const bankAlias = { type: 'BANK', value: realm[0] };
                const realmMember = await Token.resolveAlias(bankAlias);
                if (!realmMember) {
                    throw e;
                }
                alias = {
                    type: customAliasLabel ? 'CUSTOM' : type,
                    value,
                    realm: realm[0],
                    realmId: realmMember.id,
                };
                ({ keys } = await Token.provisionDeviceLow(
                    alias,
                    Token.BrowserCryptoEngine,
                ));
            } catch (e) {
                if (e.message.indexOf('Invalid alias') !== -1) {
                    if (sharedService.isAisp())
                        dispatch(setStep(AISP_ENTER_ALIAS));
                    else if (sharedService.isTransfer())
                        dispatch(setStep(PISP_ENTER_ALIAS));
                    return dispatch(
                        setErrorMessage('common.aliasInput.invalidAlias'),
                    );
                }
                throw e;
            }
        }
    }
    saveBankToLocalStorage(bank);
    await dispatch(completeFlowFromApp(alias, keys));
};

export const initiateGuestFlow = () => async (dispatch, { sharedService }) => {
    dispatch(showBankRedirectionScreen());
    if (
        sharedService.isTransfer() ||
        sharedService.isStandingOrder() ||
        sharedService.isBulkTransfer()
    ) {
        await dispatch(prepareGuestTransfer());
    } else if (sharedService.isAisp()) {
        await dispatch(redirectToBank());
    }
};

export const noApp = () => async (dispatch, { sharedService }) => {
    dispatch(setStep(LOADING_STEP));
    await cancelPollForTokenRequestResult();
    if (sharedService.isAisp()) {
        await dispatch(aispNoApp());
    } else if (sharedService.isTransfer()) {
        await dispatch(pispNoApp());
    }
};

export const notYou = () => async (dispatch, { sharedService }) => {
    await cancelPollForTokenRequestResult();
    dispatch(clearMembers());
    await logoutFromLocalStorage();
    if (sharedService.isAisp()) {
        dispatch(backOrGoTo(AISP_ENTER_ALIAS));
    } else if (sharedService.isTransfer()) {
        dispatch(backOrGoTo(PISP_ENTER_ALIAS));
    }
};

export const cancelApproval = () => (dispatch, { tokenRequestService }) => {
    const hideConsent = tokenRequestService.getHideConsent();
    const hasDefaultBankId = tokenRequestService.getDefaultBankId();
    const hasDefaultAlias = tokenRequestService.getDefaultAlias();
    if (hideConsent && hasDefaultBankId && hasDefaultAlias) {
        return dispatch(
            terminateFlow({
                error: 'REQUEST_CANCELLED',
                message: decodeURIComponent(EXCEPTION_REQUEST_CANCELLED),
            }),
        );
    }
    dispatch(backStep());
};

export const cleanUp = () => async dispatch => {
    dispatch(changeRoute(ROUTE_LOADING));

    // Clean appless state
    localStorageWrapper.remove('aisp');
    localStorageWrapper.remove('pisp');
};

export const completeFlowFromApp = (alias, keys = []) => async (
    dispatch,
    { sharedService, tokenService, tokenRequestService },
) => {
    dispatch(setStep(APP_APPROVAL));
    try {
        if (tokenRequestService.getRequestPayload()) {
            // TODO: deprecated
            const bankId = sharedService.getSelectedBankId();
            const requestId = tokenRequestService.getRequestId();
            const redirectState = tokenRequestService.getRequestRedirectState();
            const tokenPayload = tokenRequestService.getRequestPayload();
            let receiptContact = {};
            tokenPayload.from = await Token.resolveAlias(alias);
            if (sharedService.isTransfer()) {
                const pispAlias = tokenRequestService.getTppAlias();
                const receiptEmail = tokenService.getReceiptEmail();
                tokenPayload.transfer.redeemer = await Token.resolveAlias(
                    pispAlias,
                );
                receiptContact =
                    (receiptEmail && { type: 'EMAIL', value: receiptEmail }) ||
                    receiptContact;
            }
            const { notificationId } = await Token.notifyEndorseAndAddKey(
                tokenPayload,
                keys,
                getUserDeviceMetadata(),
                requestId,
                bankId,
                encodeURIComponent(redirectState),
                receiptContact,
            );
            const { tokenId, signature } = await pollForTokenRequestResult(
                requestId,
                notificationId,
            );
            tokenId &&
                (await dispatch(
                    completeFlow(requestId, tokenId, null, signature),
                ));
        } else {
            const requestId = tokenRequestService.getRequestId();
            let receiptContact = {};
            if (
                sharedService.isTransfer() ||
                sharedService.isStandingOrder() ||
                sharedService.isBulkTransfer()
            ) {
                const receiptEmail = tokenService.getReceiptEmail();
                receiptContact =
                    (receiptEmail && { type: 'EMAIL', value: receiptEmail }) ||
                    receiptContact;
            }
            const requestOptions = tokenRequestService.getRequestOptions();
            requestOptions.from = await Token.resolveAlias(alias);
            requestOptions.bankId = sharedService.getSelectedBankId();
            await Token.updateTokenRequest(requestId, requestOptions);
            const { notificationId } = await Token.notifyCreateAndEndorseToken(
                requestId,
                keys,
                getUserDeviceMetadata(),
                receiptContact,
            );
            const { tokenId, signature } = await pollForTokenRequestResult(
                requestId,
                notificationId,
            );
            tokenId &&
                (await dispatch(
                    completeFlow(requestId, tokenId, null, signature),
                ));
        }
    } catch (e) {
        if (e.name === INTL_EXCEPTION_APP_NOTIFICATION_TIMEOUT.name) {
            dispatch(
                setErrorMessage('common.exception.appNotificationTimeout'),
            );
        } else {
            console.error(e); // eslint-disable-line
            dispatch(setErrorMessage('common.appNotifier.errorRetry'));
        }
    }
};

// Complete the checkout flow V2
export const completeFlowV2 = tokenRequestId => async (
    dispatch,
    { tokenRequestService },
) => {
    const tokenReqResponse = await dispatch(getTokenRequest(true));
    const tokenRequest = tokenReqResponse?.tokenRequest;
    let statusResponse;
    const validateResponse = response => {
        const { statusReasonInformation, tokenId, transferId, status } =
            response || {};
        statusResponse =
            statusReasonInformation || 'No token or transfer id found';
        return (
            tokenId || transferId || status === externalRequestStatus.REJECTED
        );
    };
    const result = await pollUntil({
        fn: async () =>
            await fetchTokenRequestResultWithStatus(
                tokenRequestId,
                tokenRequest?.requestPayload?.to?.id,
            ),
        validate: validateResponse,
        interval: tokenRequestResultInterval, // Polling time interval
        timeout: tokenRequestResultTimeout, // Polling timeout
        cancelledCb: () => {
            dispatch(
                throwError(
                    new TokenException(
                        INTL_EXCEPTION_REQUEST_FAILED,
                        statusResponse,
                    ),
                ),
            );
        },
    });
    const transferId = result.transferId;
    const transferStatus = result.transferStatus;
    const redirectState = tokenRequestService.getRequestRedirectState();
    removeCancelTokenOnClose();
    dispatch(
        returnToTpp(POPUP_SUCCESS, {
            'request-id': tokenRequestId,
            tokenId: result.tokenId,
            signature: JSON.stringify(result.signature),
            state: redirectState,
            ...(transferId && { 'transfer-id': transferId }),
            ...(transferId &&
                transferStatus && { 'transfer-status': transferStatus }),
        }),
    );
};

export const completeFlow = (
    requestId,
    tokenId,
    member,
    signature,
    payload,
) => async (dispatch, { tokenRequestService }) => {
    const redirectState = tokenRequestService.getRequestRedirectState();
    if (!signature) {
        signature = await member.signTokenRequestState(
            tokenRequestService.getRequestId(),
            tokenId,
            tokenRequestService.getRequestPayload()
                ? encodeURIComponent(redirectState) // TODO: deprecated
                : redirectState,
        );
    }
    removeCancelTokenOnClose();
    dispatch(
        returnToTpp(POPUP_SUCCESS, {
            'request-id': requestId,
            tokenId,
            signature: JSON.stringify(signature),
            state: redirectState,
            ...payload,
        }),
    );
};

export const declineTerms = () => async dispatch => {
    addCheckForTokenReqRejection();
    dispatch(changeRoute(ROUTE_LOADING));
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_CONSENT_DECLINED),
    );
    dispatch(
        terminateFlow({ error: 'access_denied', message: 'Terms declined' }),
    );
};

export const terminateFlow = message => (
    dispatch,
    { tokenRequestService },
) => {
    const redirectState = tokenRequestService.getRequestRedirectState();
    const requestId = tokenRequestService.getRequestId();
    dispatch(terminate({ name: 'TERMINATE_FLOW', message: message }));
    dispatch(
        returnToTpp(POPUP_FAILURE, {
            ...message,
            state: redirectState,
            'request-id': requestId,
        }),
    );
};

export const goToTpp = () => async (dispatch, { tokenRequestService }) => {
    const { payload, popupStatus } = tokenRequestService.getTppReturnData();
    dispatch(cleanUp());

    // Send webapp event
    if (popupStatus === POPUP_FAILURE) {
        await dispatch(
            storeWebappEvent({
                event: WEB_APP_EVENTS.WEB_APP_RETURN_TO_TPP_FAILURE,
                payload,
            }),
        );
    } else if (popupStatus === POPUP_SUCCESS) {
        await dispatch(
            storeWebappEvent({
                event: WEB_APP_EVENTS.WEB_APP_RETURN_TO_TPP_SUCCESS,
                payload,
            }),
        );
    }

    if (isContextIframe()) {
        TokenCommunicator.dispatchMessage(popupStatus, payload);
    } else if (isPopup()) {
        TokenCommunicator.dispatchMessage(popupStatus, payload);
        window.close();
    } else {
        // redirect
        const tppRedirecturl = tokenRequestService.getRequestRedirectUrl();
        const redirectUrl = parseUrl(tppRedirecturl);
        Object.assign(redirectUrl.query, payload);
        dispatch(
            setTppRedirectUrl(
                `${redirectUrl.url}?${stringify(redirectUrl.query)}`,
            ),
        );

        if (
            tokenRequestService.isSourceWebapp() ||
            tokenRequestService.isIOSType2Flow()
        ) {
            dispatch(redirectToTpp());
        } else {
            dispatch(changeRoute(ROUTE_REDIRECT_TO_TPP));
        }
    }
};

// Fetches transfer details if present
export const getTransferDetails = options => async (
    dispatch,
    { tokenRequestService },
) => {
    let tokenReqResponse;
    if (options?.fetchLatestTokenReq) {
        // Fetch latest token request response
        tokenReqResponse = await dispatch(fetchTokenRequestInfo(true));
    } else {
        tokenReqResponse = await dispatch(getTokenRequest(true));
    }

    const tokenRequest = tokenReqResponse?.tokenRequest;
    const tokenRequestId = tokenRequest?.id;
    if (!tokenRequestId) return;
    try {
        const result = await fetchTokenRequestResultWithStatus(
            tokenRequestId,
            tokenRequest?.requestPayload?.to?.id,
        );
        const redirectState = tokenRequestService.getRequestRedirectState();
        if (result.signature && Object.keys(result.signature)?.length > 0) {
            return {
                'request-id': tokenRequestId,
                tokenId: result.tokenId,
                signature: JSON.stringify(result.signature),
                state: redirectState,
                'transfer-id': result.transferId,
                'transfer-status': result?.transferStatus,
            };
        }
    } catch (e) {
        // Do nothing
    }
    return null;
};

// Return required parameters back to the tpp.
export const returnTransferData = data => async (
    dispatch,
    { tokenRequestService, sharedService },
) => {
    const tokenReqResponse = await dispatch(getTokenRequest(true));
    const tokenRequest = tokenReqResponse?.tokenRequest;
    const tokenRequestId = tokenRequest?.id;
    if (!tokenRequestId || !data.signature) return;

    const requestOptions = tokenRequest?.requestOptions;
    const bankId = requestOptions?.bankId;
    const selectedBankId = sharedService.getSelectedBankId();
    const doesTppSupportPsr44Flow = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_PSR44_FLOW,
    );

    let doesBankSupportOneStep = false;

    if (bankId !== selectedBankId && bankId) {
        const { banks } = await dispatch(yieldBanks({ ids: [bankId] }));
        const bank = banks?.[0];
        doesBankSupportOneStep = bank?.requiresOneStepPayment;
    } else if (selectedBankId) {
        doesBankSupportOneStep = sharedService.getSelectedBank()
            ?.requiresOneStepPayment;
    }

    let transferStatus;
    let transferId;
    if (
        data['transfer-id'] &&
        (doesBankSupportOneStep || doesTppSupportPsr44Flow)
    ) {
        transferId = data['transfer-id'];
        transferStatus = data?.['transfer-status'];
    }
    dispatch(
        setTppReturnData({
            popupStatus: POPUP_SUCCESS,
            payload: {
                'request-id': tokenRequestId,
                tokenId: data.tokenId,
                signature: data.signature,
                ...(data.state && { state: data.state }),
                ...(transferId && {
                    'transfer-id': transferId,
                }),
                ...(transferStatus && {
                    'transfer-status': transferStatus,
                }),
            },
        }),
    );
    await dispatch(goToTpp());
};

export const returnToTpp = (popupStatus, payload) => async (
    dispatch,
    { tokenRequestService, sharedService },
) => {
    const isAispFlow = sharedService.isAisp();
    const transferId = payload['transfer-id'];
    const transferStatus = payload['transfer-status'];
    const customRedirectURL = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_WEB_APP_URL,
    );
    const doesTppSupportPsr44Flow = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_PSR44_FLOW,
    );

    const forwardCurrentParams = tokenRequestService.getTppFeature(
        featureConfig.FORWARD_CURRENT_TRANSFER_PARAMETERS,
        false,
    );

    if (
        popupStatus !== POPUP_FAILURE &&
        !isAispFlow &&
        doesTppSupportPsr44Flow &&
        transferId &&
        transferStatus
    ) {
        dispatch(
            setTppReturnData({
                popupStatus,
                payload,
            }),
        );
        dispatch(setStep(PISP_PAYMENT_INIT_STATUS));
        dispatch(changeRoute(ROUTE_PISP));
    } else if (
        popupStatus !== POPUP_FAILURE &&
        !transferId &&
        !transferStatus &&
        !isAispFlow &&
        doesTppSupportPsr44Flow
    ) {
        const requestId = tokenRequestService.getRequestId();
        const finalRedirectURL = customRedirectURL
            ? customRedirectURL
            : webAppUrl;
        window.location.assign(
            `${finalRedirectURL}/api/tpp-integration?` +
                `request-id=${requestId}` +
                `${
                    payload?.traceId ? `&token-trace-id=${payload.traceId}` : ''
                }`,
        );
    } else if (popupStatus === POPUP_FAILURE && forwardCurrentParams) {
        const transfer = await dispatch(getTransferDetails());
        if (transfer) {
            await dispatch(returnTransferData(transfer));
        } else {
            dispatch(setTppReturnData({ popupStatus, payload }));
            await dispatch(goToTpp());
        }
    } else {
        dispatch(setTppReturnData({ popupStatus, payload }));
        await dispatch(goToTpp());
    }
};

export const showBankRedirectionScreen = () => (
    dispatch,
    { tokenRequestService },
) => {
    dispatch(clearBankRedirectUrl());
    if (tokenRequestService.useBankRedirectionScreen()) {
        // show redirect to bank screen
        dispatch(changeRoute(ROUTE_BANK_REDIRECT));
    } else {
        dispatch(setStep(LOADING_STEP));
        dispatch(changeRoute(ROUTE_BANK_LOADING));
    }
};

export const redirectToTpp = () => (dispatch, { tokenRequestService }) => {
    const tppRedirectUrl = tokenRequestService.getTppRedirectUrl();
    window.location.assign(tppRedirectUrl);
};

export const setupBankRedirectUrl = bankRedirectUrl => async (
    dispatch,
    { tokenRequestService },
) => {
    dispatch(setBankRedirectUrl(bankRedirectUrl));
    if (!tokenRequestService.useBankRedirectionScreen()) {
        await dispatch(redirectToBankUrl());
    }
};

export const redirectToBankUrl = () => async (dispatch, { tokenService }) => {
    const bankRedirectUrl = tokenService.getBankRedirectUrl();
    blockUserToGoBack();
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_SENT_USER_TO_BANK),
    );
    if (isContextIframe()) {
        TokenCommunicator.dispatchMessage(
            TOKEN_BANK_POPUP_IS_OPEN,
            bankRedirectUrl,
        );
    } else if (window !== window.parent) {
        TokenCommunicator.dispatchMessage(
            TOKEN_BANK_POPUP_IS_OPEN,
            bankRedirectUrl,
        );
    } else {
        window.location.assign(bankRedirectUrl);
    }
};

export const handlePopState = state => async (
    dispatch,
    { sharedService },
) => {
    if (sharedService.getCurrentHistoryStep() === APP_APPROVAL) {
        dispatch(replaceState(state));
        await cancelPollForTokenRequestResult();
    } else {
        dispatch(replaceState(state));
    }
};

// utils

export const logoutFromLocalStorage = async () => {
    try {
        await Token.BrowserCryptoEngine.clearAllKeys();
        if (!localStorageWrapper.get()) return;
        // Clean state
        localStorageWrapper.remove('aisp');
        localStorageWrapper.remove('pisp');
        localStorageWrapper.remove('activeMemberId');
        localStorageWrapper.remove('bank');
        localStorageWrapper.remove('members');
        localStorageWrapper.remove('randid');
    } catch (e) {
        // Ignoring the exception here because on using firefox in private mode
        // it throws an error while removing the keys from Index DB with message as:
        // "a mutation operation was attempted on a database that did not allow mutations."
        // We can ignore above error in respect to the new co-opt flow where BrowserCryptoEngine
        // is no longer in use.

        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const loginFromLocalStorage = () => async dispatch => {
    try {
        // If the browser has a bank (user already went through flow), or if it has aisp/pisp (user
        // in the the middle of an appless flow), then try to get the member. Otherwise, clear LS.
        if (getBankFromLocalStorage()) {
            const member = Token.getMember(Token.BrowserCryptoEngine);
            const alias = await member.firstAlias();
            dispatch(addMember(member));
            dispatch(setAlias(alias));
        } else {
            dispatch(clearMembers());
            await logoutFromLocalStorage();
        }
    } catch (e) {
        dispatch(clearMembers());
        await logoutFromLocalStorage();
    }
};

export const storeRecentBanks = () => async (
    dispatch,
    { tokenRequestService },
) => {
    if (!localStorageWrapper.get()) {
        dispatch(saveRecentBanks([]));
        return;
    }
    try {
        const parsedBanks = localStorageWrapper.get('recentBanks');
        const allowedCountriesWithBanks =
            tokenRequestService.getTppFeature(
                featureConfig.SUPPORTED_COUNTRIES_WITH_BANK_IDS,
            ) || {};
        const hideDefaultCountriesAndBanks = tokenRequestService.getTppFeature(
            featureConfig.HIDE_DEFAULT_COUNTRIES_AND_BANKS,
        );
        const isCountriesPresentInTppConfig =
            Object.keys(allowedCountriesWithBanks)?.length > 0;

        if (hideDefaultCountriesAndBanks && !isCountriesPresentInTppConfig) {
            dispatch(saveRecentBanks([]));
            return;
        }

        if (!(parsedBanks instanceof Array)) {
            dispatch(saveRecentBanks([]));
            return;
        }
        // fetch banks filtered by IDs
        const banks = (
            await dispatch(
                yieldBanks({
                    ids: parsedBanks.map(b => b.id),
                }),
            )
        ).banks;
        // order results by current recentBanks
        // filter null results so it won't break in case of a bank gets removed
        //   from the bank list API
        // return recent banks list with details
        const recentBanks = parsedBanks
            .map(p => {
                const countryCode = p.selectedCountry;
                let isBankPermitted = true;
                if (isCountriesPresentInTppConfig) {
                    const permittedCountry =
                        allowedCountriesWithBanks[countryCode];
                    const permittedBanks =
                        !!permittedCountry && (permittedCountry?.bankIds || []);
                    isBankPermitted =
                        !!permittedBanks &&
                        (permittedBanks.length === 0 ||
                            permittedBanks.includes(p.id));
                }

                const bank = banks.find(
                    b =>
                        b.id === p.id &&
                        isBankPermitted &&
                        b.countries.includes(countryCode),
                );
                return bank && { ...bank, selectedCountry: p.selectedCountry };
            })
            .filter(Boolean);
        dispatch(saveRecentBanks(recentBanks || []));
        return;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        dispatch(saveRecentBanks([]));
        return;
    }
};

const recentBankReducer = (recentBanks, { id, selectedCountry }) => {
    const bank = { id, selectedCountry };
    if (!recentBanks?.length) return [bank]; // empty list
    const existing = recentBanks.findIndex(b => b.id === id);
    // item not found, insert to the top and keep 3 items length
    if (existing === -1) return [bank, ...recentBanks.slice(0, 2)];
    // item found, move to the top
    return [
        bank,
        ...recentBanks.slice(0, existing),
        ...recentBanks.slice(existing + 1),
    ];
};

export const setRecentBank = bank => async (dispatch, { sharedService }) => {
    const recentBanks = recentBankReducer(sharedService.getRecentBanks(), bank);
    if (bank.id) {
        try {
            dispatch(saveRecentBanks([]));
            localStorageWrapper.set('recentBanks', recentBanks);
            await dispatch(storeRecentBanks());
        } catch (e) {
            console.error(e); // eslint-disable-line
            throw e;
        }
    }
};

export const getCountry = async () => {
    const country = getURLParameter('country');
    if (country) {
        return country;
    }
    // const { data } = await axios.get('https://ipinfo.io/');
    // if (data) {
    //     return data.country;
    // }
    return '';
};

export const findBankLogoData = banks => {
    const newBank = banks.map(obj => {
        if (obj.rawData && !obj.rawData.logoUri) {
            try {
                const images = require.context('./../../assets/banks', true);
                const bankName = images(
                    `./${obj.rawData.country}/${obj.rawData.id}/${obj.rawData.id}.png`,
                ).default;
                obj.rawData['logoUri'] = bankName;
            } catch (e) {
                obj.rawData['logoUri'] = `/assets/${obj.rawData.id}.png`;
            }
        } else if (
            obj.rawData &&
            topBanksList[obj.rawData.country.toUpperCase()]?.includes(
                obj.rawData.id,
            )
        ) {
            const list = topBanksList[obj.rawData.country.toUpperCase()];
            if (list.includes(obj.rawData.id)) {
                try {
                    const images = require.context(
                        './../../assets/banks',
                        true,
                    );
                    const bankName = images(
                        `./${obj.rawData.country}/${obj.rawData.id}/${obj.rawData.id}.png`,
                    ).default;
                    obj.rawData['logoUri'] = bankName;
                } catch (e) {
                    obj.rawData['logoUri'] =
                        obj.rawData.logoUri || `/assets/${obj.rawData.id}.png`;
                }
            }
        }
        return obj;
    });
    return newBank;
};

export const filterBanksHavingBranches = banks => (
    dispatch,
    { sharedService },
) => {
    const banksWithBranchesList = sharedService.getBanksWithBranches();
    const newBanks = [];
    for (let i = 0; i < banks.length; i++) {
        if (Object.keys(banksWithBranches).includes(banks[i].rawData.name)) {
            if (!banksWithBranchesList[banks[i].rawData.name]) {
                newBanks.push(createBank(banks[i]));
                dispatch(setBankWithSubBranches(banks[i].title, true));
                banksWithBranchesList[banks[i].rawData.name] = true;
            }
        }
    }
    banks.map(bank => {
        if (!Object.keys(banksWithBranches).includes(bank.rawData.name)) {
            newBanks.push(bank);
        }
    });
    return newBanks;
};

export const createBank = bank => {
    return {
        ...bank,
        key: bank.title.toLowerCase(),
        data: undefined,
        rawData: {
            ...bank.rawData,
            id: bank.title.toLowerCase(),
        },
    };
};

export const setCountriesList = () => async (
    dispatch,
    { sharedService, tokenRequestService },
) => {
    const countries = sharedService.getCountries();
    const defaultCountry = sharedService.getDefaultCountry();
    const aliasName = tokenRequestService.getTppAlias()?.value;
    const supportedCountriesWithBanksByTpp = tokenRequestService.getTppFeature(
        featureConfig.SUPPORTED_COUNTRIES_WITH_BANK_IDS,
    );
    const hideDefaultCountriesAndBanks = tokenRequestService.getTppFeature(
        featureConfig.HIDE_DEFAULT_COUNTRIES_AND_BANKS,
    );

    const customCountriesList =
        (supportedCountriesWithBanksByTpp &&
            Object.keys(supportedCountriesWithBanksByTpp)) ||
        [];

    let countryList = [];
    if (countries && customCountriesList?.length > 0) {
        countryList = countries.filter(country =>
            customCountriesList.includes(country),
        );
    } else {
        if (hideDefaultCountriesAndBanks && !supportedCountriesWithBanksByTpp) {
            dispatch(setCountries(countryList));
            return;
        }
        let aliasValue = aliasName;
        for (const value in ALIAS_COUNTRY_LIST) {
            if (aliasName?.toLowerCase().includes(value.toLowerCase())) {
                aliasValue = value;
                break;
            }
        }
        if (!ALIAS_COUNTRY_LIST[aliasValue]) return;

        countryList = ALIAS_COUNTRY_LIST[aliasValue].map(country => {
            if (countries.includes(country)) return country;
        });
    }

    if (
        countries?.includes(defaultCountry) &&
        !countryList?.includes(defaultCountry)
    ) {
        countryList.push(defaultCountry);
    }

    if (countryList?.length === 1) {
        dispatch(setDefaultCountry(countryList[0]));
    }

    dispatch(setCountries(countryList));
};

export const getAppCallbackUrl = customRedirectURL => {
    let appCallbackUrl;
    if (customRedirectURL) {
        appCallbackUrl = new URL(`${customRedirectURL}/app/auth/callback`);
    } else {
        appCallbackUrl = new URL(`${webAppUrl}/app/auth/callback`);
    }
    return appCallbackUrl;
};

export const fetchTokenRequestData = isBankCallback => async (
    dispatch,
    { tokenRequestService },
) => {
    const tokenRequestId = tokenRequestService.getRequestId();
    const response = await dispatch(fetchTokenRequestInfo(isBankCallback));
    const tokenRequest = response?.tokenRequest;

    if (tokenRequestService.hasCustomColors()) {
        await addCustomCss(tokenRequestId);
    } else {
        removeCustomCss();
    }
    validateBrowserCompatibility();
    const requestPayload = tokenRequest?.requestPayload;
    if (requestPayload) {
        await dispatch(fetchCountries(requestPayload?.to?.id));
    }
    return tokenRequest;
};

export const goToTppAfterBackButton = () => async dispatch => {
    dispatch(changeRoute(TPP_LOADING));
    dispatch(
        terminateFlow({
            error: 'USER_CLICKED_ON_BROWSER_BACK_BUTTON',
            message: 'User may have clicked on the browser back button',
        }),
    );
};

export const blockUserToGoBackBeforeUnload = () => {
    window.addEventListener('beforeunload', () => {
        sessionStorageWrapper.set('blockUserToGoBack', true);
    });
};

export const allowUserToGoBack = () => {
    sessionStorageWrapper.set('blockUserToGoBack', false);
};

export const blockUserToGoBack = () => {
    sessionStorageWrapper.set('blockUserToGoBack', true);
};

export const removeCheckForTokenReqRejection = () => {
    sessionStorageWrapper.remove('tokenRequestStatus');
};

export const addCheckForTokenReqRejection = () => {
    sessionStorageWrapper.set(
        'tokenRequestStatus',
        EXTERNAL_TOKEN_REQUEST_STATUS.REJECTED,
    );
};

export * from './credentials';
