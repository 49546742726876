import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from '../../../css/List.css';
import AbstractList from './Abstract';
import ListRow from './Row';
import ListCell from './Cell';
import ListCellLoader from './CellLoader';
import {isNull, getAriaLabelForList} from '../../Util';
import {SEARCH_RESET_DELAY} from '../../config/constants';

const cx = classNames.bind(style);

let timer = Date.now();

const List = React.forwardRef(({
    data,
    value,
    loadingFirstBatch,
    loadingMore,
    border,
    selected,
    setSelected,
    withPointer,
    className,
    setSearch,
    searchTerm,
    rowClass,
    spinnerColor,
    allowDeselect,
    CellComponent,
    tabIndex,
    activeDescendant,
    showFocusOutline,
    ariaHidden,
    focused,
    appendItem,
    ...props
}, ref) => (
    <AbstractList
        ref={ref}
        className={cx({
            [className]: !!className,
            'List--no-border': border === false,
        })}
        onMouseOver={over => {
            const selectedIndex = data.findIndex(item => item === value);
            withPointer && !over && selectedIndex >= 0 && setSelected(selectedIndex, {
                preventScroll: true,
            });
        }}
        keyDown={event => {
            const { key } = event;
            if (key.length === 1 && /[a-z0-9]/i.test(key)) {
                const now = Date.now();
                if (now - timer >= SEARCH_RESET_DELAY) {
                    setSearch(key);
                } else {
                    setSearch(searchTerm + key);
                }
                timer = now;
            } else if (key === 'ArrowDown') {
                withPointer && setSelected(isNull(selected) ? 0 : selected + 1, {scroll: true});
            } else if (key === 'ArrowUp') {
                withPointer && setSelected(isNull(selected) ? 0 : selected - 1, {scroll: true});
            } else if (key === 'Escape') {
                withPointer && allowDeselect && setSelected(null, {triggerChange: true});
            } else if (key === 'Enter') {
                withPointer && setSelected(selected, {triggerChange: true});
            } else if (key === 'Tab') {
                showFocusOutline(false);
                ref.current.blur();
            }
        }}
        keyUp={ event => {
            const { key } = event;
            if (key === 'Tab') {
                showFocusOutline(true);
            }
        }}
        blur={blur => blur && showFocusOutline(false)}
        withPointer={withPointer}
        {...props}
        ariaHidden={ariaHidden}
        tabIndex={tabIndex}
        ariaExpanded={focused}
        activeDescendant={activeDescendant || `${className}-${selected}`}>
        {([
            ...data.map((item, index) => (
                <ListRow
                    id={`${className}-${index}`}
                    key={item.key}
                    className={cx({
                        'List__row--selected': selected === index,
                        'List__row--active': value && item && (value.rawData === item.rawData)
                            || false,
                        [rowClass]: !!rowClass,
                    })}
                    onClick={e => {
                        e.stopPropagation();
                        showFocusOutline(false);
                        withPointer && setSelected(index, {triggerChange: true});
                    }}
                    onMouseOver={over => withPointer && over && setSelected(index, {
                        preventScroll: true,
                    })}
                    ariaSelected={(selected === index)}
                    ariaHidden={ariaHidden}
                    ariaLabel={className && getAriaLabelForList(className, item)} >
                    <CellComponent title={item.title} data={item.data} extra={item.extra} rawData={item.rawData} appendItem={appendItem} />
                </ListRow>
            )),
            (loadingFirstBatch || loadingMore) && (
                <ListRow
                    key={-1}>
                    <ListCellLoader spinnerColor={spinnerColor} />
                </ListRow>
            ),
        ]).filter(i => !!i)}
    </AbstractList>
));

List.displayName = 'List';

List.propTypes = {
    data: PropTypes.array,
    loadingFirstBatch: PropTypes.bool,
    loadingMore: PropTypes.bool,
    border: PropTypes.bool,
    selected: PropTypes.number,
    setSearch: PropTypes.func,
    searchTerm: PropTypes.string,
    rowClass: PropTypes.string,
    spinnerColor: PropTypes.string,
    value: PropTypes.any,
    setSelected: PropTypes.func,
    withPointer: PropTypes.bool,
    className: PropTypes.string,
    allowDeselect: PropTypes.bool,
    CellComponent: PropTypes.func.isRequired,
    activeDescendant: PropTypes.string,
    showFocusOutline: PropTypes.func,
    tabIndex: PropTypes.string,
    ariaHidden: PropTypes.string,
    focused: PropTypes.bool,
    appendItem: PropTypes.node,
};

List.defaultProps = {
    border: true,
    selected: null,
    setSelected: () => null,
    showFocusOutline: () => null,
    CellComponent: ListCell,
    ariaHidden: 'false',
    appendItem: '',
};

export default List;
