import {WebTracerProvider} from '@opentelemetry/web';
import {SimpleSpanProcessor} from '@opentelemetry/tracing';
import {CollectorTraceExporter} from '@opentelemetry/exporter-collector';
import {ZoneContextManager} from '@opentelemetry/context-zone';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {B3Propagator} from '@opentelemetry/propagator-b3';

import {XMLHttpRequestInstrumentation} from '@token-io/instrumentation-xml-http-request';

// Create a provider for activating and tracking spans
const providerWithZone = new WebTracerProvider();

registerInstrumentations({
    instrumentations: [
        new XMLHttpRequestInstrumentation(),
    ],
    tracerProvider: providerWithZone,
});

// Configure a span processor and exporter for the tracer
export const TracerProvider = {
    getTracer(accessToken) {
        providerWithZone.addSpanProcessor(
            new SimpleSpanProcessor(
                new CollectorTraceExporter({
                    serviceName: 'web-app-service',
                    url: 'https://ingest.lightstep.com:443/api/v2/otel/trace',
                    headers: {
                        'Lightstep-Access-Token': accessToken},
                }),
            ),
        );
        // Register the tracer
        providerWithZone.register({
            contextManager: new ZoneContextManager(),
            propagator: new B3Propagator(),
        });
        const tracer = providerWithZone.getTracer('web-app-browser');
        return tracer;
    },
    async shutdownTracerProvider() {
        await providerWithZone.shutdown();
    },
};
