import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './LanguageSwitcher.css';
import classNames from 'classnames/bind';
import { intlShape } from 'react-intl';
import { connect } from 'reducers';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import AbstractDropdown from '@token-io/lib-web-components/src/Components/Dropdown/Abstract';
import { languages } from 'config/constants';
import { setLocale } from 'actions/shared';
import { getURLParameter } from '../../util';

const cx = classNames.bind(style);

class LanguageSwitcher extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.getPreferredLang = this.getPreferredLang.bind(this);
    }
    getPreferredLang() {
        // Find preferred language in array and set its locale code (e.g. 'en' becomes 'en-US')
        for (const [i, lang] of languages.entries()) {
            if (
                lang.code.toLowerCase() ===
                this.props.locale.slice(0, 2).toLowerCase()
            ) {
                lang.code = this.props.locale;
                return i;
            }
        }
        return 0;
    }

    onChange(lang) {
        if (this.props.locale !== lang.code) {
            this.props.setLocale(lang.code);
        }
    }

    componentDidMount() {
        const defaultLang = getURLParameter('lang');
        if (defaultLang) {
            this.props.setLocale(defaultLang);
        }
    }

    render() {
        return (
            <StaticDataProvider
                idKey={'code'}
                titleKey={'code'}
                dataKey={'key'}
                useCustomData
                customData={languages}
                value={languages[this.getPreferredLang()]}
                customSetValue={async (value, { doneCallback }) => {
                    await this.onChange(value);
                    doneCallback();
                }}
                withPointer>
                <AbstractDropdown
                    className={cx('LanguageSwitcher-container')}
                    listClass={cx('LanguageSwitcher-dropdown')}
                    rowClass={cx('LanguageSwitcher-item')}
                    scrollClass={cx('LanguageSwitcher-scroll')}
                    align={'right'}
                    width={180}
                    ariaLabel={this.props.intl.formatMessage({
                        id: 'common.langSwitcher.focus',
                    })}
                />
            </StaticDataProvider>
        );
    }
}

LanguageSwitcher.propTypes = {
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ sharedService }) => ({
    locale: sharedService.getLocale(),
});

const mapDispatchToProps = {
    setLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
