import React, { createRef, useState } from 'react';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import Button from '@token-io/lib-web-components/src/Components/Button';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames/bind';
import style from './CsrfMismatch.css';
import { copyToClipboard } from 'util/index';

const cx = classNames.bind(style);

const CsrfMismatch = ({ intl, redirectUrl }) => {
    const [isCopied, setCopied] = useState(false);
    const ref = createRef();
    return (
        <div className={cx('CsrfMismatch')}>
            <TokenContainer className={cx('CsrfMismatch-subcontainer')}>
                <strong>
                    <FormattedMessage
                        id={'common.csrfMismatch.title1'}
                        tagName={'p'}
                    />
                </strong>
                <div
                    className={cx('CsrfMismatch-redirectUrl')}
                    onMouseUp={() => {
                        copyToClipboard(ref.current);
                        setCopied(true);
                    }}>
                    <textarea
                        ref={ref}
                        className={cx('CsrfMismatch-copyArea')}
                        value={redirectUrl}
                        readOnly
                    />
                    {redirectUrl}
                </div>
                <div
                    className={cx({
                        ['CsrfMismatch-copyStatus']: true,
                        ['CsrfMismatch-copyStatus-visible']: isCopied,
                    })}>
                    <FormattedMessage
                        id={'common.csrfMismatch.copiedMessage'}
                    />
                </div>
                <Button
                    onClick={() => {
                        copyToClipboard(ref.current);
                        setCopied(true);
                    }}
                    text={intl.formatMessage({
                        id: 'common.csrfMismatch.copyUrl',
                    })}
                />
            </TokenContainer>
        </div>
    );
};

CsrfMismatch.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ sharedService }) => ({
    redirectUrl: sharedService.getBrowserRedirectUrl(),
});

export default connect(mapStateToProps)(CsrfMismatch);
