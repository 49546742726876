import { TPP_INTEGRATION_ADDRESS } from 'config/constants';
import axios from 'axios';

const TppIntegrationClient = axios.create({
    baseURL: TPP_INTEGRATION_ADDRESS,
    headers: {
        get: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        },
    },
});

export default TppIntegrationClient;
