import {generateNonce} from './Util';

const REQUEST_TIMEOUT = 5000;

/**
 * Class postie for postMessage bindings
 * @param       {object} options Constructor params
 * @constructor
 */
function Postie(options) {
    let _id = options.id;
    let _source = options.source;
    let _destination = options.destination;
    let _origin = options.origin || '*';

    // initialize message collection
    let _messages = [];

    const _self = this;

    function _setId(id) {
        _id = id;
        return _self;
    }

    function _onReceiveMessage(e) {
        let {data} = e;
        if (!data) return;

        try {
            data = _decodeMessage(data);
        } catch (e) {
            return;
        }

        const {type} = data;
        if (!type) return;

        const m = _messages.find(m => m.type === type);
        if (!m) return;

        // is weak reference
        if (m.weak) {
            _id = data.popupId;
        } else { // is strong reference
            if (data.popupId !== _id) {
                return;
            }
        }

        // execute callback
        m.callback(data);
    }

    function _bindListeners() {
        _source.addEventListener('message', _onReceiveMessage);
    }

    function _unbindListeners() {
        _source.removeEventListener('message', _onReceiveMessage);
    }

    function _dispatchMessage(message, destination, origin) {
        // dispatch message to the parent

        const d = destination || _destination;
        const o = origin || _origin;

        const m = _decodeMessage(message);

        if (_id) {
            m.popupId = _id;
        }

        d.postMessage(_encodeMessage(m), o);

        return _self;
    }

    function _onMessage(type, callback, weak) {
        // enable message type listener
        if (_messages.length && _messages.find(m => m.type === type)) {
            return;
        }
        const w = weak ? weak : false;
        _messages.push({
            type,
            callback,
            weak: w,
        });
        return _self;
    }

    function _offMessage(type) {
        // disable message type listener
        if (!_messages.length) {
            return;
        }
        const i = _messages.findIndex(m => m.type === type);
        if (i !== -1) {
            _messages.splice(i, 1);
        }
        return _self;
    }

    function _decodeMessage(message) {
        if (typeof message === 'object') {
            return message;
        }
        if (typeof message === 'string' && message.length) {
            try {
                return JSON.parse(message);
            } catch (e) {
                return {
                    type: message,
                };
            }
        }
        throw new Error('INVALID_MESSAGE');
    }

    function _encodeMessage(message) {
        if (typeof message === 'string') {
            return message;
        }
        if (typeof message === 'object') {
            try {
                return JSON.stringify(message);
            } catch (e) {
                throw new Error('INVALID_MESSAGE');
            }
        }
        throw new Error('INVALID_MESSAGE');
    }

    function _dispatchRequest(msg, success, failure, destination, origin) {
        const startTime = new Date().getTime();
        // setup and dispatch request message
        const message = _decodeMessage(msg);
        const type = message.type;
        const typeRes = `${type}_RES`;
        const nonce = generateNonce();
        let interval = null;
        const cancelInterval = () => {
            if (!interval) return;
            clearInterval(interval);
            interval = null;
        };
        if (_messages.find(m => m.type === typeRes)) {
            return;
        }
        _onMessage(typeRes, data => {
            if (data.nonce !== nonce) return;
            _offMessage(typeRes);
            cancelInterval();
            success(data);
        });
        const typeReq = `${type}_REQ`;
        const messageReq = Object.assign(message, {
            type: typeReq,
            nonce,
        });
        _dispatchMessage(messageReq, destination, origin);
        interval = setInterval(() => {
            // reject on timeout
            const timePassed = new Date().getTime() - startTime;
            if (timePassed >= REQUEST_TIMEOUT) {
                cancelInterval();
                _offMessage(typeRes);
                failure(new Error('MESSAGE_REQUEST_TIMEOUT'));
            }
        }, 500);
        return _self;
    }

    function _setupResponse(type, callback, destination, origin) {
        // setup response message
        const typeReq = `${type}_REQ`;
        if (_messages.find(m => m.type === typeReq)) {
            return;
        }
        _onMessage(typeReq, data => {
            const typeRes = `${type}_RES`;
            callback(
                data.payload,
                response => {
                    const messageRes = Object.assign({}, data, {
                        type: typeRes,
                        payload: response,
                    });
                    _dispatchMessage(messageRes, destination, origin);
                }
            );
        });
        return _self;
    }

    function _proxyRequest(type, destination, origin) {
        // setup proxy request (both ways)
        if (!(type && destination)) return;
        const o = origin || '*';
        const typeReq = `${type}_REQ`;
        const typeRes = `${type}_RES`;
        // request listener
        _onMessage(typeReq, data => {
            const message = _encodeMessage(data);
            destination.postMessage(message, o);
        });
        // response listener
        _onMessage(typeRes, data => {
            const message = _encodeMessage(data);
            _destination.postMessage(message, _origin);
        });
        return _self;
    }

    function _proxyMessage(type, destination, origin) {
        // setup proxy message (one way)
        if (!type) return;
        const d = destination || _destination;
        const o = origin || _origin;
        _onMessage(type, data => {
            const message = _encodeMessage(data);
            d.postMessage(message, o);
        });
        return _self;
    }

    function _destroy() {
        _unbindListeners();
        _destination = null;
        _source = null;
        _origin = '*';
        _messages = [];
        return _self;
    }

    // bind public methods
    _self.setId = _setId.bind(_self);
    _self.dispatchMessage = _dispatchMessage.bind(_self);
    _self.onMessage = _onMessage.bind(_self);
    _self.offMessage = _offMessage.bind(_self);
    _self.dispatchRequest = _dispatchRequest.bind(_self);
    _self.setupResponse = _setupResponse.bind(_self);
    _self.proxyRequest = _proxyRequest.bind(_self);
    _self.proxyMessage = _proxyMessage.bind(_self);
    _self.destroy = _destroy.bind(_self);

    // bind listeners
    if (_source) {
        _bindListeners();
    }
}

export default Postie;
