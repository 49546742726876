import {
    AISP_CAPTURE_CREDENTIALS,
    PISP_CAPTURE_CREDENTIALS,
    ROUTE_PISP,
    ROUTE_AISP,
    DEFAULT_DEV_KEY,
    INTERNAL_TOKEN_REQUEST_STATUS as requestStatus,
    INIT_BANK_AUTH_STATUS as bankAuthStatus,
} from 'config/constants';
import { getURLParameter } from 'util/index';
import { formatCredentials } from '../helpers';
import {
    EXCEPTION_INITIATE_BANK_AUTH,
    INTL_EXCEPTION_REQUEST_FAILED,
} from '../../config/exceptions';
import { changeRoute, setStep } from 'actions/shared/route';
import { TokenException } from '@token-io/lib-web-app';
import {
    initiateBankAuthorization,
    setTokenRequestStatus,
} from 'actions/shared/api';
import {
    getTokenRequest,
    fetchTokenRequestData,
    showBankRedirectionScreen,
    setupBankRedirectUrl,
    completeFlowV2,
    setTokenRequestId,
    setCustomDevKey,
    setBank,
    yieldBanks,
    blockUserToGoBack,
} from 'actions/shared';

// Actions
export const setCredentials = credentials => ({
    type: 'SET_CREDENTIALS',
    credentials,
});

export const setInitBankAuthStatus = status => ({
    type: 'SET_INIT_BANK_AUTH_STATUS',
    status,
});

// Thunks for credentials
export const captureCredentials = (requiredCredentials, failureCb) => async (
    dispatch,
    { sharedService, tokenRequestService },
) => {
    dispatch(setInitBankAuthStatus(bankAuthStatus.FIELDS));
    if (requiredCredentials && requiredCredentials?.length !== 0) {
        await dispatch(
            setTokenRequestStatus(
                requestStatus.PENDING_FURTHER_CREDENTIALS_REQUIRED_1,
            ),
        );

        const selectedBank = sharedService.getSelectedBank();
        const defaultBankId = tokenRequestService.getDefaultBankId();

        if (!selectedBank && defaultBankId) {
            const { banks } = await dispatch(
                yieldBanks({ ids: [defaultBankId], isDefaultBank: true }),
            );
            const bank = banks?.[0];
            dispatch(setBank(bank));
        }

        dispatch(setCredentials(requiredCredentials));
        if (sharedService.isAisp()) {
            dispatch(setStep(AISP_CAPTURE_CREDENTIALS));
            dispatch(changeRoute(ROUTE_AISP));
        } else {
            dispatch(setStep(PISP_CAPTURE_CREDENTIALS));
            dispatch(changeRoute(ROUTE_PISP));
        }
    } else {
        if (typeof failureCb === 'function') {
            failureCb();
        } else {
            throw new TokenException(
                INTL_EXCEPTION_REQUEST_FAILED,
                'Error: failure occured in capturing credentials',
            );
        }
    }
};

export const handleFinalScaStatus = scaStatus => async (
    dispatch,
    { tokenRequestService },
) => {
    const tokenRequestId = tokenRequestService.getRequestId();
    dispatch(setInitBankAuthStatus(bankAuthStatus.STATUS));
    if (scaStatus === 'AUTHENTICATED') {
        blockUserToGoBack();
        await dispatch(completeFlowV2(tokenRequestId));
    } else {
        throw new TokenException(
            EXCEPTION_INITIATE_BANK_AUTH,
            'Bank Authentication has failed',
        );
    }
};

export const initiateCredentialsCallback = response => async dispatch => {
    if (response?.redirectUrl) {
        dispatch(showBankRedirectionScreen());
        dispatch(setInitBankAuthStatus(bankAuthStatus.REDIRECT_URL));
        await dispatch(setupBankRedirectUrl(response.redirectUrl));
    } else if (
        response?.credentialFields?.fields &&
        response?.credentialFields?.fields?.length > 0
    ) {
        const credentials = response.credentialFields.fields;
        const requiredCredentials = formatCredentials(credentials);
        dispatch(
            captureCredentials(requiredCredentials, () => {
                throw new TokenException(
                    EXCEPTION_INITIATE_BANK_AUTH,
                    'Error in initiate bank auth response from TI for credentials',
                );
            }),
        );
    } else if (response?.status) {
        dispatch(handleFinalScaStatus(response.status));
    } else {
        throw new TokenException(
            EXCEPTION_INITIATE_BANK_AUTH,
            'Unknown error in initiate bank auth call',
        );
    }
};

export const initiateBankAuthFlow = (credentialValues, options) => async (
    dispatch,
    { tokenService, sharedService },
) => {
    const prevAuthStatus = tokenService.getBankAuthStatus();
    const { tokenRequest } = await dispatch(getTokenRequest(!!prevAuthStatus));

    const dontUseWebApp = options?.dontUseWebApp;
    const bank = sharedService.getSelectedBank();

    let request;
    if (credentialValues) {
        request = {
            credentials: credentialValues,
        };
    } else {
        let requestPayload;
        if (bank?.mandatoryFields) {
            if (!sharedService.isBulkTransfer()) {
                requestPayload = tokenRequest?.requestPayload;
                if (sharedService.isStandingOrder()) {
                    requestPayload.standingOrderBody.instructions = tokenService.getStandingOrderInstructions();
                } else if (sharedService.isTransfer()) {
                    requestPayload.transferBody.instructions = tokenService.getInstructions();
                } else if (sharedService.isAisp()) {
                    requestPayload.accessBody.resourceTypeList = tokenService.getResourceTypeList();
                }
            }
        }

        request = {
            ...(!dontUseWebApp && { requestPayload }),
        };
    }

    const res = await dispatch(initiateBankAuthorization(request));
    res && dispatch(initiateCredentialsCallback(res));
};

export const initiateCredentialsFlow = () => async (
    dispatch,
    { tokenRequestService },
) => {
    const requestId = getURLParameter('request-id');
    const devKey = getURLParameter('dk') || DEFAULT_DEV_KEY;
    dispatch(setCustomDevKey(devKey));
    await dispatch(setTokenRequestId(requestId));
    await dispatch(fetchTokenRequestData());
    const defaultBankId = tokenRequestService.getDefaultBankId();
    const isDefaultBank = !!defaultBankId;
    const { banks } = await dispatch(
        yieldBanks({ ids: [defaultBankId], isDefaultBank }),
    );
    const bank = banks?.[0];
    dispatch(setBank(bank));
    dispatch(initiateBankAuthFlow(null, { dontUseWebApp: true }));
};
