import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import TokenBackground from '@token-io/lib-web-components/src/Components/Layout/TokenBackground';
import LanguageSwitcher from 'components/App/LanguageSwitcher';
import {
    STEPS_WITHOUT_LOGO,
    ROUTES_WITHOUT_BACK_BUTTON,
    STEPS_WITHOUT_BACK_BUTTON,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import EmptyBackgroundTitle from './EmptyBackgroundTitle';
import { FooterText } from 'components/App/Shared/FooterText';
import TokenBackgroundBackButton from 'components/App/TokenBackgroundBackButton';
import { setHtmlLanguageAttribute } from 'util/index';
import classNames from 'classnames/bind';

const cx = classNames.bind();

const Background = ({
    children,
    logo,
    locale,
    productName,
    currentStep,
    currentRoute,
    displayBackButton,
    displayShadowTppConfig,
}) => {
    const [displayFooterShadow, setDisplayFooterShadow] = useState(true);
    const pagesWithoutBackButton =
        ROUTES_WITHOUT_BACK_BUTTON.includes(currentRoute) ||
        STEPS_WITHOUT_BACK_BUTTON.includes(currentStep);
    const displayHeaderBackButton =
        displayBackButton && !pagesWithoutBackButton;

    useEffect(() => {
        setHtmlLanguageAttribute(locale);
    }, [locale]);

    return (
        <TokenBackground>
            <TokenBackground.Header
                className={cx({
                    'TokenBackground-header--shadow': !!displayShadowTppConfig,
                })}>
                {STEPS_WITHOUT_LOGO.includes(currentStep) ? (
                    <EmptyBackgroundTitle />
                ) : (
                    <>
                        {displayHeaderBackButton && (
                            <TokenBackgroundBackButton />
                        )}
                        <TokenBackground.Title
                            logo={productName ? undefined : logo}
                            text={productName}
                            {...(displayBackButton &&
                                pagesWithoutBackButton && {
                                alignAtCenter: true,
                            })}
                        />
                    </>
                )}

                <LanguageSwitcher />
            </TokenBackground.Header>
            <TokenBackground.Content>
                {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            setDisplayFooterShadow: setDisplayFooterShadow,
                        });
                    }
                    return child;
                })}
            </TokenBackground.Content>
            <FooterText
                displayShadow={displayFooterShadow && displayShadowTppConfig}
            />
        </TokenBackground>
    );
};

Background.propTypes = {
    children: PropTypes.any,
    logo: PropTypes.string,
    locale: PropTypes.string,
    productName: PropTypes.string,
    footer: PropTypes.node,
    currentStep: PropTypes.string,
    currentRoute: PropTypes.string,
    displayShadowTppConfig: PropTypes.bool,
    displayFooterText: PropTypes.bool,
    displayBackButton: PropTypes.bool,
};

const mapStateToProps = ({ tokenRequestService, sharedService }, ownProps) => {
    const currentStep = sharedService.getCurrentStep();
    const locale = sharedService.getLocale();
    const customLogo = tokenRequestService.getTppFeature(
        featureConfig.TPP_LOGO,
    );
    const logo =
        ownProps.enfuseLogo ||
        (customLogo && `data:${customLogo.type};base64,${customLogo.data}`) ||
        '';
    const currentRoute = sharedService.getCurrentRoute();
    const displayBackButton = tokenRequestService.getTppFeature(
        featureConfig.DISPLAY_TOKEN_BACKGROUND_BACK_BUTTON,
        false,
    );
    const productName = tokenRequestService.getTppFeature(
        featureConfig.PRODUCT_NAME,
        '',
    );
    const displayShadowTppConfig = tokenRequestService.getTppFeature(
        featureConfig.DISPLAY_SHADOW,
        true,
    );

    return {
        logo,
        locale,
        productName,
        currentStep,
        currentRoute,
        displayShadowTppConfig,
        displayBackButton,
    };
};

export default connect(mapStateToProps)(Background);
