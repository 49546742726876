import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import LeftChevron from './Icons/LeftChevron';
import classNames from 'classnames/bind';
import style from './TokenBackgroundBackButton.css';
import ConsentBackToTppOverlay from './ConsentBackToTppOverlay.js';
import { intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const cx = classNames.bind(style);

const TokenBackgroundBackButton = ({ intl, tppName }) => {
    const [displayOverlay, setDisplayOverlay] = useState(false);

    const handleBackButton = () => {
        if (displayOverlay) {
            setDisplayOverlay(false);
        } else {
            setDisplayOverlay(true);
        }
    };

    return (
        <>
            <div
                className={cx('TokenBackground-back-button-container')}
                tabIndex="0"
                role="button"
                onClick={handleBackButton}
                aria-label={
                    intl.formatMessage({
                        id: 'common.backButton.ariaLabel',
                    }) +
                    ' ' +
                    tppName
                }>
                <div className={cx('TokenBackground-left-chevron-container')}>
                    <LeftChevron svgClassName={'Left-chevron-svg'} />
                </div>
                <div className={cx('TokenBackground-back-button')}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'common.backButton.text'}
                    />
                </div>
            </div>
            {displayOverlay && (
                <ConsentBackToTppOverlay handleOverlay={setDisplayOverlay} />
            )}
        </>
    );
};

TokenBackgroundBackButton.propTypes = {
    intl: intlShape.isRequired,
    tppName: PropTypes.string,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const tppName = tokenRequestService.getTppProfile()?.tppName;
    const tppAlias = tokenRequestService.getTppAlias();
    const actingAs = tokenRequestService.getTppActingAs();

    return {
        tppName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            tppAlias?.value,
    };
};

export default connect(mapStateToProps)(TokenBackgroundBackButton);
