import React from 'react';
import PropTypes from 'prop-types';
import style from './../Pisp/Consent.css';
import classNames from 'classnames/bind';
import { FaRegEdit } from 'react-icons/fa';

const cx = classNames.bind(style);

const EditIcon = ({ onKeyPress, onClick, className, ariaLabel }) => {
    return (
        <span
            className={cx({ [className]: !!className })}
            aria-label={ariaLabel}
            onKeyPress={e => {
                if (e.key === 'Enter') onKeyPress();
            }}
            onClick={() => onClick()}
            tabIndex="0"
            role="button">
            <FaRegEdit
                className={cx('Consent-EditIcon')}
                style={{
                    color: 'black',
                    cursor: 'pointer',
                    border: 'none',
                    outline: 'none',
                }}
                title="Edit"
            />
        </span>
    );
};

EditIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
};

EditIcon.defaultProps = {
    onClick: () => null,
    onKeyPress: () => null,
};

export default EditIcon;
