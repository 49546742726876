import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import style from './PaymentInitStatus.css';
import CopyIcon from 'components/App/Icons/CopyIcon.js';
import PaymentStatusTitle from './PaymentStatusTitle.js';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    formatCurrency,
    getCurrentDateTimeString,
    formatDate,
    filterDestinationAccount,
    copyOnClipboard,
    getInternalTransferStatus,
} from 'util/index';
import {
    defaultAppName,
    successfulStatusCodes,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { FormattedMessage, intlShape } from 'react-intl';
import { goToTpp } from 'actions/shared';

const cx = classNames.bind(style);

class PaymentInitStatus extends Component {
    constructor(props) {
        super(props);
        this.state = { seconds: props.hidePSR44Timer ? 20 : 10 };
    }

    async componentDidMount() {
        this.props.setDisplayFooterShadow(false);

        if (!this.props.removePSR44Timer) {
            this.intervalId = setInterval(this.countdown, 1000);
        }
    }

    countdown = () => {
        const timeoutFunc = this.props.goToTpp;
        const { seconds } = this.state;
        if (seconds < 1) {
            clearInterval(this.intervalId);
            timeoutFunc();
        } else {
            this.setState({
                seconds: seconds - 1,
            });
        }
    };

    render() {
        const {
            goToTpp,
            total,
            destinations,
            refId,
            transferStatus,
            dateTime,
            pispName,
            pispAlias,
            selectedBankName,
            transferId,
            hidePSR44Timer,
            removePSR44Timer,
        } = this.props;
        const { seconds } = this.state;
        const destinationAccount = filterDestinationAccount(destinations[0]);
        const statusCode = getInternalTransferStatus(transferStatus);

        const transferStatusMessage = this.props.intl.formatMessage(
            {
                id: `pisp.paymentInitStatus.status_code.${statusCode}`,
                defaultMessage: '',
            },
            {
                MERCHANT_NAME: pispName,
            },
        );

        const continueToMerchant =
            hidePSR44Timer || removePSR44Timer || seconds === 0;

        return (
            <Fragment>
                <TokenContainer
                    className={cx('Pisp-PaymentInitStatus-subcontainer')}
                    noMargin>
                    <div
                        className={cx({
                            'TokenContainer-margin': true,
                            'PaymentInitStatus-margin': true,
                        })}>
                        <PaymentStatusTitle
                            transferStatus={transferStatus}
                            seconds={seconds}
                        />
                    </div>
                    <div className={cx('PaymentInitStatus-data')}>
                        <TokenTitle sub>
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.paymentInitStatus.amount'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span className={cx('Colored-Text')}>
                                        {total}
                                    </span>
                                </div>
                            </div>
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={
                                            'pisp.paymentInitStatus.beneficiary'
                                        }
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    {pispName ? (
                                        <strong>{pispName}</strong>
                                    ) : (
                                        <strong>{pispAlias}</strong>
                                    )}
                                </div>
                            </div>
                            {Object.keys(destinationAccount).map(key => {
                                return (
                                    <div
                                        key={key}
                                        className={cx(
                                            'PaymentInitStatus-data-row',
                                        )}>
                                        <div
                                            className={cx(
                                                'PaymentInitStatus-data-row-title',
                                            )}>
                                            <FormattedMessage
                                                tagName={'span'}
                                                id={`pisp.paymentInitStatus.destination.${key}`}
                                            />
                                        </div>
                                        <div
                                            className={cx(
                                                'PaymentInitStatus-data-row-detail',
                                            )}>
                                            <span>
                                                <strong>
                                                    {destinationAccount[key]}
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.paymentInitStatus.bankName'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span
                                        className={cx(
                                            'PaymentInitStatus-BankName',
                                        )}>
                                        <strong>{selectedBankName}</strong>
                                    </span>
                                </div>
                            </div>
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.paymentInitStatus.status'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-TransferStatus',
                                    )}>
                                    <span
                                        className={cx(
                                            'Colored-Text TransferStatus',
                                        )}>
                                        {successfulStatusCodes.includes(
                                            transferStatus,
                                        ) ? (
                                                <FormattedMessage
                                                    tagName={'span'}
                                                    id={`pisp.paymentInitStatus.status_title.${transferStatus}`}
                                                />
                                            ) : transferStatusMessage ? (
                                                [transferStatusMessage]
                                            ) : (
                                                <FormattedMessage
                                                    id={
                                                        'pisp.paymentInitStatus.status_code.BANK_ERROR'
                                                    }
                                                    values={{
                                                        MERCHANT_NAME: pispName,
                                                    }}
                                                />
                                            )}
                                    </span>
                                </div>
                            </div>
                            {transferId ? (
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row',
                                    )}>
                                    <div
                                        style={{
                                            width: 'max-content !important',
                                        }}
                                        className={cx(
                                            'PaymentInitStatus-data-row-title',
                                        )}>
                                        <span
                                            style={{
                                                width: 'max-content',
                                            }}>
                                            <FormattedMessage
                                                tagName={'span'}
                                                id={
                                                    'pisp.paymentInitStatus.transferId'
                                                }
                                            />
                                        </span>
                                    </div>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-detail',
                                        )}>
                                        <span className={cx('TransferID')}>
                                            <strong id="refId">
                                                {transferId}{' '}
                                            </strong>
                                            <CopyIcon
                                                className={cx(
                                                    'PaymentInitStatus-CopyTransferId',
                                                )}
                                                ariaLabel={this.props.intl.formatMessage(
                                                    {
                                                        id:
                                                            'pisp.paymentInitStatus.copyTransferId',
                                                    },
                                                )}
                                                onKeyPress={() =>
                                                    copyOnClipboard(transferId)
                                                }
                                                onClick={() =>
                                                    copyOnClipboard(transferId)
                                                }
                                            />
                                        </span>
                                    </div>
                                </div>
                            ) : null}
                            {dateTime ? (
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row',
                                    )}>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-title',
                                        )}>
                                        <FormattedMessage
                                            tagName={'span'}
                                            id={'pisp.paymentInitStatus.date'}
                                        />
                                    </div>
                                    <div
                                        className={cx(
                                            'PaymentInitStatus-data-row-detail',
                                        )}>
                                        <span className={cx('Colored-Text')}>
                                            {dateTime}
                                        </span>
                                    </div>
                                </div>
                            ) : null}
                            <div className={cx('PaymentInitStatus-data-row')}>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'pisp.paymentInitStatus.refId'}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'PaymentInitStatus-data-row-detail',
                                    )}>
                                    <span className={cx('RefID')}>
                                        <strong id="refId">{refId} </strong>
                                        <CopyIcon
                                            className={cx(
                                                'PaymentInitStatus-CopyRefId',
                                            )}
                                            ariaLabel={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'pisp.consent.copyRefId',
                                                },
                                            )}
                                            onKeyPress={() =>
                                                copyOnClipboard(refId)
                                            }
                                            onClick={() =>
                                                copyOnClipboard(refId)
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                        </TokenTitle>
                    </div>
                </TokenContainer>

                <TokenButtonsWrapper>
                    <Button
                        type="primary"
                        text={
                            continueToMerchant
                                ? this.props.intl.formatMessage(
                                    {
                                        id:
                                              'common.button.continueToMerchant',
                                    },
                                    { MERCHANT_NAME: pispName },
                                )
                                : this.props.intl.formatMessage(
                                    {
                                        id:
                                              'common.button.returningInSeconds',
                                    },
                                    { SECONDS: seconds },
                                )
                        }
                        onClick={goToTpp}
                    />
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

PaymentInitStatus.propTypes = {
    total: PropTypes.string.isRequired,
    goToTpp: PropTypes.func,
    destinations: PropTypes.array,
    intl: intlShape.isRequired,
    refId: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    transferStatus: PropTypes.string.isRequired,
    pispName: PropTypes.string,
    pispAlias: PropTypes.string.isRequired,
    selectedBankName: PropTypes.string,
    transferId: PropTypes.string,
    setDisplayFooterShadow: PropTypes.func,
    hidePSR44Timer: PropTypes.bool,
    removePSR44Timer: PropTypes.bool,
};

const mapStateToProps = (
    { tokenService, tokenRequestService, sharedService },
    ownProps,
) => {
    const total = formatCurrency(
        tokenService.getAmount(),
        tokenService.getCurrency(),
        ownProps.intl.formatNumber,
    );
    const { payload } = tokenRequestService.getTppReturnData();
    const dateTime =
        formatDate(tokenService.getExecutionDate()) ??
        getCurrentDateTimeString();
    const tppAlias = tokenRequestService.getTppAlias();
    const tppName =
        payload['tpp-name'] || tokenRequestService.getTppProfile()?.tppName;
    const actingAs = tokenRequestService.getTppActingAs();
    const selectedBankName =
        payload['bank-name'] || sharedService?.getSelectedBankName();
    return {
        total,
        businessName:
            tokenRequestService.getTppFeature(featureConfig.PIS_APP_NAME) ||
            defaultAppName,
        hidePSR44Timer: Boolean(
            tokenRequestService.getTppFeature(featureConfig.HIDE_PSR44_TIMER),
        ),
        removePSR44Timer: Boolean(
            tokenRequestService.getTppFeature(featureConfig.REMOVE_PSR44_TIMER),
        ),
        pispName: actingAs?.displayName || tppName || actingAs?.secondaryName,
        pispAlias: actingAs?.secondaryName || tppAlias?.value,
        selectedBankName,
        destinations: tokenService.getDestinations(),
        refId: tokenService.getRefId(),
        dateTime,
        transferStatus: payload['transfer-status'],
        transferId: payload['transfer-id'],
    };
};

const mapDispatchToProps = {
    goToTpp,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInitStatus);
