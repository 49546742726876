import { combineReducers } from 'redux';
import { createReducer } from '@token-io/lib-web-app';

const request = createReducer(
    {},
    {
        SET_VRP_ID: (state, action) => {
            return { ...state, vrpId: action.vrpId };
        },
        SET_VRP_REF_ID: (state, action) => {
            return { ...state, vrpRefId: action.vrpRefId };
        },
        SET_VRP_LOCAL_INSTRUMENT: (state, action) => {
            const localInstrument = action.localInstrument;
            return { ...state, localInstrument };
        },
    },
);

const consentDetails = createReducer(
    {},
    {
        SET_VRP_CURRENCY: (state, action) => {
            return { ...state, currency: action.currency };
        },
        SET_VRP_STATUS: (state, action) => {
            return { ...state, status: action.status };
        },
        SET_VRP_DEBTOR_INFO: (state, action) => {
            return { ...state, debtorInfo: action.debtorInfo };
        },
        SET_VRP_CREDITOR_INFO: (state, action) => {
            return { ...state, creditorInfo: action.creditorInfo };
        },
        SET_VRP_PERIODIC_LIMITS: (state, action) => {
            return { ...state, periodicLimits: action.periodicLimits };
        },
        SET_VRP_EXPIRATION_DATE: (state, action) => {
            return { ...state, expirationDate: action.expirationDate };
        },
        SET_PISP_CONSENT_ACCEPTED: (state, action) => {
            const flag = action?.pispConsentAccepted || false;
            return {
                ...state,
                pispConsentAccepted: flag,
            };
        },
        SET_VRP_DESCRIPTION: (state, action) => {
            return { ...state, description: action.description };
        },
        SET_MAX_AMOUNT: (state, action) => {
            return {
                ...state,
                maxIndividualAmount: action.maxIndividualAmount,
            };
        },
    },
);

export const reducer = combineReducers({
    request,
    consentDetails,
});

export const selector = getState => {
    return {
        getVrpRequestId: () => getState().request.vrpId,
        getVrpRefId: () => getState().request.vrpRefId,
        getStatus: () => getState().consentDetails.status,
        getPeriodicLimits: () => getState().consentDetails.periodicLimits,
        getMaxIndividualAmount: () =>
            getState().consentDetails.maxIndividualAmount,
        getCreditorBankName: () =>
            getState().consentDetails.creditorInfo.bankName,
        getDescription: () => getState().consentDetails.description,
        getDebtorInfo: () => getState().consentDetails.debtorInfo,
        getCreditorInfo: () => getState().consentDetails.creditorInfo,
        getLocalInstrument: () => getState().request.localInstrument,
        getExpirationDate: () => getState().consentDetails.expirationDate,
        getVrpCurrency: () => getState().consentDetails.currency,
        getPispConsentAccepted: () =>
            getState().consentDetails.pispConsentAccepted,
    };
};

export const getRedactedState = getState => {
    const state = getState();
    // No redacted state here for now
    return {
        ...state,
    };
};

export const name = 'vrp';
