import React from 'react';
import PropTypes from 'prop-types';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import { connect } from 'reducers';
import style from './RedirectTpp.css';
import classNames from 'classnames/bind';
import { argbToRgba } from 'util/index';
import {
    defaultColors,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { redirectToTpp } from 'actions/shared';
import { FormattedMessage, intlShape } from 'react-intl';

const cx = classNames.bind(style);

const RedirectTpp = ({
    tppName,
    redirectToTpp,
    footer,
    hideFooter,
    intl,
    isError,
}) => {
    return (
        <div className={cx('RedirectTpp')}>
            <TokenContainer className={cx('RedirectTpp-subcontainer')}>
                <TokenTitle>
                    <FormattedMessage
                        id={`common.redirectTpp.${
                            (isError && 'errTitle') || 'title'
                        }`}
                    />
                </TokenTitle>
                <FormattedMessage
                    id={`common.redirectTpp.${
                        (isError && 'errText') || 'text'
                    }`}
                    tagName={'p'}
                    values={{
                        TPP_NAME: tppName,
                    }}
                />
                <Button
                    onClick={() => redirectToTpp()}
                    text={intl.formatMessage(
                        {
                            id: 'common.redirectTpp.button',
                        },
                        {
                            TPP_NAME: tppName,
                        },
                    )}
                />
            </TokenContainer>
            {(!hideFooter && (
                <span className={cx('RedirectTpp-footer')}>{footer}</span>
            )) ||
                null}
        </div>
    );
};

RedirectTpp.propTypes = {
    footer: PropTypes.node,
    hideFooter: PropTypes.bool.isRequired,
    color: PropTypes.string,
    redirectToTpp: PropTypes.func.isRequired,
    tppName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    isError: PropTypes.bool,
};

RedirectTpp.defaultProps = {
    hideFooter: false,
    isError: false,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const hasCustomization = tokenRequestService.hasCustomization();
    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };
    const footer =
        (hasCustomization && (
            <span>
                powered by <strong>Token</strong>
            </span>
        )) ||
        `© ${new Date().getFullYear()} Token.io, Ltd. All rights reserved.`;
    const actingAs = tokenRequestService.getTppActingAs();
    const isError = tokenRequestService.isTppRedirectError();
    const tppName = tokenRequestService.getTppProfile()?.tppName;
    const tppAlias = tokenRequestService.getTppAlias();

    return {
        footer,
        color: argbToRgba(colors['color-primary']),
        tppName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            tppAlias?.value,
        isError,
    };
};

const mapDispatchToProps = {
    redirectToTpp,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectTpp);
