import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import {
    LOADING_STEP,
    APP_APPROVAL,
    SPLASH_STEP,
    PISP_ENTER_ALIAS,
    PISP_APP_INSTALL,
    PISP_CAPTURE_CREDENTIALS,
    PISP_TRANSFER_OVERVIEW,
    PISP_CONSENT_BANK_FIRST,
    PISP_SELECT_BANK_BANK_FIRST,
    PISP_STANDING_ORDER_CONSENT_BANK_FIRST,
    PISP_BULK_TRANSFER_CONSENT_BANK_FIRST,
    PISP_PAYMENT_INIT_STATUS,
} from 'config/constants';
import PispAliasInput from 'components/App/Pisp/PispAliasInput';
import PispTransferOverview from 'components/App/Pisp/PispTransferOverview';
import PispAppNotifier from 'components/App/Pisp/PispAppNotifier';
import PispAppInstall from 'components/App/Pisp/PispAppInstall';
import Loading from 'components/App/Shared/Loading';
import SplashScreen from 'components/App/Shared/SplashScreen';
import Background from 'components/App/Background';
import ConsentBankFirstFlow from './ConsentBankFirstFlow';
import PispBankSelectorFirst from './PispBankSelectorFirst';
import CaptureCredentials from 'components/App/Shared/CaptureCredentials';
import StandingOrderConsentBankFirstFlow from './StandingOrderConsentBankFirstFlow';
import BulkTransferConsentBankFirstFlow from './BulkTransferConsentBankFirstFlow';
import PaymentInitStatus from './PaymentInitStatus';

class Pisp extends Component {
    constructor(props) {
        super(props);
    }
    static getStepComponent(currentStep) {
        const steps = {
            [PISP_ENTER_ALIAS]: <PispAliasInput />,
            [PISP_TRANSFER_OVERVIEW]: <PispTransferOverview />,
            [APP_APPROVAL]: <PispAppNotifier />,
            [PISP_APP_INSTALL]: <PispAppInstall />,
            [PISP_BULK_TRANSFER_CONSENT_BANK_FIRST]: (
                <BulkTransferConsentBankFirstFlow />
            ),
            [PISP_SELECT_BANK_BANK_FIRST]: <PispBankSelectorFirst />,
            [PISP_CONSENT_BANK_FIRST]: <ConsentBankFirstFlow />,
            [PISP_STANDING_ORDER_CONSENT_BANK_FIRST]: (
                <StandingOrderConsentBankFirstFlow />
            ),
            [PISP_CAPTURE_CREDENTIALS]: <CaptureCredentials />,
            [PISP_PAYMENT_INIT_STATUS]: <PaymentInitStatus />,
        };
        return steps[currentStep];
    }

    static getStepComponentWithoutBackground(currentStep) {
        const stepsWithoutBackground = {
            [LOADING_STEP]: <Loading />,
            [SPLASH_STEP]: <SplashScreen />,
        };
        return stepsWithoutBackground[currentStep];
    }

    render() {
        if (
            this.props.previousStep === SPLASH_STEP &&
            !this.props.fadeOutProp
        ) {
            return <SplashScreen stepToSet={this.props.currentStep} />;
        }

        const { currentStep } = this.props;
        const component = Pisp.getStepComponent(currentStep) ? (
            <Background> {Pisp.getStepComponent(currentStep)} </Background>
        ) : (
            Pisp.getStepComponentWithoutBackground(currentStep)
        );

        return <>{component}</>;
    }
}

Pisp.propTypes = {
    currentStep: PropTypes.string.isRequired,
    previousStep: PropTypes.string,
    fadeOutProp: PropTypes.bool,
};

const mapStateToProps = ({ sharedService }) => ({
    currentStep: sharedService.getCurrentStep(),
    previousStep: sharedService.getPreviousStep(),
    fadeOutProp: sharedService.getFadeOutProp(),
});

export default connect(mapStateToProps)(Pisp);
