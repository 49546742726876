import React from 'react';
import style from './RightChevron.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(style);

const RightChevron = ({ color = 'none', svgClassName }) => {
    return (
        <div className={cx('Right-chevron-container')}>
            <svg
                className={cx({
                    [svgClassName]: !!svgClassName,
                })}
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    fill={color}
                    className={cx('Right-chevron')}
                    d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                />
            </svg>
        </div>
    );
};

RightChevron.propTypes = {
    color: PropTypes.string,
    svgClassName: PropTypes.string,
};

export default RightChevron;
