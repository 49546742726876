import React, {Fragment, createElement} from 'react';
import PropTypes from 'prop-types';
import {
    KEY_ENTER,
    KEY_ESC,
    KEY_UP,
    KEY_DOWN,
    KEY_SPACEBAR,
    TRANSITION_OPENED,
    TRANSITION_IS_OPENING,
    TRANSITION_CLOSED,
    TRANSITION_IS_CLOSING,
} from './config/constants';
import EmailValidator from 'email-validator';

export const withStatic = values => Component => Object.assign(Component, values);

export const withValidator = ({type}) => Component => {
    class withValidatorWrapper extends React.PureComponent {
        static propTypes = {
            onValidityChange: PropTypes.func,
            customIsValid: PropTypes.bool,
            customSetValidation: PropTypes.func,
        }
        static defaultProps = {
            customSetValidation: null,
            customIsValid: null,
        }
        constructor(props) {
            super(props);
            this.state = {
                isValid: props.customIsValid || true,
            };
            this.setValidation = this.setValidation.bind(this);
            this.validate = this.validate.bind(this);
            this.getIsValid = this.getIsValid.bind(this);
            this._comp = React.createRef();
        }
        validate(value) {
            if (type === 'email') {
                if (EmailValidator.validate(value)) {
                    return this.setValidation(true);
                } else {
                    return this.setValidation(false, { extra: 'FAILED_VALIDATION_EMAIL' });
                }
            }
            return this.setValidation(true);
        }
        setValidation(_isValid, { extra } = {}) {
            const {onValidityChange, customIsValid, customSetValidation} = this.props;
            const {isValid: stateIsValid} = this.state;
            let isValid, setValidationFunc;
            if (customSetValidation) {
                isValid = customIsValid;
                setValidationFunc = customSetValidation;
            } else {
                isValid = stateIsValid;
                setValidationFunc = (v, {extra, doneCallback} = {}) => {
                    this.setState({
                        isValid: v,
                        extra,
                    }, doneCallback);
                };
            }
            if (isValid === _isValid) return;
            setValidationFunc(_isValid, {extra, doneCallback: () => {
                onValidityChange && onValidityChange(_isValid, { extra });
            }});
        }
        getIsValid() {
            const {customSetValidation, customIsValid} = this.props;
            const {isValid: stateIsValid} = this.state;

            if (customSetValidation) {
                return customIsValid;
            } else {
                return stateIsValid;
            }
        }
        render() {
            const isValid = this.getIsValid();
            return (<Component ref={this._comp} {...this.props} {...{
                inputType: type,
                validate: this.validate,
                isValid,
                status: isValid && 'idle' || 'error',
            }} />);
        }
    }
    return withValidatorWrapper;
};

export const withAutoFocus = Component => {
    class AutoFocusWrapper extends React.PureComponent {
        static propTypes = {
            autoFocus: PropTypes.bool,
        }
        constructor(props) {
            super(props);
            this._comp = React.createRef();
        }
        componentDidMount() {
            const {autoFocus} = this.props;
            if (this._comp.current && autoFocus) {
                this.focusTimeout = setTimeout(() => {
                    this._comp.current.focus();
                }, 100);
            }
        }
        componentWillUnmount() {
            if (this.focusTimeout) {
                clearTimeout(this.focusTimeout);
            }
        }
        render() {
            return <Component ref={this._comp} {...this.props} />;
        }
    }
    return AutoFocusWrapper;
};

export const withValue = Component => {
    class ValueWrapper extends React.PureComponent {
        static propTypes = {
            onChange: PropTypes.func,
            onKeyEnter: PropTypes.func,
            onKeyEsc: PropTypes.func,
            onKeyUp: PropTypes.func,
            onKeyDown: PropTypes.func,
            onKeySpacebar: PropTypes.func,
            customSetValue: PropTypes.func,
            useCustomData: PropTypes.bool,
            defaultValue: PropTypes.any,
            value: PropTypes.any,
            multiple: PropTypes.bool,
            idKey: PropTypes.string,
            allowDeselect: PropTypes.bool,
        };
        static defaultProps = {
            onChange: () => null,
            onKeyEnter: () => null,
            onKeyEsc: () => null,
            onKeyUp: () => null,
            onKeyDown: () => null,
            onKeySpacebar: () => null,
            customSetValue: () => null,
            useCustomData: false,
            idKey: 'id',
        };
        constructor(props) {
            super(props);
            this.state = {
                value: props.defaultValue || null,
            };
            this.setValue = this.setValue.bind(this);
            this.isSelected = this.isSelected.bind(this);
            this.setKeyDown = this.setKeyDown.bind(this);
            this._comp = React.createRef();
        }
        isSelected(data) {
            const {idKey} = this.props;
            const value = this.props.useCustomData ? this.props.value : this.state.value;
            if (!this.props.multiple) {
                return value === data;
            } else if (value instanceof Array) {
                return value.find(v => (v[idKey] || v) == (data[idKey] || data)) !== undefined;
            }
        }
        setValue(val) {
            let value, setValueFunc;
            const {useCustomData, customSetValue, multiple, onChange, idKey} = this.props;
            if (useCustomData) {
                value = this.props.value;
                setValueFunc = customSetValue;
            } else {
                value = this.state.value;
                setValueFunc = (v, {doneCallback} = {}) => {
                    this.setState({
                        value: v,
                    }, doneCallback);
                };
            }

            let final;
            if (!multiple) {
                final = val;
            } else {
                final = value instanceof Array
                    ? value
                    : [];
                if (val === null) return val;
                const data = val.rawData && val.rawData || val;
                if(this.isSelected(data)) {
                    final = final.filter(f => (f[idKey] || f) !== (data[idKey] || data));
                    final = final.length && final || null;
                } else {
                    final = final.concat([data]);
                }
            }

            setValueFunc(final, {doneCallback: () => {
                onChange(final);
            }});
        }
        setKeyDown(keyCode, e) {
            switch (keyCode) {
            case KEY_UP:
                this.props.onKeyUp(e);
                break;
            case KEY_DOWN:
                this.props.onKeyDown(e);
                break;
            case KEY_ENTER:
                this.props.onKeyEnter(e);
                break;
            case KEY_ESC:
                this.props.onKeyEsc(e);
                break;
            case KEY_SPACEBAR:
                this.props.onKeySpacebar(e);
                break;
            }
        }
        render() {
            const value = this.props.useCustomData ? this.props.value : this.state.value;
            return (
                <Component
                    ref={this._comp}
                    setValue={this.setValue}
                    setKeyDown={this.setKeyDown}
                    value={value}
                    idKey={this.props.idKey}
                    {...this.props} />
                );
        }
    }
    return ValueWrapper;
};

export const withFocusState = Component => {
    class FocusStateWrapper extends React.PureComponent {
        static propTypes = {
            onFocusChange: PropTypes.func,
            focused: PropTypes.bool,
            hover: PropTypes.bool,
        }
        constructor(props) {
            super(props);
            this.state = {
                focused: false,
                hover: false,
            };
            this.setFocus = this.setFocus.bind(this);
            this.setHover = this.setHover.bind(this);
            this._comp = React.createRef();
        }
        setFocus(val) {
            if (this.state.focused === val) return;
            const {onFocusChange} = this.props;
            this.setState({
                focused: val,
            }, () => {
                onFocusChange && onFocusChange(val);
            });
        }
        setHover(val) {
            if (this.state.hover === val) return;
            this.setState({
                hover: val,
            });
        }
        render() {
            return (<Component
                ref={this._comp}
                setFocus={this.setFocus}
                setHover={this.setHover}
                focused={this.props.focused || this.state.focused}
                hover={this.props.hover || this.state.hover}
                {...this.props} />);
        }
    }
    return FocusStateWrapper;
};

export const withTransition = ({
    cssPrefix = '',
    openTimeout = 350,
    closeTimeout = 250,
} = {}) => Component => {
    class TransitionWrapper extends React.PureComponent {
        static propTypes = {
            className: PropTypes.string,
            open: PropTypes.bool,
            onOpenComplete: PropTypes.func,
            onCloseComplete: PropTypes.func,
        }
        static defaultProps = {
            open: false,
            onOpenComplete: () => null,
            onCloseComplete: () => null,
        }
        constructor(props) {
            super(props);
            this.transitionTimeout = 0;
            this.executeTransition = this.executeTransition.bind(this);
            this.clearCurrentTransition = this.clearCurrentTransition.bind(this);
            this.state = {
                slideState: props.open && TRANSITION_OPENED || TRANSITION_CLOSED,
            };
        }
        clearCurrentTransition() {
            if (this.transitionTimeout) {
                clearTimeout(this.transitionTimeout);
                this.transitionTimeout = 0;
            }
        }
        executeTransition() {
            const {open, onOpenComplete, onCloseComplete} = this.props;
            const transitionState = open && TRANSITION_IS_OPENING || TRANSITION_IS_CLOSING;
            const newState = open && TRANSITION_OPENED || TRANSITION_CLOSED;
            this.clearCurrentTransition();
            this.setState({
                slideState: transitionState,
            });
            this.transitionTimeout = setTimeout(() => {
                this.setState({
                    slideState: newState,
                }, open && onOpenComplete || onCloseComplete);
            }, open && openTimeout || closeTimeout);
        }
        componentDidUpdate(prevProps) {
            if (prevProps.open !== this.props.open) {
                this.executeTransition();
            }
        }
        render() {
            const {slideState} = this.state;
            const prefix = cssPrefix && `${cssPrefix}--` || '';
            return (<Component
                ref={this._comp}
                cssTransition={{
                    [`${prefix}${TRANSITION_CLOSED}`]: slideState === TRANSITION_IS_OPENING,
                    [`${prefix}${TRANSITION_OPENED}`]: slideState === TRANSITION_IS_CLOSING,
                    [`${prefix}${slideState}`]: true,
                }}
                {...this.props} />);
        }
    }
    return TransitionWrapper;
};

export const createContainer = ({type, displayName, className: defaultClass = '', ...defaults}) => {
    const wrapper = ({children, className: classProp = '', ...props}) =>
        createElement(type || Fragment, {
            ...defaults,
            className: `${defaultClass} ${classProp}`,
            ...props,
        }, children);
    wrapper.propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };
    wrapper.displayName = displayName;
    return wrapper;
};
createContainer.propTypes = {type: PropTypes.node, displayName: PropTypes.string};
