import React, { useEffect, useLayoutEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Overlay from '@token-io/lib-web-components/src/Components/Overlay';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';
import style from './ContentOverlay.css';
import {
    PDF_VIEWER_PATH,
    GET_PDF_PAGE_COUNT_PATH,
    webAppUrl,
} from 'config/constants';
import CrossIcon from '../Icons/CrossIcon';
import { copyOnMobileClipboard } from 'util/index';
import { connect } from 'reducers';
import { FormattedMessage } from 'react-intl';
import DebounceRender from './DebounceRender.js';
import axios from 'axios';
import RightChevron from '../Icons/RightChevron';
import LeftChevron from '../Icons/LeftChevron';

const cx = classNames.bind(style);

const ContentOverlay = ({ link, handleClose, intl }) => {
    const root = document.getElementById('app');

    const [isCopied, setCopied] = useState(false);
    const [size, setSize] = useState(root.getBoundingClientRect());
    const [showTransition, setShowTransition] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const ref = createRef();

    const useWindowSize = () => {
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize(root.getBoundingClientRect());
            };
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    };

    const getPageCount = async () => {
        try {
            const location = getPdfLocation();
            const { data } = await axios.get(
                `${webAppUrl}${GET_PDF_PAGE_COUNT_PATH}?location=${location}`,
            );
            if (data.count) {
                setNumPages(data.count);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    useEffect(() => {
        getPageCount();
        setShowTransition(true); // Slide in transition
    }, []);

    const rect = useWindowSize();

    const handleCrossIconClick = () => {
        handleClose();
    };

    const getPdfLocation = () => {
        let queryPath = '#';
        try {
            const url = new URL(link);
            const regexp = /https?:\/\/(.*)/;
            queryPath = regexp.exec(url)[1];
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        return encodeURIComponent(queryPath);
    };

    const goToPrevPage = () => {
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    };

    const goToNextPage = () => {
        setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
    };

    return (
        <CSSTransition
            classNames="Content-overlay-transition"
            in={!!showTransition}
            timeout={400}>
            <Overlay
                overlayClass={cx('Content-overlay')}
                portalClass={cx('Content-portal')}
                rectPosition={{
                    ...rect,
                    left: rect.left,
                    width: rect.width,
                    top: rect.top,
                }}>
                <div
                    style={{ height: rect.height }}
                    className={cx('Content-pdf-container')}>
                    <>
                        <div className={cx('Content-control-panel')}>
                            <CrossIcon
                                className={cx('Content-cross-icon')}
                                onClick={handleCrossIconClick}
                            />
                            {numPages && (
                                <>
                                    <div
                                        className={cx(
                                            'Content-pdf-page-number',
                                        )}>
                                        <p>
                                            Page {pageNumber} of {numPages}
                                        </p>
                                    </div>
                                    <div
                                        className={cx(
                                            'Content-pdf-page-control',
                                        )}>
                                        <div
                                            className={cx('Left-button')}
                                            onClick={goToPrevPage}>
                                            <LeftChevron
                                                svgClassName={cx(
                                                    'Left-chevron-pdf',
                                                )}
                                                color={'#FFFFFF'}
                                            />
                                            <button
                                                className={cx(
                                                    'Content-pdf-button',
                                                )}
                                                onClick={goToPrevPage}>
                                                Prev
                                            </button>
                                        </div>
                                        <div
                                            className={cx('Right-button')}
                                            onClick={goToNextPage}>
                                            <button
                                                className={cx(
                                                    'Content-pdf-button',
                                                )}
                                                onClick={goToNextPage}>
                                                Next
                                            </button>
                                            <RightChevron
                                                svgClassName={cx(
                                                    'Right-chevron-pdf',
                                                )}
                                                color={'#FFFFFF'}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={cx('Content-pdf')}>
                            <DebounceRender pagenumber={pageNumber} link={link}>
                                <object
                                    id="pdf"
                                    type="application/pdf"
                                    data={`${PDF_VIEWER_PATH}?location=${getPdfLocation()}&page=${pageNumber}`}
                                    width="100%"
                                    height="100%">
                                    <TokenContainer
                                        className={cx(
                                            'Content-default-container',
                                        )}
                                        noMargin>
                                        <strong>
                                            <FormattedMessage
                                                id={
                                                    'common.contentOverlay.title1'
                                                }
                                                tagName={'p'}
                                            />
                                        </strong>
                                        <div
                                            className={cx(
                                                'Content-default-redirectUrl',
                                            )}
                                            onMouseUp={() => {
                                                copyOnMobileClipboard(
                                                    ref.current?.value,
                                                );
                                                setCopied(true);
                                            }}>
                                            <textarea
                                                ref={ref}
                                                className={cx(
                                                    'Content-default-copyArea',
                                                )}
                                                value={link}
                                                readOnly
                                            />
                                            {link}
                                        </div>
                                        <div
                                            className={cx({
                                                ['Content-default-copyStatus']: true,
                                                ['Content-default-copyStatus-visible']: isCopied,
                                            })}>
                                            <FormattedMessage
                                                id={
                                                    'common.contentOverlay.copiedMessage'
                                                }
                                            />
                                        </div>
                                        <Button
                                            onClick={() => {
                                                copyOnMobileClipboard(
                                                    ref.current?.value,
                                                );
                                                setCopied(true);
                                            }}
                                            text={intl.formatMessage({
                                                id:
                                                    'common.contentOverlay.copyUrl',
                                            })}
                                        />
                                    </TokenContainer>
                                </object>
                            </DebounceRender>
                        </div>
                    </>
                </div>
            </Overlay>
        </CSSTransition>
    );
};

ContentOverlay.propTypes = {
    link: PropTypes.string.isRequired,
    handleClose: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

ContentOverlay.defaultProps = {
    handleClose: () => {},
};

export default connect()(ContentOverlay);
