import React from 'react';
import PropTypes from 'prop-types';
import style from './InvalidIbanText.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

const InvalidIbanText = ({ className, text }) => (
    <span
        className={cx({
            Invalid_iban_warning_text: true,
            [className]: !!className,
        })}>
        {text}
    </span>
);

InvalidIbanText.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
};

export default InvalidIbanText;
