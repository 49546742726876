export const popupInitTimeout = 5000;
export const bindButtonTimeout = 20000; // 20s

export const REDIRECT = 'REDIRECT';
export const POPUP = 'POPUP';
export const IFRAME = 'IFRAME';

export const TOKEN_POPUP_NAME = 'TOKEN_FRAME';
export const TOKEN_WEB_APP_IFRAME_NAME = 'TOKEN_WEB_APP_IFRAME';

export const TOKEN_BANK_POPUP = 'TOKEN_BANK_POPUP';

export const POPUP_CLOSE = 'POPUP_CLOSE';
export const POPUP_SUCCESS = 'POPUP_SUCCESS';
export const POPUP_FAILURE = 'POPUP_FAILURE';
export const POPUP_VISIBILITY_CHANGE = 'POPUP_VISIBILITY_CHANGE';
export const GET_TOKEN_REQUEST_URL = 'GET_TOKEN_REQUEST_URL';
export const POPUP_SHOW = 'POPUP_SHOW';
export const POPUP_INIT = 'POPUP_INIT';
export const POPUP_ACTION = 'POPUP_ACTION';
export const POPUP_IS_LOADED = 'POPUP_IS_LOADED';
export const POPUP_KEEPALIVE = 'POPUP_KEEPALIVE';
export const POPUP_INITIAL_WIDTH = 450;
export const POPUP_INITIAL_HEIGHT = 789;

export const BUTTON_STYLE = 'BUTTON_STYLE';
export const BUTTON_CLICK = 'BUTTON_CLICK';
export const BUTTON_SET_ENABLED = 'BUTTON_SET_ENABLED';
export const BUTTON_BIND_PAY = 'BUTTON_BIND_PAY';
export const BUTTON_BIND_ACCESS = 'BUTTON_BIND_ACCESS';
export const BUTTON_SET_TYPE = 'BUTTON_SET_TYPE';
export const BUTTON_TYPE_POPUP = 'BUTTON_TYPE_POPUP';
export const BUTTON_TYPE_REDIRECT = 'BUTTON_TYPE_REDIRECT';
export const BUTTON_TYPE_IFRAME = 'BUTTON_TYPE_IFRAME';

export const WEB_APP_IFRAME_STYLE = 'WEB_APP_IFRAME_STYLE';
export const WEB_APP_IFRAME_SHOW = 'WEB_APP_IFRAME_SHOW';
export const WEB_APP_IFRAME_IS_LOADED = 'WEB_APP_IFRAME_IS_LOADED';
export const WEB_APP_IFRAME_CLOSE = 'WEB_APP_IFRAME_CLOSE';
export const WEB_APP_IFRAME_VISIBILITY_CHANGE =
    'WEB_APP_IFRAME_VISIBILITY_CHANGE';
export const WEB_APP_IFRAME_SUCCESS = 'WEB_APP_IFRAME_SUCCESS';
export const WEB_APP_IFRAME_FAILURE = 'WEB_APP_IFRAME_FAILURE';
export const WEB_APP_IFRAME_IS_OPEN = 'WEB_APP_IFRAME_IS_OPEN';

export const TOKEN_BANK_POPUP_CLOSE = 'TOKEN_BANK_POPUP_CLOSE';
export const TOKEN_BANK_POPUP_IS_OPEN = 'TOKEN_BANK_POPUP_IS_OPEN';

export const OVERLAY_INIT = 'OVERLAY_INIT';
export const OVERLAY_HIDE = 'OVERLAY_HIDE';
export const OVERLAY_IS_LOADED = 'OVERLAY_IS_LOADED';
export const OVERLAY_CLOSE_ACTION = 'OVERLAY_CLOSE_ACTION';

// Resource types for access token;
export const RESOURCE_TYPE_ALL_ADDRESSES = 1;
export const RESOURCE_TYPE_ALL_ACCOUNTS = 2;
export const RESOURCE_TYPE_ALL_TRANSACTIONS = 3;
export const RESOURCE_TYPE_ALL_BALANCES = 4;
export const RESOURCE_TYPE_ADDRESS = 5;
export const RESOURCE_TYPE_ACCOUNT = 6;
export const RESOURCE_TYPE_TRANSACTIONS = 7;
export const RESOURCE_TYPE_BALANCE = 8;

// User agent
export const FIREFOX = 'Firefox';

let browserEnv = {};

// Allow configuration of environment variables through browser environment
if (window !== undefined && window.BROWSER_ENV !== undefined) {
    browserEnv = window.BROWSER_ENV;
}

export const webAppUrl = browserEnv.webAppUrl;
