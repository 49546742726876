import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './CrossIcon.css';
import CrossIconSvg from './CrossIconSvg';

const cx = classNames.bind(style);

/**
 * Cross icon HOC.
 * @param height circular height for Cross icon.
 * @param width circular width for Cross icon.
 * @param color color of the Cross icon.
 * @param scale factor to manipulate dimensions of Cross icon svg w.r.t background circle.
 * @param className css class names for Cross icon container.
 */
const HomeIcon = ({ height, width, className, color, scale, ...props }) => {
    return (
        <div
            {...props}
            className={cx({
                'TokenCross-icon-container': true,
                [className]: !!className,
            })}>
            <CrossIconSvg
                svgHeight={height}
                svgWidth={width}
                svgColor={color}
                scale={scale}
            />
        </div>
    );
};

HomeIcon.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    scale: PropTypes.number,
    className: PropTypes.string,
};

export default HomeIcon;
