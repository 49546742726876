import { regexp } from 'config/constants';

const addRefundTextIfNotPresent = ({
    customConsentText,
    defaultRefundText,
}) => {
    const isRefundTextPresent = regexp.RETURN_REFUND_CLASS.test(
        customConsentText,
    );
    if (!isRefundTextPresent) {
        customConsentText += defaultRefundText;
    }
    return customConsentText;
};

export default addRefundTextIfNotPresent;
