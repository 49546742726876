import ConnectedIntlProvider from './components/ConnectedIntlProvider';
import ErrorBoundary from './components/ErrorBoundary';
import React from 'react';
import {applyMiddleware, compose, createStore} from 'redux';
import {
    createErrorMiddleware,
    createHistoryMiddleware,
    createSentryMiddleware,
    createThunkMiddleware,
    createTracerMiddleware,
} from './middlewares';
import {init} from '@sentry/browser';
import {Provider} from 'react-redux';
import {render} from 'react-dom';
import {throwError} from './actions';
import {TracerProvider} from './tracerProvider';
import {context, ROOT_CONTEXT, setSpan, setSpanContext, TraceFlags} from '@opentelemetry/api';
import TracerContext from './tracerContext';
import axios from 'axios';
import {getDashboardUrlByEnv, getUserParams, sessionStorageWrapper} from './util';
import {addLocaleData} from 'react-intl';

export class App {
    constructor(options = {}) {
        this.pathValue = window.location.pathname.split('/')[1];
        this.options = options;
        this._setUpSentry();
        this._addLocaleData();
        this.tracer = {};
        this.state = {};
        this.traceId = '';
        this.spanId = '';
        this.sessionId = '';

        if (this.pathValue && this.options?.tracerConfig?.enabled) {
            // start new span if new request id
            if (window.location.pathname.includes('/app/request-token')) {
                sessionStorageWrapper.remove('traceId');
                sessionStorageWrapper.remove('spanId');
                sessionStorageWrapper.remove('sessionId');
                this.sessionId = window.location.pathname.split('/')[3];
            } else {
                this.sessionId = sessionStorageWrapper.get('sessionId');
            }
            this.traceId = sessionStorageWrapper.get('traceId');
            this.spanId = sessionStorageWrapper.get('spanId');

            this.tracer = TracerProvider.getTracer(this.options.tracerConfig.lightStepAccessToken);
            let initSpan;
            if (this.traceId && this.spanId) {
                initSpan = this.tracer.startSpan(`web-app-session [${window.location.pathname}]`, {},
                    setSpanContext(ROOT_CONTEXT, {
                        traceId: this.traceId,
                        spanId: this.spanId,
                        traceFlags: TraceFlags.SAMPLED,
                    }));
            } else {
                initSpan = this.tracer.startSpan(`web-app-session init [${window.location.pathname}]`, {
                    attributes: {
                        'session-id': this.sessionId,
                    },
                });
                this.traceId = initSpan.context().traceId;
                this.spanId = initSpan.context().spanId;
                sessionStorageWrapper.set('traceId', this.traceId);
                sessionStorageWrapper.set('spanId', this.spanId);
                sessionStorageWrapper.set('sessionId', this.sessionId);
            }
            sessionStorageWrapper.set('env', this.options?.env);
            this.state = {initSpan: initSpan};
        }
    }

    _addLocaleData() {
        this.options.i18n?.locales?.forEach(locale =>
            addLocaleData(require(`react-intl/locale-data/${locale}`)));
    }

    /**
     * Registers a mapping between actions and paths.
     * Action will be dispatched when the URL path matches the store path.
     */
    handle(path, action, options = {}) {
        if (!this.paths) this.paths = [];
        this.paths.push({path, action, options});
        return this;
    }

    /**
     * Registers a default action in case no paths match.
     */
    handleDefault(action) {
        this.defaultAction = action;
        return this;
    }

    /**
     * Registers a mapping between routes and components.
     * The mapping is passed into the root component.
     */
    register(route, component) {
        if (!this.routes) this.routes = {};
        this.routes[route] = component;
        return this;
    }

    /**
     * Creates the Redux store,
     * dispatches the proper action registered by handle(),
     * and renders the root component.
     */
    render() {
        if (this?.pathValue && this.options?.tracerConfig?.enabled) {
            context.with(setSpan(context.active(), this.state.initSpan), async () => {
                this._renderApp();
            });
            this.state.initSpan.end();
            const tokenEnv = sessionStorageWrapper.get('env');
            if (tokenEnv === 'prd' || tokenEnv === 'sandbox') {
                const userParams = getUserParams();
                try {
                    axios.post(`${getDashboardUrlByEnv()}/query-lightstep?sessionId=${sessionStorageWrapper.get('sessionId')}${userParams}`);
                } catch (e) {
                    console.log(e); // eslint-disable-line no-console
                }
            }
        } else {
            this._renderApp();
        }
    }

    _renderApp() {
        this._handlePath();
        this._createStore();
        this._executePath();
        const App = this.options.root;
        const tracer = this.tracer;
        const tracingEnabled = this.options?.tracerConfig?.enabled;
        const traceId = this.traceId;
        const spanId = this.spanId;
        const sessionId = this.sessionId;
        return render(
            <TracerContext.Provider
                value={{enabled: tracingEnabled, tracer, traceId, spanId, sessionId}}>
                <Provider store={this.store}>
                    <ConnectedIntlProvider options={this.options.i18n}>
                        <ErrorBoundary>
                            <App routes={this.routes}/>
                        </ErrorBoundary>
                    </ConnectedIntlProvider>
                </Provider>
            </TracerContext.Provider>,
            document.getElementById(this.options.rootId),
        );
    }

    _setUpSentry() {
        if (!this.options.sentry) return;
        init({
            dsn: this.options.sentry.dsn,
            environment: this.options.sentry.env,
            release: this.options.sentry.release,
            beforeSend: event => this.options.sentry.beforeSend?.(event) || event,
        });
    }

    _createStore() {
        if (!this.options.store) return;
        let composeEnhancers = compose;
        if (this.options.store.devToolsEnabled) {
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        }
        const persistedState = this._getPersistedState?.();
        this.store = createStore(
            this.options.store.reducer,
            persistedState || this.options.store.initialState || undefined,
            composeEnhancers(applyMiddleware(...[
                createThunkMiddleware(this.options.store.getServices),
                createErrorMiddleware(this.options.errorHandler),
                this.options.history &&
                createHistoryMiddleware(this.options.history.stateSanitizer),
                this.options.sentry &&
                createSentryMiddleware(this.options.sentry.stateRedactor),
                ...(this.options.store.middlewares || []),
                this.options?.tracerConfig?.enabled && this.pathValue &&
                createTracerMiddleware(this.options?.tracerConfig?.stateRedactor, this.tracer),
            ].filter(Boolean))),
        );
        this.options.store.afterCreate?.(this.store);
        window.onpopstate = e => this.options.history?.onPopState(e, this.store);
        this.store.subscribe(() => this._saveState());
    }

    _handlePath() {
        if (history.state || !this.paths) return;
        for (const {path, action, options} of this.paths) {
            if (path.test(window.location.pathname)) {
                if (options.restoreState) {
                    this._getPersistedState = () => {
                        const state = this._retrieve();
                        return options.beforeLoad?.(state) || state;
                    };
                }
                this.action = action;
                return;
            }
        }
        this.action = this.defaultAction;
    }

    async _executePath() {
        if (!this.action) return;
        try {
            await this.store.dispatch(this.action());
        } catch (e) {
            this.store.dispatch(throwError(e));
        }
    }

    _saveState() {
        try {
            let state = this.store.getState();
            state = this.options.store.beforeSave?.(state) || state;
            localStorage.setItem('reduxState', JSON.stringify(state));
        } catch (e) {
            console.warn(e); // eslint-disable-line no-console
        }
    }

    _retrieve() {
        try {
            return JSON.parse(localStorage.getItem('reduxState')) || undefined;
        } catch (e) {
            return undefined;
        }
    }
}
