import React, { useEffect, useState } from 'react';
import ContentOverlay from './ContentOverlay';
import PropTypes from 'prop-types';

const HrefToPopup = ({
    contentRef,
    defaultUrls,
    children,
    fallbackToOverlay,
}) => {
    const [displayOverlay, setDisplayOverlay] = useState(false);
    const [contentLink, setContentLink] = useState(null);

    const openTab = url => {
        const newWindow = window.open(
            `${url}`,
            '_blank',
            'width:500px, height:700px;',
        );
        if (!newWindow && fallbackToOverlay) {
            setContentLink(url);
            setDisplayOverlay(true);
        }
    };

    const handleOverlayClose = () => {
        setDisplayOverlay(false);
    };

    const manipulateAnchorTag = node => {
        if (!node || node.tagName?.toLowerCase() !== 'a') return;

        const href = node.getAttribute('href');
        const tagId = node.getAttribute('id');
        const tabURL =
            href || defaultUrls[tagId] || window.location.origin + '/terms';

        node.addEventListener('click', () => openTab(tabURL));
        node.setAttribute('data-url', tabURL);
        node.removeAttribute('href');
        node.setAttribute('tabIndex', '0');
    };

    const manipulateAnchorTags = node => {
        let i = 0;
        if (node) manipulateAnchorTag(node);
        while (i < node?.childNodes?.length) {
            manipulateAnchorTags(node.childNodes[i]);
            ++i;
        }
    };

    useEffect(() => {
        manipulateAnchorTags(contentRef?.current);
    }, []);

    return (
        <>
            {children}
            {displayOverlay && (
                <ContentOverlay
                    link={contentLink}
                    handleClose={handleOverlayClose}
                />
            )}
        </>
    );
};

HrefToPopup.propTypes = {
    contentRef: PropTypes.any.isRequired,
    defaultUrls: PropTypes.object,
    children: PropTypes.any.isRequired,
    fallbackToOverlay: PropTypes.bool,
};

HrefToPopup.defaultProps = {
    defaultUrls: {},
};

export default HrefToPopup;
