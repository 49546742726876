import React from 'react';
import PropTypes from 'prop-types';
import style from './DefaultErrorPage.css';
import classNames from 'classnames/bind';
import iconError from 'assets/icon-error.svg';
import { FormattedMessage, intlShape } from 'react-intl';

const cx = classNames.bind(style);

const BankOutageError = ({ bank }) => {
    return (
        <div className={cx('ErrorPage-body')}>
            <div className={cx('ErrorPage-wrapper')}>
                <div className={cx('ErrorPage-title')}>
                    <img src={iconError} />
                    <FormattedMessage
                        id={'common.bankOutageErrorPage.title'}
                        tagName={'p'}
                        values={{
                            BANK_NAME: bank,
                        }}
                    />
                </div>
                <div className={cx('ErrorPage-subtitle')}>
                    <FormattedMessage
                        id={'common.bankOutageErrorPage.generic'}
                        tagName={'p'}
                    />
                    <FormattedMessage
                        id={'common.bankOutageErrorPage.generic2'}
                        tagName={'p'}
                    />
                </div>
            </div>
        </div>
    );
};

BankOutageError.propTypes = {
    bank: PropTypes.string.isRequired,
};

export default BankOutageError;
