import React from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';

const SuccessIcon = ({ className, title }) => (
    <FaCheckCircle title={title} className={className} />
);

SuccessIcon.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default SuccessIcon;
