import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './PolishApiMandatoryFields.css';
import classNames from 'classnames/bind';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import { formatAsKeyValuePair } from 'util/index';
import { POLISH_API_DELIVER_MODES } from 'config/constants';
import { mandatoryFieldsConfig } from 'config/mandatoryFields';

const cx = classNames.bind(style);

class PolishApiMandatoryFields extends Component {
    state = {
        dropDown: false,
    };

    handleOptionChange = selectedOption => {
        this.props.handleMandatoryFields({
            polishApiMetadata: {
                ...this.props.state?.polishApiMetadata,
                deliveryMode:
                    Object.keys(POLISH_API_DELIVER_MODES)[selectedOption?.id] ||
                    undefined,
            },
        });
        this.setState({
            option: selectedOption,
            dropDown: false,
        });
    };

    setDropDownState = state => {
        this.setState({ dropDown: state });
    };

    componentDidMount() {
        const polishApiMetadata = this.props.transferMetadata;
        this.props.handleMandatoryFields({ polishApiMetadata });
    }

    render() {
        const {
            requiredMandatoryFields,
            transferMetadata,
            intl,
            type,
        } = this.props;
        const config = mandatoryFieldsConfig?.[type];
        const displayOptions = formatAsKeyValuePair(
            Object.values(POLISH_API_DELIVER_MODES),
        );

        return (
            <>
                {requiredMandatoryFields?.map(field => {
                    return (
                        field === config.polishApi.deliveryMode &&
                        !transferMetadata?.deliveryMode && (
                            <div
                                className={cx('Consent-data-row')}
                                key={'polish_delivery_mode'}>
                                <div
                                    className={cx({
                                        'Consent-data-row-title': true,
                                        'Consent-data-row-title--fixSize': true,
                                    })}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={
                                            'pisp.consent.mandatory.deliveryMode.label'
                                        }
                                    />
                                </div>
                                <div
                                    className={cx({
                                        'Consent-data-row-detail': true,
                                        'Consent-data-row-detail--fixSize': true,
                                    })}>
                                    <StaticDataProvider
                                        useCustomData
                                        customData={displayOptions}
                                        idKey={'id'}
                                        titleKey={'id'}
                                        dataKey={'value'}
                                        customSetValue={async (
                                            value,
                                            { doneCallback },
                                        ) => {
                                            await this.handleOptionChange(
                                                value,
                                            );
                                            doneCallback();
                                        }}
                                        value={
                                            this.state.option === undefined
                                                ? null
                                                : this.state.option
                                        }
                                        withPointer>
                                        <Dropdown
                                            className={
                                                'Consent-data-row-detail-options'
                                            }
                                            listClass={
                                                'Consent-data-row-detail-options-dropdown'
                                            }
                                            overlayClass={
                                                'Consent-data-row-detail-options-overlay'
                                            }
                                            rowClass={
                                                'Consent-data-row-detail-options-item'
                                            }
                                            dropDownState={this.state.dropDown}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    'pisp.consent.mandatory.deliveryMode.placeholder',
                                            })}
                                            scrollClass={cx(
                                                'Consent-data-row-detail-options-scroll',
                                            )}
                                            setDropDownState={
                                                this.setDropDownState
                                            }
                                            allowDeselect
                                        />
                                    </StaticDataProvider>
                                </div>
                            </div>
                        )
                    );
                })}
            </>
        );
    }
}

PolishApiMandatoryFields.propTypes = {
    requiredMandatoryFields: PropTypes.array,
    transferMetadata: PropTypes.object,
    intl: intlShape.isRequired,
    handleMandatoryFields: PropTypes.func,
    state: PropTypes.object,
    type: PropTypes.string,
};

PolishApiMandatoryFields.defaultProps = {
    type: 'transfer',
};

export default injectIntl(PolishApiMandatoryFields);
