import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './HSBCErrorPage.css';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import { errorMessageKeys } from 'config/constants';
import { FormattedMessage } from 'react-intl';

const cx = classNames.bind(style);

const getErrorMessageKey = errorMessage => {
    let errorMessageKey = errorMessageKeys['DEFAULT'];
    Object.keys(errorMessageKeys).forEach(function (key) {
        if (errorMessage.toUpperCase().includes(key)) {
            errorMessageKey = errorMessageKeys[key];
        }
    });
    return errorMessageKey;
};

const HSBCErrorPage = ({ setDisplayFooterShadow, isTokenError, error }) => {
    useEffect(() => {
        setDisplayFooterShadow(false);
    }, []);

    const hsbcErrorMessageId = isTokenError
        ? 'common.hsbc.errorPage.TOKEN_ERROR'
        : `common.hsbc.errorPage.${getErrorMessageKey(error.message)}`;

    return (
        <TokenContainer noScrollable>
            <div className={cx('CustomErrorPage-title-hsbc')}>
                <FormattedMessage
                    tagName={'span'}
                    id={'common.hsbc.errorPage.title'}
                />
            </div>
            <div className={cx('CustomErrorPage-sub-title-hsbc')}>
                <FormattedMessage tagName={'span'} id={hsbcErrorMessageId} />
            </div>
        </TokenContainer>
    );
};

HSBCErrorPage.propTypes = {
    isTokenError: PropTypes.bool,
    error: PropTypes.object,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = ({ sharedService }) => {
    return {
        isTokenError: sharedService.getErrorSource(),
        error: sharedService.getError(),
    };
};

export default connect(mapStateToProps)(HSBCErrorPage);
