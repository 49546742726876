const AccessTokenHelper = {
    formatResources: async (resourceList, member, bankId) => {
        const accounts = await member.getAccounts();
        const filtered = bankId
            ? accounts.filter(acc => acc.bankId() === bankId)
            : accounts;
        const accountIds = filtered.map(acc => acc.id());
        if (typeof resourceList[0] === 'string') {
            const unflattened = resourceList.map(res => {
                if (res === 'ACCOUNTS' || res['allAccounts']) {
                    return AccessTokenHelper.unpackResources(
                        accountIds,
                        'account',
                    );
                } else if (res === 'BALANCES' || res['allBalances']) {
                    return AccessTokenHelper.unpackResources(
                        accountIds,
                        'balance',
                    );
                } else if (res === 'TRANSACTIONS' || res['allTransactions']) {
                    return AccessTokenHelper.unpackResources(
                        accountIds,
                        'transactions',
                    );
                } else if (res === 'TRANSFER_DESTINATIONS') {
                    return AccessTokenHelper.unpackResources(
                        accountIds,
                        'transferDestinations',
                    );
                } else if (res === 'FUNDS_CONFIRMATIONS') {
                    return AccessTokenHelper.unpackResources(
                        accountIds,
                        'fundsConfirmation',
                    );
                } else if (res === 'STANDING_ORDERS') {
                    return AccessTokenHelper.unpackResources(
                        accountIds,
                        'standingOrders',
                    );
                }
                return {};
            });
            return [].concat(...unflattened);
        } else {
            if (resourceList[0].type === 'ACCOUNT_FUNDS_CONFIRMATION') {
                return [
                    {
                        fundsConfirmation: {
                            accountId: accountIds[0],
                        },
                    },
                ];
            }
        }
    },
    unpackResources: (accountIds, type) => {
        return accountIds.map(accountId => ({ [type]: { accountId } }));
    },
    setResources: (builder, resourceList) => {
        resourceList.forEach(res => {
            if (res.account) {
                builder.forAccount(res.account.accountId);
            } else if (res.balance) {
                builder.forAccountBalance(res.balance.accountId);
            } else if (res.transactions) {
                builder.forAccountTransactions(res.transactions.accountId);
            } else if (res.transferDestinations) {
                builder.forAccountTransferDestinations(
                    res.transferDestinations.accountId,
                );
            } else if (res.fundsConfirmation) {
                builder.forAccountFundsConfirmation(
                    res.fundsConfirmation.accountId,
                );
            } else if (res.standingOrders) {
                builder.forAccountStandingOrders(res.standingOrders.accountId);
            }
        });
    },
};

export default AccessTokenHelper;
