import UAParser from 'ua-parser-js';

// Simple user agent validators
export const isInternetExplorer = () => /Trident\/|MSIE/.test(window.navigator.userAgent);
export const isMSEdge = () => /Edge/.test(window.navigator.userAgent);
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

// String utils
export const stringEndsWith = (str, suffix) => {
    if (((str === null) || (str === '')) || ((suffix === null) || (suffix === ''))) {
        return false;
    }
    str = str.toString();
    suffix = suffix.toString();
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
};

export const normalizeString = string => {
    return string && string.toUpperCase().replace(/ /g, '');
};

export const defaultSearch = searchTerm => {
    const startsWithRegex = new RegExp(`^${searchTerm}`, 'i');
    const includesRegex = new RegExp(searchTerm, 'i');
    const startsWith = ({data, key, title}) =>
        startsWithRegex.test(data) || startsWithRegex.test(title) ||
        startsWithRegex.test(key);
    const includes = ({data, key, title}) =>
        includesRegex.test(data) || includesRegex.test(title) ||
        includesRegex.test(key);
    return {startsWith, includes};
};

// Data type validators
export const isFunction = v => (typeof v === 'function');
export const isUndef = v => (v == undefined);
export const isNull = v => (v === null);
export const isObject = v => (typeof v === 'object' && !(v instanceof Array) && !isNull(v));
export const isNumber = v => (typeof v === 'number');

// Misc
export const generateNonce = () => {
    return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
};

export const compose = (...fns) => x => fns.reduce((v, f) => f(v), x);

const keyDefaults = {
    fetchPagingKey: 'paging',
    fetchDataKey: 'data',
    idKey: 'id',
    titleKey: 'title',
    dataKey: 'data',
    extraKey: 'extra',
};

export const formatDataList = (source, {
    fetchPagingKey, fetchDataKey, idKey, titleKey, dataKey, extraKey,
    customFormat,
} = keyDefaults) => {
    const paging = source && source[fetchPagingKey] ||
        {page: 1, perPage: 25, pageCount: 1};
    const formatFunction = customFormat || (item => item);
    const data = (fetchDataKey && source[fetchDataKey] || source).map(rawData => {
        const formattedData = formatFunction(rawData);
        return {
            key: formattedData[idKey],
            title: formattedData[titleKey],
            data: formattedData[dataKey],
            extra: formattedData[extraKey],
            rawData,
        };
    });
    return {
        data,
        paging,
    };
};

export const getUserAgent = () => {
    const parser = new UAParser();
    const result = parser.getResult();
    const os = {
        name: result.os.name,
        version: result.os.version,
    };
    const browser = {
        name: result.browser.name,
        version: result.browser.version,
    };
    return {
        os,
        browser,
    };
};

// Force redraw hack for safari
export const forceRedrawForSafari = () => {
    const ua = getUserAgent();
    if (ua.browser.name !== 'Safari') return;
    setTimeout(() => {
        const toDraw = document.getElementById('app');
        toDraw.style.display = 'none';
        setTimeout(function() {
            toDraw.style.display = '';
        }, 1);
    }, 10);
};

export const getAriaLabelForList = (listClass, item) => {
    if (listClass.includes('BankSelector-country-dropdown') && item.data) {
        return item.data.split(' ').slice(1).join(' ');
    } else if (listClass.includes('BankSelector-banks') && item.data) {
        return item.title + item.data;
    } else if (item.data) {
        return item.data;
    } else {
        return item.title;
    }
};
