import { hot } from 'react-hot-loader/root';
import { App, throwError, TokenException } from '@token-io/lib-web-app';
import React from 'react';
import './main.css';
import Root from 'components/App';
import reducer, {
    getRedactedState,
    getSanitizedState,
    getServices,
} from 'reducers';
import {
    ROUTE_AISP,
    ROUTE_BANK_LOADING,
    ROUTE_BANK_REDIRECT,
    ROUTE_REDIRECT_TO_TPP,
    TPP_LOADING,
    ROUTE_REDIRECT_OPTIONS,
    ROUTE_CSRF_MISMATCH,
    ROUTE_CONFIG,
    ROUTE_ERROR,
    ROUTE_LOADING,
    ROUTE_SPLASH,
    ROUTE_PISP,
    ROUTE_VRP,
    SENTRY_DSN,
    TOKEN_ENV,
    TOKEN_BANK_POPUP,
    TOKEN_BANK_POPUP_CLOSE,
    defaultLocale,
    gitSha,
    languages,
    sendErrorReports,
    sendTraceSpans,
    hotModule,
} from 'config/constants';
import {
    EXCEPTION_UNSUPPORTED_MESSAGING_API,
    EXCEPTION_INVALID_PATH,
} from 'config/exceptions';
import TokenCommunicator from 'services/TokenCommunicator';
import {
    initiateBankCallbackFlow,
    initiateRedirectFlow,
    initiatePaymentInitStatus,
    handlePopState,
    validateContext,
    allowUserToGoBack,
    blockUserToGoBack,
    initiateCredentialsFlow,
} from 'actions/shared';
import { validateBrowserSession } from 'actions/helpers';
import { handleInitiation, initiateBankCallbackFlowV2 } from 'actions/vrp';
import { changeRoute } from 'actions/shared/route';
import WebAppConfig from 'components/WebAppConfig';
import Aisp from 'components/App/Aisp';
import Pisp from 'components/App/Pisp';
import Vrp from 'components/App/Vrp';
import ErrorPage from 'components/App/Shared/ErrorPage';
import Loading from 'components/App/Shared/Loading';
import RedirectTpp from 'components/App/Shared/RedirectTpp';
import RedirectOptions from 'components/App/Shared/RedirectOptions';
import BankSpinner from 'components/App/Shared/BankSpinner';
import RedirectTppSpinner from 'components/App/Shared/RedirectTppSpinner';
import BankRedirect from 'components/App/Shared/BankRedirect';
import CsrfMismatch from 'components/App/Shared/CsrfMismatch';
import SplashScreen from 'components/App/Shared/SplashScreen';
import {
    addCustomCss,
    syncLocalStorageVersion,
    isPopup,
    isContextIframe,
    getInitialReduxState,
    buildPathReqEx,
    localStorageWrapper,
    sessionStorageWrapper,
    setHtmlLanguageAttribute,
} from 'util/index';
import { enableIframePassthrough } from 'actions/shared/api';
import {
    lightStepAccessToken,
    ROUTE_TERMS_CONDITIONS,
} from './config/constants';
import 'assets/favicon.png';
import Terms from './components/App/Terms';

enableIframePassthrough();

const storeCallback = async store => {
    module.hot?.accept('reducers', () => store.replaceReducer(reducer));
    try {
        const isCtxPopup = isPopup();
        const isCtxIframe = isContextIframe();

        if (isCtxPopup || isCtxIframe) {
            const path = window?.location?.pathname?.split('/');
            if (isCtxPopup) {
                TokenCommunicator.setup(store.dispatch);
                if (!path[1]) {
                    TokenCommunicator.dispatchMessage('POPUP_IS_OPEN');
                }
            } else if (isCtxIframe) {
                TokenCommunicator.setupIframe(store.dispatch);
                if (!path[1]) {
                    TokenCommunicator.dispatchMessage('WEB_APP_IFRAME_IS_OPEN');
                }
            }
        } else {
            // Redirect
            TokenCommunicator.setupBankPopup(store.dispatch);
        }
        await validateBrowserSession(store);
    } catch (e) {
        store.dispatch(
            throwError(
                new TokenException(
                    EXCEPTION_UNSUPPORTED_MESSAGING_API,
                    'Messaging API is not supported',
                ),
            ),
        );
    }
};

const RootApp = (hotModule && hot(Root)) || Root;

const options = {
    root: RootApp,
    rootId: 'app',
    store: {
        reducer,
        getServices,
        devToolsEnabled: TOKEN_ENV !== 'prd',
        initialState: getInitialReduxState(),
        afterCreate: storeCallback,
        beforeSave: getSanitizedState,
    },
    i18n: {
        defaultLocale,
        locales: languages.map(l => l.code),
        getLocale: state => state.shared.locale,
        getLocalesData: () => {
            return languages.reduce((p, n) => {
                const locale = n.code?.slice(0, 2);
                return {
                    ...p,
                    [locale]: require(`./config/locales/${locale}.json`),
                };
            }, {});
        },
        getCustomTranslations: state =>
            state.tokenRequest?.tpp?.configurations?.translations,
    },
    history: {
        stateSanitizer: getSanitizedState,
        onPopState: (e, store) => {
            if (sessionStorageWrapper.get('blockUserToGoBack') === true) {
                return false;
            } else {
                store.dispatch(handlePopState(e.state));
            }
        },
    },
    sentry: sendErrorReports && {
        dsn: SENTRY_DSN,
        env: TOKEN_ENV,
        release: gitSha,
        stateRedactor: getRedactedState,
    },
    tracerConfig: {
        enabled: sendTraceSpans,
        stateRedactor: getRedactedState,
        lightStepAccessToken: lightStepAccessToken,
    },
    env: TOKEN_ENV,
};

const initApp = async () => {
    try {
        syncLocalStorageVersion();
        const state = localStorageWrapper.get('reduxState');
        if (state) {
            const tokenRequestId = state.tokenRequest?.request.id;
            const vrpConsentId = state.vrp?.request.vrpId;
            if (state.tokenRequest?.tpp?.configurations?.colors) {
                if (tokenRequestId) {
                    await addCustomCss(tokenRequestId);
                } else if (vrpConsentId) {
                    await addCustomCss(vrpConsentId, 'VRP_CONSENT');
                }
            }

            // Sets the language and translation attributes of the <html> tag.
            setHtmlLanguageAttribute(state.shared.locale);
            document.documentElement.setAttribute('translate', 'no');
        }
    } catch (e) {
        console.error(e); // eslint-disable-line
    }

    const app = new App(options);
    app.handleDefault(() =>
        throwError(
            new TokenException(EXCEPTION_INVALID_PATH, 'Path is invalid'),
        ),
    )
        .handle(/terms/, () => changeRoute(ROUTE_TERMS_CONDITIONS), {
            restoreState: true,
            beforeLoad: state => {
                return state;
            },
        })
        .handle(buildPathReqEx('/(app/?)?$'), validateContext)
        .handle(buildPathReqEx('/app/config$'), () => changeRoute(ROUTE_CONFIG))
        .handle(buildPathReqEx('/app/request-token'), () =>
            initiateRedirectFlow(),
        )
        .handle(buildPathReqEx('/app/initiation'), () => handleInitiation())
        .handle(
            buildPathReqEx('/app/v2/callback'),
            initiateBankCallbackFlowV2,
            {
                restoreState: true,
                beforeLoad: state => {
                    blockUserToGoBack();
                    if (state) {
                        state.shared.route.route.current.id = 'ROUTE_LOADING';
                    }
                    return state;
                },
            },
        )
        .handle(
            buildPathReqEx('/app/pisp/confirmation$'),
            initiatePaymentInitStatus,
            {
                restoreState: true,
                beforeLoad: state => {
                    blockUserToGoBack();
                    if (state) {
                        state.shared.route.route.current.id = 'ROUTE_LOADING';
                    }
                    return state;
                },
            },
        )
        .handle(
            buildPathReqEx('/app/auth/callback$'),
            initiateBankCallbackFlow,
            {
                restoreState: true,
                beforeLoad: state => {
                    blockUserToGoBack();
                    if (window.name === TOKEN_BANK_POPUP) {
                        TokenCommunicator.setup();
                        TokenCommunicator.dispatchMessage(
                            TOKEN_BANK_POPUP_CLOSE,
                            window.location.href,
                        );
                        setTimeout(window.close, 100);
                    }
                    if (state) {
                        state.shared.route.route.current.id = 'ROUTE_LOADING';
                    }
                    return state;
                },
            },
        )
        .handle(
            buildPathReqEx('/app/auth/credentials$'),
            initiateCredentialsFlow,
            {
                restoreState: false,
                beforeLoad: state => {
                    allowUserToGoBack();
                    if (state) {
                        state.shared.route.route.current.id = 'ROUTE_LOADING';
                    }
                    return state;
                },
            },
        )
        .register(ROUTE_TERMS_CONDITIONS, <Terms />)
        .register(ROUTE_CONFIG, <WebAppConfig />)
        .register(ROUTE_AISP, <Aisp />)
        .register(ROUTE_PISP, <Pisp />)
        .register(ROUTE_VRP, <Vrp />)
        .register(ROUTE_ERROR, <ErrorPage />)
        .register(ROUTE_LOADING, <Loading />)
        .register(ROUTE_SPLASH, <SplashScreen />)
        .register(ROUTE_BANK_LOADING, <BankSpinner />)
        .register(ROUTE_BANK_REDIRECT, <BankRedirect />)
        .register(ROUTE_REDIRECT_TO_TPP, <RedirectTpp />)
        .register(ROUTE_CSRF_MISMATCH, <CsrfMismatch />)
        .register(ROUTE_REDIRECT_OPTIONS, <RedirectOptions />)
        .register(TPP_LOADING, <RedirectTppSpinner />)
        .render();
};

initApp();
