import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import List from '@token-io/lib-web-components/src/Components/List';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import { intlShape } from 'react-intl';
import msg from 'config/locales/en.json';
import { setBank, setOneStepSupport } from 'actions/shared';
import { FormattedMessage } from 'react-intl';
import { fetchTransferDestinationUrl } from '../../../actions/pisp';
import classNames from 'classnames/bind';
import style from './TopBanks.css';
import RightChevron from '../Icons/RightChevron';

const cx = classNames.bind(style);

const TopBanks = props => {
    const {
        intl,
        setBank,
        selectedBankId,
        banks,
        setBankAndProceedToConsent,
        setTransferDestinationsUrl,
        destinations,
        fetchTransferDestinationUrl,
        searchInputFocused,
    } = props;
    const hasTransferDestinationsUrlOnly =
        setTransferDestinationsUrl && !destinations;
    return (
        <Fragment>
            <br />
            <TokenTitle
                sub
                className="BankSelector-search-label"
                aria-hidden={searchInputFocused ? 'true' : 'false'}
                tabIndex={searchInputFocused ? '-1' : undefined}>
                <FormattedMessage id={'common.bankSelector.topBanks'} />
            </TokenTitle>
            <StaticDataProvider
                idKey={'id'}
                titleKey={'name'}
                extraKey={'identifier'}
                customFormat={({ id, name, selectedCountry, identifier }) => ({
                    id,
                    name,
                    country: intl.formatMessage({
                        id: `common.country.${selectedCountry}`,
                        defaultMessage: msg.common.country[selectedCountry],
                    }),
                    identifier: identifier || '',
                })}
                useCustomData
                customData={banks.slice(0, 5)}
                value={banks.find(item => item.id === selectedBankId)}
                customLoading={false}
                customSetValue={async (value, { doneCallback }) => {
                    await setBank(value);
                    setTimeout(() => {
                        hasTransferDestinationsUrlOnly
                            ? fetchTransferDestinationUrl(value)
                            : setBankAndProceedToConsent(value);
                    }, 500);
                    doneCallback();
                }}
                withPointer>
                <List
                    size={'large'}
                    className={'TopBanks'}
                    rowClass={cx('TopBanks-item')}
                    tabIndex={searchInputFocused ? '-1' : '0'}
                    ariaHidden={searchInputFocused ? 'true' : 'false'}
                    ariaLabel={intl.formatMessage({
                        id: 'common.bankSelector.topBanks',
                    })}
                    appendItem={<RightChevron svgColor={'#0074C7'} />}
                    focused
                />
            </StaticDataProvider>
        </Fragment>
    );
};

TopBanks.propTypes = {
    selectedBankId: PropTypes.string,
    setBank: PropTypes.func.isRequired,
    banks: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
    setOneStepSupport: PropTypes.func.isRequired,
    setBankAndProceedToConsent: PropTypes.func.isRequired,
    destinations: PropTypes.array,
    setTransferDestinationsUrl: PropTypes.string,
    fetchTransferDestinationUrl: PropTypes.func,
    searchInputFocused: PropTypes.bool,
};

TopBanks.defaultProps = {
    banks: [],
};

const mapStateToProps = ({ sharedService, tokenService }) => ({
    selectedBankId: sharedService.getSelectedBankId(),
    destinations: tokenService.getDestinations(),
    setTransferDestinationsUrl: tokenService.getSetTransferDestinationsUrl(),
});

const mapDispatchToProps = {
    setBank,
    setOneStepSupport,
    fetchTransferDestinationUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBanks);
