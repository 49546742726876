import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './AliasInput.css';
import classNames from 'classnames/bind';
import TextInput from '@token-io/lib-web-components/src/Components/TextInput';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import { validateAlias, initiateGuestFlow } from 'actions/shared';
import { FormattedMessage, intlShape } from 'react-intl';
import {
    AISP_GUEST_UNSUPPORTED,
    BANKS_WITHOUT_SUBTITLE,
} from 'config/constants';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import { formatCountriesPhone } from '../../../util';
import countryTelData from 'country-telephone-data';
const cx = classNames.bind(style);

class AliasInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alias: '',
            bankSupportedType: '',
            countryCode: '',
            country: formatCountriesPhone([
                countryTelData.allCountries.find(
                    country =>
                        props.selectedCountry.toLowerCase() === country.iso2,
                ),
            ])[0],
            selectedType: '',
        };
        this.continuePress = this.continuePress.bind(this);
        this.onChange = this.onChange.bind(this);
        this.continuePressPhone = this.continuePressPhone.bind(this);
        this.checkType = this.checkType.bind(this);
    }
    // TODO: deprecated, this lifecycle hook will be removed in React 17
    componentWillReceiveProps(nextProps) {
        if (nextProps.aliasError) {
            this.setState({
                loading: false,
            });
        }
    }

    componentDidMount() {
        this.props.setDisplayFooterShadow(false);
        this.checkType();
    }

    next() {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.props.validateAlias(this.state.alias, this.state.selectedType);
        }
    }

    nextPhone() {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.props.validateAlias(
                '+' + this.state.country.id + this.state.alias,
                this.state.selectedType,
            );
        }
    }
    continuePress() {
        if (this.state.alias) {
            this.next();
        }
    }
    continuePressPhone() {
        if (
            this.state.alias &&
            this.state.selectedType === 'PHONE' &&
            this.state.country
        ) {
            this.nextPhone();
        } else {
            this.next();
        }
    }
    updateAlias(alias) {
        this.setState({ alias });
    }

    onChange(country) {
        if (country !== this.state.country) {
            this.setState({ country });
        }
    }

    renderDoubleInput() {
        return (
            <div>
                <TextInput
                    onChange={value => {
                        this.updateAlias(value);
                        this.setState({
                            selectedType: 'EMAIL',
                        });
                    }}
                    onKeyEnter={this.continuePress}
                    placeholder={this.props.intl.formatMessage({
                        id: 'common.aliasInput.aliasTypes.email',
                    })}
                    className={cx('AliasInput-email-field')}
                    inputFieldClass={cx('AliasInput-field')}
                />
                <TokenTitle sub>
                    <FormattedMessage id={'common.aliasInput.or'} />
                </TokenTitle>
                <div className={cx('AliasInput-Phone-container')}>
                    <StaticDataProvider
                        idKey={'code'}
                        titleKey={'code'}
                        dataKey={'key'}
                        source={() => countryTelData.allCountries}
                        useCustomData
                        customData={formatCountriesPhone(
                            countryTelData.allCountries,
                        )}
                        value={this.state.country}
                        customSetValue={async (value, { doneCallback }) => {
                            await this.onChange(value);
                            doneCallback();
                        }}
                        withPointer>
                        <Dropdown
                            className={cx('phoneSelector-country')}
                            rowClass={cx('phoneSelector-country-item')}
                            allowDeselect
                        />
                    </StaticDataProvider>
                    <TextInput
                        className={cx('AliasInput-phone-field')}
                        onChange={value => {
                            this.updateAlias(value);
                            this.setState({
                                selectedType: 'PHONE',
                            });
                        }}
                        onKeyEnter={this.continuePressPhone}
                        placeholder={this.props.intl.formatMessage({
                            id: 'common.aliasInput.aliasTypes.phone',
                        })}
                        inputFieldClass={cx('AliasInput-phone-field-inner')}
                    />
                </div>
            </div>
        );
    }

    renderSwitch(param, customAliasLabel) {
        switch (param) {
            case 'EMAIL':
                return (
                    <TextInput
                        onChange={value => {
                            this.updateAlias(value);
                            this.setState({
                                selectedType: 'EMAIL',
                            });
                        }}
                        onKeyEnter={this.continuePress}
                        placeholder={this.props.intl.formatMessage({
                            id: 'common.aliasInput.aliasTypes.email',
                        })}
                        inputFieldClass={cx('AliasInput-field')}
                        value={this.state.alias}
                        autoFocus
                    />
                );
            case 'PHONE':
                return (
                    <div className={cx('AliasInput-Phone-container')}>
                        <StaticDataProvider
                            idKey={'code'}
                            titleKey={'code'}
                            dataKey={'key'}
                            useCustomData
                            customData={formatCountriesPhone(
                                countryTelData.allCountries,
                            )}
                            value={this.state.country}
                            customSetValue={async (value, { doneCallback }) => {
                                await this.onChange(value);
                                doneCallback();
                            }}
                            withPointer>
                            <Dropdown
                                placeholder={'+ 0'}
                                className={cx('phoneSelector-country')}
                                rowClass={cx('phoneSelector-country-item')}
                                allowDeselect
                            />
                        </StaticDataProvider>
                        <TextInput
                            className={cx('AliasInput-phone-field')}
                            onChange={value => {
                                this.updateAlias(value);
                                this.setState({
                                    selectedType: 'PHONE',
                                });
                            }}
                            onKeyEnter={this.continuePressPhone}
                            placeholder={this.props.intl.formatMessage({
                                id: 'common.aliasInput.aliasTypes.phone',
                            })}
                            inputFieldClass={cx('AliasInput-phone-field-inner')}
                        />
                    </div>
                );
            case 'CUSTOM':
                return (
                    <TextInput
                        onChange={value => {
                            this.updateAlias(value);
                            this.setState({
                                selectedType: 'CUSTOM',
                            });
                        }}
                        onKeyEnter={this.continuePress}
                        placeholder={customAliasLabel}
                        inputFieldClass={cx('AliasInput-field')}
                        value={this.state.alias}
                        autoFocus
                    />
                );
            default:
                // email or phone
                return this.renderDoubleInput();
        }
    }

    checkType() {
        const { aliasTypes } = this.props.bankInfo;
        const { bankSupportedType } = this.state;
        if (aliasTypes.includes('CUSTOM')) {
            this.setState({
                bankSupportedType: 'CUSTOM',
            });
        } else if (
            aliasTypes.includes('EMAIL') &&
            aliasTypes.includes('PHONE')
        ) {
            this.setState({
                bankSupportedType: 'Email or Phone',
            });
        } else {
            this.setState({
                bankSupportedType: aliasTypes[0],
            });
        }
        this.renderSwitch(bankSupportedType);
    }

    render() {
        const status =
            (!this.state.alias && Button.STATUS_DISABLED) ||
            (this.state.loading && Button.STATUS_LOADING) ||
            Button.STATUS_IDLE;
        const { customAliasLabel, aliasTypes } = this.props.bankInfo;
        const { bankSupportedType } = this.state;
        return (
            <Fragment>
                <img
                    className={cx('AliasInput-logo')}
                    src={this.props.bank.fullLogoUri}
                />
                <TokenContainer className={cx('AliasInput-subcontainer')}>
                    <TokenTitle>
                        <FormattedMessage
                            id={'common.aliasInput.title'}
                            values={{
                                BANK_NAME: this.props.bank.name,
                                ALIAS_TYPES:
                                    (aliasTypes.includes('CUSTOM') &&
                                        customAliasLabel) ||
                                    (aliasTypes.includes('PHONE') &&
                                        aliasTypes.includes('EMAIL') &&
                                        this.props.intl.formatMessage({
                                            id:
                                                'common.aliasInput.aliasTypes.both',
                                        })) ||
                                    (aliasTypes.includes('PHONE') &&
                                        this.props.intl.formatMessage({
                                            id:
                                                'common.aliasInput.aliasTypes.phone',
                                        })) ||
                                    (aliasTypes.includes('EMAIL') &&
                                        this.props.intl.formatMessage({
                                            id:
                                                'common.aliasInput.aliasTypes.email',
                                        })),
                            }}>
                        </FormattedMessage>
                    </TokenTitle>
                    {!BANKS_WITHOUT_SUBTITLE.includes(this.props.bank.id) && (
                        <TokenTitle sub>
                            <FormattedMessage
                                id={'common.aliasInput.subtitle'}
                            />
                        </TokenTitle>
                    )}
                    {this.renderSwitch(bankSupportedType, customAliasLabel)}
                    {this.props.aliasError && (
                        <p className={cx('AliasInput-error')}>
                            <FormattedMessage
                                id={this.props.aliasError}
                                values={{ BANK_NAME: this.props.bank.name }}
                            />
                        </p>
                    )}
                    {this.props.supportsGuest && (
                        <p
                            className={cx('AliasInput-guest')}
                            onClick={this.props.initiateGuestFlow}>
                            <FormattedMessage id={'common.aliasInput.guest'} />
                        </p>
                    )}
                </TokenContainer>
                <TokenButtonsWrapper>
                    <Button
                        onClick={
                            this.state.selectedType === 'PHONE' &&
                            this.state.country
                                ? () => this.nextPhone()
                                : () => this.next()
                        }
                        status={status}
                        text={<FormattedMessage id={'common.button.next'} />}
                    />
                    <Button
                        onClick={this.props.clearBankAndBack}
                        type={Button.TYPE_GHOST}
                        text={<FormattedMessage id={'common.button.back'} />}
                    />
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

AliasInput.propTypes = {
    bank: PropTypes.object.isRequired,
    bankInfo: PropTypes.object.isRequired,
    clearBankAndBack: PropTypes.func.isRequired,
    validateAlias: PropTypes.func.isRequired,
    initiateGuestFlow: PropTypes.func.isRequired,
    supportsGuest: PropTypes.bool,
    aliasError: PropTypes.string,
    intl: intlShape.isRequired,
    selectedCountry: PropTypes.string.isRequired,
    supportsAisGuestCheckout: PropTypes.bool,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = ({ sharedService }, ownProps) => ({
    aliasError: sharedService.getErrorMessage(),
    supportsGuest:
        (sharedService.isAisp() &&
            !AISP_GUEST_UNSUPPORTED.includes(ownProps.bank.id) &&
            sharedService.supportsAisGuestCheckout()) ||
        sharedService.supportsGuestCheckout(),
    selectedCountry: sharedService.getSelectedCountry(),
    supportsAisGuestCheckout: sharedService.supportsAisGuestCheckout(),
});

const mapDispatchToProps = {
    validateAlias,
    initiateGuestFlow,
};

export default connect(mapStateToProps, mapDispatchToProps)(AliasInput);
