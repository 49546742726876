import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './AppInstall.css';
import classNames from 'classnames/bind';
import { notYou as goToBankSelector } from 'actions/shared';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import {
    appUrlIOS,
    appUrlAndroid,
    defaultAppName,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { FormattedMessage } from 'react-intl';

import installIcon from 'assets/install.png';
import googlePlayIcon from 'assets/google-play.png';
import appStoreIcon from 'assets/app-store.png';

const cx = classNames.bind(style);

const AppInstall = ({ strings, goToBankSelector, appName }) => {
    return (
        <Fragment>
            <TokenContainer
                className={cx('AppInstall-subcontainer')}
                noMargin
                noScrollable>
                <div className={cx('AppInstall-body')}>
                    <div className={cx('AppInstall-wrapper')}>
                        <div className={cx('AppInstall-title')}>
                            <FormattedMessage
                                id={'common.appInstall.title'}
                                tagName={'p'}
                                values={{
                                    APP_NAME: <strong>{appName}</strong>,
                                }}
                            />
                        </div>
                        <img
                            className={cx('AppInstall-install')}
                            src={installIcon}
                        />
                        <div className={cx('AppInstall-subtitle')}>
                            <p>
                                {strings[0]}
                                <br />
                                {strings[1]}
                            </p>
                        </div>
                        <div className={cx('AppInstall-links')}>
                            <a href={appUrlAndroid}>
                                <img
                                    className={cx('AppInstall-button')}
                                    src={googlePlayIcon}
                                />
                            </a>
                            <a
                                href={appUrlIOS}
                                target="_blank"
                                rel="noreferrer noopener">
                                <img
                                    className={cx('AppInstall-button')}
                                    src={appStoreIcon}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </TokenContainer>
            <Button
                onClick={goToBankSelector}
                type={Button.TYPE_DELETE}
                text={<FormattedMessage id={'common.button.back'} />}
            />
        </Fragment>
    );
};

AppInstall.propTypes = {
    strings: PropTypes.array.isRequired,
    goToBankSelector: PropTypes.func.isRequired,
    appName: PropTypes.string.isRequired,
};

const mapStateToProps = ({ tokenRequestService, sharedService }) => ({
    appName:
        (sharedService.isAisp()
            ? tokenRequestService.getTppFeature(featureConfig.AIS_APP_NAME)
            : tokenRequestService.getTppFeature(featureConfig.PIS_APP_NAME)) ||
        defaultAppName,
});

const mapDispatchToProps = {
    goToBankSelector,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppInstall);
