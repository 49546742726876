import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './Consent.css';
import TextInput from './../Shared/TextInput.js';
import FeedbackIcon from './../Icons/FeedbackIcon.js';
import InvalidIbanText from './../Shared/InvalidIbanText.js';
import { handleCheckIban } from './../Shared/ValidateIban';
import classNames from 'classnames/bind';
import { FormattedMessage, intlShape } from 'react-intl';
import { mandatoryFieldsConfig } from 'config/mandatoryFields';
import { connect } from 'reducers';

const cx = classNames.bind(style);

const MandatoryFields = ({
    parentState,
    type,
    intl,
    selectedBank,
    tokenRequestId,
    resourceTypeList,
    getIbanIsValid,
    handleMandatoryFields,
    requiredMandatoryFields,
}) => {
    const [isIbanValid, setIsIbanValid] = useState(true);
    const [defaultIban, setDefaultIban] = useState(
        resourceTypeList?.source?.accountIdentifier?.iban?.iban,
    );
    const [displayIbanSpinner, setDisplayIbanSpinner] = useState(false);
    const currentIban = parentState?.source?.accountIdentifier?.iban?.iban;

    const handleCurrencyInput = value => {
        handleMandatoryFields({
            source: {
                ...parentState?.source,
                currency: value,
            },
        });
    };

    const captureCurrency = () => {
        return (
            <div className={cx('Consent-data-row')} key={'currency'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'aisp.consent.mandatory.currency.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e => handleCurrencyInput(e.target.value)}
                        placeholder={intl.formatMessage({
                            id: 'aisp.consent.mandatory.currency.placeholder',
                        })}
                        name="currency"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={parentState?.source?.currency}
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    const handleInvalidIban = () => {
        setIsIbanValid(false);
        setDisplayIbanSpinner(false);
    };

    const handleValidIban = () => {
        setIsIbanValid(true);
        setDisplayIbanSpinner(false);
    };

    const handleIbanInput = ibanValue => {
        const iban = ibanValue?.trim();
        setIsIbanValid(false);
        setDisplayIbanSpinner(true);
        handleMandatoryFields({
            source: {
                ...parentState?.source,
                accountIdentifier: {
                    ...parentState?.source?.accountIdentifier,
                    iban: { iban },
                },
            },
        });
        return ibanValue;
    };

    const ibanWithCountry = () => {
        return (
            (currentIban && currentIban?.length > 2 && currentIban) ||
            (selectedBank && selectedBank.country) ||
            ''
        );
    };

    const iconDisplay = () => {
        return (
            <FeedbackIcon
                isValid={isIbanValid}
                preCondition={currentIban?.length >= 12}
                displaySpinner={displayIbanSpinner}
            />
        );
    };

    const captureSourceIban = () => {
        return (
            <div className={cx('Consent-data-row')} key={'iban'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'aisp.consent.mandatory.iban.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <div style={{ display: 'flex' }}>
                        {iconDisplay()}
                        <TextInput
                            onChange={e =>
                                handleIbanInput(e.target.value?.toUpperCase())
                            }
                            placeholder={intl.formatMessage({
                                id: 'aisp.consent.mandatory.iban.placeholder',
                            })}
                            key="iban"
                            name="iban_inputField"
                            className={cx({
                                TextInput__inner__input: true,
                                'AliasInput-field': true,
                                'IbanInput-field': true,
                            })}
                            aria-required="true"
                            validateInput={
                                !isIbanValid &&
                                !displayIbanSpinner &&
                                !!currentIban
                            }
                            value={ibanWithCountry()}
                            required
                            autoFocus
                        />
                    </div>
                    {currentIban && !isIbanValid && !displayIbanSpinner && (
                        <InvalidIbanText
                            text={intl.formatMessage({
                                id: 'aisp.consent.mandatory.iban.notValid',
                            })}
                        />
                    )}
                </div>
            </div>
        );
    };

    useEffect(() => {
        resourceTypeList && handleMandatoryFields({ ...resourceTypeList });
    }, []);

    useEffect(() => {
        const timeout = defaultIban ? 0 : 1000;
        handleCheckIban({
            iban: currentIban,
            requestId: tokenRequestId,
            handleValidIban: handleValidIban,
            handleInvalidIban: handleInvalidIban,
            timeout,
        });
    }, [currentIban]);

    useEffect(() => {
        if (defaultIban && !isIbanValid) {
            setDefaultIban('');
        }
        getIbanIsValid(isIbanValid);
    }, [isIbanValid]);

    const config = mandatoryFieldsConfig?.[type];
    const defaultCurrency = resourceTypeList?.source?.currency;

    return (
        <>
            {requiredMandatoryFields?.map(field => {
                if (field === config?.iban) {
                    if (!defaultIban) {
                        return captureSourceIban();
                    }
                } else if (field === config?.currency) {
                    if (!defaultCurrency) {
                        return captureCurrency();
                    }
                }
                return null;
            })}
        </>
    );
};

MandatoryFields.propTypes = {
    requiredMandatoryFields: PropTypes.array,
    resourceTypeList: PropTypes.object,
    intl: intlShape.isRequired,
    handleMandatoryFields: PropTypes.func,
    parentState: PropTypes.object,
    type: PropTypes.string,
    tokenRequestId: PropTypes.string,
    selectedBank: PropTypes.object,
    getIbanIsValid: PropTypes.func,
};

MandatoryFields.defaultProps = {
    type: 'access',
};

const mapStateToProps = ({ sharedService, tokenRequestService }) => {
    const selectedBank = sharedService.getSelectedBank();
    const tokenRequestId = tokenRequestService.getRequestId();
    return {
        selectedBank,
        tokenRequestId,
    };
};

export default connect(mapStateToProps, null)(MandatoryFields);
