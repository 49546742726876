import React from 'react';
import style from './LeftChevron.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(style);

const LeftChevron = ({ color = 'none', svgClassName }) => {
    return (
        <div className={cx('Left-chevron-container')}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                className={cx({
                    [svgClassName]: !!svgClassName,
                })}
                viewBox="0 0 24 24">
                <path
                    className={cx('Left-chevron')}
                    fill={color}
                    d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                />
            </svg>
        </div>
    );
};

LeftChevron.propTypes = {
    color: PropTypes.string,
    svgClassName: PropTypes.string,
};

export default LeftChevron;
