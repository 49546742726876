import React from 'react';
import PropTypes from 'prop-types';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import { FormattedMessage } from 'react-intl';
import {
    unsuccessfulVrpStatusCodes,
    successfulVrpStatusCodes,
    pendingVrpStatusCodes,
} from 'config/constants';
import SuccessIcon from '../Icons/SuccessIcon';
import FailureIcon from '../Icons/FailureIcon';
import ClockIcon from '../Icons/ClockIcon';
import style from '../Pisp/PaymentStatusTitle.css';
import classNames from 'classnames/bind';
import { connect } from 'reducers';

const cx = classNames.bind(style);

const Successful = ({ vrpConsentStatus, seconds, pispName }) => {
    return (
        <center>
            <TokenTitle
                className={cx({
                    'PaymentInitStatus-status--success': true,
                })}>
                <div>
                    <SuccessIcon
                        title="success"
                        className={cx('PaymentInitStatus-status-icon')}
                    />
                </div>
                <b className="Vrp-init-status-title">
                    <FormattedMessage
                        tagName={'span'}
                        className="Vrp-init-status-title"
                        id={`vrp.initStatus.status_title.${vrpConsentStatus}`}
                    />
                </b>
            </TokenTitle>
            <TokenTitle sub>
                <b>
                    <FormattedMessage id={'vrp.initStatus.doNotCloseMessage'} />
                </b>
            </TokenTitle>
            <TokenTitle
                role="alert"
                aria-live={seconds === 10 ? 'polite' : 'off'}
                sub>
                <FormattedMessage
                    id={`vrp.initStatus.status_message.${vrpConsentStatus}`}
                    values={{
                        MERCHANT_NAME: pispName,
                        SECONDS: seconds,
                    }}
                />
            </TokenTitle>
        </center>
    );
};

Successful.propTypes = {
    vrpConsentStatus: PropTypes.string,
    seconds: PropTypes.number,
    pispName: PropTypes.string,
};

const Unsuccessful = ({ vrpConsentStatus, seconds, pispName }) => {
    return (
        <center>
            <TokenTitle
                className={cx({
                    'PaymentInitStatus-status--error': true,
                })}>
                <div>
                    <FailureIcon
                        title="failure"
                        className={cx('PaymentInitStatus-status-icon')}
                    />
                </div>
                <b className="Vrp-init-status-title">
                    <FormattedMessage
                        tagName={'span'}
                        className="Vrp-init-status-title"
                        id={`vrp.initStatus.status_title.${vrpConsentStatus}`}
                    />
                </b>
            </TokenTitle>
            <TokenTitle role="alert" aria-live={'polite'} sub>
                <FormattedMessage
                    id={`vrp.initStatus.status_message.${vrpConsentStatus}`}
                    values={{
                        MERCHANT_NAME: pispName,
                    }}
                />
                <TokenTitle sub>
                    <b>
                        <FormattedMessage
                            id={'vrp.initStatus.doNotCloseMessage'}
                        />
                    </b>
                </TokenTitle>
                <TokenTitle
                    role="alert"
                    aria-live={seconds === 10 ? 'polite' : 'off'}
                    sub>
                    <FormattedMessage
                        id={'vrp.initStatus.subTitle'}
                        values={{
                            MERCHANT_NAME: pispName,
                            SECONDS: seconds,
                        }}
                    />
                </TokenTitle>
            </TokenTitle>
        </center>
    );
};

Unsuccessful.propTypes = {
    vrpConsentStatus: PropTypes.string,
    seconds: PropTypes.number,
    pispName: PropTypes.string,
};

const Submitted = ({ vrpConsentStatus, seconds, pispName }) => {
    return (
        <center>
            <TokenTitle
                className={cx({
                    'PaymentInitStatus-status--submitted': true,
                })}>
                <div>
                    <ClockIcon
                        title="submitted"
                        className={cx('PaymentInitStatus-status-icon')}
                    />
                </div>
                <b className="Vrp-init-status-title">
                    <FormattedMessage
                        tagName={'span'}
                        className="Vrp-init-status-title"
                        id={`vrp.initStatus.status_title.${vrpConsentStatus}`}
                    />
                </b>
            </TokenTitle>
            <TokenTitle sub>
                <b>
                    <FormattedMessage id={'vrp.initStatus.doNotCloseMessage'} />
                </b>
            </TokenTitle>
            <TokenTitle
                role="alert"
                aria-live={seconds === 10 ? 'polite' : 'off'}
                sub>
                <FormattedMessage
                    id={`vrp.initStatus.status_message.${vrpConsentStatus}`}
                    values={{
                        MERCHANT_NAME: pispName,
                        SECONDS: seconds,
                    }}
                />
            </TokenTitle>
        </center>
    );
};

Submitted.propTypes = {
    vrpConsentStatus: PropTypes.string,
    seconds: PropTypes.number,
    pispName: PropTypes.string,
};

const VrpConsentInitStatusSubTitle = ({
    vrpConsentStatus,
    seconds,
    pispName,
}) => {
    if (successfulVrpStatusCodes.includes(vrpConsentStatus)) {
        return (
            <Successful
                vrpConsentStatus={vrpConsentStatus}
                seconds={seconds}
                pispName={pispName}
            />
        );
    } else if (pendingVrpStatusCodes.includes(vrpConsentStatus)) {
        return (
            <Submitted
                vrpConsentStatus={vrpConsentStatus}
                seconds={seconds}
                pispName={pispName}
            />
        );
    } else if (unsuccessfulVrpStatusCodes.includes(vrpConsentStatus)) {
        return (
            <Unsuccessful
                vrpConsentStatus={vrpConsentStatus}
                seconds={seconds}
                pispName={pispName}
            />
        );
    }
    return <></>;
};

VrpConsentInitStatusSubTitle.propTypes = {
    vrpConsentStatus: PropTypes.string,
    seconds: PropTypes.number,
    pispName: PropTypes.string,
};

const VrpConsentInitStatusTitle = ({ vrpConsentStatus, pispName, seconds }) => {
    return (
        <TokenTitle className={cx({ 'PaymentInitStatus-title': true })}>
            <FormattedMessage id={'vrp.initStatus.title'} />
            <VrpConsentInitStatusSubTitle
                vrpConsentStatus={vrpConsentStatus}
                seconds={seconds}
                pispName={pispName}
            />
        </TokenTitle>
    );
};

VrpConsentInitStatusTitle.propTypes = {
    vrpConsentStatus: PropTypes.string,
    pispName: PropTypes.string,
    seconds: PropTypes.number,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const { tppName } = tokenRequestService.getTppProfile();
    const tppAlias = tokenRequestService.getTppAlias();

    return {
        pispName: tppName || tppAlias || 'merchant',
    };
};

export default connect(mapStateToProps, null)(VrpConsentInitStatusTitle);
