import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './ConfirmFundsConsent.css';
import { FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import {
    backToBankSelector,
    proceedToBank,
    setInstructionsSourceIban,
} from 'actions/aisp';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import {
    DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
    defaultAppName,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { declineTerms } from 'actions/shared';
import TermsLoader from '../Terms/TermsLoader';
import { sanitizeHTML, hideConsentText } from '../../../util';

const cx = classNames.bind(style);

const ConfirmFundsConsentBankFirstFlow = ({
    declineTerms,
    businessName,
    aispName,
    resources,
    backToBankSelector,
    proceedToBank,
    defaultIban,
    setDisplayFooterShadow,
    intl,
}) => {
    const expireDate = new Date();
    expireDate.setDate(
        expireDate.getDate() + DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS,
    );

    const consentText = intl.formatMessage(
        {
            id: 'aisp.caf.consent.text',
            defaultMessage: '',
        },
        { BUSINESS_NAME: aispName },
    );

    const status = Button.STATUS_IDLE;

    const hideConsentTextBlock = hideConsentText(consentText);

    useEffect(() => {
        setDisplayFooterShadow(false);
    }, []);

    return (
        <Fragment>
            <TokenContainer className={cx('Consent-container')} noMargin>
                <div className="Caf-tokenContainer-margin">
                    <TokenTitle>
                        <FormattedMessage id={'aisp.caf.consent.title'} />
                    </TokenTitle>
                    <TokenTitle sub>
                        <FormattedMessage
                            id={'aisp.caf.consent.subtitle1'}
                            values={{ BUSINESS_NAME: businessName }}
                        />
                    </TokenTitle>
                </div>
                <div className={cx('Consent-data')}>
                    <TokenTitle sub>
                        <div className={cx('Consent-data-row')}>
                            <div className={cx('Consent-data-row-title')}>
                                <FormattedMessage
                                    tagName={'span'}
                                    id={'aisp.caf.consent.accountNumber'}
                                />
                            </div>
                            <div className={cx('Consent-data-row-detail')}>
                                <span className={cx('Colored-Text')}>
                                    {resources[0].bankAccount.iban
                                        ? resources[0].bankAccount.iban.iban
                                        : resources[0].bankAccount.domestic
                                            .accountNumber}
                                </span>
                            </div>
                        </div>
                        <div className={cx('Consent-data-row')}>
                            <div className={cx('Consent-data-row-title')}>
                                <FormattedMessage
                                    tagName={'span'}
                                    id={'aisp.caf.consent.beneficiary'}
                                />
                            </div>
                            <div className={cx('Consent-data-row-detail')}>
                                <span className={cx('Colored-Text')}>
                                    {aispName}
                                </span>
                            </div>
                        </div>
                        <div className={cx('Consent-data-row')}>
                            <div className={cx('Consent-data-row-title')}>
                                <FormattedMessage
                                    tagName={'span'}
                                    id={'aisp.caf.consent.tokenValidUntil'}
                                />
                            </div>
                            <div className={cx('Consent-data-row-detail')}>
                                <span className={cx('Colored-Text')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'aisp.caf.consent.ongoing'}
                                    />
                                </span>
                            </div>
                        </div>
                    </TokenTitle>
                </div>
                {!hideConsentTextBlock && (
                    <div
                        className={cx({
                            'Consent-details': true,
                        })}
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(consentText),
                        }}
                    />
                )}
                <TermsLoader />
            </TokenContainer>

            <TokenButtonsWrapper>
                <Button
                    status={status}
                    onClick={proceedToBank}
                    text={<FormattedMessage id={'common.button.accept'} />}
                />
                {defaultIban !== '' ? (
                    <Button
                        type={Button.TYPE_GHOST_WARNING}
                        onClick={declineTerms}
                        text={<FormattedMessage id={'common.button.decline'} />}
                    />
                ) : (
                    <Button
                        type={Button.TYPE_GHOST}
                        onClick={backToBankSelector}
                        text={<FormattedMessage id={'common.button.back'} />}
                    />
                )}
            </TokenButtonsWrapper>
        </Fragment>
    );
};

ConfirmFundsConsentBankFirstFlow.propTypes = {
    declineTerms: PropTypes.func.isRequired,
    aispName: PropTypes.string,
    businessName: PropTypes.string.isRequired,
    resources: PropTypes.array.isRequired,
    proceedToBank: PropTypes.func.isRequired,
    defaultIban: PropTypes.string.isRequired,
    setInstructionsSourceIban: PropTypes.func.isRequired,
    backToBankSelector: PropTypes.func.isRequired,
    locale: PropTypes.string,
    setDisplayFooterShadow: PropTypes.func,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({
    tokenService,
    tokenRequestService,
    sharedService,
}) => {
    const tppName = tokenRequestService.getTppProfile().tppName;
    const actingAs = tokenRequestService.getTppActingAs();
    const defaultIban = tokenService.getResources();
    return {
        businessName:
            tokenRequestService.getTppFeature(featureConfig.AIS_APP_NAME) ||
            defaultAppName,
        aispName: actingAs?.displayName || tppName,
        resources: tokenService.getResources(),
        locale: sharedService.getLocale(),
        defaultIban: defaultIban?.[0]?.bankAccount?.iban?.iban || '',
    };
};

const mapDispatchToProps = {
    backToBankSelector,
    proceedToBank,
    setInstructionsSourceIban,
    declineTerms,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmFundsConsentBankFirstFlow);
