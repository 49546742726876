import {
    TOKEN_BANK_POPUP,
    POPUP_SHOW,
    POPUP_FAILURE,
    POPUP_SUCCESS,
    POPUP_IS_LOADED,
    POPUP_VISIBILITY_CHANGE,
} from 'iframe/constants';
import Postie from '@token-io/lib-web-components/src/Postie';
import {
    generateNonce,
    isFunction,
} from '@token-io/lib-web-components/src/Util';
import TokenPopup from 'iframe/TokenPopup';

// Bank pop up controller
function TokenBankPopup({ url }) {
    const _self = this;
    let _popup = null;
    let _postie = null;
    let _onVisibilityChangeCallback = null;

    function _show() {
        _popup = new TokenPopup({
            url: url,
            popupName: TOKEN_BANK_POPUP,
        });

        _postie = new Postie({
            source: window,
            destination: _popup.getPopup(),
            origin: url,
            id: generateNonce(),
        });

        _setupBankPopupMessages(_postie, window.parent[0], '*');
    }

    function _setupBankPopupMessages(postie, destination, origin) {
        const requestMessages = [POPUP_SHOW];
        const returnMessages = [POPUP_SUCCESS, POPUP_FAILURE];

        // proxy messages from the parent to the popup
        requestMessages.forEach(function (msg) {
            postie.proxyMessage(msg);
        });

        // proxy messages from the popup to the parent
        returnMessages.forEach(function (msg) {
            postie.proxyMessage(msg, destination, origin);
        });

        // tell the parent that the popup is loaded
        _popup.onLoad(function () {
            postie.dispatchMessage(
                {
                    type: POPUP_IS_LOADED,
                },
                destination,
                origin,
            );
        });

        // setup bank popup visibility change messages
        const dispatchBankPopupVisibility = visible => {
            if (isFunction(_onVisibilityChangeCallback)) {
                _onVisibilityChangeCallback(visible);
            }

            postie.dispatchMessage(
                {
                    type: POPUP_VISIBILITY_CHANGE,
                    visible,
                },
                destination,
                origin,
            );
        };

        // keep the parent notified about the change of visibility of the bank popup
        _popup.onVisibilityChange(dispatchBankPopupVisibility);

        // bank popup is now visible, so tell the parent
        dispatchBankPopupVisibility(true);
    }

    function _init() {
        _show();
    }

    _self.getPopup = () => _popup;

    _self.onVisibilityChange = fn => {
        if (!isFunction(fn)) throw new Error('Function expected');
        _onVisibilityChangeCallback = fn;
    };

    _init();
}

export default TokenBankPopup;
