import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import AppNotifier from 'components/App/Shared/AppNotifier';
import { intlShape } from 'react-intl';
import {
    defaultAppName,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';

// Higher order component AppNotifier for PISP
const PispAppNotifier = ({ intl, appName, ...props }) => (
    <AppNotifier
        {...props}
        strings={[
            intl.formatMessage(
                { id: 'common.appNotifier.title' },
                { APP_NAME: appName },
            ),
            intl.formatMessage(
                { id: 'pisp.appNotifier.subtitle' },
                { APP_NAME: appName },
            ),
            '',
        ]}
    />
);

PispAppNotifier.propTypes = {
    intl: intlShape.isRequired,
    appName: PropTypes.string.isRequired,
};

const mapStateToProps = ({ tokenRequestService }) => ({
    appName:
        tokenRequestService.getTppFeature(featureConfig.PIS_APP_NAME) ||
        defaultAppName,
});

export default connect(mapStateToProps)(PispAppNotifier);
