import React from 'react';
import PropTypes from 'prop-types';
import style from '../../../css/TextInput.css';
import {compose} from '../../Util';
import {
    withValidator,
    withAutoFocus,
    withValue,
    withFocusState,
    withStatic,
} from '../../Hoc.jsx';
import {SpinnerSmall} from '../Spinner';
import iconSuccess from '../../../assets/icon-success.svg';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

const STATUS_IDLE = 'idle';
const STATUS_DISABLED = 'disabled';
const STATUS_LOADING = 'loading';
const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';

const TYPE_TEXT = 'text';
const TYPE_EMAIL = 'email';
const TYPE_PASSWORD = 'password';
const TYPE_NUMBER = 'number';

const TextInput = React.forwardRef(({
    className,
    inputFieldClass,
    status,
    placeholder,
    value,
    message,
    focused,
    hover,
    prepend,
    append,
    setValue,
    setKeyDown,
    setFocus,
    setHover,
    onClick,
    validate,
    inputType,
    spinnerColor,
    onKeyDown,
    activeDescendant,
    ariaLabel,
    tabFocused,
    id,
}, ref) => (
    <div
        className={cx({
            'TextInput': true,
            [`TextInput--${status}`]: true,
            'TextInput--focused': focused,
            'TextInput--hover': hover,
            [className]: !!className,
        })}
        onMouseEnter={() => {
            setHover(true);
        }}
        onMouseLeave={() => {
            setHover(false);
        }}
        onClick={() => {
            ref && ref.current && ref.current.focus();
            onClick(ref && ref.current);
        }}
        onKeyDown={onKeyDown}>
        <span className={cx('TextInput__title')} />
        <div className={cx({'TextInput__inner': true, 'TextInput__inner--tabFocused': !!tabFocused})}>
            {prepend && (
                <span className={cx('TextInput__inner__prepend')}>
                    {prepend}
                </span>
            )}
            {status === STATUS_DISABLED ? (
                <span
                    className={cx('TextInput__inner__disabled-input')}>
                    {value}
                </span>
            ) : (
                <input
                    type={inputType}
                    id={id}
                    ref={ref}
                    className={cx({
                        'TextInput__inner__input': true,
                        [inputFieldClass]: !!inputFieldClass,
                    })}
                    placeholder={placeholder}
                    value={value || ''}
                    onChange={e => {
                        const value = e.target.value;
                        setValue(value);
                        if (validate) {
                            validate(value);
                        }
                    }}
                    onKeyDown={e => {
                        setKeyDown(e.keyCode, e);
                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                    onBlur={() => {
                        setFocus(false);
                    }}
                    autoComplete="off"
                    aria-owns="listbox"
                    aria-activedescendant={activeDescendant}
                    aria-label={ariaLabel} />
            )}
            {status === STATUS_LOADING && (
                <span className={cx('TextInput__inner__loader')}>
                    <SpinnerSmall color={spinnerColor} />
                </span>
            )}
            {status === STATUS_SUCCESS && (
                <span className={cx('TextInput__inner__success')}>
                    <img src={iconSuccess} />
                </span>
            )}
            {append && (
                <span className={cx('TextInput__inner__append')}>
                    {append}
                </span>
            )}
        </div>
        {message && status === STATUS_ERROR &&
            <span className={cx('TextInput__message')}>{message}</span>}
    </div>
));

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
    className: PropTypes.string,
    inputFieldClass: PropTypes.string,
    placeholder: PropTypes.string,
    status: PropTypes.oneOf([
        STATUS_IDLE,
        STATUS_DISABLED,
        STATUS_LOADING,
        STATUS_SUCCESS,
        STATUS_ERROR,
    ]),
    inputType: PropTypes.oneOf([
        TYPE_TEXT,
        TYPE_EMAIL,
        TYPE_PASSWORD,
        TYPE_NUMBER,
    ]),
    value: PropTypes.string,
    message: PropTypes.node,
    id: PropTypes.string,
    focused: PropTypes.bool,
    hover: PropTypes.bool,
    prepend: PropTypes.node,
    append: PropTypes.node,
    setValue: PropTypes.func,
    setKeyDown: PropTypes.func,
    setFocus: PropTypes.func,
    setHover: PropTypes.func,
    onClick: PropTypes.func,
    validate: PropTypes.func,
    spinnerColor: PropTypes.string,
    onKeyDown: PropTypes.func,
    activeDescendant: PropTypes.string,
    ariaLabel: PropTypes.string,
    tabFocused: PropTypes.bool,
};

TextInput.defaultProps = {
    status: STATUS_IDLE,
    inputType: TYPE_TEXT,
    value: '',
    focused: false,
    hover: false,
    setValue: () => null,
    setKeyDown: () => null,
    setFocus: () => null,
    setHover: () => null,
    onClick: () => null,
};

const TextInputComposer = compose(
    withAutoFocus,
    withValue,
    withFocusState,
    withStatic({
        STATUS_IDLE,
        STATUS_DISABLED,
        STATUS_LOADING,
        STATUS_SUCCESS,
        STATUS_ERROR,
        TYPE_TEXT,
        TYPE_EMAIL,
        TYPE_PASSWORD,
        TYPE_NUMBER,
    }),
);

const EmailInputComposer = compose(
    TextInputComposer,
    withValidator({type: TYPE_EMAIL}),
    withStatic({
        displayName: 'EmailInput',
    }),
);

export const EmailInput = EmailInputComposer(TextInput);

export default TextInputComposer(TextInput);
