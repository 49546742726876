// Radio button

import React from 'react';
import PropTypes from 'prop-types';
import style from '../../../css/Radio.css';
import {compose} from '../../Util';
import {
    withAutoFocus,
    withValue,
    withFocusState,
    withStatic,
} from '../../Hoc.jsx';
import classNames from 'classnames/bind';
import {KEY_SPACEBAR} from '../../config/constants';

const cx = classNames.bind(style);

const STATUS_IDLE = 'idle';
const STATUS_DISABLED = 'disabled';

const RadioButton = React.forwardRef(({
    className,
    status,
    focused,
    hover,
    setKeyDown,
    setFocus,
    setHover,
    value,
    setValue,
}, ref) => (
    <div
        className={cx({
            'RadioButton': true,
            'RadioButton--checked': value === true,
            [`RadioButton--${status}`]: true,
            'RadioButton--focused': focused,
            'RadioButton--hover': hover,
            [className]: !!className,
        })}
        onMouseEnter={() => {
            setHover(true);
        }}
        onMouseLeave={() => {
            setHover(false);
        }}
        onFocus={() => {
            setFocus(true);
        }}
        onBlur={() => {
            setFocus(false);
        }}
        onClick={() => (status === STATUS_IDLE) && setValue(!value)}
        tabIndex='-1'
        onKeyDown={e => {
            setKeyDown(e.keyCode, e);
            const key = e.keyCode;
            if (key === KEY_SPACEBAR) {
                (status === STATUS_IDLE) && setValue(!value);
            }
        }}
        ref={ref} >
        <div className={cx('RadioButton__outer')}>
            {(value === true) && <span className={cx('RadioButton__inner')} />}
        </div>
    </div>
));

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
    className: PropTypes.string,
    status: PropTypes.oneOf([
        STATUS_IDLE,
        STATUS_DISABLED,
    ]),
    value: PropTypes.bool,
    focused: PropTypes.bool,
    hover: PropTypes.bool,
    setValue: PropTypes.func,
    setFocus: PropTypes.func,
    setHover: PropTypes.func,
    setKeyDown: PropTypes.func,
};

RadioButton.defaultProps = {
    status: STATUS_IDLE,
    focused: false,
    hover: false,
    setFocus: () => null,
    setHover: () => null,
    setValue: () => null,
    setKeyDown: () => null,
};

const RadioButtonComposer = compose(
    withAutoFocus,
    withValue,
    withFocusState,
    withStatic({
        STATUS_IDLE,
        STATUS_DISABLED,
    }),
);

export default RadioButtonComposer(RadioButton);
