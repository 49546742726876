import { REGEXP_BLOCK_USER_ON_PATHS } from 'config/constants';

const blockUserOnURLPaths = () => {
    try {
        const REGEXPS = REGEXP_BLOCK_USER_ON_PATHS.filter(entity =>
            entity.regexp?.test(window.location.href),
        );

        let blockUserOnPath = false;
        if (REGEXPS?.length > 0) {
            blockUserOnPath = REGEXPS.reduce(
                (prev, curr) => prev && curr?.match,
                true,
            );
        }

        if (blockUserOnPath) {
            return true;
        }
    } catch (e) {
        // Do nothing
        console.error(e);
    }
    return false;
};

export default blockUserOnURLPaths;
