import React, {Component, Fragment} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {compose} from '../../Util';
import {withStatic} from '../../Hoc.jsx';
import style from '../../../css/Overlay.css';
import Dom from '../../Dom';

const cx = classNames.bind(style);

const ANCHOR_TOP = 'top';
const ANCHOR_BOTTOM = 'bottom';
const ALIGN_LEFT = 'left';
const ALIGN_RIGHT = 'right';

class Overlay extends Component {
    constructor(props) {
        super(props);
        this.overlayContainer = Dom.createElement('div', {
            'class': cx({'Overlay': true, [this.props.overlayClass]: !!this.props.overlayClass}),
        });
        
        const evtHandlers = {
            ...(props.onClick && {'click': props.onClick}),
            ...(props.onMouseUp && {'mouseup': props.onMouseUp}),
        };

        Object.keys(evtHandlers).forEach( name => {
            this.overlayContainer.addEventListener(name, evt => {
                if (evt.target !== this.overlayContainer) return;
                evtHandlers[name](evt)
            }, false);
        });

        document.body.appendChild(this.overlayContainer);
    }

    componentWillUnmount() {
        document.body.removeChild(this.overlayContainer);
    }

    render() {
        const {dummy, anchor, children, align, width, rectPosition} = this.props;
        let rect;
        if (rectPosition && Object.keys(rectPosition)?.length > 0) {
            rect = rectPosition;
        } else {
            rect = dummy ? dummy.getBoundingClientRect() : {
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
            };
        }
        const styleWidth = width || rect.width;
        return createPortal(
            <Fragment>
                <div
                    className={cx({'Overlay__bounds': true, [this.props.portalClass]: !!this.props.portalClass})}
                    style={{
                        left: rect.left + (align === ALIGN_RIGHT ? rect.width - styleWidth : 0)
                            + window.scrollX,
                        top: rect.top + (anchor === ANCHOR_BOTTOM ? rect.height : 0)
                            + window.scrollY,
                        width: styleWidth,
                    }}>
                    {children}
                </div>
            </Fragment>,
            this.overlayContainer
        );
    }
}

Overlay.propTypes = {
    children: PropTypes.node.isRequired,
    anchor: PropTypes.oneOf([
        ANCHOR_TOP,
        ANCHOR_BOTTOM,
    ]),
    align: PropTypes.oneOf([
        ALIGN_LEFT,
        ALIGN_RIGHT,
    ]),
    width: PropTypes.number,
    dummy: PropTypes.any,
    onClick: PropTypes.func,
    overlayClass: PropTypes.string,
    portalClass: PropTypes.string,
    rectPosition: PropTypes.object,
};

Overlay.defaultProps = {
    anchor: ANCHOR_TOP,
    align: ALIGN_LEFT,
    width: 0,
    onClick: () => null,
    rectPosition: {},
};

export default compose(
    withStatic({
        ANCHOR_TOP, ANCHOR_BOTTOM, ALIGN_LEFT, ALIGN_RIGHT,
    }),
)(Overlay);
