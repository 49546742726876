import React from 'react';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import AliasInput from 'components/App/Shared/AliasInput';
import { clearBankAndBack } from 'actions/aisp';

// Higher order component AliasInput for AISP
const AispAliasInput = props => <AliasInput {...props} />;

AispAliasInput.propTypes = {
    bank: PropTypes.object,
    bankInfo: PropTypes.object,
    clearBankAndBack: PropTypes.func,
};

const mapStateToProps = ({ sharedService }) => ({
    bank: sharedService.getSelectedBank(),
    bankInfo: sharedService.getSelectedBankInfo(),
});

const mapDispatchToProps = {
    clearBankAndBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(AispAliasInput);
