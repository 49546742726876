import {
    clearBank,
    clearMembers,
    completeFlow,
    completeFlowFromApp,
    redirectToBankV2,
    loginFromLocalStorage,
    logoutFromLocalStorage,
    setBank,
    yieldBanks,
    setBankInfo,
    setLinkingRequestId,
    setRecentBank,
    setupBankRedirectUrl,
    showBankRedirectionScreen,
    terminateFlow,
    validateAlias,
    setCountriesList,
    clearInstructionsSource,
    initiateBankAuthFlow,
    setOneStepSupport,
    isBankLive,
    setBankStatus,
} from 'actions/shared';
import { backOrGoTo, changeRoute, setStep } from 'actions/shared/route';
import {
    addCancelTokenOnClose,
    getAndValidateAccounts,
    getBankFromLocalStorage,
    getTempMemberLS,
    getTokenClient,
    setTokenRequestStatus,
    replaceExistingAccessToken,
    fetchCustomerTrackingMetadata,
} from 'actions/shared/api';
import AccessTokenHelper from 'services/AccessTokenHelper';
import {
    AISP_APP_INSTALL,
    AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST,
    AISP_CONSENT_BANK_FIRST,
    AISP_ENTER_ALIAS,
    AISP_SELECT_BANK_BANK_FIRST,
    LOADING_STEP,
    ROUTE_AISP,
    TOKEN_TYPE_ACCESS,
    TOKEN_PROVIDER,
    webAppUrl,
    INTERNAL_TOKEN_REQUEST_STATUS as requestStatus,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import { EXCEPTION_NO_RESOURCE } from 'config/exceptions';
import { TokenException } from '@token-io/lib-web-app';

const Token = getTokenClient();

export const setAispTokenType = ({
    tppAlias,
    resources,
    actingAs,
    memberId,
    source,
}) => ({
    type: 'SET_TOKEN_TYPE',
    tokenType: TOKEN_TYPE_ACCESS,
    tppAlias,
    resources,
    actingAs,
    memberId,
    source,
});

// Sets the Iban if present
export const setInstructionsSourceIban = source => ({
    type: 'SET_INSTRUCTIONS_SOURCE_IBAN',
    source,
});

export const setMandatoryAccessFields = mandatoryFields => ({
    type: 'SET_MANDATORY_ACCESS_FIELDS',
    mandatoryFields,
});

// thunks

export const initiateAispFlow = resources => async dispatch => {
    dispatch(changeRoute(ROUTE_AISP));
    if (!resources?.length) {
        throw new TokenException(
            EXCEPTION_NO_RESOURCE,
            'Access token must have a resource',
        );
    }

    await dispatch(loginFromLocalStorage());

    await dispatch(setCountriesList());

    dispatch(acceptTermsBankFirstFlow());
};

export const acceptTermsBankFirstFlow = () => async (
    dispatch,
    { userService, tokenRequestService, tokenService },
) => {
    const defaultBankId = tokenRequestService.getDefaultBankId();
    const isDefaultBank = !!defaultBankId;
    const bank = getBankFromLocalStorage();
    if (defaultBankId) {
        const { banks } = await dispatch(
            yieldBanks({ ids: [defaultBankId], isDefaultBank }),
        );
        let defaultBank = banks?.[0];
        defaultBank = {
            ...defaultBank,
            selectedCountry: defaultBank.countries[0],
        };
        await dispatch(setBankAndProceedToConsent(defaultBank));
    } else if (userService.hasPairedMembers() && bank) {
        dispatch(setBank(bank));
        if (tokenRequestService.getHideConsent()) {
            await dispatch(requestAccess(bank));
        } else {
            dispatch(changeRoute(ROUTE_AISP));
            typeof tokenService.getResources()[0] === 'string'
                ? dispatch(setStep(AISP_CONSENT_BANK_FIRST))
                : dispatch(setStep(AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST));
        }
    } else {
        dispatch(setStep(AISP_SELECT_BANK_BANK_FIRST));
    }
};

export const setBankAndProceed = bank => async (
    dispatch,
    { tokenRequestService },
) => {
    dispatch(setStep(LOADING_STEP));
    await dispatch(setRecentBank(bank));
    dispatch(setBank(bank));
    if (bank.supportsCheckoutFlowV2) {
        // Only for direct bank backword compatibility
        await dispatch(redirectToBankV2());
    } else {
        const defaultAlias = tokenRequestService.getDefaultAlias();
        const guest = await dispatch(getTempMemberLS());
        dispatch(setBankInfo(await guest.getBankInfo(bank.id)));
        const requestId = await tokenRequestService.getRequestId();
        const customRedirectURL = tokenRequestService.getTppFeature(
            featureConfig.CUSTOM_WEB_APP_URL,
        );
        if (customRedirectURL) {
            dispatch(
                setLinkingRequestId(
                    await guest.storeLinkingRequest(
                        `${customRedirectURL}/app/auth/callback`,
                        requestId,
                    ),
                ),
            );
        } else {
            dispatch(
                setLinkingRequestId(
                    await guest.storeLinkingRequest(
                        `${webAppUrl}/app/auth/callback`,
                        requestId,
                    ),
                ),
            );
        }
        if (bank.supportsAppless) {
            await dispatch(redirectToBank());
        } else if (defaultAlias) {
            const defaultAliasValue =
                typeof defaultAlias === 'object' && defaultAlias.value
                    ? defaultAlias.value
                    : defaultAlias;
            await dispatch(validateAlias(defaultAliasValue, 'EMAIL'));
        } else {
            dispatch(setStep(AISP_ENTER_ALIAS));
        }
    }
};

export const setBankAndProceedToConsent = bank => async (
    dispatch,
    { tokenRequestService, tokenService },
) => {
    dispatch(setStep(LOADING_STEP));
    const isSelectedBankLive = await dispatch(isBankLive(bank.id));
    if (!isSelectedBankLive) {
        await dispatch(setBankStatus(bank.name, isSelectedBankLive));
        await dispatch(backToBankSelector());
        return;
    }

    // Set token request result internal status
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_BANK_SELECTED),
    );

    await dispatch(setRecentBank(bank));
    dispatch(setBank(bank));

    // Since one step support is now decided through mandatory fields
    // And mandatory fields does not have provision for AIS flow
    dispatch(setOneStepSupport(false));

    if (tokenRequestService.getHideConsent()) {
        dispatch(proceedToBank());
    } else {
        if (typeof tokenService.getResources()[0] === 'string') {
            dispatch(setStep(AISP_CONSENT_BANK_FIRST));
        } else {
            dispatch(setStep(AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST));
        }
    }
};

export const noApp = () => async (dispatch, { sharedService }) => {
    const bank = sharedService.getSelectedBank();
    if (bank.supportsCheckoutFlowV2) {
        await dispatch(redirectToBankV2());
    } else if (bank && bank.supportsAppless) {
        await dispatch(redirectToBank());
    } else {
        dispatch(setStep(AISP_APP_INSTALL));
    }
};

export const backToBankSelector = () => dispatch => {
    dispatch(clearBank());
    dispatch(clearInstructionsSource());
    dispatch(setStep(AISP_SELECT_BANK_BANK_FIRST));
};

export const proceedToBank = () => async (
    dispatch,
    { tokenRequestService, sharedService, userService },
) => {
    dispatch(setStep(LOADING_STEP));

    // Set token request result internal status
    await dispatch(
        setTokenRequestStatus(requestStatus.PENDING_WEB_APP_CONSENT_ACCEPTED),
    );

    const bank = sharedService.getSelectedBank();
    const localStorageBank = getBankFromLocalStorage();

    await Token.updateTokenRequest(tokenRequestService.getRequestId(), {
        ...tokenRequestService.getRequestOptions(),
        bankId: sharedService.getSelectedBankId(),
    });

    const defaultBankId = tokenRequestService.getDefaultBankId() || '';

    if (
        userService.hasPairedMembers() &&
        localStorageBank &&
        defaultBankId === ''
    ) {
        await dispatch(requestAccess(localStorageBank));
    } else if (bank.provider !== TOKEN_PROVIDER) {
        dispatch(initiateBankAuthFlow());
    } else {
        if (bank.supportsCheckoutFlowV2) {
            // Only for direct bank backword compatibility
            await dispatch(redirectToBankV2());
        } else {
            const defaultAlias = tokenRequestService.getDefaultAlias();
            const guest = await dispatch(getTempMemberLS());
            dispatch(setBankInfo(await guest.getBankInfo(bank.id)));
            const requestId = await tokenRequestService.getRequestId();
            const customRedirectURL = tokenRequestService.getTppFeature(
                featureConfig.CUSTOM_WEB_APP_URL,
            );
            if (customRedirectURL) {
                dispatch(
                    setLinkingRequestId(
                        await guest.storeLinkingRequest(
                            `${customRedirectURL}/app/auth/callback`,
                            requestId,
                        ),
                    ),
                );
            } else {
                dispatch(
                    setLinkingRequestId(
                        await guest.storeLinkingRequest(
                            `${webAppUrl}/app/auth/callback`,
                            requestId,
                        ),
                    ),
                );
            }
            if (bank.supportsAppless) {
                await dispatch(redirectToBank());
            } else if (defaultAlias) {
                const defaultAliasValue =
                    typeof defaultAlias === 'object' && defaultAlias.value
                        ? defaultAlias.value
                        : defaultAlias;
                await dispatch(validateAlias(defaultAliasValue, 'EMAIL'));
            } else {
                dispatch(setStep(AISP_ENTER_ALIAS));
            }
        }
    }
};

export const clearBankAndBack = () => async (
    dispatch,
    { tokenService, tokenRequestService, sharedService },
) => {
    await logoutFromLocalStorage();
    dispatch(clearMembers());
    const hasDefaultBankId = tokenRequestService.getDefaultBankId();
    if (hasDefaultBankId) {
        return dispatch(
            terminateFlow({
                error: 'access_denied',
                message: 'Token window was cancelled',
            }),
        );
    }
    if (typeof tokenService.getResources()[0] === 'string') {
        dispatch(backOrGoTo(AISP_CONSENT_BANK_FIRST, true));
    } else {
        dispatch(backOrGoTo(AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST, true));
    }
};

export const requestAccess = bank => async (
    dispatch,
    { userService, tokenService, tokenRequestService },
) => {
    dispatch(setStep(LOADING_STEP));
    await dispatch(setRecentBank(bank));
    dispatch(setBank(bank));
    const aispAlias = tokenRequestService.getTppAlias();
    const member = userService.getFirstPairedMember();
    const resources = await AccessTokenHelper.formatResources(
        tokenService.getResources(),
        member,
        bank.id,
    );
    dispatch(setBankInfo(await member.getBankInfo(bank.id)));
    await dispatch(getAndValidateAccounts(member, bank));
    let token;
    const accessTokenBuilder = await dispatch(
        createAccessTokenBuilderFromTokenRequest(member),
    );
    try {
        token = await accessTokenBuilder.execute();
    } catch (e) {
        if (e.message.indexOf('ALREADY_EXISTS') !== -1) {
            token = await replaceExistingAccessToken(
                member,
                aispAlias,
                resources,
            );
        } else {
            throw e;
        }
    }
    await addCancelTokenOnClose(member, token.id);
    const { status } = await member.endorseToken(token.id);
    if (status === 'SUCCESS') {
        await dispatch(
            completeFlow(tokenRequestService.getRequestId(), token.id, member),
        );
    } else {
        await member.cancelToken(token.id);
        await dispatch(completeFlowFromApp(await member.firstAlias()));
    }
};

// This gets called when we want to redirect to the bank, in the appless flow. The redux state
// that we need is stored in localStorage, and an appless member is created and stored. Finally.
// the browser is redirected to the bank's website
export const redirectToBank = () => async (
    dispatch,
    { sharedService, tokenRequestService },
) => {
    dispatch(showBankRedirectionScreen());
    const bankInfo = sharedService.getSelectedBankInfo();
    const requestId = await tokenRequestService.getRequestId();
    const linkingRequestId = tokenRequestService.getLinkingRequestId();
    let redirectUrl;
    const customRedirectURL = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_WEB_APP_URL,
    );
    if (customRedirectURL) {
        redirectUrl = `${
            bankInfo.bankLinkingUri
        }&redirect-uri=${encodeURIComponent(
            `${customRedirectURL}/app/auth/callback`,
        )}&request-id=${requestId}&linking-request-id=${linkingRequestId}`;
    } else {
        redirectUrl = `${
            bankInfo.bankLinkingUri
        }&redirect-uri=${encodeURIComponent(
            `${webAppUrl}/app/auth/callback`,
        )}&request-id=${requestId}&linking-request-id=${linkingRequestId}`;
    }
    await dispatch(setupBankRedirectUrl(redirectUrl));
};

export const applessAisp = accessToken => async (
    dispatch,
    { sharedService },
) => {
    const guest = Token.getMember(Token.BrowserCryptoEngine);
    const customerTrackingMetadata = await fetchCustomerTrackingMetadata();
    guest.addCustomerTrackingMetadata(customerTrackingMetadata);
    const bank = sharedService.getSelectedBank();
    await guest.linkAccounts({ bankId: bank.id, accessToken });
    const accessTokenBuilder = await dispatch(
        createAccessTokenBuilderFromTokenRequest(guest),
    );
    const token = await accessTokenBuilder.execute();
    await addCancelTokenOnClose(guest, token.id);
    await guest.endorseToken(token.id);
    await dispatch(completeFlow(token.tokenRequestId, token.id, guest));
};

export const createAccessTokenBuilderFromTokenRequest = member => async (
    dispatch,
    { tokenService, sharedService, tokenRequestService },
) => {
    const tokenRequestId = tokenRequestService.getRequestId();
    const aispAlias = tokenRequestService.getTppAlias();
    const bank = sharedService.getSelectedBank();
    const actingAs = tokenRequestService.getTppActingAs();
    const description = tokenRequestService.getDescription();
    const tokenExpiration = tokenRequestService.getTokenExpiration();
    const builder = member
        .createAccessTokenBuilder()
        .setTokenRequestId(tokenRequestId)
        .setToAlias(aispAlias);
    const resources = await AccessTokenHelper.formatResources(
        tokenService.getResources(),
        member,
        bank.id,
    );
    AccessTokenHelper.setResources(builder, resources);
    actingAs && builder.setActingAs(actingAs);
    description && builder.setDescription(description);
    tokenExpiration && builder.setExpiresAtMs(tokenExpiration);
    return builder;
};
