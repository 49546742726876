import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './DefaultErrorPage.css';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import { FormattedMessage } from 'react-intl';
import iconError from 'assets/icon-error.svg';

const cx = classNames.bind(style);

const DefaultErrorPage = ({ error, setDisplayFooterShadow }) => {
    const errorText =
        error && error.intlKey ? (
            <FormattedMessage
                id={`common.exception.${error.intlKey}`}
                values={error.intlValues}
            />
        ) : (
            <FormattedMessage id={'common.errorPage.generic'} />
        );

    const errorTitle =
        error && error.intlTitleKey ? (
            <p className={cx('ErrorPage-title-strong')}>
                <FormattedMessage
                    id={`common.errorPage.${error.intlTitleKey}`}
                />
            </p>
        ) : (
            <>
                <p className={cx('ErrorPage-title-strong')}>
                    <FormattedMessage id={'common.errorPage.error'} />
                </p>
                <p>
                    <FormattedMessage id={'common.errorPage.title1'} />
                    <br />
                    <FormattedMessage id={'common.errorPage.title2'} />
                </p>
            </>
        );

    useEffect(() => {
        setDisplayFooterShadow(false);
    }, []);

    return (
        <TokenContainer
            className={cx('ErrorPage-subcontainer')}
            noMargin
            noScrollable>
            <div className={cx('ErrorPage-body')}>
                <div className={cx('ErrorPage-wrapper')}>
                    <div className={cx('ErrorPage-title')}>
                        <img src={iconError} />
                        {errorTitle}
                    </div>
                    <div className={cx('ErrorPage-subtitle')}>
                        <p>{errorText}</p>
                    </div>
                </div>
            </div>
        </TokenContainer>
    );
};

DefaultErrorPage.propTypes = {
    error: PropTypes.object,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = ({ sharedService }) => {
    return {
        error: sharedService.getError(),
    };
};

export default connect(mapStateToProps)(DefaultErrorPage);
