import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import base64url from 'base64url';
import { connect } from 'reducers';
import FlickerImage from './../Shared/FlickerImage';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import { changeRoute } from 'actions/shared/route';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import style from './CaptureCredentials.css';
import {
    terminateFlow,
    initiateBankAuthFlow,
    initiateCredentialsCallback,
} from 'actions/shared';
import { initBankAuth } from 'actions/shared/api';
import TokenButtonsWrapper from './TokenButtonsWrapper.js';
import {
    ROUTE_LOADING,
    decoupleAuthorizationInterval,
    decoupleAuthorizationTimeout,
    CREDENTIAL_FIELD_TYPE as fieldType,
} from 'config/constants';
import { EXCEPTION_INITIATE_BANK_AUTH } from 'config/exceptions';
import {
    getButtonStatusOnCredsPage,
    segregateCredentials,
    pollUntil,
    getDeveloperId,
    sanitizeHTML,
} from 'util/index';
import { FormattedMessage, intlShape } from 'react-intl';
import TextInput from './../Shared/TextInput.js';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);
const IBAN = 'IBAN';

class CaptureCredentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credentialsValue: {},
            dropDown: false,
            decoupledFlow: false,
            ...segregateCredentials(props.overallCredentials),
        };
        this.pollResult = React.createRef();
    }

    confirm = () => {
        this.props.changeRoute(ROUTE_LOADING);
        const credentials = base64url.encode(
            JSON.stringify(this.state.credentialsValue),
        );
        this.props.initiateBankAuthFlow(credentials);
    };

    displayCredentialField = (data, isPassword) => {
        const intl = this.props.intl;
        return (
            <div key={data.displayName} className={cx('Credentials-data-row')}>
                <div
                    className={cx({
                        'Credentials-data-row-title': true,
                        'Credentials-data-row-title--fixSize': true,
                    })}>
                    {data.displayName}
                </div>
                <div
                    className={cx({
                        'Credentials-data-row-detail': true,
                        'Credentials-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e => {
                            if (data?.id?.toUpperCase()?.trim() === IBAN)
                                e.target.value = e.target.value?.toUpperCase();
                            this.setState({
                                credentialsValue: {
                                    ...this.state.credentialsValue,
                                    [data.id]: e.target.value,
                                },
                            });
                        }}
                        placeholder={
                            intl.formatMessage({
                                id: 'common.credentials.enter',
                            }) +
                            ' ' +
                            data.displayName +
                            ' ' +
                            intl.formatMessage({
                                id: 'common.credentials.value',
                            })
                        }
                        key={'credentials' + data.displayName}
                        type={isPassword ? 'password' : 'text'}
                        name="credentials_inputField"
                        dataTip={data.description}
                        className={cx({
                            TextInput__inner__input: true,
                            'Credential-field': true,
                        })}
                        aria-required="true"
                        value={
                            (this.state.credentialsValue &&
                                this.state.credentialsValue[data.id]) ||
                            ''
                        }
                    />
                </div>
            </div>
        );
    };

    displayOptions = data => {
        const customOptions = data.options?.map(option => {
            const [scaCode, ...rest] = option.value
                ?.split(/:(.+)/)
                .filter(Boolean);
            return {
                ...option,
                value:
                    Array.isArray(rest) && rest.length > 0 ? rest[0] : scaCode,
            };
        });

        return (
            <div key={data.displayName} className={cx('Credentials-data-row')}>
                <div
                    className={cx({
                        'Credentials-data-row-title': true,
                        'Credentials-data-row-title--fixSize': true,
                    })}>
                    {data.displayName}
                </div>
                <div
                    className={cx({
                        'Credentials-data-row-detail': true,
                        'Credentials-data-row-detail--fixSize': true,
                    })}>
                    <StaticDataProvider
                        useCustomData
                        customData={customOptions}
                        idKey={'id'}
                        titleKey={'id'}
                        dataKey={'value'}
                        customSetValue={async (
                            slctdValue,
                            { doneCallback },
                        ) => {
                            const value = data.options?.find(
                                option => option.id === slctdValue.id,
                            );
                            await this.handleOptionChange(data.id, value);
                            doneCallback();
                        }}
                        value={
                            this.state.option === undefined
                                ? null
                                : this.state.option
                        }
                        withPointer>
                        <Dropdown
                            className={'Credentials-options'}
                            listClass={'Credentials-options-dropdown'}
                            overlayClass={'Credentials-options-overlay'}
                            rowClass={'Credentials-options-item'}
                            dropDownState={this.state.dropDown}
                            placeholder={'Please select'}
                            scrollClass={cx('Credentials-options-scroll')}
                            setDropDownState={this.setDropDownState}
                            allowDeselect
                        />
                    </StaticDataProvider>
                </div>
            </div>
        );
    };

    handleOptionChange = (id, selectedOption) => {
        this.setState({
            credentialsValue: {
                ...this.state.credentialsValue,
                [id]: selectedOption?.value,
            },
            option: selectedOption,
            dropDown: false,
        });
    };

    setDropDownState = state => {
        this.setState({ dropDown: state });
    };

    async componentDidMount() {
        this.props.setDisplayFooterShadow(false);
        if (this.state?.decoupledFlow) {
            const emptyCredentials = base64url.encode(JSON.stringify({}));
            let result;
            try {
                result = await pollUntil({
                    fn: () =>
                        initBankAuth({
                            credentials: emptyCredentials,
                            requestId: this.props.tokenRequestId,
                            useCredentialFlow: true,
                            developerId: this.props.developerId,
                        }),
                    validate: response => {
                        if (response) {
                            if (this.pollResult.current) {
                                if (
                                    JSON.stringify(response) !==
                                    JSON.stringify(this.pollResult.current)
                                ) {
                                    this.pollResult.current = response;
                                    return true;
                                }
                            } else {
                                const result = segregateCredentials(
                                    response?.credentialFields?.fields,
                                );
                                if (!result.decoupledFlow) {
                                    this.pollResult.current = response;
                                    return true;
                                }
                            }
                            this.pollResult.current = response;
                            return false;
                        }
                    },
                    interval: decoupleAuthorizationInterval,
                    timeout: decoupleAuthorizationTimeout,
                    cancelledCb: () =>
                        this.props.paymentFailure({
                            error: 'access_denied',
                            message: 'Timeout: Final Sca status not found',
                        }),
                    failOnReject: true,
                });
            } catch (e) {
                this.props.paymentFailure({
                    error: EXCEPTION_INITIATE_BANK_AUTH,
                    message: e.response?.data,
                });
            }
            this.props.initiateCredentialsCallback(result);
        }
    }

    render() {
        const {
            captureCredentials,
            staticImages,
            flickerCode,
            psuMessages,
            decoupledFlow,
            credentialsValue,
        } = this.state;
        const { declinePayment, selectedBank } = this.props;
        const status =
            Button[
                getButtonStatusOnCredsPage(captureCredentials, credentialsValue)
            ];

        return (
            <Fragment>
                <TokenContainer
                    className={cx('Credentials-subcontainer')}
                    noMargin>
                    <div
                        className={cx({
                            'Credentials-title-margin': true,
                        })}>
                        {!decoupledFlow && (
                            <>
                                <TokenTitle>
                                    <FormattedMessage
                                        id={'common.credentials.title'}
                                    />
                                </TokenTitle>
                                <TokenTitle
                                    className={cx(
                                        'Credentials-bank-information',
                                    )}>
                                    {selectedBank.name}
                                    <img
                                        className="Credentials-bank-logo"
                                        src={selectedBank.logoUri}
                                        alt={selectedBank.name}
                                    />
                                </TokenTitle>
                                <TokenTitle sub>
                                    <FormattedMessage
                                        id={'common.credentials.subtitle1'}
                                        tagName={'p'}
                                    />
                                </TokenTitle>
                            </>
                        )}
                        {psuMessages?.map((data, i) => {
                            return (
                                <TokenTitle
                                    sub
                                    className={cx(
                                        'Credentials-psumessage-container',
                                    )}
                                    key={i}>
                                    <span
                                        className={cx('Credentials-psumessage')}
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHTML(
                                                data?.description,
                                            ),
                                        }}>
                                    </span>
                                </TokenTitle>
                            );
                        })}
                    </div>
                    <div className={cx('Credentials-static-images')}>
                        {staticImages?.map((data, i) => {
                            return (
                                <div key={i}>
                                    <img
                                        src={
                                            'data:image/png;base64,' +
                                            data.image
                                        }
                                        className={cx(
                                            'Credentials-static-image',
                                        )}
                                        alt=""
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={cx('Credentials-flicker-image-container')}>
                        {flickerCode && (
                            <div className={cx('Credentials-flicker-image')}>
                                <FlickerImage flickerCode={flickerCode} />
                            </div>
                        )}
                    </div>
                    {captureCredentials?.length > 0 && (
                        <div className={cx('Credentials-data')}>
                            <TokenTitle sub>
                                {captureCredentials?.map(data => {
                                    switch (data.type) {
                                        case fieldType.OPTIONS:
                                            return this.displayOptions(data);
                                        case fieldType.PASSWORD:
                                            return this.displayCredentialField(
                                                data,
                                                true,
                                            );
                                        default:
                                            return this.displayCredentialField(
                                                data,
                                            );
                                    }
                                })}
                            </TokenTitle>
                        </div>
                    )}
                </TokenContainer>

                {!decoupledFlow && (
                    <TokenButtonsWrapper>
                        <Button
                            status={status}
                            onClick={this.confirm}
                            text={
                                <FormattedMessage id={'common.button.next'} />
                            }
                        />
                        <Button
                            type={Button.TYPE_GHOST_WARNING}
                            onClick={declinePayment}
                            status={Button.STATUS_IDLE}
                            text={
                                <FormattedMessage
                                    id={'common.button.decline'}
                                />
                            }
                        />
                    </TokenButtonsWrapper>
                )}
            </Fragment>
        );
    }
}

CaptureCredentials.propTypes = {
    overallCredentials: PropTypes.array,
    declinePayment: PropTypes.func,
    tokenRequestId: PropTypes.string,
    changeRoute: PropTypes.func,
    traceId: PropTypes.string,
    paymentFailure: PropTypes.func,
    bankAuthStatus: PropTypes.string,
    initiateBankAuthFlow: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    setDisplayFooterShadow: PropTypes.func,
    handleFinalScaStatus: PropTypes.func,
    developerId: PropTypes.string,
    initiateCredentialsCallback: PropTypes.func,
    selectedBank: PropTypes.object,
};

const mapStateToProps = ({
    tokenService,
    tokenRequestService,
    sharedService,
}) => {
    const overallCredentials = tokenService.getCredentials();
    const bankAuthStatus = tokenService.getBankAuthStatus();
    const requestId = tokenRequestService.getRequestId();
    const traceId = tokenService.getTraceId();
    const customDevKey = sharedService.getCustomDevKey();
    const developerId = getDeveloperId(customDevKey);
    const selectedBank = sharedService.getSelectedBank();
    return {
        overallCredentials: overallCredentials || [],
        tokenRequestId: requestId,
        traceId,
        bankAuthStatus,
        developerId,
        selectedBank,
    };
};

const mapDispatchToProps = {
    declinePayment: () =>
        terminateFlow({
            error: 'access_denied',
            message: 'Payment declined',
        }),
    paymentFailure: ({ error, message }) =>
        terminateFlow({
            error,
            message,
        }),
    changeRoute,
    initiateBankAuthFlow,
    initiateCredentialsCallback,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaptureCredentials);
