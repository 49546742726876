import React from 'react';
import AppInstall from 'components/App/Shared/AppInstall';
import { injectIntl, intlShape } from 'react-intl';

// Higher order component AppInstall for AISP
const AispAppInstall = ({ intl, ...props }) => (
    <AppInstall
        {...props}
        strings={[
            intl.formatMessage({ id: 'aisp.appInstall.subtitle' }),
            intl.formatMessage({ id: 'common.appInstall.subtitle' }),
        ]}
    />
);

AispAppInstall.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(AispAppInstall);
