import React from 'react';
import PropTypes from 'prop-types';
import {compose} from '../../Util';
import {withStatic} from '../../Hoc.jsx';

import approval from '../../../assets/approval.png';
import approval2x from '../../../assets/approval@2x.png';
import approval3x from '../../../assets/approval@3x.png';

import poweredByToken from '../../../assets/poweredByToken.png';
import poweredByToken2x from '../../../assets/poweredByToken@2x.png';
import poweredByToken3x from '../../../assets/poweredByToken@3x.png';

const APPROVAL = 'approval';
const POWERED_BY_TOKEN = 'poweredByToken';

const images = {
    [APPROVAL]: [
        approval,
        approval2x,
        approval3x,
    ],
    [POWERED_BY_TOKEN]: [
        poweredByToken,
        poweredByToken2x,
        poweredByToken3x,
    ],
};

export const TokenImage = ({id, className}) => {
    const img = images[id];
    return (
        <picture>
            <source srcSet={`${img[0]}, ${img[1]} 2x, ${img[2]} 3x`} />
            <img src={img[0]} alt={id} className={className} />
        </picture>
    );
};

TokenImage.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default compose(
    withStatic({APPROVAL, POWERED_BY_TOKEN}),
)(TokenImage);
