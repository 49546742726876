import {
    TOKEN_ENV,
    INITIATE_BANK_AUTH_PATH,
    appNotificationInterval,
    RESOLVE_TOKEN_REQUEST_PATH,
    GET_BANK_STATUS,
    VRP_REQUEST_PATH,
    VRP_DECLINE_PATH,
    FETCH_OVERALL_TPP_CONFIGURATIONS,
    GET_MEMBER_INFO,
    appNotificationTimeout,
    sdkErrorLoggingEnabled,
    sendErrorReports,
    tokenRootUrl,
    WEB_APP_EVENTS,
    GET_TOKEN_REQUEST_RESULT_WITH_STATUS_PATH,
    webAppUrl,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import {
    poll,
    getSentryInterceptor,
    getDeviceId,
    getDeveloperId,
    getInstantDevKey,
    localStorageWrapper,
} from 'util/index';
import {
    clearMembers,
    logoutFromLocalStorage,
    terminateFlow,
} from 'actions/shared/index';
import {
    INTL_EXCEPTION_APP_NOTIFICATION_TIMEOUT,
    INTL_EXCEPTION_NO_LINKED_ACCOUNTS,
    EXCEPTION_INITIATE_BANK_AUTH,
    EXCEPTION_UPDATE_VRP_CONSENT_BANK,
    INTL_EXCEPTION_REQUEST_FAILED,
} from 'config/exceptions';
import { TokenException } from '@token-io/lib-web-app';
import { TokenClient } from '@token-io/app';
import {
    TppIntegrationClient,
    storeWebappEvent,
    TokenApiClient,
} from 'actions/helpers/Api';
import axios from 'axios';

let TOKEN, appNotificationId, cancelTokenRequest, invalidateNotificationRequest;

const webAppClient = axios.create({
    baseURL: window.location.origin,
});

export const setTempMember = member => ({
    type: 'SET_TEMP_MEMBER',
    member,
});

export const getTokenClient = () => {
    if (TOKEN) return TOKEN;
    TOKEN = new TokenClient({
        env: TOKEN_ENV,
        developerKey: getInstantDevKey(),
        loggingEnabled: sdkErrorLoggingEnabled,
        customResponseInterceptor: sendErrorReports && getSentryInterceptor(),
    });
    return TOKEN;
};

const Token = getTokenClient();

// Token API calls
export const enableIframePassthrough = () => {
    if (document.domain.endsWith(tokenRootUrl)) {
        document.domain = tokenRootUrl.substring(1);
        Token.Util.enableIframePassthrough(TOKEN_ENV);
    }
};

export const saveBankToLocalStorage = bank => {
    localStorageWrapper.set('bank', bank);
};

export const getBankFromLocalStorage = () => {
    return localStorageWrapper.get('bank');
};

export const getMemberInfo = async (memberId, subTppRefId) => {
    try {
        const result = {};
        const response = await TppIntegrationClient({
            method: 'get',
            url: GET_MEMBER_INFO.replace(/{[\w]+}/g, memberId),
        });
        const memberInfo = response.data?.memberInfo;
        const { aliases, realm, ...rest } = memberInfo;
        const _aliases = aliases?.verified || aliases?.unverified;
        result.memberInfo = { realm, ...rest };

        const realmId = realm?.id;

        if (_aliases?.length > 0) {
            if (realmId) {
                result.memberInfo = {
                    ...result.memberInfo,
                    alias:
                        _aliases.filter(
                            val => val?.realmId === realmId,
                        )?.[0] || _aliases[0],
                };
            } else {
                result.memberInfo = {
                    ...result.memberInfo,
                    alias: _aliases?.[0],
                };
            }
        }

        const configResp = await TppIntegrationClient({
            method: 'get',
            url: FETCH_OVERALL_TPP_CONFIGURATIONS.replace(
                /{MEMBER_ID}/g,
                memberId,
            )
                .replace(/{REALM_ID}/g, realmId)
                .replace(/{SUB_TPP_ID}/g, subTppRefId),
        });
        result.tppConfigurations = configResp.data?.configurations;
        result.bankSelectionPreset = configResp.data?.bankSelectionPreset;

        return result;
    } catch (e) {
        console.log(e); // eslint-disable-line
        throw new TokenException(INTL_EXCEPTION_REQUEST_FAILED, e.message);
    }
};

export const declineVrpConsent = async vrpId => {
    if (!vrpId) return;
    try {
        await TppIntegrationClient({
            method: 'post',
            url: VRP_DECLINE_PATH.replace(/{[\w]+}/g, vrpId),
        });
    } catch (e) {
        console.error(e); // eslint-disable-line
    }
};

export const updateVrpRequest = async (vrpId, updateOptions) => {
    if (!vrpId) return;
    try {
        const { bankId, pispConsentAccepted } = updateOptions;
        const response = await TppIntegrationClient({
            method: 'put',
            url: VRP_REQUEST_PATH.replace(/{[\w]+}/g, vrpId),
            data: {
                ...(bankId && {
                    bank_id: bankId,
                }),
                vrp_consent_id: vrpId,
                ...(pispConsentAccepted && {
                    pisp_consent_accepted: pispConsentAccepted,
                }),
            },
        });
        if (response.status === 200) {
            return response.data && response.data.vrpConsent;
        } else {
            throw new TokenException(
                EXCEPTION_UPDATE_VRP_CONSENT_BANK,
                'Error in updating the bank in vrp consent from TI',
            );
        }
    } catch (e) {
        console.log(e); // eslint-disable-line
        throw new TokenException(
            EXCEPTION_UPDATE_VRP_CONSENT_BANK,
            (e.response && e.response.data) || e.message,
        );
    }
};

export const getBankStatus = async (bankId, productType) => {
    if (!bankId) return;
    productType = productType === 'AIS' ? productType : 'SIP';
    const resp = await TokenApiClient({
        method: 'get',
        url: GET_BANK_STATUS.replace(/{BANK_ID}/g, bankId).replace(
            /{PRODUCT_TYPE}/g,
            productType,
        ),
    });
    return resp.data.status;
};

export const fetchVrpConsent = async vrpId => {
    if (!vrpId) return;
    const resp = await TppIntegrationClient({
        method: 'get',
        url: VRP_REQUEST_PATH.replace(/{[\w]+}/g, vrpId),
    });
    return resp.data;
};

export const fetchTokenRequest = async tokenRequestId => {
    const resp = await TppIntegrationClient({
        method: 'get',
        url: RESOLVE_TOKEN_REQUEST_PATH.replace(/{[\w]+}/g, tokenRequestId),
    });
    return resp.data;
};

export const fetchProfilePicture = async (member, memberId) => {
    let profilePicture;
    try {
        const imgData = await member.getProfilePicture(memberId, 'ORIGINAL');
        profilePicture = imgData.payload;
    } catch (e) {
        profilePicture = null;
    }
    return profilePicture;
};

export const setMemberReceiptEmail = async (member, email) => {
    await member.setReceiptContact('EMAIL', email);
};

export const getMemberProfileById = async (memberId, utilMember) => {
    if (!utilMember) return {};
    const tppName = await utilMember.getProfileName(memberId);
    const profilePicture = await fetchProfilePicture(utilMember, memberId);

    return {
        memberId,
        tppName,
        profilePicture,
    };
};

export const replaceExistingAccessToken = async (
    member,
    aispAlias,
    resources,
) => {
    const aispMember = await Token.resolveAlias(aispAlias);
    const existingToken = await member.getActiveAccessToken(aispMember.id);
    const { token } = await member.replaceAccessToken(existingToken, resources);
    return token;
};

export const pollForTokenRequestResult = async (
    tokenRequestId,
    notificationId,
) => {
    if (!notificationId) return;
    appNotificationId = notificationId;
    try {
        await addInvalidateNotificationOnClose(notificationId);
        return (
            (await poll(
                () => Token.getTokenRequestResult(tokenRequestId),
                appNotificationInterval,
                appNotificationTimeout,
                INTL_EXCEPTION_APP_NOTIFICATION_TIMEOUT,
                () => !appNotificationId,
                () =>
                    invalidateNotificationRequest() &&
                    removeInvalidateNotificationOnClose(),
            )) || {}
        );
    } finally {
        removeInvalidateNotificationOnClose();
    }
};

export const cancelPollForTokenRequestResult = async () => {
    if (appNotificationId) {
        await Token.invalidateNotification(appNotificationId);
        appNotificationId = null;
        removeInvalidateNotificationOnClose();
    }
};

export const addInvalidateNotificationOnClose = async notificationId => {
    if (!notificationId) return;
    // Setup blocking invalidate notification function
    const invalidateNotification = await Token.getBlockingInvalidateNotificationFunction(
        notificationId,
    );
    invalidateNotificationRequest = () => {
        invalidateNotification();
        return true;
    };
    window.addEventListener('beforeunload', invalidateNotificationRequest);
};

export const removeInvalidateNotificationOnClose = () => {
    appNotificationId = null;
    if (typeof invalidateNotificationRequest !== 'function') return true;
    window.removeEventListener('beforeunload', invalidateNotificationRequest);
    invalidateNotificationRequest = null;
    return true;
};

export const addCancelTokenOnClose = async (clientMember, tokenId) => {
    if (!tokenId) return;
    // Setup blocking cancel token function
    const cancelToken = await clientMember.getBlockingCancelTokenFunction(
        tokenId,
    );
    // Cancel Token before unload
    cancelTokenRequest = () => {
        cancelToken();
    };
    window.addEventListener('beforeunload', cancelTokenRequest);
};

export const removeCancelTokenOnClose = () => {
    if (typeof cancelTokenRequest !== 'function') return;
    window.removeEventListener('beforeunload', cancelTokenRequest);
    cancelTokenRequest = null;
};

export const getTempMember = () => async (dispatch, { userService }) => {
    if (userService.hasTempMember()) return userService.getTempMember();
    const tempMember = await Token.createMember(
        null,
        Token.MemoryCryptoEngine,
        'TRANSIENT',
    );
    dispatch(setTempMember(tempMember));
    return tempMember;
};

export const getTempMemberLS = () => async dispatch => {
    const tempMember = await Token.createMember(
        null,
        Token.BrowserCryptoEngine,
        'TRANSIENT',
    );
    dispatch(setTempMember(tempMember));
    return tempMember;
};

export const getAndValidateAccounts = (member, bank, withBalance) => async dispatch => {
    try {
        let accounts = await member.getAccounts();
        if (bank) {
            accounts = accounts.filter(acc => {
                return acc.bankId() === bank.id;
            });

            if (!accounts.length) {
                dispatch(clearMembers());
                await logoutFromLocalStorage();
                throw new TokenException(
                    INTL_EXCEPTION_NO_LINKED_ACCOUNTS,
                    'No linked accounts',
                    { BANK_NAME: bank.name },
                );
            }
        }
        if (withBalance) {
            accounts = await Promise.all(
                accounts.map(async acc => ({
                    id: acc.id(),
                    name: acc.name(),
                    bankId: acc.bankId(),
                    balance: await acc.getBalance(),
                })),
            );
        }
        return accounts;
    } catch (e) {
        dispatch(clearMembers());
        await logoutFromLocalStorage();
        throw e;
    }
};

export const fetchCustomerTrackingMetadata = async () => {
    let ipAddress;
    try {
        ipAddress = await webAppClient({
            method: 'get',
            url: '/fetch-ip-address',
        });
    } catch (e) {
        console.error(e); // eslint-disable-line
    }
    return {
        ...(ipAddress && { ipAddress: ipAddress?.data }),
        userAgent: window.navigator.userAgent,
        deviceId: getDeviceId(),
    };
};

export const fetchTokenRequestResultWithStatus = async (
    tokenRequestId,
    memberId,
) => {
    const params = {
        TOKEN_REQUEST_ID: tokenRequestId,
        MEMBER_ID: memberId,
    };
    try {
        const response = await TppIntegrationClient({
            method: 'get',
            url: GET_TOKEN_REQUEST_RESULT_WITH_STATUS_PATH.replace(
                /{([\w]+)}/g,
                function (match, group) {
                    return params[group];
                },
            ),
        });
        return response.data;
    } catch (e) {
        console.error(e); // eslint-disable-line
        return null;
    }
};

export const initBankAuth = async initBankAuthRequest => {
    try {
        const response = await TppIntegrationClient({
            method: 'post',
            url: INITIATE_BANK_AUTH_PATH,
            data: initBankAuthRequest,
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new TokenException(
                EXCEPTION_INITIATE_BANK_AUTH,
                'Error in initiate bank auth from TI',
            );
        }
    } catch (e) {
        console.error(e); // eslint-disable-line
        throw e;
    }
};

export const initiateBankAuthorization = request => async (
    dispatch,
    { sharedService, tokenRequestService },
) => {
    const requestId = tokenRequestService.getRequestId();
    const useCredentialFlow = tokenRequestService.getUseCredentialFlow();
    const customDevKey = sharedService.getCustomDevKey();
    const developerId = getDeveloperId(customDevKey);
    const bankId = sharedService.getSelectedBankId();

    let tokenRedirectUrl;
    const customRedirectURL = tokenRequestService.getTppFeature(
        featureConfig.CUSTOM_WEB_APP_URL,
    );
    if (customRedirectURL) {
        tokenRedirectUrl = `${customRedirectURL}/app/auth/callback`;
    } else {
        tokenRedirectUrl = `${webAppUrl}/app/auth/callback`;
    }

    let initBankAuthRequest = {
        requestId,
        useCredentialFlow,
        developerId,
    };

    if (request?.credentials) {
        initBankAuthRequest = {
            ...request,
            ...initBankAuthRequest,
        };
        // Store webapp event of this moment
        await dispatch(
            storeWebappEvent({
                event: WEB_APP_EVENTS.WEB_APP_CREDENTIALS_CAPTURED,
            }),
        );
    } else {
        initBankAuthRequest = {
            ...request,
            ...initBankAuthRequest,
            bankId,
            tokenRedirectUrl,
        };
    }

    try {
        return await initBankAuth(initBankAuthRequest);
    } catch (e) {
        await dispatch(
            terminateFlow({
                error: EXCEPTION_INITIATE_BANK_AUTH,
                message: e.response?.data,
            }),
        );
    }
};

export * from 'actions/helpers/Api/index.js';
