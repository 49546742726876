import Dom from '@token-io/lib-web-components/src/Dom';
import {
    OVERLAY_IS_LOADED,
    OVERLAY_CLOSE_ACTION,
    OVERLAY_INIT,
} from 'iframe/constants';
import Postie from '@token-io/lib-web-components/src/Postie';
import {
    generateNonce,
    isFunction,
} from '@token-io/lib-web-components/src/Util';

// View which loads the page with the token overlay
function TokenOverlayController({ url }) {
    const _self = this;
    const _element = window.document.body;
    const _id = generateNonce();

    let onload;
    let _overlayIframe = null;
    let _postie = null;
    let _frameLoaded = false;
    let _onOverlayLoaded = null;
    let _overlayCloseAction = null;

    const _attrs = {
        name: 'token-overlay-iframe',
        frameBorder: 0,
        style: {
            position: 'static',
            display: 'inline',
            width: '0',
            height: '0',
            zIndex: '9999',
        },
    };

    const popupFrameAttrs = {
        src: url + '/overlay',
    };

    function _updateToFrameOverlay() {
        Dom.applyAttrs(_overlayIframe, popupFrameAttrs);
    }

    function _updateStyles() {
        Dom.applyStyle(_overlayIframe, _attrs.style);
    }

    function _overlayIframeLoaded() {
        return _overlayIframe instanceof window.Node;
    }

    function _isVisible() {
        return window.getComputedStyle(_overlayIframe)['display'] !== 'none';
    }

    function _hide() {
        if (!_isVisible()) return;
        _attrs.style.position = 'static';
        _attrs.style.display = 'inline';
        _attrs.style.width = '0';
        _attrs.style.height = '0';
        _updateStyles();
        _destroy();
    }

    function _show() {
        _attrs.style.position = 'fixed';
        _attrs.style.display = 'block';
        _attrs.style.width = '100%';
        _attrs.style.height = '100%';
        _attrs.style.top = '0';
        _attrs.style.right = '0';
        _attrs.style.bottom = '0';
        _attrs.style.left = '0';
        _updateStyles();
    }

    function _isLoaded() {
        return _frameLoaded === true;
    }

    function _destroy() {
        _unbindListeners();
        _frameLoaded = false;
        _element.removeChild(_overlayIframe);
        _overlayIframe = null;
        onload = null;
    }

    function _onLoad() {
        if (onload) {
            onload();
        }
    }

    function _setupMessages(postie) {
        postie
            .onMessage(OVERLAY_IS_LOADED, () => {
                if (!_onOverlayLoaded) return;
                _onOverlayLoaded(_self);
            })
            .onMessage(OVERLAY_CLOSE_ACTION, () => {
                if (isFunction(_overlayCloseAction)) _overlayCloseAction();
            });
    }

    function _bindListeners() {
        if (!_overlayIframeLoaded()) return;
        _overlayIframe.addEventListener('load', _onLoad);
        _postie = new Postie({
            id: _id,
            source: window,
            destination: _overlayIframe.contentWindow,
            origin: url,
        });
        _setupMessages(_postie);
    }

    function _unbindListeners() {
        _overlayIframe.removeEventListener('load', _onLoad);
        _postie.destroy();
        _postie = null;
    }

    function _init() {
        onload = () => {
            _postie.dispatchMessage({
                type: OVERLAY_INIT,
            });
        };

        if (_overlayIframeLoaded()) {
            onload();
            return;
        }

        _frameLoaded = true;
        _overlayIframe = Dom.createElement('iframe', _attrs);
        _updateToFrameOverlay();
        _element.appendChild(_overlayIframe);
        _bindListeners();
    }

    _self.show = () => {
        _show();
    };

    _self.hide = () => {
        _hide();
    };

    _self.isVisible = () => {
        return _isVisible();
    };

    _self.isLoaded = () => {
        return _isLoaded();
    };

    _self.destroy = () => {
        _destroy();
        return _self;
    };

    _self.onLoad = fn => {
        if (!isFunction(fn)) throw new Error('Function expected');
        _onOverlayLoaded = fn;
    };

    _self.onCloseAction = fn => {
        if (!isFunction(fn)) throw new Error('Function expected');
        _overlayCloseAction = fn;
    };

    _self.bindOverlayCloseAction = fn => {
        if (!isFunction(fn)) throw new Error('Function expected');
        _overlayCloseAction = () => {
            fn();
        };
    };

    _init();
}

export default TokenOverlayController;
