import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import {
    LOADING_STEP,
    SPLASH_STEP,
    APP_APPROVAL,
    AISP_ENTER_ALIAS,
    AISP_APP_INSTALL,
    AISP_SELECT_BANK_BANK_FIRST,
    AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST,
    AISP_CONSENT_BANK_FIRST,
    AISP_CAPTURE_CREDENTIALS,
} from 'config/constants';
import AispAliasInput from 'components/App/Aisp/AispAliasInput';
import AispAppNotifier from 'components/App/Aisp/AispAppNotifier';
import AispAppInstall from 'components/App/Aisp/AispAppInstall';
import CaptureCredentials from 'components/App/Shared/CaptureCredentials';
import Loading from 'components/App/Shared/Loading';
import Background from 'components/App/Background';
import AispBankSelectorFirst from './AispBankSelectorFirst';
import ConsentBankFirstFlow from './ConsentBankFirstFlow';
import ConfirmFundsConsentBankFirstFlow from './ConfirmFundsConsentBankFirstFlow';
import SplashScreen from 'components/App/Shared/SplashScreen';

class Aisp extends Component {
    static getStepComponent(currentStep) {
        const steps = {
            [AISP_ENTER_ALIAS]: <AispAliasInput />,
            [APP_APPROVAL]: <AispAppNotifier />,
            [AISP_APP_INSTALL]: <AispAppInstall />,
            [AISP_SELECT_BANK_BANK_FIRST]: <AispBankSelectorFirst />,
            [AISP_CONSENT_BANK_FIRST]: <ConsentBankFirstFlow />,
            [AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST]: (
                <ConfirmFundsConsentBankFirstFlow />
            ),
            [AISP_CAPTURE_CREDENTIALS]: <CaptureCredentials />,
        };
        return steps[currentStep];
    }

    static getStepComponentWithoutBackground(currentStep) {
        const stepsWithoutBackground = {
            [LOADING_STEP]: <Loading />,
            [SPLASH_STEP]: <SplashScreen />,
        };
        return stepsWithoutBackground[currentStep];
    }

    render() {
        const { currentStep } = this.props;
        const component = Aisp.getStepComponent(currentStep) ? (
            <Background> {Aisp.getStepComponent(currentStep)} </Background>
        ) : (
            Aisp.getStepComponentWithoutBackground(currentStep)
        );

        return <>{component}</>;
    }
}

Aisp.propTypes = {
    currentStep: PropTypes.string.isRequired,
};

const mapStateToProps = ({ sharedService }) => ({
    currentStep: sharedService.getCurrentStep(),
});

export default connect(mapStateToProps)(Aisp);
