import { TOKEN_API_ADDRESS } from 'config/constants';
import axios from 'axios';

const TokenApiClient = axios.create({
    baseURL: TOKEN_API_ADDRESS,
    headers: {
        get: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        },
    },
});

export default TokenApiClient;
