import React, { Component } from 'react';
import Background from '../Background';
import TermsAndCondition from './TermsAndCondition';
import { connect } from 'reducers';
import enfuceTpplogo from 'assets/enfucelogo.png';
import PropTypes from 'prop-types';
import { isEnfuseLicenseTpp, isTppGmbh } from '../../../util';
import EnfuseTermsAndCondition from './EnfuseTermsAndConition';
import GMBHTermsAndConditions from './GMBHTermsAndConditions';

class Terms extends Component {
    render() {
        const { isEnfuse, isGmbh } = this.props;
        if (isGmbh) {
            return (
                <div>
                    <Background>
                        <GMBHTermsAndConditions />
                    </Background>
                </div>
            );
        } else if (isEnfuse) {
            return (
                <div>
                    <Background enfuseLogo={enfuceTpplogo}>
                        <EnfuseTermsAndCondition />
                    </Background>
                </div>
            );
        } else {
            return (
                <div>
                    <Background>
                        <TermsAndCondition />
                    </Background>
                </div>
            );
        }
    }
}

Terms.propTypes = {
    isEnfuse: PropTypes.bool,
    isGmbh: PropTypes.bool,
};
const mapStateToProps = ({ tokenRequestService, sharedService }) => {
    const realmId = tokenRequestService.getRealmIdFromMemberInfo();
    const { memberId } = tokenRequestService.getTppProfile();
    const selectedCountry = sharedService.getSelectedCountry();
    const isEnfuse = isEnfuseLicenseTpp(realmId, memberId, selectedCountry);
    const requestedRealmId = tokenRequestService.getRealmIdFromMemberInfo();
    const isGmbh = isTppGmbh(memberId) || isTppGmbh(requestedRealmId);
    return {
        isEnfuse,
        isGmbh,
    };
};

export default connect(mapStateToProps)(Terms);
