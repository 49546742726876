const getFeatureFromTppConfig = (
    configurations,
    featureKey,
    defaultValue = null,
) => {
    if (configurations && Object.keys(configurations).length !== 0) {
        const featureKeySplit = featureKey.split('.');
        let configKey = featureKeySplit[0];
        while (featureKeySplit.length > 1) {
            featureKeySplit.shift();
            if (
                configurations &&
                Object.prototype.hasOwnProperty.call(configurations, configKey)
            ) {
                configurations = configurations?.[configKey];
                configKey = featureKeySplit[0];
            } else {
                configKey = configKey.concat('.', featureKeySplit[0]);
            }
        }
        return typeof configurations?.[configKey] === 'boolean'
            ? !!configurations?.[configKey]
            : !isNullOrUndefined(configurations?.[configKey])
                ? configurations?.[configKey]
                : defaultValue;
    }
    return defaultValue;
};

const isNullOrUndefined = value => {
    if (value === null || value === undefined) {
        return true;
    }
    return false;
};

export default getFeatureFromTppConfig;
