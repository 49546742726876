import { mandatoryFieldsConfig as mandatoryConfig } from 'config/mandatoryFields';

export const isPropertiesNullOrEmpty = (find, attr) => {
    if (!find) {
        return true;
    }
    if (!attr) return find && Object.values(find).some(el => !el);
    else return find && attr.some(v => !find[v]);
};

const checkMandatoryFieldsPresent = (
    mandatoryFields,
    requiredMandatoryFields,
    type = 'transfer',
) => {
    const creditorAcc =
        mandatoryFields?.instructions?.transferDestinations?.[0];
    const creditorLegalNames = creditorAcc?.customerData?.legalNames?.[0];
    const creditorAgent =
        mandatoryFields?.stetMetadata?.beneficiary?.creditorAgent;
    const sourceBIC =
        mandatoryFields?.instructions?.source?.bic ||
        mandatoryFields?.instructions?.source?.account?.iban?.bic;

    if (
        requiredMandatoryFields?.fields?.includes(
            mandatoryConfig[type]?.debtorName,
        ) &&
        !mandatoryFields?.instructions?.source?.customerData?.legalNames?.[0]
    ) {
        return false;
    } else if (
        requiredMandatoryFields?.fields?.includes(
            mandatoryConfig[type]?.creditorName,
        ) &&
        !creditorLegalNames
    ) {
        return false;
    } else if (
        requiredMandatoryFields?.fields?.includes(
            mandatoryConfig[type]?.debtorBic,
        ) &&
        !sourceBIC
    ) {
        return false;
    } else if (
        requiredMandatoryFields?.['stetFields']?.includes(
            mandatoryConfig[type]?.stet?.creditorAgent,
        ) &&
        isPropertiesNullOrEmpty(creditorAgent, ['name', 'bicFi'])
    ) {
        return false;
    } else if (
        requiredMandatoryFields?.['polishapiFields']?.includes(
            mandatoryConfig[type]?.polishApi?.deliveryMode,
        ) &&
        isPropertiesNullOrEmpty(mandatoryFields?.polishApiMetadata, [
            'deliveryMode',
        ])
    ) {
        return false;
    } else if (
        requiredMandatoryFields?.fields?.includes(
            mandatoryConfig[type]?.iban,
        ) &&
        isPropertiesNullOrEmpty(mandatoryFields?.source?.accountIdentifier, [
            'iban',
        ])
    ) {
        return false;
    } else if (
        requiredMandatoryFields?.fields?.includes(
            mandatoryConfig[type]?.currency,
        ) &&
        isPropertiesNullOrEmpty(mandatoryFields?.source, ['currency'])
    ) {
        return false;
    }
    return true;
};

export default checkMandatoryFieldsPresent;
