import React, { useState } from 'react';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Button from '@token-io/lib-web-components/src/Components/Button';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames/bind';
import style from './BankRedirect.css';
import { redirectToBankUrl } from 'actions/shared';

const cx = classNames.bind(style);

const BankRedirect = ({ bank, intl, redirectToBankUrl, isRedirectReady }) => {
    const [isRedirecting, setRedirecting] = useState(false);
    return (
        <div className={cx('BankRedirect')}>
            <TokenContainer className={cx('BankRedirect-subcontainer')}>
                <TokenTitle>
                    <FormattedMessage id={'common.bankRedirect.title1'} />
                </TokenTitle>
                <FormattedMessage
                    id={'common.bankRedirect.title2'}
                    tagName={'p'}
                    values={{
                        BANK_NAME: bank.name,
                    }}
                />
                <Button
                    onClick={async () => {
                        setRedirecting(true);
                        await redirectToBankUrl();
                    }}
                    text={intl.formatMessage(
                        {
                            id: 'common.bankRedirect.button',
                        },
                        {
                            BANK_NAME: bank.name,
                        },
                    )}
                    status={
                        (!isRedirectReady && Button.STATUS_DISABLED) ||
                        (isRedirecting && Button.STATUS_LOADING) ||
                        Button.STATUS_IDLE
                    }
                />
            </TokenContainer>
        </div>
    );
};

BankRedirect.propTypes = {
    bank: PropTypes.object,
    redirectToBankUrl: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    isRedirectReady: PropTypes.bool,
};

const mapStateToProps = ({ sharedService, tokenService }) => ({
    bank: sharedService.getSelectedBank(),
    isRedirectReady: !!tokenService.getBankRedirectUrl(),
});

const mapDispatchToProps = {
    redirectToBankUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankRedirect);
