import { SpinnerWithLockBig } from '@token-io/lib-web-components/src/Components/Spinner';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { argbToRgba } from 'util/index';
import './BankSpinner.css';
import {
    defaultColors,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import Background from 'components/App/Background';

const Spinner_default = ({ bank, color, tppName, isAisp }) => (
    <SpinnerWithLockBig
        color={color}
        label={
            <FormattedMessage
                id={'common.bankSpinner.label'}
                tagName={'span'}
                values={{ BANK_NAME: bank.name }}
            />
        }
        message={
            <Fragment>
                <FormattedMessage
                    id={'common.bankSpinner.title1'}
                    tagName={'span'}
                />
                <FormattedMessage
                    id={'common.bankSpinner.title2'}
                    tagName={'strong'}
                    values={{ BANK_NAME: bank.name }}
                />
                <span className="no-before">
                    <FormattedMessage
                        id={
                            isAisp
                                ? 'common.bankSpinner.title3'
                                : 'common.bankSpinner.title4'
                        }
                        tagName={'span'}
                        values={{ TPP_NAME: tppName }}
                    />
                </span>
            </Fragment>
        }
    />
);

Spinner_default.propTypes = {
    bank: PropTypes.object,
    color: PropTypes.string,
    tppName: PropTypes.string,
    isAisp: PropTypes.bool,
};

const BankSpinner = ({ displayLogoOnBankRedirectScreen }) => {
    return displayLogoOnBankRedirectScreen ? (
        <Background>
            <Spinner />
        </Background>
    ) : (
        <Spinner />
    );
};

BankSpinner.propTypes = {
    displayLogoOnBankRedirectScreen: PropTypes.bool,
};

const mapStateToProps = ({ sharedService, tokenRequestService }) => {
    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };
    const { tppName } = tokenRequestService.getTppProfile();
    const actingAs = tokenRequestService.getTppActingAs();
    const tppAlias = tokenRequestService.getTppAlias();
    const isAisp = sharedService.isAisp();
    const displayLogoOnBankRedirectScreen = tokenRequestService.getTppFeature(
        featureConfig.DISPLAY_LOGO_ON_BANK_REDIRECT_SCREEN,
        false,
    );
    return {
        tppName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            tppAlias.value,
        bank: sharedService.getSelectedBank(),
        color:
            argbToRgba(colors['color-spinner']) ||
            argbToRgba(colors['color-primary']),
        displayLogoOnBankRedirectScreen,
        isAisp,
    };
};

const Spinner = connect(mapStateToProps)(Spinner_default);

export default connect(mapStateToProps)(BankSpinner);
