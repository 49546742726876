import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../../css/TokenContainer.css';
import classNames from 'classnames/bind';
import {forceRedrawForSafari} from '../../Util.js';

const cx = classNames.bind(style);

class TokenContainer extends Component {
    componentDidMount() {
        // hack for safari redraw issue
        forceRedrawForSafari();
    }
    render() {
        const {className, children, noMargin, noScrollable, innerRef, ...props} = this.props;
        const classNames = cx({
            'TokenContainer': true,
            'TokenContainer-margin': !noMargin,
            'TokenContainer-scrollable': !noScrollable,
            [className]: !!className,
        });
        return <div ref={innerRef} className={classNames} {...props}>{children}</div>;
    }
}

TokenContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    noMargin: PropTypes.bool,
    noScrollable: PropTypes.bool,
    innerRef: PropTypes.any,
};

export default TokenContainer;
