import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {isNull} from '../../Util';
import style from '../../../css/Scroll.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

class SimpleScroll extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        selected: PropTypes.number,
        autoFocus: PropTypes.bool,
        maxHeight: PropTypes.number,
        setSelected: PropTypes.func,
        withPointer: PropTypes.bool,
        className: PropTypes.string,
    }
    static defaultProps = {
        selected: null,
        autoFocus: false,
    }
    constructor(props) {
        super(props);
        this.state = {
            tabFocused: false,
        };
        this.container = React.createRef();
        this.overflow = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.showFocusOutline = this.showFocusOutline.bind(this);
    }
    /*
     * Scrolls list item into view
     *
     * @param {index} selected - index of the selected item to scroll into view, this is
     * essentially always the one that is denoted by cursor
     */
    handleScroll(selected) {
        const container = this.container.current;
        const overflow = this.overflow.current;
        if (selected === null || !overflow) {
            container !== null ? container.scrollTop = 0 : null;
            return 0;
        }
        if (selected >= overflow.children.length) {
            container.scrollTop = 0;
            return 0;
        }
        const el = overflow.children[selected];
        const offsetUp = el.offsetTop - overflow.offsetTop - container.scrollTop;
        const offsetDown = offsetUp + el.offsetHeight - container.offsetHeight;
        const scrollUp = offsetUp < 0;
        const scrollDown = offsetDown > 0;
        const scrollBy = scrollDown && offsetDown || scrollUp && offsetUp || 0;
        if (scrollBy === 0) return 0;
        container.scrollTop += scrollBy;
        return scrollBy;
    }

    showFocusOutline = visible => {
        this.setState({tabFocused: visible});
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected) {
            this.handleScroll(this.props.selected);
        }
    }
    componentDidMount() {
        const {autoFocus} = this.props;
        if (this.overflow.current && autoFocus) {
            this.focusTimeout = setTimeout(() => {
                this.overflow.current.focus();
            }, 100);
        }
        const {selected} = this.props;
        if (isNull(selected)) return;
        this.handleScroll(selected);
    }
    componentWillUnmount() {
        if (this.focusTimeout) {
            clearTimeout(this.focusTimeout);
        }
    }
    setSelected(selected, {doneCallback, preventScroll, ...options} = {}) {
        if (this.props.withPointer && this.props.setSelected) {
            this.props.setSelected(selected, {
                doneCallback: () => {
                    !preventScroll && this.handleScroll(selected);
                    doneCallback && doneCallback();
                },
                ...options,
            });
        }
    }
    render() {
        const {children, maxHeight, className, ...props} = this.props;
        return (
            <div
                className={cx({
                    'Scroll': true,
                    'Scroll__tabFocused': !!this.state.tabFocused,
                    [className]: !!className,
                })}
                style={{
                    maxHeight,
                }}
                ref={this.container}>
                {React.Children.map(children, child => React.cloneElement(child, {
                    ref: this.overflow,
                    border: false,
                    ...props,
                    setSelected: this.setSelected,
                    showFocusOutline: this.showFocusOutline,
                    tabFocused: this.state.tabFocused,
                }))}
            </div>
        );
    }
}

export default SimpleScroll;
