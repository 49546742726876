import root from 'window-or-global';

/**
 * Utility function for creating reducers.
 */
export const createReducer =
  (initialState, actionHandlers) =>
      (state = initialState, action) => {
          if (actionHandlers.hasOwnProperty(action.type)) {
              return actionHandlers[action.type](state, action);
          } else {
              return state;
          }
      };

/**
 * Flattens an object, making nested keys the new top-level keys.
 * {a: {b: {c: 4}}} will turn into {'a.b.c': 4}
 */
const flattenObject = (obj, prefix = '') =>
    Object.keys(obj).reduce((flattened, key) => {
        const value = obj[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'string') {
            flattened[prefixedKey] = value;
        } else {
            Object.assign(flattened, flattenObject(value, prefixedKey));
        }
        return flattened;
    }, {});

const removeKey = (key, {[key]: _, ...rest}) => rest;

export const getUpdatedMessages = (messages, customTranslations, locale) => {
    const updatedMessages = flattenObject(messages[locale]);
    if(customTranslations &&  Object.keys(customTranslations).length !== 0) {
        for (const [key, value] of Object.entries(customTranslations)) {
            const [localeKey, ...rest] = key.split(/\.(.+)/).filter(Boolean);
            if(localeKey === locale) {
                updatedMessages[rest] = value;
                if (customTranslations[rest]) {
                    customTranslations = removeKey(rest, customTranslations);
                }
            } else if (localeKey?.length !== 2 && customTranslations[key]) {
                updatedMessages[key] = value;
            }
        }
    }
    return updatedMessages;
};

/**
 * get the dashboard url for lightStep api to create the snapshot
 * @returns {string}
 */
export const getDashboardUrlByEnv = () => {
    if (sessionStorageWrapper.get('env') === 'prd') {
        return 'https://dashboard.token.io';
    }

    if (sessionStorageWrapper.get('env') === 'sandbox') {
        return 'https://dashboard.sandbox.token.io';
    }

    if (sessionStorageWrapper.get('env') === 'stg') {
        return 'https://dashboard.stg.token.io';
    }

    if (sessionStorageWrapper.get('env') === 'dev') {
        return 'https://dashboard.dev.token.io';
    }

    return 'https://dashboard.dev.token.io';
};

export const getUserParams = additionalParams => {
    const _additionalParams = additionalParams || {};
    const params = {
        ['requestId']: sessionStorageWrapper.get('requestId'),
        ['refId']: sessionStorageWrapper.get('refId'),
        ['memberId']: sessionStorageWrapper.get('memberId'),
        ['bankId']: sessionStorageWrapper.get('bankId'),
        ['web-app-session']: 'web-app-session',
        ..._additionalParams,
    };

    return Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            return acc + `&${key}=${params[key]}`;
        } else {
            return acc;
        }
    }, '');
};

export function buildLightstepErrorParams(errorMessage) {
    if (typeof errorMessage !== 'string') {
        return {};
    }

    let errorPriority = 'medium';

    if (hasPriorityStatus(highPriorityErrors, errorMessage)) {
        errorPriority = 'high';
    }

    if (hasPriorityStatus(lowPriorityErrors, errorMessage)) {
        errorPriority = 'low';
    }

    return {
        errorMessage,
        errorPriority,
    };
}

function hasPriorityStatus(priorityErrors, errorMessage) {
    return priorityErrors.reduce((acc, error) => {
        if (!!acc || errorMessage.includes(error)) {
            return true;
        }
        return acc;
    }, false);
}

const highPriorityErrors = [
    'FAILED_PRECONDITION', // web-app have not sent the correct payload
];

const lowPriorityErrors = [
    'Reason: NOT_FOUND: MEMBER_ID_NOT_FOUND', // back button issue
    'IBAN validation failed with status',
    'Terms declined',
    'User authentication failed.',
    'Error: value: Request failed with status code 403. Reason: PERMISSION_DENIED: Member', // this is the same error as "User authentication failed.",
    'Path is invalid',
    'User may have clicked on the browser back button',
    'access_denied',
    'Provided IBAN is invalid',
    'deadline exceeded after',
    'Reason: NOT_FOUND: Unable to locate token request',
    'FAILURE_GENERIC', // it is the error from backend…like its a generic error ..which may occur for any general reason like invalid iban …etc. So it should be LOW because it’s not a web-app error
    'ERROR: HTTP message validation error(s) detected. See the error details for more information', // error where bank requesting payload
];

export const localStorageWrapper = {
    set: (k, v) => {
        try {
            if (!k || !v) {
                return;
            }
            if (typeof v === 'object') {
                v = JSON.stringify(v);
            }
            localStorage.setItem(k, v);
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
    get: k => {
        let v;
        try {
            if (!k) {
                return localStorage;
            }
            v = localStorage.getItem(k);
            return JSON.parse(v);
        } catch (e) {
            console.log(e);
            return v;
        }
    },
    remove: k => {
        try {
            if (!k) {
                return;
            }
            localStorage.removeItem(k);
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
    clear: () => {
        try {
            localStorage.clear();
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
};

export const sessionStorageWrapper = {
    set: (k, v) => {
        try {
            if (!k) {
                return;
            }
            if (typeof v === 'object') {
                v = JSON.stringify(v);
            }
            root.sessionStorage.setItem(k, v);
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
    get: k => {
        let v;
        try {
            if (!k) {
                return root.sessionStorage;
            }
            v = root.sessionStorage.getItem(k);
            return JSON.parse(v);
        } catch (e) {
            console.log(e);
            return v;
        }
    },
    remove: k => {
        if (!k) {
            return;
        }
        try {
            root.sessionStorage.removeItem(k);
        } catch (e) {
            // do nothing
            console.log(e);
        }
    },
};
