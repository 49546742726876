import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClockCircle } from 'react-icons/ai';

const PendingIcon = ({ className, title }) => (
    <AiOutlineClockCircle title={title} className={className} />
);

PendingIcon.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default PendingIcon;
