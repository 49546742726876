import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './AppNotifier.css';
import {
    cancelApproval,
    notYou,
    noApp,
    clearErrorMessage,
} from 'actions/shared';
import classNames from 'classnames/bind';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenImage from '@token-io/lib-web-components/src/Components/Image/TokenImage';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import { FormattedMessage } from 'react-intl';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';

const cx = classNames.bind(style);

class AppNotifier extends Component {
    componentWillUnmount() {
        this.props.clearErrorMessage();
    }

    componentDidMount() {
        this.props.setDisplayFooterShadow(false);
    }

    render() {
        const {
            strings,
            notYou,
            noApp,
            cancelApproval,
            error,
            hasCustomization,
        } = this.props;
        return (
            <Fragment>
                <TokenContainer className={cx('AppNotifier-subcontainer')}>
                    <TokenTitle>{strings[0]}</TokenTitle>
                    <TokenTitle sub>{strings[1]}</TokenTitle>
                    <div className={cx('AppNotifier-body')}>
                        <TokenImage
                            id={TokenImage.APPROVAL}
                            className={cx({
                                'AppNotifier-approve': true,
                                'AppNotifier-desaturate': hasCustomization,
                            })}
                        />
                    </div>
                    <div className={cx('AppNotifier-footer')}>
                        {(error && (
                            <p className={cx('AppNotifier-error')}>
                                <FormattedMessage
                                    id={'common.appNotifier.errorTitle'}
                                />
                                <br />
                                <FormattedMessage id={error} />
                            </p>
                        )) ||
                            null}
                        {(!hasCustomization && (
                            <p className={cx('AppNotifier-app')}>
                                {strings[2]}
                                <span onClick={noApp}>{strings[3]}</span>
                            </p>
                        )) ||
                            null}
                        {(strings[4] && !hasCustomization && (
                            <p className={cx('AppNotifier-app')}>
                                <span onClick={notYou}>{strings[4]}</span>
                            </p>
                        )) ||
                            null}
                    </div>
                </TokenContainer>
                <TokenButtonsWrapper>
                    <Button
                        onClick={cancelApproval}
                        type={
                            error
                                ? Button.TYPE_SECONDARY
                                : Button.TYPE_GHOST_WARNING
                        }
                        text={
                            <FormattedMessage
                                id={
                                    (error && 'common.button.back') ||
                                    'common.button.cancelRequest'
                                }
                            />
                        }
                    />
                </TokenButtonsWrapper>
            </Fragment>
        );
    }
}

AppNotifier.propTypes = {
    strings: PropTypes.array.isRequired,
    notYou: PropTypes.func.isRequired,
    noApp: PropTypes.func.isRequired,
    cancelApproval: PropTypes.func.isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    error: PropTypes.string,
    hasCustomization: PropTypes.bool,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = ({ sharedService, tokenRequestService }) => ({
    error: sharedService.getErrorMessage(),
    hasCustomization: tokenRequestService.hasCustomization(),
});

const mapDispatchToProps = {
    noApp,
    notYou,
    cancelApproval,
    clearErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNotifier);
