import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './GPHSBCErrorPage.css';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import { errorMessageKeys } from 'config/constants';
import FailureIcon from '../Icons/FailureIcon';
import { FormattedMessage } from 'react-intl';

const cx = classNames.bind(style);

const getErrorMessageKey = errorMessage => {
    let errorMessageKey = errorMessageKeys['DEFAULT'];
    Object.keys(errorMessageKeys).forEach(function (key) {
        if (errorMessage.toUpperCase().includes(key)) {
            errorMessageKey = errorMessageKeys[key];
        }
    });
    return errorMessageKey;
};

const GPHSBCErrorPage = ({ setDisplayFooterShadow, isTokenError, error }) => {
    useEffect(() => {
        setDisplayFooterShadow(false);
    }, []);

    const gpHsbcErrorMessageId = isTokenError
        ? 'common.gpHsbc.errorPage.TOKEN_ERROR'
        : `common.gpHsbc.errorPage.${getErrorMessageKey(error.message)}`;

    return (
        <TokenContainer noMargin noScrollable>
            <div className={cx('CustomErrorPage-container-gp')}>
                <div className={cx('CustomErrorPage-subcontainer-gp')}>
                    <div className={cx('CustomErrorPage-title-container-gp')}>
                        <div>
                            <FailureIcon
                                title="failure"
                                className={cx('CustomErrorPage-cross-icon-gp')}
                            />
                        </div>
                        <div className={cx('CustomErrorPage-title-gp')}>
                            <FormattedMessage
                                tagName={'span'}
                                id={'common.gpHsbc.errorPage.title'}
                            />
                        </div>
                    </div>
                    <div className={cx('CustomErrorPage-body-text-gp')}>
                        <FormattedMessage
                            tagName={'span'}
                            id={gpHsbcErrorMessageId}
                        />
                    </div>
                </div>
            </div>
        </TokenContainer>
    );
};

GPHSBCErrorPage.propTypes = {
    isTokenError: PropTypes.bool,
    error: PropTypes.object,
    setDisplayFooterShadow: PropTypes.func,
};

const mapStateToProps = ({ sharedService }) => {
    return {
        isTokenError: sharedService.getErrorSource(),
        error: sharedService.getError(),
    };
};

export default connect(mapStateToProps)(GPHSBCErrorPage);
