import React from 'react';
import PropTypes from 'prop-types';
import style from './FeedbackIcon.css';
import classNames from 'classnames/bind';
import { connect } from 'reducers';
import {
    defaultColors,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import SmallSpinner from './../Shared/SmallSpinner';
import CheckIcon from './../Icons/CheckIcon';
import CrossIcon from './../Icons/CrossIconCircle';
import { argbToRgba } from 'util/index';

const cx = classNames.bind(style);

const FeedbackIcon = ({ color, isValid, displaySpinner, preCondition }) => {
    return (
        (displaySpinner && (
            <SmallSpinner
                className={cx({
                    TextInput__inner__loader: true,
                    Custom__spinner: true,
                })}
                color={color}
            />
        )) ||
        (preCondition &&
            ((isValid && <CheckIcon title="Correct" />) || (
                <CrossIcon title="Wrong branch Number" />
            )))
    );
};

FeedbackIcon.propTypes = {
    color: PropTypes.string,
    isValid: PropTypes.bool,
    displaySpinner: PropTypes.bool,
    preCondition: PropTypes.bool,
};

const mapStateToProps = ({ tokenRequestService }, ownProps) => {
    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };
    return {
        color:
            argbToRgba(colors['color-spinner']) ||
            argbToRgba(colors['color-primary']),
        isValid: ownProps.isValid,
        displaySpinner: ownProps.displaySpinner,
        preCondition: ownProps.preCondition,
    };
};

export default connect(mapStateToProps, null)(FeedbackIcon);
