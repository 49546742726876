import {
    CURRENCY_PRECISION,
    enfuseLicenseTpps,
    LS_VERSION,
    months,
    TOKEN_ENV,
    TPP_INTEGRATION_ADDRESS,
    TOKEN_POPUP_NAME,
    TOKEN_WEB_APP_IFRAME_NAME,
    INTERNAL_TRANSFER_STATUS_GROUPS as transferStatusGroups,
    PATH_TO_VALIDATE_IBAN,
    localCustomizationTppList,
    defaultCustomizationTppList,
    schemes as schemeConfig,
    REQUEST_TYPE,
} from 'config/constants';
import {
    INTL_EXCEPTION_INDEXED_DB,
    INTL_EXCEPTION_LOCALSTORAGE,
    INTL_EXCEPTION_WEB_CRYPTO,
} from 'config/exceptions';
import { TokenException } from '@token-io/lib-web-app';
import { getUserAgent } from '@token-io/lib-web-components/src/Util';
import msg from 'config/locales/en.json';
import { addBreadcrumb } from '@sentry/browser';
import { parse } from 'query-string';
import uaParser from 'ua-parser-js';
import axios from 'axios';
import { topBanksList } from './../config/topBanksList';
import { developerKeys } from './../config/devKeys';
import { mandatoryFieldsConfig as mandatoryConfig } from 'config/mandatoryFields';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';
import localStorageWrapper from './localStorageWrapper';

export const isDesktop = !uaParser().device.type;
export const isMobile = !isDesktop;

export const isPopup = () =>
    window.opener &&
    window !== window.opener &&
    window.name === TOKEN_POPUP_NAME;

export const isContextIframe = () =>
    window.frames && window.frames.name === TOKEN_WEB_APP_IFRAME_NAME;

export const getWebappContext = () => {
    let context = 'UNKNOWN';
    if (isPopup()) {
        context = 'POP_UP';
    } else if (isContextIframe()) {
        context = 'IFRAME';
    } else if (isMobile) {
        context = 'REDIRECT';
    }

    // add suffix
    if (isMobile) {
        context = context + '_MOBILE';
    } else {
        context = context + '_DESKTOP';
    }

    return context;
};

export const validateBrowserCompatibility = () => {
    try {
        if (!localStorageWrapper.get()) {
            throw new TokenException(INTL_EXCEPTION_LOCALSTORAGE);
        }
        if (!window.crypto && !window.msCrypto) {
            throw new TokenException(INTL_EXCEPTION_WEB_CRYPTO);
        }
        if (!window.indexedDB) {
            throw new TokenException(INTL_EXCEPTION_INDEXED_DB);
        }
    } catch (e) {
        // do nothing
        console.error(e);
    }
};

export const getSentryInterceptor = () => ({
    onFulfilled(res = {}) {
        addBreadcrumb({
            category: 'ttid',
            message: res.headers?.['token-trace-id'],
            data: { url: res.config?.url },
        });
        return res;
    },
    onRejected(err = {}) {
        addBreadcrumb({
            category: 'ttid-error',
            message: err.response?.headers?.['token-trace-id'],
            data: { error: err.response?.data },
        });
        return Promise.reject(err);
    },
});

export const poll = async (
    fn,
    interval,
    timeout,
    timeoutException,
    cancelled,
    cancelledCb,
) => {
    const startTime = new Date().getTime();
    while (!cancelled()) {
        try {
            return await fn();
        } catch (e) {
            if (new Date().getTime() - startTime > timeout) {
                cancelledCb();
                if (timeoutException) {
                    throw new TokenException(
                        timeoutException,
                        timeoutException,
                    );
                }
            } else if (!cancelled()) {
                await new Promise(resolve => setTimeout(resolve, interval));
            }
        }
    }
};

export const pollUntil = async ({
    fn,
    validate,
    interval,
    timeout,
    cancelledCb,
    failOnReject,
}) => {
    const startTime = new Date().getTime();

    const executePoll = async (resolve, reject) => {
        const currentTime = new Date().getTime();
        try {
            const result = await fn();
            if (validate(result)) {
                return resolve(result);
            }
        } catch (e) {
            if (failOnReject) {
                return reject(e);
            } else {
                // Do nothing
            }
        }

        if (currentTime - startTime > timeout) {
            cancelledCb(resolve);
        } else {
            setTimeout(executePoll, interval, resolve, reject);
        }
    };

    return new Promise(executePoll);
};

export const getUserDeviceMetadata = () => {
    const ua = getUserAgent();
    return {
        application: ua.browser.name,
        applicationVersion: ua.browser.version,
        device: ua.os.name,
    };
};

export const formatCurrency = (
    amount = 0,
    currency = 'USD',
    formatNumber = x => parseFloat(x).toFixed(2),
) => {
    return formatNumber(amount, { style: 'currency', currency });
};

export const getCurrentDateTimeString = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    return `${formatDate(
        formattedDate,
    )};${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const extractDateFromISO = isoDate => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return `${formatDate(formattedDate)}`;
};

export const formatDate = date => {
    if (!date) return undefined;
    date = date.replace(/-/g, '');
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date[6] === '0' ? date.substring(7) : date.substring(6);
    let str = 'th';
    if (day === '1') str = 'st';
    if (day === '2') str = 'nd';
    if (day === '3') str = 'rd';
    return `${day}${str} ${months[month]} ${year}`;
};

// https://github.com/srcagency/country-to-emoji-flag/blob/master/index.js
export const flagFromCountryCode = country => {
    const offset = 127397;
    const A = 65;
    const Z = 90;
    const f = country.codePointAt(0);
    const s = country.codePointAt(1);

    if (country.length !== 2 || f > Z || f < A || s > Z || s < A)
        throw new Error('Not an alpha2 country code');

    return String.fromCodePoint(f + offset) + String.fromCodePoint(s + offset);
};

export const formatCountries = (countries = [], formatMessage) => {
    const map = new Map();
    countries
        .filter(countryCode => !!msg.common.country[countryCode])
        .map(countryCode => {
            const country = {
                key: flagFromCountryCode(countryCode),
                id: `common.country.${countryCode}`,
                code: countryCode.toLowerCase(),
            };
            map[msg.common.country[countryCode]] = {
                key: `${country.key} ${formatMessage({
                    id: country.id,
                    defaultMessage: msg.common.country[countryCode],
                })}`,
                code: country.code,
            };
        });
    return Object.values(
        Object.keys(map)
            .sort()
            .reduce((r, k) => ((r[k] = map[k]), r), {}),
    );
};

export const formatCountry = (countryCode, formatMessage) => {
    const country = {
        key: flagFromCountryCode(countryCode),
        id: `common.country.${countryCode}`,
        code: countryCode.toLowerCase(),
    };
    return {
        key: `${country.key} ${formatMessage({
            id: country.id,
            defaultMessage: msg.common.country[countryCode],
        })}`,
        code: country.code,
    };
};

export const formatCountriesPhone = (countries = []) =>
    countries.map(c => ({
        key: `${flagFromCountryCode(c.iso2.toUpperCase())} + ${c.dialCode}`,
        id: c.dialCode,
        code: c.iso2,
    }));

export const normalizeString = string => {
    return string && string.toUpperCase().replace(/ /g, '');
};

export const normalizeMoney = amount => {
    return typeof amount === 'number'
        ? amount.toFixed(CURRENCY_PRECISION)
        : Number(amount).toFixed(CURRENCY_PRECISION);
};

export const getDateTimeString = timestamp => {
    const date = new Date(parseInt(timestamp));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    return `${formatDate(
        formattedDate,
    )};${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

const mapAccount = (account, formatNumber) => {
    const a = {
        key: account.name,
        id: account.id,
        value: account.balance
            ? formatCurrency(
                account.balance.current.value,
                account.balance.current.currency,
                formatNumber,
            )
            : '',
    };
    return account ? a : undefined;
};

export const joinFields = (fields, separator = ', ') => {
    const actualFields = [];
    fields.map(f => {
        if (typeof f === 'string' && f !== '') {
            actualFields.push(f.replace(/\|/, ',').replace(',,', ','));
        }
    });
    return actualFields.join(separator);
};

export const transformAccounts = (accounts, formatNumber) =>
    accounts.map(o => mapAccount(o, formatNumber));

export const findId = (list, id) =>
    list.reduce((lastVal, item) => {
        if (item.id === id) return item;
        return lastVal;
    }, null);

export const getHashValue = key => parse(location.hash)[key];

export const getQueryParams = () => parse(location.search);

export const getURLParameter = name => parse(location.search)[name];

export const addCustomCss = (
    tokenRequestId,
    requestType = REQUEST_TYPE.TOKEN_REQUEST,
    memberInfo = {},
) =>
    new Promise(resolve => {
        if (!localStorageWrapper.get('reduxState')) return resolve();
        try {
            let el = window.document.getElementById('customStyle');
            let queryParams = `id=${tokenRequestId}&type=${requestType}`;
            const memberId = memberInfo.memberId;
            const realmId = memberInfo.realmId;
            const subTppId = memberInfo.subTppId;
            if (memberId) {
                queryParams += `&memberId=${memberId}`;
            }
            if (realmId) {
                queryParams += `&realmId=${realmId}`;
            }
            if (subTppId) {
                queryParams += `&subTppId=${subTppId}`;
            }
            const url = `/customize.css?${queryParams}`;
            if (el) {
                if (el.getAttribute('href') === url) return resolve();
                el.remove();
            }
            el = window.document.createElement('link');
            el.setAttribute('id', 'customStyle');
            el.setAttribute('rel', 'stylesheet');
            el.addEventListener('load', () => {
                resolve();
            });
            window.document.head.append(el);
            el.setAttribute('href', url);
        } catch (e) {
            return resolve();
        }
    });

export const removeCustomCss = () => {
    const el = window.document.getElementById('customStyle');
    if (el) {
        el.remove();
    }
};

export const argbToRgba = argb =>
    (argb && argb.replace(/^#?([a-fA-F\d]{2})([a-fA-F\d]{6})$/g, '#$2$1')) ||
    '';

export const syncLocalStorageVersion = () => {
    const localStorageVersion = localStorageWrapper.get('version');
    if (
        !localStorageVersion ||
        localStorageVersion !== parseFloat(LS_VERSION)
    ) {
        localStorageWrapper.clear();
        localStorageWrapper.set('version', LS_VERSION);
    }
};

export const copyToClipboard = element => {
    // Text element has to be visible
    element.classList.add('element--visible');

    // Select the text field
    element.select();

    // Copy the text inside the text field
    document.execCommand('copy');

    // Hide element
    element.classList.remove('element--visible');

    return true;
};

export const copyOnClipboard = refId => {
    // Create dummy element for reference
    const textArea = document.createElement('textarea');

    // Assign value to this input
    textArea.value = refId;
    document.body.appendChild(textArea);

    // Select the text field
    textArea.select();

    // Copy the text inside the text field
    document.execCommand('Copy');
    textArea.remove();
};

export const copyOnMobileClipboard = text => {
    const input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');
    document.body.removeChild(input);
};

export const getFilteredBanks = (country, banks) => {
    let filteredBanks = banks;
    const bankIds = topBanksList[country.code.toUpperCase()];
    if (bankIds) {
        filteredBanks = banks.map(bank => {
            if (!bankIds.includes(bank.key)) {
                return bank;
            }
        });
    }
    return filteredBanks.filter(bank => typeof bank !== 'undefined');
};

export const mergeBankList = (topBanks, banks) => {
    return topBanks.concat(banks);
};

export const getBankRawData = banks => {
    return banks.map(bank => bank.rawData);
};

export const formatBankBranchData = (banks, bankName) => {
    const banksData = {};
    banks.map(bank => {
        if (bank.name === bankName) {
            banksData[bank.identifier] = bank;
        }
    });
    return banksData;
};

export const filterDestinationAccount = destinationAccount => {
    const accountSchemes = Object.keys(schemeConfig);
    const filtered = Object.keys(destinationAccount)
        .filter(key => accountSchemes.includes(key))
        .reduce((obj, key) => {
            obj[key] = destinationAccount[key];
            return obj;
        }, {});
    const scheme = Object.keys(filtered)[0];
    return formatAccountFields(scheme, filtered[scheme]);
};

const formatAccountFields = (scheme, account) => {
    if (typeof account === 'undefined') {
        return {};
    }
    const filtered = {};
    Object.values(schemeConfig[scheme])
        .filter(value => Object.keys(account).includes(value))
        .forEach(value => (filtered[value] = account[value]));
    return filtered;
};

export const buildPathReqEx = (path, flags) => {
    return new RegExp('^' + path, flags);
};

export const isCredentialFlow = tppRedirectUrl => {
    if (
        tppRedirectUrl?.includes(TPP_INTEGRATION_ADDRESS) &&
        TOKEN_ENV !== 'prd'
    ) {
        return true;
    }
    return false;
};

export const formatAsKeyValuePair = arr => {
    return arr.map((v, i) => {
        return { id: i, value: v };
    });
};

export const isEnfuseLicenseTpp = (realmId, memberId, country) => {
    return (
        (enfuseLicenseTpps?.includes(realmId) ||
            enfuseLicenseTpps?.includes(memberId)) &&
        country !== 'GB'
    );
};

export const isTppGmbh = memberId => {
    const config = getTppConfig(memberId);
    return config && config.isTppGmbh;
};

export const getDestinationPaymentRail = (destination, bank) => {
    if (!destination || !bank) {
        return 'domestic';
    }

    const paymentMethods = Object.keys(destination);
    const supportedTransferDestinationTypes =
        bank.supportedTransferDestinationTypes || [];

    const internationalPaymentMethods = ['sepa', 'sepaInstant'];
    const internationalPaymentMethodsBanks = ['SEPA', 'SEPA_INSTANT'];

    const hasEnoughSupportedTypes =
        supportedTransferDestinationTypes.length >= 2;
    const hasInternationalPaymentMethod = !supportedTransferDestinationTypes.every(
        method => internationalPaymentMethodsBanks.includes(method),
    );
    const isInternationalPaymentMethodUsed = paymentMethods.some(method =>
        internationalPaymentMethods.includes(method),
    );

    const isInternational =
        hasEnoughSupportedTypes &&
        isInternationalPaymentMethodUsed &&
        hasInternationalPaymentMethod;

    return isInternational ? 'international' : 'domestic';
};

export const getBankDoesRequireDebtorAccount = (
    bank,
    tokenType,
    paymentRail,
) => {
    const paymentCategory = getDestinationPaymentRail(paymentRail, bank);
    if (tokenType === 'TOKEN_TYPE_STANDING_ORDER') {
        return bank?.mandatoryFields?.standingOrder?.[
            paymentCategory
        ]?.fields?.some(
            value =>
                value === mandatoryConfig['standingOrder']?.debtorAccount,
        );
    } else if (tokenType === 'TOKEN_TYPE_TRANSFER') {
        return bank?.mandatoryFields?.transfer?.[paymentCategory]?.fields?.some(
            value => value === mandatoryConfig['transfer']?.debtorAccount,
        );
    } else {
        return false;
    }
};

export const getButtonStatusOnCredsPage = (
    requiredCredentials,
    credentialsValue,
) => {
    if (
        requiredCredentials &&
        requiredCredentials?.length !== Object.keys(credentialsValue)?.length
    ) {
        return 'STATUS_DISABLED';
    }

    if (requiredCredentials && Object.values(credentialsValue).includes('')) {
        return 'STATUS_DISABLED';
    }

    return 'STATUS_IDLE';
};

export const validateInputFromTI = async (urlPath, data) => {
    return await axios.put(`${TPP_INTEGRATION_ADDRESS + urlPath}`, {
        ...data,
    });
};

export const getDeveloperId = customDevKey => {
    if (!customDevKey) {
        return 'global-test@token.io';
    } else if (developerKeys?.[customDevKey]) {
        return developerKeys[customDevKey];
    }
    return customDevKey + '@token.io';
};

export const deepTrim = obj => {
    if (!obj || (!Array.isArray(obj) && typeof obj != 'object')) return obj;
    return Object.keys(obj).reduce(
        (acc, key) => {
            acc[key] =
                typeof obj[key] == 'string'
                    ? obj[key]?.trim()
                    : deepTrim(obj[key]);
            return acc;
        },
        Array.isArray(obj) ? [] : {},
    );
};

// Recursive remove empty objects
export const removeEmpty = obj => {
    try {
        for (const prop in obj) {
            if (!obj[prop]) {
                delete obj[prop];
            }

            if (typeof obj[prop] !== 'object') {
                continue;
            }

            removeEmpty(obj[prop]);
            if (Object.keys(obj[prop])?.length === 0) {
                delete obj[prop];
            }
        }
    } catch (e) {
        console.error(e); // eslint-disable-line
    }
    return obj;
};

export const validateIban = async (iban, tokenRequestId) => {
    const data = { iban: iban, tokenRequestId: tokenRequestId };
    return await validateInputFromTI(PATH_TO_VALIDATE_IBAN, data);
};

export const getTppConfig = tppMemberOrRealmId => {
    const tppConfig = localCustomizationTppList?.[tppMemberOrRealmId];
    if (tppConfig) {
        const { customKey, ...rest } = tppConfig;
        return {
            ...defaultCustomizationTppList?.[customKey],
            ...rest,
        };
    }
    return null;
};

export const getInternalTransferStatus = transferCode => {
    for (const group in transferStatusGroups) {
        if (transferStatusGroups[group].includes(transferCode)) {
            return group;
        }
    }
    return transferCode;
};

export const toCamelCase = str => {
    if (!str || typeof str !== 'string') return '';
    const frags = str?.split('.');
    const result = [];
    for (let i = 0; i < frags.length; i++) {
        result[i] = _.camelCase(frags[i]);
    }
    return result.join('.');
};

export const deepMergeObjects = (target, source) => {
    for (const key in source) {
        if (source[key] instanceof Object) {
            if (!target[key]) {
                target[key] = {};
            }
            deepMergeObjects(target[key], source[key]);
            continue;
        }
        target[key] = source[key];
    }
    return target;
};

export const getDeviceId = () => {
    let deviceId = localStorageWrapper.get('deviceId');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorageWrapper.set('deviceId', deviceId);
    }
    return deviceId;
};

export const sanitizeHTML = html => {
    const withImageTag = html
        ?.replace(/<image>/g, '<img>')
        ?.replace(/<\/image>/g, '</img>');
    return DOMPurify.sanitize(withImageTag, {
        ALLOWED_TAGS: [
            'h1',
            'div',
            'img',
            'blockquote',
            'p',
            'h2',
            'ol',
            'ul',
            'li',
            'a',
            'code',
            'strong',
            'u',
            'em',
            'span',
            'br',
        ],
        ALLOWED_ATTR: [
            'href',
            'src',
            'class',
            'target',
            'aria-label',
            'role',
            'id',
        ],
    });
};

export const convertToReadableDate = date => {
    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return new Date(date).toLocaleDateString(undefined, options);
};

export const camelToSnakeCase = str => {
    if (!str) return str;
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const hideConsentText = customConsentText => {
    return (
        typeof customConsentText === 'string' &&
        customConsentText?.trim()?.length === 0
    );
};

export const keyExists = obj => {
    if (!obj || (Array.isArray(obj) && typeof obj != 'object')) {
        return false;
    } else {
        return Object.keys(obj)?.length > 0;
    }
};

export const setHtmlLanguageAttribute = locale => {
    if (locale) {
        document.documentElement.setAttribute('lang', locale);
    }
};

export { default as segregateCredentials } from './segregateCredentials';

export { default as addRefundTextIfNotPresent } from './addRefundTextIfNotPresent';

export { default as getSupportedPaymentNetworks } from './getSupportedPaymentNetworks';

export { default as getFeatureFromTppConfig } from './getFeatureFromTppConfig';

export { default as getInitialReduxState } from './getInitialReduxState';

export { default as getTokenReqIdFromURL } from './getTokenReqIdFromURL';

export { default as getInstantDevKey } from './getInstantDevKey';

export { default as blockUserOnURLPaths } from './blockUserOnURLPaths';

export { default as checkMandatoryFieldsPresent } from './checkMandatoryFieldsPresent';

export { default as localStorageWrapper } from './localStorageWrapper';

export { default as sessionStorageWrapper } from './sessionStorageWrapper';
