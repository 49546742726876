import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from '../../../css/List.css';

const cx = classNames.bind(style);

export const ROW_TYPE_1_COL = '1col';
export const ROW_TYPE_2_COL = '2col';
export const ROW_SIZE_STANDARD = 'standard';
export const ROW_SIZE_LARGE = 'large';

const AbstractList = React.forwardRef(({
    className,
    size,
    type,
    keyDown,
    keyUp,
    onMouseOver,
    children,
    withPointer,
    activeDescendant,
    tabIndex,
    ariaLabel,
    ariaExpanded,
    ariaHidden,
    role,
    blur,
}, ref) => (
    <div
        className={cx({
            'List': true,
            [className]: !!className,
        })}
        ref={ref}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => {
            if (!(withPointer && keyDown)) return;
            e.preventDefault();
            keyDown(e);
        }}
        onKeyUp={e => {
            if (!keyUp) return;
            keyUp(e);
        }}
        onMouseMove={() => {
            onMouseOver && onMouseOver(true);
        }}
        onMouseLeave={() => {
            onMouseOver && onMouseOver(false);
        }}
        onBlur={() => {
            blur && blur(true);
        }}
        role={role}
        tabIndex={tabIndex}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-activedescendant={activeDescendant}>
        {React.Children.map(children, child => React.cloneElement(child, {
            size,
            type,
        }))}
    </div>
));

AbstractList.displayName = 'AbstractList';

AbstractList.propTypes = {
    type: PropTypes.oneOf([
        ROW_TYPE_1_COL,
        ROW_TYPE_2_COL,
    ]),
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    className: PropTypes.string,
    children: PropTypes.node,
    keyDown: PropTypes.func,
    keyUp: PropTypes.func,
    withPointer: PropTypes.bool,
    onMouseOver: PropTypes.func,
    tabIndex: PropTypes.string,
    ariaLabel: PropTypes.string,
    activeDescendant: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    role: PropTypes.string,
    blur: PropTypes.func,
    ariaHidden: PropTypes.string,
};

AbstractList.defaultProps = {
    type: ROW_TYPE_1_COL,
    size: ROW_SIZE_STANDARD,
    withPointer: false,
    role: 'listbox',
};

export default AbstractList;
