// Number of pixels from the bottom the user must scroll to before more items are loaded
export const SCROLL_BOTTOM_LOAD_DISTANCE = 50;

// Number of milliseconds that must elapse from when the user stopped typing
// before the search is reset
export const SEARCH_RESET_DELAY = 1000;

// Number of milliseconds that must elapse from when the user stopped typing in
// the search bar before api call is fired
export const API_CALL_DELAY = 500;

// Number of list items that are displayed at once
export const LIST_PAGE_SIZE = 25;

// Key codes
export const KEY_ENTER = 13;
export const KEY_ESC = 27;
export const KEY_UP = 38;
export const KEY_DOWN = 40;
export const KEY_SPACEBAR = 32;

// Transitions
export const TRANSITION_OPENED = 'opened';
export const TRANSITION_IS_OPENING = 'is-opening';
export const TRANSITION_CLOSED = 'closed';
export const TRANSITION_IS_CLOSING = 'is-closing';
