import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {ROW_SIZE_STANDARD, ROW_SIZE_LARGE} from './Abstract';
import {SpinnerSmall} from '../Spinner';
import style from '../../../css/List.css';

const cx = classNames.bind(style);

const ListCellLoader = ({
    size,
    className,
    spinnerColor,
}) => (
    <div className={cx({
        'List__row__cell': true,
        'List__row__cell--loader': true,
        [`List__row__cell--${size}`]: true,
        [className]: !!className,
    })}>
        <span className={cx('List__row__cell__loader')}>
            <SpinnerSmall color={spinnerColor} />
        </span>
    </div>
);

ListCellLoader.propTypes = {
    size: PropTypes.oneOf([
        ROW_SIZE_STANDARD,
        ROW_SIZE_LARGE,
    ]),
    spinnerColor: PropTypes.string,
    className: PropTypes.string,
};

ListCellLoader.defaultProps = {
    size: ROW_SIZE_STANDARD,
};

export default ListCellLoader;
