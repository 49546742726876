import { combineReducers } from 'redux';
import { REDACTED_VALUE } from 'config/constants';
import { findId } from 'util/index';
import { createReducer } from '@token-io/lib-web-app';

const members = createReducer(
    { members: [] },
    {
        ADD_MEMBER: (state, action) => {
            return { ...state, members: [...state.members, action.member] };
        },
        CLEAR_MEMBERS: (state, action) => {
            return { ...state, members: [] };
        },
        SET_TEMP_MEMBER: (state, action) => {
            return { ...state, tempMember: action.member };
        },
        SET_ALIAS: (state, action) => {
            return { ...state, alias: action.alias };
        },
    },
);

const clearAccounts = state => {
    return { ...state, accounts: [], selectedAccountIds: [] };
};

const accounts = createReducer(
    { accounts: [], selectedAccountIds: [] },
    {
        SET_ACCOUNTS: (state, action) => {
            const accounts = action.accounts;
            return { ...state, accounts };
        },
        SET_SELECTED_ACCOUNT_IDS: (state, action) => {
            const selectedAccountIds = action.accountIds;
            return { ...state, selectedAccountIds };
        },
        ADD_SELECTED_ACCOUNT_ID: (state, action) => {
            const selectedAccountIds = [...state.selectedAccountIds];
            selectedAccountIds.push(action.accountId);
            return { ...state, selectedAccountIds };
        },
        // clear accounts when member is switched
        SET_TEMP_MEMBER: (state, action) => {
            return clearAccounts(state);
        },
        // clear accounts when members are cleared
        CLEAR_MEMBERS: (state, action) => {
            return clearAccounts(state);
        },
    },
);

export const reducer = combineReducers({
    members,
    accounts,
});

export const selector = getState => {
    const getPairedMembers = () => getState().members.members;
    const getSelectedAccountIds = () => getState().accounts.selectedAccountIds;
    const getMemberAccounts = () => getState().accounts.accounts;
    const getTempMember = () => getState().members.tempMember;
    const getAlias = () => getState().members.alias;

    return {
        getPairedMembers,
        getSelectedAccountIds,
        getMemberAccounts,
        getTempMember,
        getAlias,
        hasAlias: () => !!getAlias(),
        hasTempMember: () => !!getTempMember(),
        hasPairedMembers: () => getPairedMembers().length > 0,
        getFirstPairedMember: () => getPairedMembers()[0],
        getSelectedAccounts: () =>
            getSelectedAccountIds().map(accountId =>
                findId(accounts, accountId),
            ),
        getBankMemberAccounts: bankId =>
            getMemberAccounts().filter(account => account.bankId === bankId),
        getFirstAccount: () => getMemberAccounts()[0],
        getAccount: id => findId(getMemberAccounts(), id),
    };
};

export const getRedactedState = getState => {
    const state = getState();
    return {
        ...state,
        members: {
            ...state.members,
            alias: REDACTED_VALUE,
        },
    };
};

export const name = 'user';
