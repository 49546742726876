import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ className, onClick, ariaLabel, text }) => {
    return (
        <button
            aria-label={ariaLabel}
            onClick={onClick}
            className={className}
            tabIndex="0">
            <span
                style={{ outline: 'none', border: 'none' }}
                className="Button__content">
                {text}
            </span>
        </button>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
};

Button.defaultProps = {
    onClick: () => null,
    text: '',
};

export default Button;
