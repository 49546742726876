import React from 'react';

class WebAppConfig extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.localStorage.clear();
    }
    render() {
        return <div>Config - Cleared LocalStorage</div>;
    }
}

export default WebAppConfig;
