import {
    INTERNAL_TOKEN_REQUEST_STATUS,
    INTERNAL_TOKEN_REQUEST_STATUS_MAP,
    STORE_WEB_APP_EVENTS_PATH,
    WEB_APP_EVENTS,
} from 'config/constants';
import TppIntegrationClient from './tppIntegrationClient';

const storeWebappEvent = ({ event, payload }) => async (
    dispatch,
    { tokenRequestService },
) => {
    try {
        let _event;

        const tokenRequestId = tokenRequestService.getRequestId();
        const memberId = tokenRequestService.getTppMemberAndRealmId()?.memberId;

        switch (event) {
            case INTERNAL_TOKEN_REQUEST_STATUS[event]:
                if (INTERNAL_TOKEN_REQUEST_STATUS_MAP[event]) {
                    _event = INTERNAL_TOKEN_REQUEST_STATUS_MAP[event];
                    break;
                }
            /* falls through */
            default:
                _event = WEB_APP_EVENTS[event];
        }

        if (!_event) return;

        await TppIntegrationClient({
            method: 'post',
            url: STORE_WEB_APP_EVENTS_PATH,
            data: {
                memberId,
                resourceId: tokenRequestId,
                eventType: _event,
                data:
                    payload && payload instanceof String
                        ? payload
                        : JSON.stringify(payload),
            },
        });
    } catch (e) {
        console.error(e); // eslint-disable-line
    }
};

export default storeWebappEvent;
