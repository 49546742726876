import {addBreadcrumb, captureException} from '@sentry/browser';
import {context, setSpan, SpanStatusCode} from '@opentelemetry/api';
import stringify from 'json-stringify-safe';
import {getDashboardUrlByEnv , getUserParams, buildLightstepErrorParams, sessionStorageWrapper} from './util';
import axios from 'axios';

export const createThunkMiddleware = getServices => ({dispatch, getState}) => next => action => {
    if (typeof action === 'function') {
        return action(dispatch, getServices(getState));
    }
    return next(action);
};

export const createErrorMiddleware = errorHandler => () => next => action => {
    if (action.type === 'THROW_ERROR') {
        console.error(action.error); // eslint-disable-line
        errorHandler?.(action.error);
    }
    return next(action);
};

export const createTracerMiddleware = (stateRedactor,tracer) => ({getState}) => {
    return next => {
        return action => {
            if (action) {
                const {type, ...data} = action;
                if (data.redacted) {
                    data.redacted.forEach(redact => {
                        delete data[redact];
                    });
                }
                const {route, steps} = getState().shared.route;
                const step = steps[1] ? steps[1] : steps[0];
                const childSpan = tracer.startSpan(`${route.current.id}-[${type}]--[${step}]`, {});
                context.with(setSpan(context.active(), childSpan), async () => {
                    setSpanAttributes(type, childSpan, data, action, stateRedactor, getState);
                    childSpan.end();
                });
                next(action);
            }
        };
    };
};

export const createSentryMiddleware = stateRedactor => ({getState}) => next => action => {
    if (action) {
        const {type, ...data} = action;
        if (data.redacted) {
            data.redacted.forEach(redact => {
                delete data[redact];
            });
        }
        addBreadcrumb({
            category: 'redux-action',
            message: type,
            data: data,
        });
        if (type === 'THROW_ERROR') {
            captureException(action.error, {
                extra: {
                    reduxState: stateRedactor?.(getState) || getState(),
                },
            });
        }
    }
    return next(action);
};

export const createHistoryMiddleware = stateSanitizer => ({getState}) => next => action => {
    const result = next(action);
    if (typeof action === 'object') {
        const state = stateSanitizer?.(getState) || getState();
        if (action.type === 'THROW_ERROR') {
            history.replaceState(state, null);
        } else if (action.type === 'ADD_STEP') {
            if (action.ignore) return result;
            action.replace
                ? history.replaceState(state, null, action.step)
                : history.pushState(state, null, action.step);
        } else if (action.type === 'BACK_STEP') {
            history.back();
        }
    }
    return result;
};

const setSpanAttributes = (type, childSpan, data, action) => {
    try {
        if (type === 'SET_TOKEN_REQUEST_ID') {
            childSpan.setAttribute('request-id', data.tokenRequestId);
            sessionStorageWrapper.set('requestId', data.tokenRequestId);
        }
        if (type === 'SET_REF_ID') {
            childSpan.setAttribute('ref-id', data.refId);
            sessionStorageWrapper.set('refId', data.refId);
        }
        if (type === 'SET_PROFILE') {
            childSpan.setAttribute('member-id', data.profile.memberId);
            sessionStorageWrapper.set('memberId', data.profile.memberId);
        }
        if (type === 'SET_SELECTED_BANK') {
            childSpan.setAttribute('bank-id', data.bank.id);
            sessionStorageWrapper.set('bankId', data.bank.id);
        }
        if (type === 'TERMINATE_FLOW') {
            const {error} = action;
            const errorMessage = stringify(error.message);
            const errorParams = buildLightstepErrorParams(errorMessage);
            childSpan.setStatus({code: SpanStatusCode.ERROR});
            childSpan.setAttribute('errorMessage', errorMessage);
            childSpan.setAttribute('errorPriority', errorParams.errorPriority || 'medium');
            const userParams = getUserParams(errorParams);
            axios.post(`${getDashboardUrlByEnv()}/query-lightstep?sessionId=${sessionStorageWrapper.get('sessionId')}${userParams}`);
        }
        if (type === 'THROW_ERROR') {
            const {error} = action;
            const errorMessage = stringify(error.message);
            const errorParams = buildLightstepErrorParams(errorMessage);
            childSpan.setStatus({code: SpanStatusCode.ERROR});
            childSpan.setAttribute('errorMessage', errorMessage);
            childSpan.setAttribute('errorPriority', errorParams.errorPriority || 'medium');
            childSpan.setAttribute('errorStack', stringify(error.stack));
            const userParams = getUserParams(errorParams);
            axios.post(`${getDashboardUrlByEnv()}/query-lightstep?sessionId=${sessionStorageWrapper.get('sessionId')}${userParams}`);
        }
        childSpan.setAttribute('redux-action', type);
    } catch (e) {
        console.log(e); // eslint-disable-line no-console
    }
};
