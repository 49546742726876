import { SpinnerWithLockBig } from '@token-io/lib-web-components/src/Components/Spinner';
import { connect } from 'reducers';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { argbToRgba } from 'util/index';
import {
    defaultColors,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import Background from 'components/App/Background';

const Spinner = ({ tppName, color }) => (
    <SpinnerWithLockBig
        color={color}
        label={
            <FormattedMessage
                id={'common.redirectTppSpinner.label'}
                tagName={'span'}
            />
        }
        message={
            <Fragment>
                <FormattedMessage
                    id={'common.redirectTppSpinner.title1'}
                    tagName={'span'}
                    values={{
                        TPP_NAME: tppName,
                    }}
                />
            </Fragment>
        }
    />
);

Spinner.propTypes = {
    bank: PropTypes.object,
    color: PropTypes.string,
    tppName: PropTypes.string,
};

const RedirectTppSpinner = ({
    tppName,
    color,
    displayLogoOnBankRedirectScreen,
}) => {
    return displayLogoOnBankRedirectScreen ? (
        <Background>
            <Spinner tppName={tppName} color={color} />
        </Background>
    ) : (
        <Spinner tppName={tppName} color={color} />
    );
};

RedirectTppSpinner.propTypes = {
    tppName: PropTypes.string,
    color: PropTypes.string,
    displayLogoOnBankRedirectScreen: PropTypes.bool,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };
    const { tppName } = tokenRequestService.getTppProfile();
    const actingAs = tokenRequestService.getTppActingAs();
    const displayLogoOnBankRedirectScreen = tokenRequestService.getTppFeature(
        featureConfig.DISPLAY_LOGO_ON_BANK_REDIRECT_SCREEN,
        false,
    );
    return {
        tppName: actingAs?.displayName || tppName,
        color:
            argbToRgba(colors['color-spinner']) ||
            argbToRgba(colors['color-primary']),
        displayLogoOnBankRedirectScreen,
    };
};

export default connect(mapStateToProps)(RedirectTppSpinner);
