import { CREDENTIAL_FIELD_TYPE as fieldType } from 'config/constants';

const segregateCredentials = overallCredentials => {
    let flickerCode;
    let decoupledFlow = false;

    const staticImages = [];
    const captureCredentials = [];
    const psuMessages = [];

    if (overallCredentials) {
        overallCredentials?.map(data => {
            switch (data.type) {
                case fieldType.FLICKER:
                    flickerCode = data.flickerCode;
                    break;
                case fieldType.IMAGE:
                    staticImages.push(data);
                    break;
                case fieldType.PSU_MESSAGE:
                    psuMessages.push(data);
                    break;
                case fieldType.DECOUPLED:
                    psuMessages.push(data);
                    decoupledFlow = true;
                    break;
                default:
                    if (data.options) {
                        data = {
                            ...data,
                            type: fieldType.OPTIONS,
                        };
                    }
                    captureCredentials.push(data);
            }
        });
    }

    return {
        psuMessages,
        staticImages,
        flickerCode,
        decoupledFlow,
        captureCredentials,
    };
};

export default segregateCredentials;
