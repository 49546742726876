/* eslint-disable max-len */
// User-friendly messages
// intlKey must correspond to a localization key in common.exception in messages.js
// These are displayed to the user after redirection to the ErrorPage
export const INTL_EXCEPTION_REQUEST_FAILED = {
    name: 'EXCEPTION_REQUEST_FAILED',
    intlKey: 'requestFailed',
};
export const INTL_EXCEPTION_APP_NOTIFICATION_TIMEOUT = {
    name: 'EXCEPTION_APP_NOTIFICATION_TIMEOUT',
    intlKey: 'appNotificationTimeout',
};
export const INTL_EXCEPTION_INVALID_ALIAS = {
    name: 'EXCEPTION_INVALID_ALIAS',
    intlKey: 'invalidAlias',
};
export const INTL_EXCEPTION_FAILED_NOTIFICATION = {
    name: 'EXCEPTION_FAILED_NOTIFICATION',
    intlKey: 'failedNotification',
};
export const INTL_EXCEPTION_TRANSACTION_DENIED = {
    name: 'EXCEPTION_TRANSACTION_DENIED',
    intlKey: 'transactionDenied',
};
export const INTL_EXCEPTION_NO_LINKED_ACCOUNTS = {
    name: 'EXCEPTION_NO_LINKED_ACCOUNTS',
    intlKey: 'noLinkedAccounts',
};
export const INTL_EXCEPTION_WEB_CRYPTO = {
    name: 'EXCEPTION_WEB_CRYPTO',
    intlKey: 'webCrypto',
};
export const INTL_EXCEPTION_INDEXED_DB = {
    name: 'EXCEPTION_INDEXED_DB',
    intlKey: 'indexedDB',
};
export const INTL_EXCEPTION_LOCALSTORAGE = {
    name: 'EXCEPTION_LOCALSTORAGE',
    intlKey: 'localStorage',
};

//These errors are passed to TPP according to the status from retrieveTokenRequest's response.
export const EXCEPTION_TOKEN_REQUEST_STATUS_EXTERNAL = {
    PROCESSED: {
        name: 'EXCEPTION_TOKEN_REQUEST_PROCESSED',
        intlKey: 'requestProcessed',
        message: 'This request has already been processed',
        options: {
            redirectBack: false,
        },
    },
    REJECTED: {
        name: 'EXCEPTION_TOKEN_REQUEST_REJECTED',
        intlKey: 'requestRejected',
        message: 'This request has already been rejected',
        options: {
            redirectBack: false,
        },
    },
    EXPIRED: {
        name: 'EXCEPTION_TOKEN_REQUEST_EXPIRED',
        intlKey: 'requestExpired',
        message: 'This request has been expired',
        options: {
            redirectBack: false,
        },
    },
};

export const EXCEPTION_TOKEN_REQUEST_RESTRICTED = {
    name: 'EXCEPTION_TOKEN_REQUEST_GEO_LOCATION_RESTRICTED',
    intlKey: 'geoLocationRestricted',
    message: 'This service is not available in your location',
    options: {
        redirectBack: false,
        intlTitleKey: 'unavailableServiceTitle',
    },
};

export const EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE = {
    name: 'EXCEPTION_SERVICE_TEMPORARILY_UNAVAILABLE',
    intlKey: 'serviceTemproarailyUnavailable',
    message: 'This service is temporarily not available',
    options: {
        redirectBack: false,
        intlTitleKey: 'unavailableServiceTitle',
    },
};

export const EXCEPTION_TOKEN_REQUEST_STATUS_INTERNAL = {
    PENDING_WEB_APP_CONSENT_DECLINED: {
        name: 'EXCEPTION_PENDING_WEB_APP_CONSENT_DECLINED',
        intlKey: 'consentDeclined',
        message: 'Consent declined by PSU at web-app',
        options: {
            redirectBack: false,
        },
    },
    PENDING_CONSENT_SENT_TO_BANK: {
        name: 'EXCEPTION_PENDING_CONSENT_SENT_TO_BANK',
        intlKey: 'consentSentToBank',
        message: 'Consent has been sent to the bank',
        options: {
            redirectBack: false,
        },
    },
    PROCESSING_CONSENT_ACCEPTED_BY_BANK: {
        name: 'EXCEPTION_PROCESSING_CONSENT_ACCEPTED_BY_BANK',
        intlKey: 'consentAcceptedByBank',
        message: 'Consent has been accepted by the bank',
        options: {
            redirectBack: false,
        },
    },
    PROCESSING_CALLBACK_INITIATED_BY_TPP: {
        name: 'EXCEPTION_PROCESSING_CALLBACK_INITIATED_BY_TPP',
        intlKey: 'callbackInitiatedByTpp',
        message: 'Callback has initiated by the tpp',
        options: {
            redirectBack: false,
        },
    },
    // PENDING_REDIRECT_URL_SENT: {
    //     name: 'EXCEPTION_PENDING_REDIRECT_URL_SENT',
    //     intlKey: 'redirectUrlSent',
    //     message: 'User has been redirected to the bank',
    //     options: {
    //         redirectBack: false,
    //         ignoreIfSameSession: true,
    //     },
    // },
    PENDING_WEB_APP_SENT_USER_TO_BANK: {
        name: 'EXCEPTION_PENDING_WEB_APP_SENT_USER_TO_BANK',
        intlKey: 'userSentToBank',
        message: 'User has been redirected to the bank',
        options: {
            redirectBack: false,
            ignoreIfSameSession: true,
        },
    },
};

export const EXCEPTION_VRP_REQUEST = {
    PROCESSED: {
        error: 'EXCEPTION_VRP_REQUEST_PROCESSED',
        message:
            'This request has already been processed with successful status',
    },
    REJECTED: {
        name: 'EXCEPTION_VRP_REQUEST_REJECTED',
        intlKey: 'requestRejected',
        message: 'This request has already been rejected',
    },
    EXPIRED: {
        name: 'EXCEPTION_VRP_REQUEST_EXPIRED',
        intlKey: 'requestExpired',
        message: 'This request has been expired',
    },
    UNSUCCESSFUL: {
        error: 'EXCEPTION_VRP_REQUEST_UNSUCCESSFUL',
        message:
            'We are unable to process your request due to some technical error.',
    },
};

// Technical messages: values should not correspond to localization keys
// These are logged in the console after redirection to the ErrorPage
// while a generic error message is displayed to the user
export const EXCEPTION_UNSUPPORTED_MESSAGING_API =
    'EXCEPTION_UNSUPPORTED_MESSAGING_API';
export const EXCEPTION_UNSUPPORTED_CONTEXT = 'EXCEPTION_UNSUPPORTED_CONTEXT';
export const EXCEPTION_NO_PARENT_WINDOW = 'EXCEPTION_NO_PARENT_WINDOW';
export const EXCEPTION_UNVERIFIED_ALIAS = 'EXCEPTION_UNVERIFIED_ALIAS';
export const EXCEPTION_NO_REDEEMER_ALIAS = 'EXCEPTION_NO_REDEEMER_ALIAS';
export const EXCEPTION_WRONG_ALIAS_TYPE = 'EXCEPTION_WRONG_ALIAS_TYPE';
export const EXCEPTION_NO_DESTINATION = 'EXCEPTION_NO_DESTINATION';
export const EXCEPTION_NO_RESOURCE = 'EXCEPTION_NO_RESOURCE';
export const EXCEPTION_BANK_FLOW_FAILURE = 'EXCEPTION_BANK_FLOW_FAILURE';
export const EXCEPTION_INVALID_TOKEN_REQUEST_URL =
    'EXCEPTION_INVALID_TOKEN_REQUEST_URL';
export const EXCEPTION_INVALID_PATH = 'EXCEPTION_INVALID_PATH';
export const EXCEPTION_INVALID_BANK = 'EXCEPTION_INVALID_BANK';
export const EXCEPTION_INVALID_TRANSFER_DESTINATION_URL =
    'EXCEPTION_INVALID_TRANSFER_DESTINATION_URL';
export const EXCEPTION_REQUEST_CANCELLED = 'EXCEPTION_REQUEST_CANCELLED';
export const EXCEPTION_TOKEN_ERROR = 'EXCEPTION_TOKEN_ERROR';
export const EXCEPTION_BANK_ERROR = 'EXCEPTION_BANK_ERROR';
export const EXCEPTION_UPDATE_VRP_CONSENT_BANK =
    'EXCEPTION_UPDATE_VRP_CONSENT_BANK';
export const EXCEPTION_INITIATE_BANK_AUTH = 'EXCEPTION_INITIATE_BANK_AUTH';
export const EXCEPTION_INITIATE_BANK_AUTH_TIMEOUT =
    'EXCEPTION_INITIATE_BANK_AUTH_TIMEOUT';
export const EXCEPTION_ACCESS_DENIED = 'EXCEPTION_ACCESS_DENIED';
