/**
 * Constructor for a new TokenException.
 * Optional localization if this error code contains a localization key.
 *
 * @param code - code of the exception
 * If string, then normal exception is thrown.
 * If object containing intlKey, then localization is performed.
 * @param message - error message
 * @param intlValues (optional) - internationalization values
 */

export function TokenException(code, message, intlValues) {
    const error = new Error(message);
    if (code.intlKey) {
        error.name = code.name;
        error.intlKey = code.intlKey;
        error.intlValues = intlValues;
    } else {
        error.name = code;
    }
    error.options = code.options;
    return error;
}
