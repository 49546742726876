import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeHTML } from 'util/index';
import { connect } from 'reducers';
import { CUSTOM_TPP_FEATURES as featureConfig } from 'config/constants';
import TokenBackground from '@token-io/lib-web-components/src/Components/Layout/TokenBackground';
import classNames from 'classnames/bind';
import style from './FooterText.css';

const cx = classNames.bind(style);

const CustomFooterText = ({
    customFooterText = '<span>powered by <strong>Token</strong></span>',
    className,
    tppLogo,
    resellerLogo,
    displayShadow,
}) => {
    if (customFooterText) {
        const tppImg = `<image src="${tppLogo}" class='Footer-image'></image>`;
        const resellerImg = `<image src="${resellerLogo}" class='Footer-image'></image>`;
        return (
            <TokenBackground.Footer
                className={cx({
                    'display-block': true,
                    'TokenBackground-footer--shadow': !!displayShadow,
                    'TokenBackground-footer--outerMargin': true,
                })}>
                <div
                    className={className}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(customFooterText)
                            .replace(/{TPP_LOGO}/g, tppLogo ? tppImg : '')
                            .replace(
                                /{RESELLER_LOGO}/g,
                                resellerLogo ? resellerImg : '',
                            )
                            .replace(/\|/g, "<div class='rt-divider'></div>"),
                    }}>
                </div>
            </TokenBackground.Footer>
        );
    }
    return <span></span>;
};

const Footer = ({
    customFooterText,
    isSpinner,
    hasCustomization,
    className,
    tppLogo,
    resellerLogo,
    displayShadow,
}) => {
    if (hasCustomization || customFooterText) {
        if (typeof customFooterText === 'string') {
            return (
                <CustomFooterText
                    customFooterText={customFooterText}
                    className={className}
                    tppLogo={tppLogo}
                    resellerLogo={resellerLogo}
                    displayShadow={isSpinner ? false : displayShadow}
                />
            );
        } else {
            return (
                <CustomFooterText
                    className={className}
                    displayShadow={isSpinner ? false : displayShadow}
                />
            );
        }
    }
    return '';
};

Footer.propTypes = {
    customFooterText: PropTypes.string,
    isSpinner: PropTypes.bool,
    hasCustomization: PropTypes.bool,
    className: PropTypes.string,
    tppLogo: PropTypes.string,
    resellerLogo: PropTypes.string,
    displayShadow: PropTypes.bool,
};

CustomFooterText.propTypes = {
    customFooterText: PropTypes.string,
    className: PropTypes.string,
    tppLogo: PropTypes.string,
    resellerLogo: PropTypes.string,
    displayShadow: PropTypes.bool,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const hasCustomization = tokenRequestService.hasCustomization();
    const customFooterText = tokenRequestService.getTppFeature(
        featureConfig.FOOTER_TEXT,
    );
    const tppLogoPayload = tokenRequestService.getTppFeature(
        featureConfig.TPP_LOGO,
    );
    const resellerLogoPayload = tokenRequestService.getTppFeature(
        featureConfig.RESELLER_LOGO,
    );
    const tppLogo = tppLogoPayload
        ? `data:${tppLogoPayload.type};base64,${tppLogoPayload.data}`
        : '';
    const resellerLogo = resellerLogoPayload
        ? `data:${resellerLogoPayload.type};base64,${resellerLogoPayload.data}`
        : '';
    return {
        hasCustomization,
        customFooterText,
        tppLogo,
        resellerLogo,
    };
};

const FooterText = connect(mapStateToProps)(Footer);

export { FooterText, CustomFooterText };
