import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './Consent.css';
import TextInput from './../Shared/TextInput.js';
import classNames from 'classnames/bind';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { mandatoryFieldsConfig } from 'config/mandatoryFields';

const cx = classNames.bind(style);

class MandatoryFields extends Component {
    componentDidMount() {
        const instructions = this.props.instructions;
        instructions && this.props.handleMandatoryFields({ instructions });
    }

    handleDebtorName = value => {
        const state = this.props.state;
        const sourceLegalName =
            (state?.instructions?.source?.customerData?.legalNames && [
                ...state?.instructions?.source?.customerData?.legalNames,
            ]) ||
            [];
        sourceLegalName[0] = value;
        this.props.handleMandatoryFields({
            instructions: {
                ...state?.instructions,
                source: {
                    ...state?.instructions?.source,
                    customerData: {
                        ...state?.instructions?.source?.customerData,
                        legalNames: sourceLegalName,
                    },
                },
            },
        });
    };

    handleBeneficiaryName = value => {
        const state = this.props.state;
        const beneficiaryLegalName =
            (state?.instructions?.transferDestinations?.[0]?.customerData
                ?.legalNames && [
                ...state?.instructions?.transferDestinations?.[0]?.customerData
                    ?.legalNames,
            ]) ||
            [];
        beneficiaryLegalName[0] = value;
        this.props.handleMandatoryFields({
            instructions: {
                ...state?.instructions,
                transferDestinations: [
                    {
                        ...state?.instructions?.transferDestinations?.[0],
                        customerData: {
                            ...state?.instructions?.transferDestinations?.[0]
                                ?.customerData,
                            legalNames: beneficiaryLegalName,
                        },
                    },
                    ...state?.instructions?.transferDestinations?.slice(1),
                ],
            },
        });
    };

    handleDebtorBic = value => {
        const state = this.props.state;
        this.props.handleMandatoryFields({
            instructions: {
                ...state?.instructions,
                source: {
                    ...state?.instructions?.source,
                    bic: value,
                },
            },
        });
    };

    displayCreditorName = () => {
        return (
            <div className={cx('Consent-data-row')} key={'beneficiary_name'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'pisp.consent.mandatory.beneficiaryName.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e =>
                            this.handleBeneficiaryName(e.target.value)
                        }
                        placeholder={this.props.intl.formatMessage({
                            id:
                                'pisp.consent.mandatory.beneficiaryName.placeholder',
                        })}
                        name="beneficiary_name"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={
                            this.props?.state?.instructions
                                ?.transferDestinations?.[0]?.customerData
                                ?.legalNames?.[0]
                        }
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    handleDebtorBBAN = value => {
        const state = this.props.state;
        this.props.handleMandatoryFields({
            instructions: {
                ...state?.instructions,
                source: {
                    ...state?.instructions?.source,
                    accountIdentifier: {
                        ...state?.instructions?.source?.accountIdentifier,
                        bban: {
                            ...state?.instructions?.source?.accountIdentifier
                                ?.bban,
                            bban: value.trim(),
                        },
                    },
                },
            },
        });
    };

    displayDebtorBBAN = () => {
        return (
            <div className={cx('Consent-data-row')} key={'bban'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'pisp.consent.mandatory.debtorBban.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e => this.handleDebtorBBAN(e.target.value)}
                        placeholder={this.props.intl.formatMessage({
                            id: 'pisp.consent.mandatory.debtorBban.placeholder',
                        })}
                        name="bban"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={
                            this.props?.state?.instructions?.source
                                ?.accountIdentifier?.bban?.bban
                        }
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    displayDebtorName = () => {
        return (
            <div className={cx('Consent-data-row')} key={'debtor_name'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'pisp.consent.mandatory.debtorName.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e => this.handleDebtorName(e.target.value)}
                        placeholder={this.props.intl.formatMessage({
                            id: 'pisp.consent.mandatory.debtorName.placeholder',
                        })}
                        name="debtor_name"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={
                            this.props?.state?.instructions?.source
                                ?.customerData?.legalNames?.[0]
                        }
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    displayDebtorBic = () => {
        return (
            <div className={cx('Consent-data-row')} key={'debtor_bic'}>
                <div
                    className={cx({
                        'Consent-data-row-title': true,
                        'Consent-data-row-title--fixSize': true,
                    })}>
                    <FormattedMessage
                        tagName={'span'}
                        id={'pisp.consent.mandatory.debtorBic.label'}
                    />
                </div>
                <div
                    className={cx({
                        'Consent-data-row-detail': true,
                        'Consent-data-row-detail--fixSize': true,
                    })}>
                    <TextInput
                        onChange={e => this.handleDebtorBic(e.target.value)}
                        placeholder={this.props.intl.formatMessage({
                            id: 'pisp.consent.mandatory.debtorBic.placeholder',
                        })}
                        name="debtor_bic"
                        className={cx({
                            TextInput__inner__input: true,
                            Mandatory_field: true,
                        })}
                        aria-required="true"
                        value={this.props?.state?.instructions?.source?.bic}
                        required
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    render() {
        const { requiredMandatoryFields, instructions, type } = this.props;
        const config = mandatoryFieldsConfig?.[type];
        const creditorData =
            instructions?.transferDestinations?.[0]?.customerData;
        const defaultCreditorName = creditorData?.legalNames?.[0];
        const defaultDebtorName =
            instructions?.source?.customerData?.legalNames?.[0];
        const defaultDebtorBic =
            instructions?.source?.bic ||
            instructions?.source?.account?.iban?.bic;
        const defaultSourceBBAN =
            instructions?.source?.accountIdentifier?.bban?.bban;

        return (
            <>
                {requiredMandatoryFields?.map(field => {
                    if (field === config?.creditorName) {
                        if (!defaultCreditorName) {
                            return this.displayCreditorName();
                        }
                    } else if (field === config?.debtorName) {
                        if (!defaultDebtorName) {
                            return this.displayDebtorName();
                        }
                    } else if (field === config?.debtorBic) {
                        if (!defaultDebtorBic) {
                            return this.displayDebtorBic();
                        }
                    } else if (field === config?.bban) {
                        if (!defaultSourceBBAN) {
                            return this.displayDebtorBBAN();
                        }
                    }
                    return null;
                })}
            </>
        );
    }
}

MandatoryFields.propTypes = {
    requiredMandatoryFields: PropTypes.array,
    instructions: PropTypes.object,
    intl: intlShape.isRequired,
    handleMandatoryFields: PropTypes.func,
    state: PropTypes.object,
    type: PropTypes.string,
};

MandatoryFields.defaultProps = {
    type: 'transfer',
};

export default injectIntl(MandatoryFields);
