import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../../css/TokenButtonsContainer.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

class TokenButtonsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: {
                type: '',
                tabFocused: false,
            },
        };
        this.showFocusOutline = this.showFocusOutline.bind(this);
    }

    showFocusOutline = (type, visible) => {
        this.setState({status: {type: type, tabFocused: visible}});
    };

    render() {
        const {className, children, alignment, ...props} = this.props;
        const classNames = cx({
            TokenButtonsContainer: true,
            [`TokenButtonsContainer--${alignment}`]: true,
            [className]: !!className,
        });
        return (
            <div className={classNames} {...props}>
                {React.Children.map(children, child => React.cloneElement(child, {
                    showFocusOutline: this.showFocusOutline,
                    tabStatus: this.state.status,
                }))}
            </div>
        );
    }
}

TokenButtonsContainer.defaultProps = {
    alignment: 'row',
};

TokenButtonsContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
};

export default TokenButtonsContainer;
