import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import classNames from 'classnames/bind';
import style from './SplashScreen.css';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import { CSSTransition } from 'react-transition-group';
import { setStep } from 'actions/shared/route';
import { setFadeOutProp } from 'actions/shared';
import { sanitizeHTML, argbToRgba } from 'util/index';
import {
    defaultColors,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';

const cx = classNames.bind(style);

class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inProp: false,
        };
        this.timeoutRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({ inProp: true });
    };

    componentDidUpdate = () => {
        if (this.props.stepToSet && this.state.inProp) {
            this.setState({ inProp: false });
            this.timeoutRef.current = setTimeout(() => {
                this.props.setFadeOutProp(true);
                this.props.setStep(this.props.stepToSet);
            }, 2000);
        }
    };

    componentWillUnmount = () => {
        clearTimeout(this.timeoutRef.current);
    };

    render() {
        const {
            splashScreenHtml,
            tppLogo,
            tppSplashLogo,
            resellerLogo,
            displayName,
            productName,
            productLogo,
        } = this.props;
        const tppImg = `<image src="${tppLogo}"></image>`;
        const resellerImg = `<image src="${resellerLogo}"></image>`;

        const tppSplashImg = tppSplashLogo ? (
            <span
                className={cx('TokenSplash-upper-half-tpp-logo')}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(
                        `<image src="${tppSplashLogo}"></image>`,
                    ),
                }}>
            </span>
        ) : null;

        const productImg = productLogo ? (
            <span
                key={'product-logo'}
                className={cx('TokenSplash-upper-half-product-logo')}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(
                        `<image src="${productLogo}"></image>`,
                    ),
                }}>
            </span>
        ) : null;

        const productIdentifier = productName ? (
            <span
                key={'product-identifier'}
                className={cx({
                    'TokenSplash-upper-half-product-name': true,
                    'TokenSplash-upper-half-product-name--no-prepend': !productImg,
                })}>
                {productName}
            </span>
        ) : null;

        const renderProductStuff = () => {
            return [productImg, productIdentifier];
        };

        return (
            <>
                <CSSTransition
                    in={!!this.state.inProp}
                    timeout={3000}
                    classNames="TokenSplash">
                    <TokenContainer noMargin noScrollable>
                        <div className={cx({ TokenSplash: true })}>
                            <div className={cx('TokenSplash-upper-half')}>
                                <div
                                    className={cx(
                                        'TokenSplash-upper-half-container',
                                    )}>
                                    <div
                                        className={cx(
                                            'TokenSplash-upper-half-product-container',
                                        )}>
                                        {renderProductStuff()}
                                    </div>
                                    {tppSplashImg}
                                </div>
                            </div>
                            <div
                                className={cx('TokenSplash-lower-half')}
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(splashScreenHtml)
                                        .replace(/{DISPLAY_NAME}/g, displayName)
                                        .replace(
                                            /{TPP_LOGO}/g,
                                            tppLogo ? tppImg : '',
                                        )
                                        .replace(
                                            /{RESELLER_LOGO}/g,
                                            resellerLogo ? resellerImg : '',
                                        ),
                                }}>
                            </div>
                        </div>
                    </TokenContainer>
                </CSSTransition>
            </>
        );
    }
}

SplashScreen.defaultProps = {
    stepToSet: null,
};

SplashScreen.propTypes = {
    stepToSet: PropTypes.string,
    setStep: PropTypes.func.isRequired,
    setFadeOutProp: PropTypes.func,
    splashScreenHtml: PropTypes.string,
    tppLogo: PropTypes.string,
    tppSplashLogo: PropTypes.string,
    productName: PropTypes.string,
    productLogo: PropTypes.string,
    resellerLogo: PropTypes.string,
    displayName: PropTypes.string,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const splashScreenHtml = tokenRequestService
        .getTppFeature(featureConfig.SPLASH_SCREEN)
        ?.trim();

    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };

    const { tppName } = tokenRequestService.getTppProfile();
    const tppAlias = tokenRequestService.getTppAlias();
    const actingAs = tokenRequestService.getTppActingAs();

    const tppLogoPayload = tokenRequestService.getTppFeature(
        featureConfig.TPP_LOGO,
    );
    const resellerLogoPayload = tokenRequestService.getTppFeature(
        featureConfig.RESELLER_LOGO,
    );
    const tppSplashLogoPayload = tokenRequestService.getTppFeature(
        featureConfig.TPP_LOGO_FOR_SPLASH_SCREEN,
    );
    const productLogoPayload = tokenRequestService.getTppFeature(
        featureConfig.PRODUCT_LOGO,
    );
    const tppLogo = tppLogoPayload
        ? `data:${tppLogoPayload.type};base64,${tppLogoPayload.data}`
        : '';

    const resellerLogo = resellerLogoPayload
        ? `data:${resellerLogoPayload.type};base64,${resellerLogoPayload.data}`
        : '';
    const tppSplashLogo = tppSplashLogoPayload
        ? `data:${tppSplashLogoPayload.type};base64,${tppSplashLogoPayload.data}`
        : '';
    const productLogo = productLogoPayload
        ? `data:${productLogoPayload.type};base64,${productLogoPayload.data}`
        : '';
    const productName = tokenRequestService.getTppFeature(
        featureConfig.PRODUCT_NAME,
    );

    return {
        splashScreenHtml,
        tppLogo,
        tppSplashLogo,
        resellerLogo,
        productName,
        productLogo,
        displayName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            tppAlias.value,
        bgColor: argbToRgba(colors['color-splash-header']),
    };
};

const mapDispatchToProps = {
    setStep,
    setFadeOutProp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
