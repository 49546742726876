import {
    POPUP_SHOW,
    POPUP_CLOSE,
    TOKEN_BANK_POPUP_IS_OPEN,
    TOKEN_BANK_POPUP_CLOSE,
    webAppUrl,
    ROUTE_REDIRECT_OPTIONS,
} from 'config/constants';
import { initiatePopupFlow } from 'actions/shared';
import Postie from '@token-io/lib-web-components/src/Postie';
import { localStorageWrapper } from 'util';
import { EXCEPTION_NO_PARENT_WINDOW } from 'config/exceptions';
import { TokenException } from '@token-io/lib-web-app';
import TokenOverlayController from 'iframe/token/TokenOverlayController';
import TokenBankPopup from 'iframe/TokenBankPopup';
import { POPUP_VISIBILITY_CHANGE } from 'iframe/constants';
import { changeRoute } from 'actions/shared/route';

let _postie, _keepalive, _overlay, _bankPopup, _isBankCallback;

const onBankPopupClose = () => {
    hideOverlay();
    if (_bankPopup) {
        _bankPopup.hidePopup();
    }
    if (!_postie) return;
    _postie.dispatchMessage({
        type: POPUP_CLOSE,
    });
};

const hideOverlay = () => {
    if (!_overlay) return;
    _overlay.hide();
    _overlay = null;
};

const verifyOpener = () => {
    if (
        !(window && window.opener && window !== window.opener) &&
        !window.frames
    ) {
        throw new TokenException(
            EXCEPTION_NO_PARENT_WINDOW,
            'No parent window',
        );
    }
};

const TokenCommunicator = {
    setupMessages(postie) {
        postie.onMessage(
            POPUP_SHOW,
            data => {
                TokenCommunicator.dispatch(initiatePopupFlow(data));
            },
            true,
        );
    },
    setupKeepAlive(parent) {
        _keepalive = setInterval(() => {
            const linked = !!parent || !!window.opener;
            if (!linked) {
                clearInterval(_keepalive);
                _keepalive = 0;
                window.close();
            }
        }, 500);
    },
    setup(dispatch, target) {
        verifyOpener();
        TokenCommunicator.dispatch = dispatch;
        const id = localStorageWrapper.get('popup_id');
        _postie = new Postie({
            id,
            source: window,
            destination: target || window.opener,
        });
        TokenCommunicator.setupMessages(_postie);
        TokenCommunicator.setupKeepAlive(target);
    },
    setupIframe(dispatch) {
        TokenCommunicator.setup(dispatch, window.parent);
    },
    setupBankPopup(dispatch) {
        TokenCommunicator.setup(dispatch, window.parent[0]);
        _postie.onMessage(
            TOKEN_BANK_POPUP_CLOSE,
            function (payload) {
                _isBankCallback = true;
                if (_overlay) _overlay.hide();
                _overlay = null;
                const url = payload.payload;
                window.document.location.assign(url);
            },
            true,
        );
        _postie.onMessage(
            TOKEN_BANK_POPUP_IS_OPEN,
            function (payload) {
                const bankUrl = payload?.payload;
                if (!_overlay) {
                    _overlay = new TokenOverlayController({ url: webAppUrl });
                    _overlay.show();
                    _overlay.bindOverlayCloseAction(onBankPopupClose);
                }

                const bankPopup = new TokenBankPopup({
                    url: bankUrl,
                });

                _bankPopup = bankPopup.getPopup();
            },
            true,
        );
        _postie.onMessage(
            POPUP_VISIBILITY_CHANGE,
            function (data) {
                if (!data.visible) {
                    if (_overlay) _overlay.hide();
                    _overlay = null;
                    if (!_isBankCallback) {
                        TokenCommunicator.dispatch(
                            changeRoute(ROUTE_REDIRECT_OPTIONS),
                        );
                    }
                }
            },
            true,
        );
    },
    async sendMessage(type, payload) {
        verifyOpener();
        return new Promise((resolve, reject) => {
            _postie.dispatchRequest(
                {
                    type,
                    payload,
                },
                resolve,
                reject,
            );
        });
    },
    dispatchMessage(type, payload) {
        verifyOpener();
        _postie.dispatchMessage({
            type,
            payload,
        });
    },
};

export default TokenCommunicator;
