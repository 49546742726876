import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'reducers';
import style from './BankSelector.css';
import classNames from 'classnames/bind';
import TokenContainer from '@token-io/lib-web-components/src/Components/Layout/TokenContainer';
import TokenButtonsWrapper from '../Shared/TokenButtonsWrapper.js';
import BankOutageError from '../Shared/BankOutageError.js';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import Search from '@token-io/lib-web-components/src/Components/Search';
import { ROW_SIZE_LARGE } from '@token-io/lib-web-components/src/Components/List/Abstract';
import AsyncDataProvider from '@token-io/lib-web-components/src/Components/Data/AsyncDataProvider';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import RecentBankList from './RecentBankList';
import {
    fetchBanks,
    fetchTopBanks,
    declineTerms,
    clearBankStatus,
    setCountry,
} from 'actions/shared';
import {
    argbToRgba,
    formatCountries,
    getBankRawData,
    formatCountry,
} from 'util/index';
import { FormattedMessage, intlShape } from 'react-intl';
import {
    defaultColors,
    CUSTOM_TPP_FEATURES as featureConfig,
} from 'config/constants';
import TopBanks from './TopBanks';
import { fetchTransferDestinationUrl } from '../../../actions/pisp';

const cx = classNames.bind(style);

class BankSelectorFirst extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            recentBanks: [],
            country: null, // Denotes the currently selected country
            loadingMore: false,
            searchTerm: undefined,
            showMore: false,
            dropDown: undefined,
            displayBankOutageError: false,
            searchInputFocused: false,
            paging: {
                page: 1,
                perPage: 25,
                pageCount: 1,
            },
        };
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.setPaging = this.setPaging.bind(this);
        this.searchRef = React.createRef();
        this.setDropDownState = this.setDropDownState.bind(this);
        this.setSearchFocus = this.setSearchFocus.bind(this);
    }

    dismissBankOutageError() {
        this.setState({ displayBankOutageError: false });
        // clear bank status
        this.props.clearBankStatus();
    }

    setDisplayBankOutageError() {
        if (
            this.props.bankStatus &&
            Object.keys(this.props.bankStatus).length > 0
        ) {
            if (
                this.props.bankStatus.isLive != null &&
                !this.props.bankStatus.isLive
            ) {
                this.setState({ displayBankOutageError: true }, () => {
                    // display previously selected country as selected by default in case of bank outage error.
                    const previouslySelectedCountry = this.props
                        .selectedCountry;
                    if (previouslySelectedCountry != null) {
                        const country = formatCountry(
                            previouslySelectedCountry,
                            this.props.intl.formatMessage,
                        );
                        this.handleCountryChange(country);
                    }
                });
            }
        }
    }

    /*
     * Handler for when the user changes country, does proper state management and fires API call
     */
    handleCountryChange(selectedItem) {
        if (
            JSON.stringify(selectedItem) !== JSON.stringify(this.state.country)
        ) {
            this.props.fetchTopBanks(selectedItem);
            this.setState(
                {
                    country: selectedItem,
                    loadingMore: false,
                    showMore: false,
                },
                selectedItem &&
                    (() => {
                        this.props.fetchBanks(
                            { country: selectedItem.code },
                            this.setPaging,
                        );
                    }),
            );
            this.state.displayBankOutageError && this.dismissBankOutageError();
            this.props.setCountry(selectedItem.code);
        } else {
            this.setDropDownState(false);
        }
    }

    setSearchFocus(focus) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!focus) {
            this.dismissBankOutageError();
        }
        this.setState({ searchInputFocused: focus });
    }

    setDropDownState(state) {
        if (
            this.props.countries.length !== 1 &&
            !this.props.destinationCountry
        ) {
            this.dismissBankOutageError();
        }
        this.setState({ dropDown: state });
    }

    setPaging(paging) {
        this.setState({
            paging,
        });
    }

    proceed = () => {
        const hasTransferDestinationsUrlOnly =
            this.props.setTransferDestinationsUrl && !this.props.destinations;
        const selectedBank = this.props.selectedBank;

        if (hasTransferDestinationsUrlOnly) {
            this.props.fetchTransferDestinationUrl(selectedBank);
        } else {
            this.props.setBankAndProceedToConsent(selectedBank);
        }
    };

    displayButtonContainer = () => {
        const { displayRecentBanks } = this.props;
        const { recentBanks, country } = this.state;
        if (
            displayRecentBanks &&
            recentBanks?.length > 0 &&
            !country &&
            !this.state.displayBankOutageError
        ) {
            return true;
        } else {
            return false;
        }
    };

    componentDidUpdate() {
        this.props.setDisplayFooterShadow(!this.displayButtonContainer());
    }

    async componentDidMount() {
        let recentBanks = this.props.recentBanksList;
        // If countries list present in requestPayload, filter out any bank not
        // in the mentioned country list.
        const requestCountries = this.props.requestCountries;
        if (requestCountries && requestCountries.length > 0) {
            recentBanks = recentBanks?.filter(b =>
                requestCountries.includes(b.selectedCountry),
            );
        }
        this.setState({ recentBanks });

        // Check if there is only one country present or
        // if there is any default country is there then
        // set it immediately after the component is mounted
        if (
            this.props.countries.length === 1 ||
            (this.props.destinationCountry &&
                this.props.countries.includes(this.props.destinationCountry))
        ) {
            const destinationCountry =
                this.props.destinationCountry || this.props.countries[0];
            const country = formatCountry(
                destinationCountry,
                this.props.intl.formatMessage,
            );
            this.handleCountryChange(country);
        }

        this.setDisplayBankOutageError();
    }

    render() {
        const {
            country,
            loadingMore,
            searchTerm,
            paging,
            recentBanks,
        } = this.state;
        const {
            declineTerms,
            countries,
            banks,
            intl,
            fetchBanks,
            setBankAndProceedToConsent,
            selectedBankId,
            selectedBank,
            spinnerColor,
            setTransferDestinationsUrl,
            destinations,
            topBanks,
            fetchTransferDestinationUrl,
            displayRecentBanks,
            showBankSelectorTitle,
            tppProfile,
            bankStatus,
        } = this.props;
        const { page, perPage, pageCount } = paging;
        const status =
            (recentBanks?.findIndex(bank => bank.id === selectedBankId) ===
                -1 &&
                Button.STATUS_DISABLED) ||
            Button.STATUS_IDLE;
        const hasTransferDestinationsUrlOnly =
            setTransferDestinationsUrl && !destinations;
        return (
            <Fragment>
                <TokenContainer className={'BankSelector-container'}>
                    <TokenTitle>
                        <FormattedMessage id={'common.bankSelector.title'} />
                    </TokenTitle>
                    {showBankSelectorTitle && (
                        <>
                            <TokenTitle sub>
                                <FormattedMessage
                                    id={'common.bankSelector.bankSelectorTitle'}
                                    values={{
                                        TPP_NAME: tppProfile.tppName,
                                    }}
                                />
                            </TokenTitle>
                            <br />
                        </>
                    )}

                    <TokenTitle sub className="BankSelector-country-label">
                        {(this.state.country && (
                            <FormattedMessage
                                id={'common.bankSelector.subtitleSelected'}
                            />
                        )) || (
                            <FormattedMessage
                                id={'common.bankSelector.subtitle'}
                            />
                        )}
                    </TokenTitle>
                    {(countries.length === 1 && (
                        <div className="BankSelector_Single">
                            {
                                formatCountries(
                                    countries,
                                    intl.formatMessage,
                                )[0].key
                            }
                        </div>
                    )) || (
                        <StaticDataProvider
                            source={formatCountries(
                                countries,
                                intl.formatMessage,
                            )}
                            idKey={'code'}
                            titleKey={'code'}
                            dataKey={'key'}
                            onChange={this.handleCountryChange}
                            value={country}
                            withPointer>
                            <Dropdown
                                className={'BankSelector-country'}
                                listClass={'BankSelector-country-dropdown'}
                                rowClass={'BankSelector-country-item'}
                                dropDownState={this.state.dropDown}
                                setDropDownState={this.setDropDownState}
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        'common.bankSelector.countryPlaceholder',
                                })}
                                ariaLabel={this.props.intl.formatMessage({
                                    id: 'common.bankSelector.subtitle',
                                })}
                                allowDeselect
                            />
                        </StaticDataProvider>
                    )}

                    {country ? (
                        <TokenTitle sub className="BankSelector-search-label">
                            <label htmlFor="Bank-Selector-Input">
                                <FormattedMessage
                                    id={'common.bankSelector.search.label'}
                                />
                            </label>
                        </TokenTitle>
                    ) : null}
                    {country ? (
                        <div className={cx('BankSelector-input-container')}>
                            <AsyncDataProvider
                                page={page}
                                pageSize={perPage}
                                source={async options => {
                                    const searching =
                                        options.search !== searchTerm;
                                    const nextPage =
                                        (searching && 1) ||
                                        (page && page + 1) ||
                                        2;
                                    if (
                                        !searching &&
                                        (loadingMore || page >= pageCount)
                                    )
                                        return;
                                    this.setState({
                                        loadingMore: true,
                                        searchTerm: options.search,
                                    });
                                    await fetchBanks(
                                        Object.assign(options, {
                                            country: country && country.code,
                                            page: nextPage,
                                        }),
                                        this.setPaging,
                                    );
                                    this.setState({
                                        loadingMore: false,
                                    });
                                }}
                                fetchOnMount={false}
                                useCustomData
                                customData={banks}
                                customLoading={loadingMore}
                                customSetValue={async (
                                    value,
                                    { doneCallback },
                                ) => {
                                    const bank = {
                                        ...value.rawData,
                                        selectedCountry: country.code.toUpperCase(),
                                    };
                                    hasTransferDestinationsUrlOnly
                                        ? fetchTransferDestinationUrl(bank)
                                        : setBankAndProceedToConsent(bank);
                                    doneCallback();
                                }}
                                value={selectedBank}
                                withPointer>
                                <Search
                                    ref={this.searchRef}
                                    id={'Bank-Selector-Input'}
                                    size={ROW_SIZE_LARGE}
                                    placeholder={
                                        this.state.searchInputFocused
                                            ? null
                                            : this.props.intl.formatMessage({
                                                id:
                                                      'common.bankSelector.search.placeholder',
                                            })
                                    }
                                    className={'BankSelector-input'}
                                    listClass={'BankSelector-banks'}
                                    rowClass={'BankSelector-banks-item'}
                                    spinnerColor={spinnerColor}
                                    ariaLabel={this.props.intl.formatMessage({
                                        id:
                                            'common.bankSelector.search.ariaLabel',
                                    })}
                                    setSearchFocus={this.setSearchFocus}
                                />
                            </AsyncDataProvider>
                        </div>
                    ) : (
                        (displayRecentBanks &&
                            recentBanks?.length &&
                            !this.state.displayBankOutageError && (
                            <RecentBankList banks={recentBanks} />
                        )) ||
                        null
                    )}
                    {country &&
                        !this.state.searchInputFocused &&
                        topBanks.length > 0 &&
                        !this.state.displayBankOutageError && (
                        <TopBanks
                            banks={getBankRawData(topBanks)}
                            setBankAndProceedToConsent={
                                setBankAndProceedToConsent
                            }
                            searchInputFocused={
                                this.state.searchInputFocused
                            }
                        />
                    )}
                    {this.state.displayBankOutageError && bankStatus.bank && (
                        <BankOutageError bank={bankStatus.bank} />
                    )}
                </TokenContainer>
                {this.displayButtonContainer() && (
                    <TokenButtonsWrapper>
                        <Button
                            status={status}
                            onClick={this.proceed}
                            text={
                                <FormattedMessage id={'common.button.next'} />
                            }
                        />
                        <Button
                            type={Button.TYPE_GHOST_WARNING}
                            onClick={declineTerms}
                            text={
                                <FormattedMessage
                                    id={'common.button.decline'}
                                />
                            }
                        />
                    </TokenButtonsWrapper>
                )}
            </Fragment>
        );
    }
}

BankSelectorFirst.propTypes = {
    countries: PropTypes.array.isRequired,
    banks: PropTypes.array.isRequired,
    selectedBankId: PropTypes.string,
    selectedBank: PropTypes.object,
    bankStatus: PropTypes.object,
    fetchBanks: PropTypes.func.isRequired,
    setBankAndProceedToConsent: PropTypes.func.isRequired,
    backToBankSelector: PropTypes.func.isRequired,
    hideConsent: PropTypes.bool,
    spinnerColor: PropTypes.string,
    intl: intlShape.isRequired,
    tokenType: PropTypes.string.isRequired,
    transferTypeDestinations: PropTypes.array.isRequired,
    setTransferDestinationsUrl: PropTypes.string,
    destinations: PropTypes.array,
    declineTerms: PropTypes.func.isRequired,
    clearBankStatus: PropTypes.func,
    fetchTopBanks: PropTypes.func.isRequired,
    topBanks: PropTypes.array.isRequired,
    recentBanksList: PropTypes.array,
    setCountry: PropTypes.func,
    destinationCountry: PropTypes.string,
    alias: PropTypes.string,
    tppProfile: PropTypes.object,
    fetchTransferDestinationUrl: PropTypes.func,
    tppMemberAndRealmId: PropTypes.object.isRequired,
    displayRecentBanks: PropTypes.bool,
    requestCountries: PropTypes.array.isRequired,
    showBankSelectorTitle: PropTypes.bool,
    resellerName: PropTypes.string,
    setDisplayFooterShadow: PropTypes.func,
    hideDefaultCountriesAndBanks: PropTypes.bool,
    selectedCountry: PropTypes.string,
};

BankSelectorFirst.defaultProps = {
    banks: [],
    topBanks: [],
    requestCountries: [],
};

const mapStateToProps = ({
    sharedService,
    tokenRequestService,
    tokenService,
}) => {
    const colors = {
        ...defaultColors,
        ...tokenRequestService.getTppFeature(featureConfig.CUSTOM_COLORS),
    };
    const tppProfile = tokenRequestService.getTppProfile();
    const tppMemberAndRealmId = tokenRequestService.getTppMemberAndRealmId();
    const hideDefaultCountriesAndBanks = tokenRequestService.getTppFeature(
        featureConfig.HIDE_DEFAULT_COUNTRIES_AND_BANKS,
    );
    const displayRecentBanks = tokenRequestService.getTppFeature(
        featureConfig.SUPPORT_RECENT_BANKS,
        !tokenRequestService.hasTppFeatures(),
    );
    const memberCountries = sharedService.getCountries();
    const requestCountries = tokenRequestService.getRequestCountries();
    const countries = requestCountries ? requestCountries : memberCountries;
    const showBankSelectorTitle = tokenRequestService.getTppFeature(
        featureConfig.SHOW_BANK_SELECTOR_TITLE,
        false,
    );
    return {
        banks: sharedService.getBanks(),
        countries: countries,
        requestCountries: requestCountries,
        selectedBankId: sharedService.getSelectedBankId(),
        bankStatus: sharedService.getBankStatus(),
        selectedBank: sharedService.getSelectedBank(),
        spinnerColor: argbToRgba(colors['color-primary']),
        hideConsent: tokenRequestService.getHideConsent(),
        tokenType: sharedService.getTokenType(),
        transferTypeDestinations: tokenService.getTransferTypeDestinations(
            sharedService.getTokenType(),
        ),
        selectedCountry: sharedService.getSelectedCountry(),
        setTransferDestinationsUrl: tokenService.getSetTransferDestinationsUrl(),
        destinations: tokenService.getDestinations(),
        topBanks: sharedService.getTopBanks(),
        recentBanksList: sharedService.getRecentBanks(),
        destinationCountry: sharedService.getDefaultCountry(),
        alias: tokenRequestService.getTppAlias().value,
        resellerName: tokenRequestService.getResellerName(),
        tppMemberAndRealmId,
        displayRecentBanks,
        showBankSelectorTitle,
        hideDefaultCountriesAndBanks,
        tppProfile,
    };
};

const mapDispatchToProps = {
    fetchBanks,
    setCountry,
    fetchTopBanks,
    fetchTransferDestinationUrl,
    declineTerms,
    clearBankStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankSelectorFirst);
