let browserEnv = {};

// Allow configuration of environment variables through browser environment
if (window !== undefined && window.BROWSER_ENV !== undefined) {
    browserEnv = window.BROWSER_ENV;
}

export const TOKEN_POPUP_NAME = 'TOKEN_FRAME';
export const TOKEN_WEB_APP_IFRAME_NAME = 'TOKEN_WEB_APP_IFRAME';

// Config values from server

// Update config for breaking changes that require flushing localStorage
export const LS_VERSION = browserEnv.localStorageVersion;
export const TOKEN_ENV = browserEnv.tokenEnv;
export const hotModule = browserEnv.hotModule;
export const gitSha = browserEnv.gitSha;
export const webAppUrl = browserEnv.webAppUrl;
export const tokenRootUrl = browserEnv.tokenRootUrl;
export const sdkErrorLoggingEnabled = browserEnv.sdkErrorLoggingEnabled;
export const sendErrorReports = browserEnv.sendErrorReports;
export const sendTraceSpans = browserEnv.sendTraceSpans;
export const lightStepAccessToken = browserEnv.lightStepAccessToken;
export const appNotificationInterval =
    browserEnv.timeouts.appNotificationInterval;
export const appNotificationTimeout =
    browserEnv.timeouts.appNotificationTimeout;
export const decoupleAuthorizationInterval =
    browserEnv.timeouts.decoupleAuthorizationInterval;
export const decoupleAuthorizationTimeout =
    browserEnv.timeouts.decoupleAuthorizationTimeout;
export const tokenRequestResultInterval =
    browserEnv.timeouts.tokenRequestResultInterval;
export const tokenRequestResultTimeout =
    browserEnv.timeouts.tokenRequestResultTimeout;
export const defaultLocale = browserEnv.defaultLocale;
export const defaultColors = browserEnv.defaultColors;
export const defaultName = 'Token.io Ltd';
export const defaultAppName = 'Token.io';

export const TOKEN_PROVIDER = 'Token';
export const enfuseLicenseTpps = browserEnv.enfuseLicenseTpps || [];

export const localCustomizationTppList =
    browserEnv?.localCustomizationTppList || {};

export const defaultCustomizationTppList =
    browserEnv?.defaultCustomizationTppList || {};

export const CUSTOM_TPP_FEATURES = {
    SUPPORT_PSR44_FLOW: 'supportPsr44Flow',
    ENABLE_TSP_CONFIG: 'enableTspConfig',
    CUSTOM_WEB_APP_URL: 'customWebAppUrl',
    DISPLAY_BENEFICARY_DETAILS: 'displayBeneficiaryDetails',
    IS_ENFUSE: 'isEnfuse',
    SUPPORTED_COUNTRIES_WITH_BANK_IDS: 'supportedCountriesWithBankIds',
    ENABLE_WEB_APP: 'enableWebApp',
    SUPPORT_RECENT_BANKS: 'supportRecentBanks',
    OVERRIDE_DEFAULT_CUSTOMIZATIONS: 'overrideDefaultCustomizations',
    DISPLAY_LOGO_ON_BANK_REDIRECT_SCREEN: 'displayLogoOnBankRedirectScreen',
    FOOTER_TEXT: 'footerText',
    CATEGORY: 'category',
    CUSTOM_PIS_CONSENT_TEXT: 'translations.pisp.consent.text',
    CUSTOM_AIS_CONSENT_TEXT: 'translations.aisp.consent.text',
    CUSTOM_PIS_TERMS_AND_CONDITIONS_TEXT:
        'translations.pisp.consent.termsAndConditions.loader',
    CUSTOM_AIS_TERMS_AND_CONDITIONS_TEXT:
        'translations.aisp.consent.termsAndConditions.loader',
    TRANSLATIONS: 'translations',
    CUSTOM_COLORS: 'colors',
    SPLASH_SCREEN: 'splashScreen',
    SHOW_BANK_SELECTOR_TITLE: 'showBankSelectorTitle',
    TPP_LOGO: 'tppLogo',
    AIS_APP_NAME: 'ais.tags.businessName',
    PIS_APP_NAME: 'pis.tags.businessName',
    AIS_NAME: 'ais.tags.displayName',
    PIS_NAME: 'pis.tags.displayName',
    AISP: 'ais',
    PISP: 'pis',
    TAGS: 'tags',
    DISPLAY_TOKEN_BACKGROUND_BACK_BUTTON: 'displayWebAppBackButton',
    MODIFY_PRE_SELECTED_BANK: 'modifyPreSelectedBank',
    WEB_APP_BUTTONS_ALIGNMENT: 'webAppButtonsAlignment',
    RESELLER_LOGO: 'resellerLogo',
    PRODUCT_LOGO: 'productLogo',
    PRODUCT_NAME: 'productName',
    TPP_LOGO_FOR_SPLASH_SCREEN: 'tppLogoSplashScreen',
    CUSTOM_ERROR: 'displayCustomErrorPage',
    DISPLAY_SHADOW: 'displayShadow',
    HIDE_DEFAULT_COUNTRIES_AND_BANKS: 'hideDefaultCountriesAndBanks',
    ENABLE_ACCEPT_BUTTON: 'enableAcceptButtonWithCustomText',
    // Enable web-app to forward current transfer params if exists in case of error scenarios as well
    FORWARD_CURRENT_TRANSFER_PARAMETERS: 'forwardCurrentTransferParams',
    HIDE_PSR44_TIMER: 'hidePSR44Timer',
    REMOVE_PSR44_TIMER: 'removePSR44Timer',
};

export const REQUEST_TYPE = {
    VRP_CONSENT: 'VRP_CONSENT',
    TOKEN_REQUEST: 'TOKEN_REQUEST',
};

export const EXTERNAL_TOKEN_REQUEST_STATUS = {
    UNKNOWN: 'UNKNOWN',
    PENDING: 'PENDING', // NOT_FOUND error will be replaced with this status.
    PROCESSED: 'PROCESSED', // This is the successful status. Token id exists.
    REJECTED: 'REJECTED', // The request has been rejected.
    EXPIRED: 'EXPIRED', // The token request is expired and will never be finished.
};

export const WEB_APP_EVENTS = {
    WEB_APP_INITIALIZED: 'WEB_APP_INITIALIZED',
    WEB_APP_BANK_SELECTED: 'WEB_APP_BANK_SELECTED',
    WEB_APP_CONSENT_ACCEPTED: 'WEB_APP_CONSENT_ACCEPTED',
    WEB_APP_CONSENT_DECLINED: 'WEB_APP_CONSENT_DECLINED',
    WEB_APP_BANK_CALLBACK: 'WEB_APP_BANK_CALLBACK',
    WEB_APP_RETURN_TO_TPP_SUCCESS: 'WEB_APP_RETURN_TO_TPP_SUCCESS',
    WEB_APP_RETURN_TO_TPP_FAILURE: 'WEB_APP_RETURN_TO_TPP_FAILURE',
    WEB_APP_CREDENTIALS_REQUIRED: 'WEB_APP_CREDENTIALS_REQUIRED',
    WEB_APP_CREDENTIALS_CAPTURED: 'WEB_APP_CREDENTIALS_CAPTURED',
    WEB_APP_USER_REDIRECTED_TO_BANK: 'WEB_APP_USER_REDIRECTED_TO_BANK',
    WEB_APP_PAYMENT_CONFIRMATION_DISPLAYED:
        'WEB_APP_PAYMENT_CONFIRMATION_DISPLAYED',
};

export const INTERNAL_TOKEN_REQUEST_STATUS = {
    PROCESSED: 'PROCESSED',
    REJECTED: 'REJECTED',
    PROCESSED_NO_CALLBACK: 'PROCESSED_NO_CALLBACK',
    PENDING_WEB_APP_INVOKED: 'PENDING_WEB_APP_INVOKED',
    PENDING_WEB_APP_BANK_SELECTED: 'PENDING_WEB_APP_BANK_SELECTED',
    PENDING_WEB_APP_CONSENT_ACCEPTED: 'PENDING_WEB_APP_CONSENT_ACCEPTED',
    PENDING_WEB_APP_CONSENT_DECLINED: 'PENDING_WEB_APP_CONSENT_DECLINED',
    PROCESSED_COMPLETED_PAYMENT_CONFIRMATION_PAGE_DISPLAYED:
        'PROCESSED_COMPLETED_PAYMENT_CONFIRMATION_PAGE_DISPLAYED',
    PROCESSED_FAILED_PAYMENT_ERROR_PAGE_DISPLAYED:
        'PROCESSED_FAILED_PAYMENT_ERROR_PAGE_DISPLAYED',
    PENDING_CONSENT_SENT_TO_BANK: 'PENDING_CONSENT_SENT_TO_BANK',
    PROCESSING_CONSENT_ACCEPTED_BY_BANK: 'PROCESSING_CONSENT_ACCEPTED_BY_BANK',
    PROCESSING_CALLBACK_INITIATED_BY_TPP:
        'PROCESSING_CALLBACK_INITIATED_BY_TPP',
    PENDING_REDIRECT_URL_SENT: 'PENDING_REDIRECT_URL_SENT',
    PENDING_WEB_APP_SENT_USER_TO_BANK: 'PENDING_WEB_APP_SENT_USER_TO_BANK',
    PENDING_TOKEN_CREATED_NOT_AUTHORIZED:
        'PENDING_TOKEN_CREATED_NOT_AUTHORIZED',
    PENDING_CREDENTIALS_REQUIRED: 'PENDING_CREDENTIALS_REQUIRED',
    PENDING_FURTHER_CREDENTIALS_REQUIRED_1:
        'PENDING_FURTHER_CREDENTIALS_REQUIRED_1',
    PENDING_FURTHER_CREDENTIALS_REQUIRED_2:
        'PENDING_FURTHER_CREDENTIALS_REQUIRED_2',
};

export const INTERNAL_TOKEN_REQUEST_STATUS_MAP = {
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_INVOKED]:
        WEB_APP_EVENTS.WEB_APP_INITIALIZED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_BANK_SELECTED]:
        WEB_APP_EVENTS.WEB_APP_BANK_SELECTED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_CONSENT_ACCEPTED]:
        WEB_APP_EVENTS.WEB_APP_CONSENT_ACCEPTED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_SENT_USER_TO_BANK]:
        WEB_APP_EVENTS.WEB_APP_USER_REDIRECTED_TO_BANK,
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_COMPLETED_PAYMENT_CONFIRMATION_PAGE_DISPLAYED]:
        WEB_APP_EVENTS.WEB_APP_PAYMENT_CONFIRMATION_DISPLAYED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_FAILED_PAYMENT_ERROR_PAGE_DISPLAYED]:
        WEB_APP_EVENTS.WEB_APP_PAYMENT_CONFIRMATION_DISPLAYED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_CREDENTIALS_REQUIRED]:
        WEB_APP_EVENTS.WEB_APP_CREDENTIALS_REQUIRED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_FURTHER_CREDENTIALS_REQUIRED_1]:
        WEB_APP_EVENTS.WEB_APP_CREDENTIALS_REQUIRED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_FURTHER_CREDENTIALS_REQUIRED_2]:
        WEB_APP_EVENTS.WEB_APP_CREDENTIALS_REQUIRED,
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_CONSENT_DECLINED]:
        WEB_APP_EVENTS.WEB_APP_CONSENT_DECLINED,
};

export const INTERNAL_TOKEN_REQUEST_STATUS_REASON = {
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED]:
        'Token request has been processed',
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_NO_CALLBACK]:
        'Token request has been processed',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_INVOKED]:
        'TPP has successfully invoked the web-app',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_BANK_SELECTED]:
        'Bank has been selected at web-app',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_CONSENT_ACCEPTED]:
        'Consent accepted by PSU at web-app',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_CONSENT_DECLINED]:
        'Consent declined by PSU at web-app',
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_COMPLETED_PAYMENT_CONFIRMATION_PAGE_DISPLAYED]:
        'PSU was shown payment success page',
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_FAILED_PAYMENT_ERROR_PAGE_DISPLAYED]:
        'PSU was shown payment error page',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_FURTHER_CREDENTIALS_REQUIRED_1]:
        'Credentials are required from PSU at webapp.',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_CONSENT_SENT_TO_BANK]:
        'Consent has been sent to the bank',
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSING_CONSENT_ACCEPTED_BY_BANK]:
        'Consent has been accepted by the bank',
    [INTERNAL_TOKEN_REQUEST_STATUS.PROCESSING_CALLBACK_INITIATED_BY_TPP]:
        'Callback has initiated by the tpp',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_REDIRECT_URL_SENT]:
        'User has been redirected to the bank',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_SENT_USER_TO_BANK]:
        'User has been redirected to the bank',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_TOKEN_CREATED_NOT_AUTHORIZED]:
        'Token has been created but not authorized',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_CREDENTIALS_REQUIRED]:
        'Credentials are required from PSU at webapp.',
    [INTERNAL_TOKEN_REQUEST_STATUS.PENDING_FURTHER_CREDENTIALS_REQUIRED_2]:
        'Final Credentials are required from PSU',
    [INTERNAL_TOKEN_REQUEST_STATUS.REJECTED]: 'User Cancelled',
};

export const PENDING_TOKEN_REQUEST_STATUS = [
    INTERNAL_TOKEN_REQUEST_STATUS.PENDING_WEB_APP_CONSENT_ACCEPTED,
];

export const PROCESSED_TOKEN_REQUEST_STATUS = [
    EXTERNAL_TOKEN_REQUEST_STATUS.PROCESSED,
    INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED,
    INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_NO_CALLBACK,
    INTERNAL_TOKEN_REQUEST_STATUS.PENDING_TOKEN_CREATED_NOT_AUTHORIZED,
    INTERNAL_TOKEN_REQUEST_STATUS.PROCESSED_COMPLETED_PAYMENT_CONFIRMATION_PAGE_DISPLAYED,
];

export const PENDING_CREDENTIALS_TOKEN_REQUEST_STATUS = [
    INTERNAL_TOKEN_REQUEST_STATUS.PENDING_CREDENTIALS_REQUIRED,
    INTERNAL_TOKEN_REQUEST_STATUS.PENDING_FURTHER_CREDENTIALS_REQUIRED_1,
    INTERNAL_TOKEN_REQUEST_STATUS.PENDING_FURTHER_CREDENTIALS_REQUIRED_2,
];

export const INIT_BANK_AUTH_STATUS = {
    REDIRECT_URL: 'REDIRECT_URL',
    FIELDS: 'FIELDS',
    STATUS: 'STATUS',
};

export const CREDENTIAL_FIELD_TYPE = {
    PSU_MESSAGE: 'PSU_MESSAGE',
    FLICKER: 'FLICKER',
    IMAGE: 'IMAGE',
    OPTIONS: 'OPTIONS',
    PASSWORD: 'PASSWORD',
    DECOUPLED: 'DECOUPLED',
};

export const TPP_INTEGRATION_ADDRESS = browserEnv.tppIntegrationUrl;
export const TOKEN_API_ADDRESS = browserEnv.tokenApiUrl;
export const PATH_TO_VALIDATE_IBAN = '/validate-iban';
export const STORE_WEB_APP_EVENTS_PATH = '/web-app-events';
export const GET_TOKEN_REQUEST_RESULT_WITH_STATUS_PATH =
    '/token-requests/{TOKEN_REQUEST_ID}/result?memberId={MEMBER_ID}';
export const SET_TOKEN_REQUEST_RESULT_STATUS_INTERNAL_PATH =
    '/token-requests/{TOKEN_REQUEST_ID}/internal/status';
export const RESOLVE_TOKEN_REQUEST_PATH = '/token-requests/{TOKEN_REQUEST_ID}';
export const GET_BANK_STATUS =
    'bank-information/banks/{BANK_ID}/outage-status?ProductType={PRODUCT_TYPE}';
export const VRP_REQUEST_PATH = '/vrp-consents/{VRP_ID}';
export const VRP_DECLINE_PATH = '/vrp-consents/{VRP_ID}/decline';
export const FETCH_OVERALL_TPP_CONFIGURATIONS =
    '/members/tpp/{MEMBER_ID}/configurations?realm_id={REALM_ID}&sub_tpp_id={SUB_TPP_ID}';
export const GET_MEMBER_INFO = '/members/{MEMBER_ID}/info';
export const INITIATE_BANK_AUTH_PATH = '/initiate-bank-auth';
export const PDF_VIEWER_PATH = '/pdf-viewer';
export const GET_PDF_PAGE_COUNT_PATH = '/pdf-page-count';

export const REDACTED_VALUE = '<redacted>';
export const SENTRY_DSN =
    'https://b1d54746826043bc8e3defef3f5c6d8c@sentry.io/1213193';
export const DEV_KEY = 'd3b08f1f-83d9-4969-98f0-73c887e95a8d';
export const DEFAULT_DEV_KEY = 'global-test';
export const GEO_LOCATION_RESTRICTION = 'GEO LOCATION RESTRICTION';

// App URLs
export const appUrlIOS =
    'https://itunes.apple.com/us/app/token-open-banking/id1140442537';
export const appUrlAndroid = '#';

// Access token expiry default
export const DEFAULT_ACCESS_TOKEN_EXPIRATION_DAYS = 90;

// Number of decimals for currency
export const CURRENCY_PRECISION = 4;

// Token Types
export const TOKEN_TYPE_TRANSFER = 'TOKEN_TYPE_TRANSFER';
export const TOKEN_TYPE_VRP = 'TOKEN_TYPE_VRP';
export const TOKEN_TYPE_ACCESS = 'TOKEN_TYPE_ACCESS';
export const TOKEN_TYPE_STANDING_ORDER = 'TOKEN_TYPE_STANDING_ORDER';
export const TOKEN_TYPE_BULK_TRANSFER = 'TOKEN_TYPE_BULK_TRANSFER';

// Dialogs
export const ROUTE_NONE = 'ROUTE_NONE';
export const ROUTE_CONFIG = 'ROUTE_CONFIG';
export const ROUTE_LOADING = 'ROUTE_LOADING';
export const ROUTE_ERROR = 'ROUTE_ERROR';
export const ROUTE_AISP = 'ROUTE_AISP';
export const ROUTE_PISP = 'ROUTE_PISP';
export const ROUTE_VRP = 'ROUTE_VRP';
export const ROUTE_BANK_LOADING = 'ROUTE_BANK_LOADING';
export const ROUTE_BANK_REDIRECT = 'ROUTE_BANK_REDIRECT';
export const ROUTE_REDIRECT_TO_TPP = 'ROUTE_REDIRECT_TO_TPP';
export const ROUTE_REDIRECT_OPTIONS = 'ROUTE_REDIRECT_OPTIONS';
export const ROUTE_CSRF_MISMATCH = 'ROUTE_CSRF_MISMATCH';
export const ROUTE_SPLASH = 'ROUTE_SPLASH';

// Token Popup message types
export const POPUP_SUCCESS = 'POPUP_SUCCESS';
export const POPUP_FAILURE = 'POPUP_FAILURE';
export const POPUP_SHOW = 'POPUP_SHOW';
export const POPUP_CLOSE = 'POPUP_CLOSE';

// Web-app iframe messages types
export const WEB_APP_IFRAME_SHOW = 'WEB_APP_IFRAME_SHOW';
export const WEB_APP_IFRAME_SUCCESS = 'WEB_APP_IFRAME_SUCCESS';
export const WEB_APP_IFRAME_FAILURE = 'WEB_APP_IFRAME_FAILURE';
export const WEB_APP_IFRAME_LOADED = 'WEB_APP_IFRAME_LOADED';

// Token bank pop up messages types
export const TOKEN_BANK_POPUP = 'TOKEN_BANK_POPUP';
export const TOKEN_BANK_POPUP_IS_OPEN = 'TOKEN_BANK_POPUP_IS_OPEN';
export const TOKEN_BANK_POPUP_CLOSE = 'TOKEN_BANK_POPUP_CLOSE';

// AISP steps
export const AISP_NONE = '/app/aisp';
export const AISP_CONSENT_BANK_FIRST = '/app/aisp/consent-bank-first';
export const AISP_ENTER_ALIAS = '/app/aisp/enter-alias';
export const AISP_APP_INSTALL = '/app/aisp/app-install';
export const AISP_CONFIRM_FUNDS_CONSENT_BANK_FIRST =
    '/app/aisp/confirm-funds-consent-bank-first';
export const AISP_SELECT_BANK_BANK_FIRST = '/app/aisp/select-bank-first';
export const AISP_CAPTURE_CREDENTIALS = '/app/aisp/capture-credentials';

//Terms and Conditions
export const ROUTE_TERMS_CONDITIONS = 'ROUTE_TERMS_CONDITIONS';

// PISP steps
export const PISP_NONE = '/app/pisp';
export const PISP_BULK_TRANSFER_CONSENT_BANK_FIRST =
    '/app/pisp/bulk-transfer-consent-bank-first';
export const PISP_ENTER_ALIAS = '/app/pisp/enter-alias';
export const PISP_APP_INSTALL = '/app/pisp/app-install';
export const PISP_TRANSFER_OVERVIEW = '/app/pisp/transfer-overview';
export const PISP_CONSENT_BANK_FIRST = '/app/pisp/consent-bank-first';
export const PISP_STANDING_ORDER_CONSENT_BANK_FIRST =
    '/app/pisp/standing-order-consent-bank-first';
export const PISP_SELECT_BANK_BANK_FIRST = '/app/pisp/select-bank-first';
export const PISP_PAYMENT_INIT_STATUS = '/app/pisp/payment-init-status';
export const PISP_CAPTURE_CREDENTIALS = '/app/pisp/capture-credentials';

// VRP steps
export const VRP_CONSENT_BANK_FIRST = '/app/pisp/vrp-consent-bank-first';
export const VRP_SELECT_BANK_FIRST = '/app/vrp/select-bank-first';
export const VRP_CONSENT_INIT_STATUS = '/app/vrp/consent-init-status';

// Shared steps
export const LOADING_STEP = 'loading';
export const SPLASH_STEP = 'splash';
export const TPP_LOADING = 'tpp-loading';
export const APP_APPROVAL = 'app-approval';

export const regexp = {
    CREDITOR_ADDRESS: /(?:transferDestinations\.customerData\.address\.)(.*)/,
    CREDITOR_ADDRESS_FIELD: /(?:transferDestinations\.customerData\.address\.)?(?:.*\.)(.*)/,
    RETURN_REFUND_CLASS: /class="rt-reverse-payments-support"/,
};

// Bank Status
export const BANK_OUTAGE = ['COMPLETED_OUTAGE', 'COMPLETE_OUTAGE'];

export const REGEXP_BLOCK_USER_ON_PATHS = [
    {
        regexp: /^https?:\/\/.+\/app\/(pisp|aisp).*$/,
        match: true, // Block user if Regexp matches
    },
    {
        regexp: /^https?:\/\/.+\/app\/pisp\/confirmation.*$/,
        match: false, // Don't block user if Regexp matches
    },
];

export const REGEXP_EXTRACT_TOKEN_REQUEST_ID = /request-token\/([a-z0-9:]+)/i;

export const REGEXP_DIRECT_CREDENTIALS_FLOW = /^https?:\/\/.+\/app\/auth\/credentials/;

// Popup steps without token logo in header
export const STEPS_WITHOUT_LOGO = [
    AISP_ENTER_ALIAS,
    PISP_ENTER_ALIAS,
    APP_APPROVAL,
];

export const ROUTES_WITHOUT_BACK_BUTTON = [
    ROUTE_TERMS_CONDITIONS,
    ROUTE_BANK_LOADING,
    ROUTE_ERROR,
];

export const STEPS_WITHOUT_BACK_BUTTON = [
    PISP_PAYMENT_INIT_STATUS,
    VRP_CONSENT_INIT_STATUS,
];

// Banks that don't support AISP
// TODO: add backend config
export const AISP_GUEST_UNSUPPORTED = ['tandem'];

// on alias input in PISP, banks that don't want the subtitle displayed
export const BANKS_WITHOUT_SUBTITLE = ['tandem'];

// Languages
export const languages = [
    {
        key: 'English',
        code: 'en',
    },
    {
        key: 'Deutsch',
        code: 'de',
    },
    {
        key: 'Slovenian',
        code: 'si',
    },
    {
        key: 'Portuguese',
        code: 'pt',
    },
    {
        key: 'Italian',
        code: 'it',
    },
    {
        key: 'French',
        code: 'fr',
    },
    {
        key: 'Czech',
        code: 'cs',
    },
    {
        key: 'Polish',
        code: 'pl',
    },
    {
        key: 'Spanish',
        code: 'es',
    },
    {
        key: 'Hungarian',
        code: 'hu',
    },
    {
        key: 'Dutch',
        code: 'nl',
    },
    {
        key: 'Romanian',
        code: 'ro',
    },
];

// BankSelector constants

// Number of banks that are loaded at once
export const BANK_PAGE_SIZE = 25;

export const BANK_CHUNK_SIZE = 100;

// Months
export const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
};

export const TYPE_2_ALIAS = ['iostype2flow.com.noverify'];

export const banksWithBranches = {
    Sparkasse: false,
    Volksbank: false,
};

export const ALIAS_COUNTRY_LIST = {
    uitst: [
        'DE',
        'NO',
        'BE',
        'FI',
        'PT',
        'BG',
        'DK',
        'LT',
        'LU',
        'LV',
        'HR',
        'FR',
        'HU',
        'SE',
        'SK',
        'GB',
        'IE',
        'US',
        'EE',
        'IT',
        'GR',
        'ES',
        'CY',
        'CZ',
        'PL',
        'RO',
        'NL',
    ],
};

export const REGIONS = {
    gb: ['GB', 'US'],
    eu: [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
    ],
};

export const schemes = {
    sepa: ['iban', 'bic'],
    fasterPayments: ['accountNumber', 'sortCode'],
    elixir: ['accountNumber'],
    swift: ['account', 'bic'],
    sepaInstant: ['iban', 'bic'],
    euDomesticNonEuro: ['iban', 'bic'],
};

export const successfulVrpStatusCodes = ['AUTHORIZED'];

export const unsuccessfulVrpStatusCodes = ['REJECTED', 'FAILED', 'REVOKED'];

export const pendingVrpStatusCodes = [
    'PENDING_REDIRECT_AUTH_VERIFICATION',
    'PENDING',
];

export const processedVrpStatusCodes = [
    'AUTHORIZED',
    'REJECTED',
    'FAILED',
    'REVOKED',
];

export const unsuccessfulStatusCodes = [
    'INSUFFICIENT_FUNDS',
    'FAILURE_INSUFFICIENT_FUNDS',
    'FAILURE_INVALID_CURRENCY',
    'FAILURE_INVALID_AMOUNT',
    'TOKEN_ERROR',
    'FAILURE_CANCELED',
    'FAILURE_EXPIRED',
    'FAILURE_DECLINED',
    'FAILURE_GENERIC',
    'FAILURE_PERMISSION_DENIED',
    'INVALID_STATUS',
    'BANK_ERROR',
    'PENDING_EXTERNAL_AUTHORIZATION',
];

export const successfulStatusCodes = ['SUCCESS', 'SENT'];

export const submittedStatusCodes = ['INITIATED', 'PROCESSING'];

export const errorMessageKeys = {
    DEFAULT: 'BANK_ERROR',
    ACCESS_DENIED: 'BANK_ERROR_ACCESS_DENIED',
    PERMISSION_DENIED: 'BANK_ERROR_ACCESS_DENIED',
    FAILURE_GENERIC: 'FAILURE_GENERIC',
};

export const POLISH_API_DELIVER_MODES = {
    STANDARD_D2: 'Standard D2',
    STANDARD_D1: 'Standard D1',
    URGENT_D1: 'Urgent D1',
    EXPRESS_D0: 'Express D0',
};

export const INTERNAL_TRANSFER_STATUS_GROUPS = {
    UNSUCCESSFUL: [
        'FAILURE_INVALID_CURRENCY',
        'PENDING_EXTERNAL_AUTHORIZATION',
        'FAILURE_CANCELLED',
        'FAILURE_EXPIRED',
        'FAILURE_DECLINED',
        'FAILURE_GENERIC',
        'FAILURE_PERMISSION_DENIED',
        'INVALID_STATUS',
    ],
    INSUFFICIENT_FUNDS: ['INSUFFICIENT_FUNDS', 'FAILURE_INSUFFICIENT_FUNDS'],
};

export const VRP_CONSENT_STATUS = {
    PENDING_REDIRECT_AUTH: 'PENDING_REDIRECT_AUTH',
    PENDING_MORE_INFO: 'PENDING_MORE_INFO',
    AUTHORIZED: 'AUTHORIZED',
    EXPIRED: 'EXPIRED',
    UNSUCCESSFUL: 'UNSUCCESSFUL',
    REJECTED: 'REJECTED', // The request has been rejected.
};

export const PAYMENT_TYPE_GET_BANKS_OPTIONS = {
    SUPPORTS_VRP: 'supports_variable_recurring_payment',
    SUPPORTS_AIS: 'supports_information',
    SUPPORTS_SCHEDULED_PAYMENTS: 'supports_scheduled_payment',
    SUPPORTS_SEND_PAYMENT: 'supports_send_payment',
    SUPPORTS_STANDING_ORDER: 'supports_standing_order',
    SUPPORTS_BULK_TRANSFER: 'supports_bulk_transfer',
};

// Bank selector presets
export const BANK_SELECTION_PRESETS = {
    ENABLE_ALL: 'ENABLE_ALL',
    DISABLE_ALL: 'DISABLE_ALL',
    CUSTOM: 'CUSTOM',
};
