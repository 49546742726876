import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {isFunction, isNull, defaultSearch, formatDataList} from '../../Util';
import {withValue} from '../../Hoc.jsx';

class StaticDataProvider extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        source: PropTypes.oneOfType([
            PropTypes.func.isRequired,
            PropTypes.array.isRequired,
        ]),
        idKey: PropTypes.string.isRequired,
        titleKey: PropTypes.string.isRequired,
        dataKey: PropTypes.string.isRequired,
        extraKey: PropTypes.string.isRequired,
        withPointer: PropTypes.bool,
        value: PropTypes.any,
        setValue: PropTypes.func,
        defaultValue: PropTypes.any,
        searchFunc: PropTypes.func,
        customFormat: PropTypes.func,
        multiple: PropTypes.bool,
        useCustomData: PropTypes.bool,
        customData: PropTypes.any,
    }
    static defaultProps = {
        idKey: 'id',
        titleKey: 'title',
        dataKey: 'data',
        extraKey: 'extra',
        withPointer: false,
        searchFunc: defaultSearch,
    }
    constructor() {
        super();
        this.state = {
            data: [],
            selected: null,
            searchTerm: '',
        };
        this.fetchData = this.fetchData.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.getValue = this.getValue.bind(this);
        this.getData = this.getData.bind(this);
    }
    setSearch(term) {
        const {searchFunc} = this.props;
        const {searchTerm} = this.state;
        const data = this.getData();
        if (searchTerm === term) return;
        const {startsWith, includes} = searchFunc(term);
        this.setState({
            searchTerm: term,
        }, () => {
            const startedWith = data.findIndex(startsWith);
            const included = data.findIndex(includes);
            const found = startedWith > 1 ? startedWith : included;
            (found >= 0) && this.setSelected(found, {scroll: true});
        });
    }
    setSelected(selected, {triggerChange, doneCallback} = {}) {
        const data = this.getData();
        const {setValue} = this.props;
        if (selected < 0 || selected >= data.length) return;
        this.setState({
            selected,
        }, () => {
            doneCallback && doneCallback();
        });
        if (triggerChange) {
            const value = !isNull(selected) && data[selected].rawData || null;
            setValue(value);
        }
    }
    fetchData(options = {}) {
        const {source, useCustomData, customData} = this.props;
        const data = useCustomData ? customData
            : isFunction(source) && source(options) || source;
        this.setState({
            data,
        });
    }
    componentDidMount() {
        this.fetchData();
    }
    getValue() {
        const {multiple, value, idKey} = this.props;
        const data = this.getData();
        if (multiple || value && value.rawData || !value || !data.length) {
            return value;
        }
        return data.find(item => item.rawData[idKey] === value[idKey]);
    }
    getData() {
        const {data: stateData} = this.state;
        const {idKey, titleKey, dataKey, extraKey, useCustomData,
            customData, customFormat} = this.props;
        const sourceList = useCustomData ? customData : stateData;
        const {data} = formatDataList(sourceList, {
            idKey, titleKey, dataKey, extraKey,
            customFormat,
        });
        return data;
    }
    render() {
        const {children, withPointer, multiple, idKey} = this.props;
        const {selected, searchTerm} = this.state;
        const data = this.getData();
        const value = this.getValue();
        return (
            <React.Fragment>
                {React.Children.map(children, child => React.cloneElement(child, {
                    fetchData: this.fetchData,
                    setSelected: withPointer ? this.setSelected : null,
                    data,
                    selected: withPointer ? selected : null,
                    value: withPointer ? value : null,
                    withPointer,
                    setSearch: this.setSearch,
                    searchTerm,
                    multiple,
                    idKey,
                }))}
            </React.Fragment>
        );
    }
}

export default withValue(StaticDataProvider);
