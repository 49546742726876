import { combineReducers } from 'redux';
import {
    REDACTED_VALUE,
    TOKEN_TYPE_BULK_TRANSFER,
    TOKEN_TYPE_VRP,
    TOKEN_TYPE_STANDING_ORDER,
    TOKEN_TYPE_TRANSFER,
} from 'config/constants';
import {
    normalizeMoney,
    deepTrim,
    removeEmpty,
    getSupportedPaymentNetworks,
} from 'util/index';
import { createReducer } from '@token-io/lib-web-app';

const transfer = createReducer(
    {},
    {
        SET_TOKEN_TYPE: (state, action) => {
            const amount = normalizeMoney(action.amount);
            const currency = action.currency;
            const destinations = action.destinations;
            const instructions = action.instructions;
            const executionDate = action.executionDate;
            const setTransferDestinationsUrl =
                action.setTransferDestinationsUrl;
            const remittanceReference = action.remittanceReference;
            const confirmFunds = action.confirmFunds;
            const returnRefundAccount = action.returnRefundAccount;
            return {
                ...state,
                amount,
                currency,
                destinations,
                instructions,
                executionDate,
                setTransferDestinationsUrl,
                remittanceReference,
                confirmFunds,
                returnRefundAccount,
            };
        },
        SET_DESTINATION_ACCOUNT: (state, action) => {
            const destinations = action.destinations;
            return { ...state, destinations };
        },
        SET_PAYMENT_ACCOUNT: (state, action) => {
            const sourceAccountId = action.accountId;
            return { ...state, sourceAccountId };
        },
        CLEAR_PAYMENT_ACCOUNT: (state, action) => {
            return { ...state, sourceAccountId: undefined };
        },
        SET_ACCOUNTS: (state, action) => {
            const sourceAccountId =
                state.sourceAccountId ||
                (action.accounts.length && action.accounts[0].id);
            return { ...state, sourceAccountId };
        },
        SET_RECEIPT_EMAIL: (state, action) => {
            const receiptEmail = action.email;
            return { ...state, receiptEmail };
        },
        CLEAR_INSTRUCTIONS_SOURCE: (state, action) => {
            return {
                ...state,
                instructions: {
                    ...state.instructions,
                    source: undefined,
                },
            };
        },
        SET_INSTRUCTIONS_SOURCE_IBAN: (state, action) => {
            const ibanValue = action?.source?.iban?.trim();
            const bicValue = action?.source?.bic?.trim();
            return {
                ...state,
                instructions: {
                    ...state.instructions,
                    source: {
                        ...state.instructions?.source,
                        account: ibanValue
                            ? {
                                iban: {
                                    iban: ibanValue,
                                    bic: bicValue,
                                },
                            }
                            : undefined,
                        accountIdentifier: ibanValue
                            ? {
                                iban: {
                                    iban: ibanValue,
                                },
                            }
                            : undefined,
                        bic: bicValue,
                    },
                },
            };
        },
        SET_MANDATORY_TRANSFER_FIELDS: (state, action) => {
            const debtorLegalNames = deepTrim(
                action?.transfer?.instructions?.source?.customerData
                    ?.legalNames,
            );
            const beneficiaryLegalNames = deepTrim(
                action?.transfer?.instructions?.transferDestinations?.[0]
                    ?.customerData?.legalNames,
            );
            const beneficiaryAddress = deepTrim(
                action?.transfer?.instructions?.transferDestinations?.[0]
                    ?.customerData?.address,
            );
            const debtorBic = action?.transfer?.instructions?.source?.bic?.trim();
            const accountIdentifier =
                action?.transfer?.instructions?.source?.accountIdentifier;
            return removeEmpty({
                ...state,
                instructions: {
                    ...state?.instructions,
                    source: {
                        ...state?.instructions?.source,
                        customerData: {
                            ...state?.instructions?.source?.customerData,
                            legalNames: debtorLegalNames,
                        },
                        bic: debtorBic
                            ? debtorBic
                            : state?.instructions?.source?.bic,
                        ...(accountIdentifier && {
                            accountIdentifier: accountIdentifier
                                ? accountIdentifier
                                : state?.instructions?.source
                                    ?.accountIdentifier,
                        }),
                    },
                    transferDestinations: [
                        {
                            ...state?.instructions?.transferDestinations?.[0],
                            customerData: {
                                ...state?.instructions
                                    ?.transferDestinations?.[0]?.customerData,
                                legalNames: beneficiaryLegalNames,
                                address: beneficiaryAddress,
                            },
                        },
                        ...state?.instructions?.transferDestinations?.slice(1),
                    ],
                },
                destinations: [
                    {
                        ...state?.destinations?.[0],
                        customerData: {
                            ...state?.destinations?.[0]?.customerData,
                            legalNames: beneficiaryLegalNames,
                            address: beneficiaryAddress,
                        },
                    },
                    ...state?.destinations?.slice(1),
                ],
            });
        },
        SET_MANDATORY_STET_TRANSFER_METADATA: (state, action) => {
            const stetTransferMetadata = deepTrim(action?.stetTransferMetadata);
            return {
                ...state,
                instructions: {
                    ...state?.instructions,
                    metadata: {
                        ...state?.instructions?.metadata,
                        providerTransferMetadata: {
                            ...state?.instructions?.metadata
                                ?.providerTransferMetadata,
                            stetTransferMetadata,
                        },
                    },
                },
            };
        },
        SET_MANDATORY_POLISH_API_TRANSFER_METADATA: (state, action) => {
            const polishApiTransferMetadata = deepTrim(
                action?.polishApiTransferMetadata,
            );
            return {
                ...state,
                instructions: {
                    ...state?.instructions,
                    metadata: {
                        ...state?.instructions?.metadata,
                        providerTransferMetadata: {
                            ...state?.instructions?.metadata
                                ?.providerTransferMetadata,
                            polishApiTransferMetadata,
                        },
                    },
                },
            };
        },
    },
);

const access = createReducer(
    { resources: [] },
    {
        SET_TOKEN_TYPE: (state, action) => {
            const resources = action.resources;
            const source = action.source;
            return { ...state, resources, source };
        },
        SET_MANDATORY_ACCESS_FIELDS: (state, action) => {
            const mandatoryFields = action.mandatoryFields;
            return { ...state, source: mandatoryFields?.source };
        },
        CLEAR_INSTRUCTIONS_SOURCE: (state, action) => {
            return { ...state, source: undefined };
        },
    },
);

const standingOrder = createReducer(
    {},
    {
        SET_TOKEN_TYPE: (state, action) => {
            const amount = normalizeMoney(action.amount);
            const currency = action.currency;
            const destinations = action.destinations;
            const instructions = action.instructions;
            const frequency = action.frequency;
            const startDate = action.startDate;
            const endDate = action.endDate;
            const remittanceReference = action.remittanceReference;
            const returnRefundAccount = action.returnRefundAccount;
            return {
                ...state,
                amount,
                currency,
                destinations,
                instructions,
                frequency,
                startDate,
                endDate,
                remittanceReference,
                returnRefundAccount,
            };
        },
        SET_PAYMENT_ACCOUNT: (state, action) => {
            const sourceAccountId = action.accountId;
            return { ...state, sourceAccountId };
        },
        CLEAR_PAYMENT_ACCOUNT: (state, action) => {
            return { ...state, sourceAccountId: undefined };
        },
        SET_ACCOUNTS: (state, action) => {
            const sourceAccountId =
                state.sourceAccountId ||
                (action.accounts.length && action.accounts[0].id);
            return { ...state, sourceAccountId };
        },
        SET_RECEIPT_EMAIL: (state, action) => {
            const receiptEmail = action.email;
            return { ...state, receiptEmail };
        },
        CLEAR_INSTRUCTIONS_SOURCE: (state, action) => {
            return {
                ...state,
                instructions: {
                    ...state.instructions,
                    source: undefined,
                },
            };
        },
        SET_INSTRUCTIONS_SOURCE_IBAN: (state, action) => {
            const ibanValue = action?.source?.iban?.trim();
            const bicValue = action?.source?.bic?.trim();
            return {
                ...state,
                instructions: {
                    ...state.instructions,
                    source: {
                        ...state.instructions?.source,
                        account: ibanValue
                            ? {
                                iban: {
                                    iban: ibanValue,
                                    bic: bicValue,
                                },
                            }
                            : undefined,
                        accountIdentifier: ibanValue
                            ? {
                                iban: {
                                    iban: ibanValue,
                                },
                            }
                            : undefined,
                        bic: bicValue,
                    },
                },
            };
        },
        SET_MANDATORY_STANDING_ORDER_FIELDS: (state, action) => {
            const debtorLegalNames = deepTrim(
                action?.transfer?.instructions?.source?.customerData
                    ?.legalNames,
            );
            const beneficiaryLegalNames = deepTrim(
                action?.transfer?.instructions?.transferDestinations?.[0]
                    ?.customerData?.legalNames,
            );
            const debtorBic = action?.transfer?.instructions?.source?.bic?.trim();
            const beneficiaryAddress = deepTrim(
                action?.transfer?.instructions?.transferDestinations?.[0]
                    ?.customerData?.address,
            );

            return removeEmpty({
                ...state,
                instructions: {
                    ...state?.instructions,
                    source: {
                        ...state?.instructions?.source,
                        customerData: {
                            ...state?.instructions?.source?.customerData,
                            legalNames: debtorLegalNames,
                        },
                        bic: debtorBic
                            ? debtorBic
                            : state?.instructions?.source?.bic,
                    },
                    transferDestinations: [
                        {
                            ...state?.instructions?.transferDestinations?.[0],
                            customerData: {
                                ...state?.instructions
                                    ?.transferDestinations?.[0]?.customerData,
                                legalNames: beneficiaryLegalNames,
                                address: beneficiaryAddress,
                            },
                        },
                        ...state?.instructions?.transferDestinations?.slice(1),
                    ],
                },
                destinations: [
                    {
                        ...state?.destinations?.[0],
                        customerData: {
                            ...state?.destinations?.[0]?.customerData,
                            legalNames: beneficiaryLegalNames,
                            address: beneficiaryAddress,
                        },
                    },
                    ...state?.destinations?.slice(1),
                ],
            });
        },
        SET_MANDATORY_STET_STANDING_ORDER_METADATA: (state, action) => {
            const stetStandingOrderMetadata = deepTrim(
                action?.stetTransferMetadata,
            );
            return {
                ...state,
                instructions: {
                    ...state?.instructions,
                    metadata: {
                        ...state?.instructions?.metadata,
                        providerStandingOrderMetadata: {
                            ...state?.instructions?.metadata
                                ?.providerStandingOrderMetadata,
                            stetStandingOrderMetadata,
                        },
                    },
                },
            };
        },
        SET_MANDATORY_POLISH_API_STANDING_ORDER_METADATA: (state, action) => {
            const polishApiStandingOrderMetaData = deepTrim(
                action?.polishApiTransferMetadata,
            );
            return {
                ...state,
                instructions: {
                    ...state?.instructions,
                    metadata: {
                        ...state?.instructions?.metadata,
                        providerStandingOrderMetadata: {
                            ...state?.instructions?.metadata
                                ?.providerStandingOrderMetadata,
                            polishApiStandingOrderMetaData,
                        },
                    },
                },
            };
        },
    },
);

const bulkTransfer = createReducer(
    {},
    {
        SET_TOKEN_TYPE: (state, action) => {
            const totalAmount = normalizeMoney(action.amount);
            const transfers = action.transfers;
            const source = action.source;
            return {
                ...state,
                totalAmount,
                transfers,
                source,
            };
        },
        SET_PAYMENT_ACCOUNT: (state, action) => {
            const sourceAccountId = action.accountId;
            return { ...state, sourceAccountId };
        },
        CLEAR_PAYMENT_ACCOUNT: (state, action) => {
            return { ...state, sourceAccountId: undefined };
        },
        SET_ACCOUNTS: (state, action) => {
            const sourceAccountId =
                state.sourceAccountId ||
                (action.accounts.length && action.accounts[0].id);
            return { ...state, sourceAccountId };
        },
        SET_RECEIPT_EMAIL: (state, action) => {
            const receiptEmail = action.email;
            return { ...state, receiptEmail };
        },
        CLEAR_INSTRUCTIONS_SOURCE: (state, action) => {
            return {
                ...state,
                instructions: {
                    ...state.instructions,
                    source: undefined,
                },
            };
        },
        SET_INSTRUCTIONS_SOURCE_IBAN: (state, action) => {
            const ibanValue = action.source?.iban?.trim();
            const bicValue =
                action.source?.bic && action.source.bic !== ''
                    ? action.source.bic.trim()
                    : undefined;
            return {
                ...state,
                source: {
                    ...state.source,
                    account: {
                        iban: {
                            iban: ibanValue,
                            bic: bicValue,
                        },
                    },
                    accountIdentifier: {
                        iban: {
                            iban: ibanValue,
                        },
                    },
                },
            };
        },
    },
);

const vrp = createReducer(
    {},
    {
        SET_VRP_LOCAL_INSTRUMENT: (state, action) => {
            const localInstrument = action.localInstrument;
            return { ...state, localInstrument };
        },
    },
);

const token = createReducer(
    {},
    {
        SET_REF_ID: (state, action) => {
            const refId = action.refId;
            return { ...state, refId };
        },
        SET_RESOLVED_PAYLOAD: (state, action) => {
            const resolvedPayload = action.resolvedPayload;
            return { ...state, resolvedPayload };
        },
        SET_BANK_REDIRECT_URL: (state, action) => {
            const bankRedirectUrl = action.bankRedirectUrl;
            return { ...state, bankRedirectUrl };
        },
        SET_TOKEN_ID: (state, action) => {
            const tokenId = action.tokenId;
            return { ...state, tokenId };
        },
        SET_CREDENTIALS: (state, action) => {
            const credentials = action.credentials;
            return { ...state, credentials };
        },
        SET_INIT_BANK_AUTH_STATUS: (state, action) => {
            const status = action.status;
            return { ...state, initiateBankAuthStatus: status };
        },
        SET_TOKEN_TRACE_ID: (state, action) => {
            const traceId = action.traceId;
            return { ...state, traceId };
        },
    },
);

export const reducer = combineReducers({
    token,
    transfer,
    access,
    standingOrder,
    bulkTransfer,
    vrp,
});

export const selector = getState => {
    return {
        getRefId: () => getState().token.refId,
        getTokenId: () => getState().token.tokenId,
        getResolvedPayload: () => getState().token.resolvedPayload,
        getBankRedirectUrl: () => getState().token.bankRedirectUrl,
        getSourceAccountId: () => getState().transfer.sourceAccountId,
        getAmount: () => getState().transfer.amount,
        getCurrency: () => getState().transfer.currency,
        getRemittanceReference: () => getState().transfer.remittanceReference,
        getReturnRefundAccountFlag: () =>
            getState().transfer.returnRefundAccount,
        getDestinations: () => getState().transfer.destinations,
        getTransferTypeDestinations: tokenType => {
            let destinations = [];
            switch (tokenType) {
                case TOKEN_TYPE_TRANSFER:
                    destinations = getSupportedPaymentNetworks(
                        getState().transfer.destinations,
                    );
                    break;
                case TOKEN_TYPE_STANDING_ORDER:
                    destinations = getSupportedPaymentNetworks(
                        getState().standingOrder.destinations,
                    );
                    break;
                case TOKEN_TYPE_BULK_TRANSFER:
                    destinations = getSupportedPaymentNetworks(
                        getState().bulkTransfer.transfers.map(
                            transfer => transfer.destination,
                        ),
                    );
                    break;
                case TOKEN_TYPE_VRP:
                    destinations = [getState().vrp.localInstrument];
                    break;
            }
            return destinations;
        },
        getInstructions: () => getState().transfer.instructions,
        getCafFlag: () => getState().transfer.confirmFunds,
        getDefaultIban: () =>
            getState().transfer.instructions.source.account.iban.iban,
        receiptRequested: () => !!getState().transfer.receiptEmail,
        getReceiptEmail: () => getState().transfer.receiptEmail,
        getExecutionDate: () => getState().transfer.executionDate,
        getSetTransferDestinationsUrl: () =>
            getState().transfer.setTransferDestinationsUrl,
        getResources: () => getState().access.resources,
        getResourceTypeList: () => getState().access,
        getStandingOrderAmount: () => getState().standingOrder.amount,
        getStandingOrderCurrency: () => getState().standingOrder.currency,
        getStandingOrderFrequency: () => getState().standingOrder.frequency,
        getStandingOrderStartDate: () => getState().standingOrder.startDate,
        getStandingOrderEndDate: () => getState().standingOrder.endDate,
        getStandingOrderRemittanceReference: () =>
            getState().standingOrder.remittanceReference,
        getStandingOrderReturnRefundAccountFlag: () =>
            getState().standingOrder.returnRefundAccount,
        getStandingOrderDestinations: () =>
            getState().standingOrder.destinations,
        getStandingOrderInstructions: () =>
            getState().standingOrder.instructions,
        getAccountNumber: () => getState().consent.accountNumber,
        getBulkTransferAmount: () => getState().bulkTransfer.totalAmount,
        getBulkTransferCurrency: () =>
            getState().bulkTransfer.transfers.length > 0 &&
            getState().bulkTransfer.transfers[0].currency,
        getBulkTransferSource: () => getState().bulkTransfer.source,
        getBulkTransfers: () => getState().bulkTransfer.transfers,
        getCredentials: () => getState().token.credentials,
        getBankAuthStatus: () => getState().token.initiateBankAuthStatus,
        getTraceId: () => getState().token.traceId,
    };
};

export const getRedactedState = getState => {
    const state = getState();
    return {
        ...state,
        transfer: {
            ...state.transfer,
            receiptEmail: REDACTED_VALUE,
        },
    };
};

export const name = 'token';
