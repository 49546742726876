import { APP_APPROVAL, LOADING_STEP, SPLASH_STEP } from 'config/constants';
import { addStep, backStep } from '@token-io/lib-web-app';

export const changeRoute = route => ({
    type: 'SET_CURRENT_ROUTE',
    route: route,
});

export const setStep = (nextStep, replace) => (dispatch, { sharedService }) => {
    const currentStep = sharedService.getCurrentStep();
    if (
        nextStep !== currentStep &&
        !(currentStep === SPLASH_STEP && nextStep === LOADING_STEP)
    ) {
        const ignore = nextStep === LOADING_STEP || nextStep === SPLASH_STEP;
        const replaceStepsCurrent = [APP_APPROVAL];
        replace = replace || replaceStepsCurrent.includes(currentStep);
        dispatch(addStep(nextStep, ignore, replace));
    }
};

export const backOrGoTo = (step, replace) => (dispatch, { sharedService }) => {
    if (step === sharedService.getPreviousHistoryStep()) {
        dispatch(backStep());
    } else {
        dispatch(setStep(step, replace));
    }
};

export { backStep };
