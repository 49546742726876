import { combineReducers } from 'redux';
import {
    ROUTE_LOADING,
    ROUTE_ERROR,
    defaultLocale,
    LOADING_STEP,
    TOKEN_TYPE_TRANSFER,
    TOKEN_TYPE_ACCESS,
    TOKEN_TYPE_VRP,
    banksWithBranches as defaultBanklist,
} from 'config/constants';
import { getURLParameter } from 'util/index';
import { createReducer } from '@token-io/lib-web-app';
import {
    TOKEN_TYPE_BULK_TRANSFER,
    TOKEN_TYPE_STANDING_ORDER,
} from '../config/constants';

export const currentLocale =
    getURLParameter('lang') ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    defaultLocale;

export const createRoute = (prev, next) => {
    return {
        previous: prev,
        current: {
            id: next,
        },
    };
};

const tokenType = createReducer(
    {},
    {
        SET_TOKEN_TYPE: (state, action) => {
            return action.tokenType;
        },
    },
);

const locale = createReducer(currentLocale, {
    SET_LOCALE: (state, action) => {
        return action.locale;
    },
});

const route = createReducer(
    { route: createRoute(null, ROUTE_LOADING), steps: [LOADING_STEP] },
    {
        SET_CURRENT_ROUTE: (state, action) => {
            const route = createRoute(state.route.current, action.route);
            return { ...state, route };
        },
        THROW_ERROR: state => {
            const route = createRoute(state.route.current, ROUTE_ERROR);
            return { ...state, route };
        },
        ADD_STEP: (state, action) => {
            state.steps.push(action.step);
            return { ...state };
        },
    },
);

const error = createReducer(
    { isTokenError: true },
    {
        THROW_ERROR: (state, action) => {
            return {
                ...state,
                error: {
                    code: action.error.name,
                    message: action.error.message,
                    intlKey: action.error.intlKey,
                    intlValues: action.error.intlValues,
                    redirectBack: action.error.options?.redirectBack,
                    intlTitleKey: action.error.options?.intlTitleKey,
                },
            };
        },
        SET_ERROR_MESSAGE: (state, action) => {
            const errorMessage = action.message;
            return { ...state, errorMessage };
        },
        CLEAR_ERROR_MESSAGE: state => {
            return { ...state, errorMessage: undefined };
        },
        SET_ERROR_SOURCE: (state, action) => {
            return {
                ...state,
                isTokenError: action.isTokenError,
            };
        },
        TERMINATE_FLOW: (state, action) => {
            return {
                ...state,
                error: {
                    code: action.name,
                    message: action.message,
                },
            };
        },
    },
);

const csrfMismatch = createReducer(
    { redirectUrl: '' },
    {
        SET_BROWSER_REDIRECT_URL: (state, { redirectUrl }) => {
            return { ...state, redirectUrl };
        },
    },
);

const clearSelectedBank = state => {
    return { ...state, selectedBank: undefined, selectedBankInfo: undefined };
};

const banks = createReducer(
    { banks: [], banksWithBranches: defaultBanklist, recentBanks: [] },
    {
        SET_COUNTRIES: (state, action) => {
            return { ...state, countries: action.countries };
        },
        SET_BANKS: (state, action) => {
            return { ...state, banks: action.banks };
        },
        ADD_BANKS: (state, action) => {
            return { ...state, banks: [...state.banks, ...action.banks] };
        },
        CLEAR_BANKS: state => {
            return { ...clearSelectedBank(state), banks: [] };
        },
        SET_SELECTED_BANK: (state, action) => {
            return { ...state, selectedBank: action.bank };
        },
        CLEAR_SELECTED_BANK: state => {
            return clearSelectedBank(state);
        },
        SET_SELECTED_BANK_INFO: (state, action) => {
            return { ...state, selectedBankInfo: action.bankInfo };
        },
        CLEAR_BANK_STATUS: state => {
            return { ...state, bankStatus: undefined };
        },
        SET_BANK_STATUS: (state, action) => {
            return {
                ...state,
                bankStatus: { bank: action.bankName, isLive: !!action.isLive },
            };
        },
        SET_TOP_BANKS: (state, action) => {
            return { ...state, topBanks: action.banks };
        },
        CLEAR_TOP_BANKS: (state, action) => {
            return { ...state, topBanks: undefined };
        },
        SET_RECENT_BANKS: (state, action) => {
            return { ...state, recentBanks: action.banks };
        },
        SET_BANKS_WITH_SUB_BRANCHES: (state, action) => {
            return {
                ...state,
                banksWithBranches: {
                    ...state.banksWithBranches,
                    [action.bankName]: action.status,
                },
            };
        },
        CLEAR_BANKS_WITH_SUB_BRANCHES: state => {
            return {
                ...state,
                banksWithBranches: {
                    ...defaultBanklist,
                },
            };
        },
        SET_SUB_BRANCHES: (state, action) => {
            return {
                ...state,
                banksBranchList: {
                    ...state.banksBranchList,
                    [action.bankName]: action.branchList,
                },
            };
        },
        ADD_SUB_BRANCHES: (state, action) => {
            return {
                ...state,
                banksBranchList: {
                    ...state.banksBranchList,
                    [action.bankName]: {
                        ...state[action.bankName],
                        ...action.branchList,
                    },
                },
            };
        },
        CLEAR_SUB_BRANCHES: (state, action) => {
            return {
                ...state,
                banksBranchList: {
                    ...state.banksBranchList,
                    [action.bankName]: undefined,
                },
            };
        },
    },
);

const isRedirect = createReducer(false, {
    SET_REDIRECT: () => true,
});

const oneStepSupport = createReducer(false, {
    SET_ONE_STEP_SUPPORT: (state, action) => action.oneStepSupport,
});

const defaultCountry = createReducer(null, {
    SET_DEFAULT_COUNTRY: (state, action) => action.defaultCountry.toUpperCase(),
});

const selectedCountry = createReducer(null, {
    SET_SELECTED_COUNTRY: (state, action) => {
        return action.selectedCountry?.toUpperCase() || '';
    },
});

const customDevKey = createReducer(null, {
    SET_CUSTOM_DEV_KEY: (state, action) => action?.devKey?.trim() || '',
});

const fadeOutProp = createReducer(null, {
    SET_DISPLAY_FADE_OUT_ANIMATION: (state, action) =>
        action?.fadeOutProp || false,
});

export const reducer = combineReducers({
    tokenType,
    locale,
    route,
    error,
    csrfMismatch,
    banks,
    isRedirect,
    oneStepSupport,
    defaultCountry,
    selectedCountry,
    customDevKey,
    fadeOutProp,
});

export const selector = getState => {
    return {
        isTransfer: () => getState().tokenType === TOKEN_TYPE_TRANSFER,
        isAisp: () => getState().tokenType === TOKEN_TYPE_ACCESS,
        isVrp: () => getState().tokenType === TOKEN_TYPE_VRP,
        isStandingOrder: () =>
            getState().tokenType === TOKEN_TYPE_STANDING_ORDER,
        isBulkTransfer: () => getState().tokenType === TOKEN_TYPE_BULK_TRANSFER,
        getCountries: () => getState().banks.countries,
        getBanks: () => getState().banks.banks,
        getIsRedirect: () => getState().isRedirect,
        getTokenType: () => getState().tokenType,
        getLocale: () => getState().locale,
        getSelectedBank: () => getState().banks.selectedBank,
        getSelectedBankId: () => getState().banks.selectedBank?.id,
        getSelectedBankName: () => getState().banks.selectedBank?.name,
        getSelectedCountry: () => getState().selectedCountry,
        getSelectedBankInfo: () => getState().banks.selectedBankInfo,
        supportsGuestCheckout: () =>
            getState().banks.selectedBank?.supportsGuestCheckout,
        supportsAisGuestCheckout: () =>
            getState().banks.selectedBank?.supportsAisGuestCheckout,
        getBankStatus: () => getState().banks.bankStatus,
        getRoute: () => getState().route.route,
        getCurrentRoute: () => getState().route.route.current.id,
        getSteps: () => getState().route.steps,
        getPreviousStep: () => getState().route.steps.slice(-2)[0],
        getCurrentStep: () => getState().route.steps.slice(-1)[0],
        getPreviousHistoryStep: () =>
            getState()
                .route.steps.filter(step => step !== LOADING_STEP)
                .slice(-2)[0],
        getCurrentHistoryStep: () =>
            getState()
                .route.steps.filter(step => step !== LOADING_STEP)
                .slice(-1)[0],
        getError: () => getState().error.error, // Fatal error, displays error page
        getErrorMessage: () => getState().error.errorMessage, // Non-fatal error, displays a message on component
        getBrowserRedirectUrl: () => getState().csrfMismatch.redirectUrl,
        getDoesBankSupportOneStep: () => getState().oneStepSupport,
        getTopBanks: () => getState().banks.topBanks,
        getRecentBanks: () => getState().banks.recentBanks,
        getDefaultCountry: () => getState().defaultCountry,
        getCustomDevKey: () => getState().customDevKey,
        getFadeOutProp: () => getState().fadeOutProp,
        getBanksWithBranches: () => getState().banks.banksWithBranches,
        getBanksBranchList: () => getState().banks.banksBranchList,
        getErrorSource: () => getState().error.isTokenError,
    };
};

export const getRedactedState = getState => {
    const state = getState();
    // No redacted state here for now
    return {
        ...state,
    };
};

export const name = 'shared';
