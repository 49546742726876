import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {compose} from '../../Util';
import {withAutoFocus, withStatic} from '../../Hoc.jsx';
import style from '../../../css/Button.css';

const cx = classNames.bind(style);

const TYPE_PRIMARY = 'primary';
const TYPE_SECONDARY = 'secondary';
const TYPE_DELETE = 'delete';
const TYPE_GHOST = 'ghost';
const TYPE_GHOST_WARNING = 'ghost-warning';
const TYPE_SUCCESS = 'success';

const STATUS_IDLE = 'idle';
const STATUS_DISABLED = 'disabled';
const STATUS_LOADING = 'loading';

const WITH_FORWARD = 'forward';
const WITH_ICON = 'icon';

const Button = React.forwardRef(({
    type,
    status,
    loadingText,
    withForward,
    icon,
    onClick,
    text,
    className,
    showFocusOutline,
    tabStatus,
}, ref) => {
    const isTabFoucused = tabStatus?.tabFocused && (tabStatus?.type === type);
    return (
        <div className={cx({
            'Button-container': true,
            'Button-container--tabFocussed': !!isTabFoucused,
        })}>
            <button
                className={cx({
                    'Button': true,
                    [`Button--${type}--tabFocussed`]: !!isTabFoucused,
                    [`Button--${type}--${status}`]: true,
                    'Button--with-forward': withForward,
                    'Button--with-icon': !!icon,
                    [className]: !!className,
                })}
                onClick={() => {
                    showFocusOutline(type, false);
                    if (status === STATUS_IDLE) {
                        onClick();
                    }
                }}
                onBlur={e => {
                    showFocusOutline(type, false);
                }}
                onKeyUp={e => {
                    if (e.key === 'Tab') showFocusOutline(type, true);
                }}
                onKeyDown={e => {
                    if (e.key === 'Tab') showFocusOutline(type, false);
                }}
                ref={ref}>
                <span className={cx('label')}>
                    {status === STATUS_LOADING && loadingText || text || 'Continue'}
                </span>
            </button>
        </div>
    );
});

Button.displayName = 'Button';

Button.propTypes = {
    type: PropTypes.oneOf([
        TYPE_PRIMARY,
        TYPE_SECONDARY,
        TYPE_DELETE,
        TYPE_SUCCESS,
        TYPE_GHOST,
        TYPE_GHOST_WARNING,
    ]),
    status: PropTypes.oneOf([
        STATUS_IDLE,
        STATUS_DISABLED,
        STATUS_LOADING,
    ]),
    loadingText: PropTypes.node,
    withForward: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    showFocusOutline: PropTypes.func,
    tabStatus: PropTypes.any,
    text: PropTypes.node,
};

Button.defaultProps = {
    type: TYPE_PRIMARY,
    status: STATUS_IDLE,
    loadingText: 'Please wait...',
    withForward: false,
    icon: '',
    onClick: () => {},
    showFocusOutline: () => {},
    autoFocus: false,
};

export default compose(
    withAutoFocus,
    withStatic({
        TYPE_PRIMARY, TYPE_SECONDARY, TYPE_DELETE, TYPE_SUCCESS,
        TYPE_GHOST, STATUS_IDLE, STATUS_DISABLED, STATUS_LOADING,
        WITH_FORWARD, WITH_ICON, TYPE_GHOST_WARNING
    }),
)(Button);
